import { IForemanSelector } from "./types";
import { DateInput } from '../../../../components/DateInput/DateInput'; 

const ForemanSelector = (props: IForemanSelector) => {
	const { employees, values, valid, onChange } = props;
    return ( 
        <fieldset className="modal__content--jobplan">
			<label>Прораб</label>
			<select style={{color: 'var(--input-text-normal)'}} 
					name="foremanId" 
					value={values.foremanId} 
					onChange={onChange}>
				<option hidden value = ""> -- Выберите прораба -- </option>		
				{employees?.map((item: any) =>
					<option
					    key={item?.contractorEmployeeId}
						value={item?.contractorEmployeeId}
					>
					{item?.lastName} {item?.firstName} {item?.secondName}
					</option>)
				}
			</select>
			<label>Плановые сроки<br />выполнения работ<br />по Смете</label>
			<label className="symbol__colon--after">Начало</label>
			<DateInput valid={valid} onChange={onChange} value={values.workStartPlan} field={"workStartPlan"} />
			<label className="symbol__colon--after">Окончание</label>
			<DateInput valid={valid} onChange={onChange} value={values.workEndPlan} field={"workEndPlan"} />
		</fieldset>
    )
}

export default ForemanSelector;