import { useMemo } from 'react'
import styles from './StatusBar.module.scss'
import { useSelector } from 'react-redux'
import { type IJobPlanChainOrder } from '../../../../types/JobPlan'
import { getDaysDiff, parseDateString } from '../../../../utils/estimateUtils'
import { formatPrice } from '../../../../utils/formatPrice'
import { type IStatus } from '../../../../types/Status'
import { orderCodes } from '../../../../utils/statusCodes'

const StatusBar = (): JSX.Element => {
  const order = useSelector<any>(state => state.order.order) as IJobPlanChainOrder
  const orderStatuses = useSelector<any>(state => state.order.orderStatuses) as IStatus[]
  const statusText = useMemo(() => {
    if (orderStatuses.length === 0) {
      return ''
    }
    const statusData = orderStatuses.filter(item => item.currentStateCode === order.status)?.[0]
    if (statusData !== undefined) {
      return statusData.currentStateText
    }
  }, [order.status, orderStatuses])

  return (
    <div className={styles.statusBar}>
      <div>
        <span className={`limit__text--length ${styles.limitTitle}`}>{order.chainName}</span>
        <br/>
        <span className={`limit__text--length ${styles.limitTitle}`}>{order.orderName}</span>
      </div>
      <div>
        <span>{order.workStartPlan !== '' ? parseDateString(order.workStartPlan) : '.. ...'}</span>
        <span className="symbol__mdash--before"></span>
        <span>{order.workEndPlan !== '' ? parseDateString(order.workEndPlan) : '.. ...'}</span>
      </div>

      <div>
        <span>Продолжительность</span>
        <span>{getDaysDiff(order.workStartPlan, order.workEndPlan)}</span>
        <span>дн.</span>
      </div>

      <div>
        <span>{formatPrice(order.cost)}</span>
        <span>{order.isPaid ? 'оплачено' : 'не оплачено'}</span>
      </div>

      <div>
        {statusText !== undefined && (
          <span className="text__status--order">{statusText}</span>
        )}
      </div>

      <div>

        {order.brigadeFIO !== ''
          ? (
          <a className="link-light" href="/_" title={`тел: ${order.brigadePhone}`}>{order.brigadeFIO}</a>
            )
          : (
          <>
            {order.status === orderCodes.noBrigade && (
              <span>(бригада не подобрана)</span>
            )}
            {order.status === orderCodes.runReady && (
              <span>(подбор бригады)</span>
            )}
          </>
            )}

      </div>

      {/* '<!-- Простой. -->' */}
      <div title="Всего дней простоя в работе">
        <span>Простой</span>

        {/* '<!-- Если нет простоя, выводится тире. -->' */}
        {/* <!-- <span className="symbol__mdash--before"></span> --> */}
        <span>{order.downTimeDuration}</span>

        <span>дн.</span>
      </div>

    </div>
  )
}

export default StatusBar
