import ObjectPageHead from '../ObjectPageHead/ObjectPageHead'
import { useSelector } from 'react-redux'
import ParentDataBar from './components/ParentDataBar/ParentDataBar'
import StatusBar from './components/StatusBar/StatusBar'
import OrderBox from './components/OrderBox/OrderBox'

const Order = (): JSX.Element => {
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number
  return (
    <div>
      <ObjectPageHead title={'Карточка наряда'} roleCode={userRoleCode}/>
      <ParentDataBar/>
      <StatusBar/>
      <OrderBox/>
    </div>
  )
}

export default Order
