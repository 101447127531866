import { useSelector, useDispatch } from 'react-redux'
import ShortEstimate from '../Components/ShortEstimate'
import { setBuildingItemOpenState } from '../../../redux/buildingList/buildingListActions'
import { CommonRow } from '../Components/CommonRow'
import styles from '../Components/estimate.module.scss'
import { WideEstimate } from '../Components/WideEstimate'
import { estimateCodes } from '../../../utils/statusCodes'
import { type IBuilding } from '../Types'
import { useMemo } from 'react'
import { NotificationSign } from '../../../components/NotificationSign/notificationSign'

interface IObjectRow {
  building: IBuilding
  setModalBuildingId: any
}

const ObjectRow = ({ building, setModalBuildingId }: IObjectRow): JSX.Element => {
  const dispatch = useDispatch<any>()

  const openItems = useSelector((state: any) => state.buildingList.openItems)
  const buildingId = building.building.id
  const isOpen = openItems[buildingId] as boolean

  const setItemState = (id: string, isOpen: boolean): void => {
    dispatch(setBuildingItemOpenState({ [id]: isOpen }))
  }

  const hasSomeJobplan = useMemo(() => {
    return building.estimates.some(estimate => estimate.jobPlanId) ? '' : 'no-jobplan'
  }, [building.estimates])

  const getNotification = useMemo(() => {
    return isOpen 
      ? {hasNotification: false, notificationText: ''} 
      : {hasNotification: building.hasNotification, notificationText: building.notificationText}
  }, [building, isOpen])

  return (
    <div className={`building__box ${isOpen ? 'open' : ''} ${hasSomeJobplan}`}
         onClick={() => {
           setItemState(buildingId, !isOpen)
         }}>
      <NotificationSign
        hasNotification={getNotification.hasNotification}
        notificationText={getNotification.notificationText}
        page='object-list'
      />
      <div className="data-building__area">
         <CommonRow building={building}
                    buildingId={buildingId}
                    isOpen={isOpen}
                    setItemState={setItemState}
                    setModalBuildingId={setModalBuildingId}/>
      </div>
      {isOpen && (
      <div className={styles.estimatesArea}>
          {building.estimates.map((estimate: any) => (
            estimate.estimateStatus >= estimateCodes.jobPlanRequire
              ? <WideEstimate key={estimate.id} estimate={estimate}/>
              : <ShortEstimate key={estimate.id} estimate={estimate} />
          ))}
      </div>)}
    </div>
  )
}

export default ObjectRow
