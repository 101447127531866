import type React from 'react'
import styles from './footer.module.scss'

interface INewFooter {
  hideExitButton?: boolean
}
const NewFooter = ({ hideExitButton = false }: INewFooter): JSX.Element => {
  const logoutHandler = (e: React.MouseEvent): void => {
    localStorage.removeItem('user')
    localStorage.removeItem('tabsCount')
  }
  return (
    <footer
      className={styles.container}
      style={{ display: 'flex', alignItems: 'inherit', height: 'auto' }}
    >
      <div className={`${styles.openFooter}`}
           style={{ display: 'flex', justifyContent: 'space-between', padding: '0 100px', alignItems: 'center' }}>

        <a className="link-light" href="/help" target="_blank" rel="noreferrer" title="Описание интерфейса">Интерфейс</a>

        <a className="link-light" href="//" target="_blank" rel="noreferrer" title="Регламентация работы на сайте">Правила</a>

        <a className="link-light" href="//" target="_blank" rel="noreferrer" title="Политика конфиденциальности">Конфиденциальность</a>

        <a className="link-light" href="//" target="_blank" rel="noreferrer" title="Соглашение на обработку персональных данных">Персональные
          данные</a>

        <a className="link-light" href="//" target="_blank" rel="noreferrer" title="Нормативная техническая документация">Нормативы</a>

        <a className="link-light" href="//" target="_blank" rel="noreferrer" title="Переход на страницу Форума">Форум</a>

        <a className="link-light" href="//" target="_blank" rel="noreferrer" title="Цели и задачи сайта">О сайте</a>

        <a className="link-light" href="//" target="_blank" rel="noreferrer" title="Телефоны, почта и пр.">Контакты</a>

      </div>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 100px' }}
        className={styles.footerStatic}
      >
        <a className="link-light" href="/help" target="_blank" rel="noreferrer" title="Информация по тематическим разделам">Справка</a>

        <address>@Platforma, Inc. 2022</address>
        <a className="link-light" href="/" onClick={logoutHandler} title="Выйти из учётной записи">{hideExitButton ? '' : 'Выход'}</a>
      </div>
    </footer>
  )
}

export { NewFooter }
