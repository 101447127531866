import axios from "axios";
import Config from "../../config";
import { baseNotification } from "../../utils/estimateUtils";

export const SET_ERROR = "SET_ERROR";
export const SET_USER_SUCCESS = "SET_USER_SUCCESS";
export const SET_USER_ROLE_SUCCESS = "SET_USER_ROLE_SUCCESS";

const setError = (payload) => ({
  type: SET_ERROR,
  payload
})

const setUser = (payload) => ({
  type: SET_USER_SUCCESS,
  payload
})

export const getUser = () => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    if(!token){
      dispatch(setError());
      return;
    }
    const result = await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetUserInfo`, {
      headers: {
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(setUser(result.data));
    dispatch(setError(null));

  } catch (e) {
    if(e.request?.status === 402) {
      baseNotification("Срок годности токена истек. Пожалуйста авторизуйтесь повторно", "", "danger");
    }else{
      baseNotification("Внутреняя ошибка", "", "danger");
    }
    dispatch(setError(e));
  }
}

export const userLogin = async(login, password, code = "", tokenLifetime = 0) => {
  const response = await fetch(Config.BACKEND_ADDRESS + "api/auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      login,
      password,
      code,
      tokenLifetime
    }),
  });
  if(response.ok) {
    const result = await response.json();
    result.isRemember = !!tokenLifetime;
    localStorage.setItem("user", JSON.stringify(result));
  }
  return response;
}

export const userRegister = async (phone, login, password, role, code) => {
  const response = await fetch(Config.BACKEND_ADDRESS + 'api/auth/register', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: "include",
    body: JSON.stringify({
      phone,
      login,
      password,
      role,
      code
    })
  })
  return response;
}