import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createComplaint, getComplaintCard, addMessage } from '../../../redux/actions/actionContractor'
import { baseNotification } from '../../../utils/estimateUtils'
import { COMPLAINTCODES } from '../../../utils/getComplaints'
import { ListFilesRev } from '../../EstimateManagement/ListFilesRev'
import { clearEstimateFiles, setAddedFiles } from '../../../redux/estimate/actionEstimate'
import { estimateDocumentCodes } from '../../../utils/statusCodes'

interface ComplaintMessageParams {
  setOpenControlPanelHandler: Function
  createId: string | null
  id: string | null
  complaintTopic: number
}

const ComplaintMessage = (params: ComplaintMessageParams): JSX.Element => {
  const {
    setOpenControlPanelHandler,
    createId,
    id,
    complaintTopic
  } = params

  const [sendButtonEnable, setSendButonEnable] = useState<boolean>(false)
  const [postMessage, setPostMessage] = useState<string>('')
  const { complaintCardObject } = useSelector((state: any) => state.contractorReducer)
  const state = useSelector((state: any) => state.estimateReducer)
  const files = state.estimate.documents
  const filesAdded = state.filesAdded
  const dispatch = useDispatch<any>()
  const history = useHistory()

  useEffect(() => {
    dispatch(clearEstimateFiles())
  }, [dispatch])

  useEffect(() => {
    checkSendButtonEnabled(!!postMessage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complaintTopic, postMessage])

  const checkSendButtonEnabled = (messageTextPresent: boolean) => {
    setSendButonEnable(messageTextPresent && (complaintTopic > 0))
  }

  const messageTextChangeHandle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    checkSendButtonEnabled(!!e.target.value)
    setPostMessage(e.target.value)
  }

  const sendPostMessageHandler = async () => {
    if (createId) {
      const createResult = await dispatch(createComplaint(createId, complaintTopic, postMessage, filesAdded))
      const complaintId = createResult?.id
      if (complaintId) {
        history.push(`/complaint-card?id=${complaintId}`)
      } else {
        baseNotification('Не удалось создать спор', '', 'danger')
      }
    } else {
      const newStatus = complaintCardObject?.status === COMPLAINTCODES.START
        ? COMPLAINTCODES.RESPOND
        : complaintCardObject?.status === COMPLAINTCODES.QUERY
          ? COMPLAINTCODES.PARTYRESPOND
          : complaintCardObject?.status === COMPLAINTCODES.STOP ? COMPLAINTCODES.RESTART : null
      await dispatch(addMessage(id, newStatus, postMessage, filesAdded))
      await dispatch(getComplaintCard(id))
    }
    dispatch(setAddedFiles([]))
  }

  return (
    <div className="manage-complaint__message">
    <div>
        <textarea placeholder="(введите текст сообщения)" onChange={messageTextChangeHandle}></textarea>
        <button
            name="control__manage-add"
            type="button"
            title="Добавление «реплики»"
            disabled={!sendButtonEnable}
            onClick={sendPostMessageHandler}
        >Добавить сообщение и выбранные файлы</button>
        <button
            name="control__manage-close"
            type="button"
            title="Сворачивание сектора без сохранения изменений"
            onClick={() => setOpenControlPanelHandler(false)}
        >Отмена</button>
    </div>
    <div>
        <ListFilesRev files={files}
                      fileButtons={{ chooseFileBtn: true, addFileBtn: true, removeFileBtn: true }}
                      estimate={null}
                      filesRemoved={[]}
                      type={estimateDocumentCodes.complaintDoc}
                      maxHeight={200}/>
    </div>
</div>
  )
}

export default ComplaintMessage
