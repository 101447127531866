import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import { createStoreInstance } from './redux'
import * as Sentry from '@sentry/react'
import Config from './config'

Sentry.init({
  dsn: 'https://25193302334240b28f1c64b0a93031d4@o4505289408774144.ingest.sentry.io/4505289412771840',
  integrations: [
    new Sentry.BrowserTracing({}),
    new Sentry.Replay({
      networkDetailAllowUrls: [RegExp(`${Config.BACKEND_ADDRESS as string}.+`)],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header']
    })
  ],
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
  <Provider store={createStoreInstance()}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
