import styles from './OpenAllButton.module.scss'

interface IOpenAllButton {
  openAll: boolean
  setOpenAll: Function
}

export const OpenAllButton = ({ openAll, setOpenAll }: IOpenAllButton) => {
  return (
        <button className={styles.openAllButton} onClick={() => setOpenAll(!openAll)}>{openAll ? 'Свернуть все' : 'Развернуть все'}</button>
  )
}
