import axios from 'axios';
import { parseInputDate } from '../../utils/estimateUtils';
import Config from '../../config';
import { baseNotification } from '../../utils/estimateUtils';
import {
  GET_ORDERS_LIST_ERROR,
  GET_ORDERS_LIST_START,
  GET_ORDERS_LIST_SUCCESS,
  SET_ORDER_STATUS,
  SET_ORDER_FILTERS
} from './ordersListActionTypes';

const getOrdersListStart = () => ({
  type: GET_ORDERS_LIST_START
});

const getOrdersListSuccess = (payload) => ({
  type: GET_ORDERS_LIST_SUCCESS,
  payload,
});

const getOrdersListError = () => ({
  type: GET_ORDERS_LIST_ERROR
});

const getFiltersData = filters => {
  const filtersData = {
    hasOpenComplaints: filters.hasOpenComplaints
  };

  ['buildingFilter', 'estimateFilter', 'orderFilter', 'brigadeFilter'].forEach(key => {
    if (filters[key]) {
      filtersData[key] = filters[key];
    }
  });

  if (filters.dateFrom && filters.dateTo) {
    filtersData.dateFrom = parseInputDate(filters.dateFrom);
    filtersData.dateTo = parseInputDate(filters.dateTo);
  }

  if (filters.orderStatus !== -1) {
    filtersData.orderStatus = filters.orderStatus;
  }

  if (filters.withDowntimeType !== -1) {
    filtersData.withDowntimeType = filters.withDowntimeType;
  }

  return filtersData;
};

const getCurrentStatuses = (orders) => {
  const currentCodes = {};

  orders.forEach(item => {
    currentCodes[item.status.toString()] = item.status;
  });

  return Object.keys(currentCodes).map(key => currentCodes[key]);
};

export const getOrdersList = (page, filters) => async (dispatch, getState) => {
  dispatch(getOrdersListStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const ordersList = await axios.post(Config.BACKEND_ADDRESS + `Foreman/GetOrders`, {
      meta: {
        page: page || 0,
        count: 10
      },
      ...getFiltersData(filters)
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const orderStatuses = await axios.get(Config.BACKEND_ADDRESS + `Order/GetWorkFlow/List`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const currentStatuses = getState().ordersList.currentStatuses;

    dispatch(getOrdersListSuccess({
      orders: ordersList.data.foremanOrders || [],
      meta: ordersList.data.meta,
      orderStatuses: orderStatuses.data,
      ...(!currentStatuses && {currentStatuses: getCurrentStatuses(ordersList.data.foremanOrders)})
    }));
  } catch (e) {
    if(!token){
      baseNotification("Пожалуйста войдите в свой аккаунт", "", "danger");
    }
    dispatch(getOrdersListError());
  }
};

export const setOrderStatus = (orderId, status) => ({
  type: SET_ORDER_STATUS,
  payload: {
    orderId,
    status
  }
});

export const setOrdersFilters = (filters) => ({
  type: SET_ORDER_FILTERS,
  payload: {
    filters
  }
})

export const updateOrderStatus = (data) => async (dispatch, getState) => {
  const token = JSON.parse(localStorage.getItem("user"));

  return await axios.post(Config.BACKEND_ADDRESS + 'Order/SaveNewStatus', data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    }
  });
};