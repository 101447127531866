import type React from 'react'
import { useState } from 'react'
import eye from '../../assets/eye.svg'
import eyeCrossed from '../../assets/eye-crossed.svg'
import styles from './EyePassword.module.scss'

interface IEyePassword {
  disabled: boolean
  onChange: React.ChangeEventHandler
  className: string
  placeholder?: string
}

export const EyePassword = ({ disabled, onChange, className, placeholder }: IEyePassword) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const eyeClickHandler = () => {
    if (!disabled) {
      setShowPassword(!showPassword)
    }
  }
  return (
        <div className={styles.passEyeContainer}>
          <input placeholder={placeholder ?? '(не указано)'}
            type={showPassword ? 'text' : 'password'}
            disabled = {disabled}
            onChange={onChange}
            className={className}>
          </input>
          <img src={showPassword ? eyeCrossed : eye}
            title="Показать пароль"
            alt="show_password"
            onClick={eyeClickHandler}
            className={disabled ? styles.passEyeDisableImg : styles.passEyeImg}>
          </img>
        </div>
  )
}
