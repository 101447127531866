import { useState } from 'react'
import ObjectRow from './ObjectRow/ObjectRow'
import { useSelector } from 'react-redux'
import { ModalEstimate } from './Modals/ModalEstimate'
import { ROLECODES } from '../../utils/getRoleName'
import { type IBuilding } from './Types'

interface ITable {
  buildings: IBuilding[]
}

const Table = ({ buildings }: ITable): JSX.Element => {
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number
  const [modalBuildingId, setModalBuildingId] = useState('')
  const estimateTypes = useSelector((state: any) => state.contractorReducer.estimateTypes)

  if ((buildings === undefined) || (buildings.length === 0)) {
    return <></>
  }

  return (
    <>
      <ModalEstimate buildingId={modalBuildingId} types={estimateTypes} setModalBuildingId={setModalBuildingId} />
      {buildings.map((building: any) => (
        <ObjectRow key={building.building.id}
                   building={building}
                   setModalBuildingId={userRoleCode === ROLECODES.CONTRACTOR ? setModalBuildingId : undefined} />
      ))}
    </>
  )
}

export default Table
