import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import moment from "moment";
import { useFilterRemoved } from '../../../../hooks/useFilter';
import { useDispatch, useSelector } from 'react-redux';
import { IModalAddBreakdown, TAddBreakdownState, TAddBreakdownValid } from "./types";
import ForemanSelector from "./ForemanSelector";
import FilesSelector from "./FilesSelector";
import PaymentSelector from './PaymentSelector';
import AmountSelector from './AmountSelector';
import ApplyGroup from './ApplyGroup';
import { parseHumanInputDate } from "../../../../utils/estimateUtils";
import { ROLECODES } from '../../../../utils/getRoleName';
import { saveDocumentsAndState } from '../../../../redux/estimate/actionEstimate';
import { estimateDocumentCodes } from '../../../../utils/statusCodes';

const ModalAddBreakdown = ({isOpen, setModal, estimate, filesRemoved}: IModalAddBreakdown) => {
  const initialState: TAddBreakdownState = {
    id: estimate?.info?.id,
    title: estimate?.info?.title,
    foremanId: '',
    typeId: estimate?.info?.typeId,
    workStartPlan: parseHumanInputDate(estimate?.info?.workStartPlan),
    workEndPlan: parseHumanInputDate(estimate?.info?.workEndPlan),
    note: estimate?.info?.estimateNote,
    prepayment: estimate?.info?.prepayment,
    detailing: -1,
    offert: false,
    bankAccountId: ''
  }

  const initialValid: TAddBreakdownValid = {
    foremanId: false,
    bankAccountId: false,
    workStartPlan: false,
    workEndPlan: false,
    detailing: false,
    offert: false,
  }

  const dispatch = useDispatch<any>();
  const [values, setValues] = useState(initialState);
  const [valid, setValid] = useState(initialValid);
  const employees = useSelector((state: any) => state.profile.data?.employees)?.filter
    ((item: any) => item?.firstName?.length > 1 && !item.isBlocked && item.isVerified && item.role === ROLECODES.FOREMAN);
  const bankAccounts = useSelector((state: any) => state.profile.data?.bankAccounts);
  const state = useSelector((state: any) => state.estimateReducer);
  const { filesAdded } = state;
  const docType = estimateDocumentCodes.jobPlanTask;
  const files = useFilterRemoved(estimate.documents, docType, state.filesRemoved);

  useEffect(() => {
    const {workStartPlan, workEndPlan} = values;
    const momentStart = moment(workStartPlan, 'DD.MM.YYYY', true);
    const momentEnd = moment(workEndPlan, 'DD.MM.YYYY', true);
    const isAfter = momentEnd.isSameOrAfter(momentStart);
    if (workEndPlan && workStartPlan){
        setValid({
            ...valid,
            workEndPlan: momentEnd.isValid() && isAfter,
            workStartPlan: momentStart.isValid() && isAfter
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [values.workStartPlan, values.workEndPlan])

  let element = document.getElementById('root');
  if (element !== null && isOpen) {
    element.classList.value = 'role__contractor page__building--list modal__open--jobplan-query';
  }

  const onChange = (e: any) => {
    const {name, value, checked} = e.target as HTMLInputElement;
    switch (name) {
      case 'detailing':
        setValues({
          ...values,
          [name]: +value,
        })
        setValid({
          ...valid,
          [name]: true,
        })
        break;
      case 'workEndPlan':
      case 'workStartPlan':
        setValues({
          ...values,
          [name]: value
        })
        break;
      case 'offert':
        setValues({
          ...values,
          offert: checked,
        })
        setValid({
          ...valid,
          offert: checked,
        })
        break;
      default:
        setValues({
          ...values,
          [name]: value,
        })
        setValid({
          ...valid,
          [name]: true,
        })
    }
  }

  const saveEstimate = () => {
    dispatch(saveDocumentsAndState(filesAdded, values))
    setModal(false);
  };

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div id="jobplan__query">
	    <div className="modal__back"></div>
		  <div className="modal__window jobplan__query" style={{background: 'rgb(234, 232, 230)', height: '705px'}}>
			  <h3 className="modal__window--title">Запрос Разбивки</h3>
			  <div className="modal__window--notice notify">
			   <p>Заполните поля и выберите необходимые опции.</p>
			  </div>
			  <form className="modal__window--content">
          <ForemanSelector employees={employees} values={values} valid={valid} onChange={onChange}/>
          <FilesSelector estimate={estimate} filesRemoved={filesRemoved} docType={docType} files={files}/>
          <hr></hr>
          <PaymentSelector bankAccounts={bankAccounts} values={values} onChange={onChange}/>
          <AmountSelector estimate={estimate} values={values} onChange={onChange}/>
          <hr></hr>
          <ApplyGroup onChange={onChange} saveEstimate={saveEstimate} setModal={setModal} values={values} valid={valid}/>
        </form>  
      </div>
    </div>,
    document.getElementById("root")!
  )
}


export { ModalAddBreakdown };