import { handlePlanDate } from '../../utils/estimateUtils'
import { type StatusPanelParams } from './types'
import { COMPLAINTCODES, COMPLAINTTOPICS } from '../../utils/getComplaints'
import styles from './styles.module.scss'

const StatusPanel = (params: StatusPanelParams): JSX.Element => {
  const {
    complaintTopics,
		 complaintCardObject,
		 roleObject,
		 remark,
		 complaintTopic,
		 setComplaintTopic,
		 openControlPanelButtonEnable,
		 setOpenControlPanelHandler,
		 hasOtherComplaints
  } = params
  const complaintReason = complaintTopics.filter(item => item.code === complaintCardObject.topicId)[0]?.name
  const setComplaintTopicHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setComplaintTopic(parseInt(e.target?.value))
  }
  const complaintTopicValue = remark ? COMPLAINTTOPICS.REMARK : complaintTopic
  const hideDowntime = complaintTopicValue === COMPLAINTTOPICS.BREAK || complaintTopicValue === COMPLAINTTOPICS.FINE
  return (
    <div className="bar__status">
		<div>
      <div className="theme-complaint">{complaintReason}</div>
			<div className="theme-complaint"></div>
			<select name={`themes__${roleObject.roleName}`}
					className={`${styles.complaintSelect} ${complaintTopicValue ? 'selected' : ''}`}
					onChange={setComplaintTopicHandler}
					value = {complaintTopicValue}>
                <option value={0} disabled>(выберите тему спора)</option>
                {complaintTopics.filter(
									item =>
									  item.roleId === roleObject?.roleId && 
										remark === (item.code === COMPLAINTTOPICS.REMARK)
								  ).map(item => 
                    (<option
											  value={item.code}
												key={item.id}
												title={`${hasOtherComplaints && item.code === COMPLAINTTOPICS.STOP ? 'По наряду есть открытый спор по простою' : ''}`}
												disabled={hasOtherComplaints && item.code === COMPLAINTTOPICS.STOP}>
													{item.name}
										</option>)
                  )
								}
			</select>
		</div>
		<div className="text__status--complaint"></div>
		<button
			name="control__manage-complaint"
			type="button"
			title="Выводит сектор управления Спором"
			disabled={!openControlPanelButtonEnable}
			onClick={setOpenControlPanelHandler}
		>Ответить</button>
		<div>
			<span>Период спора</span>
			<span>{handlePlanDate(complaintCardObject.startDate, false)}</span>
			<span className="symbol__mdash--before"></span>
			<span>{handlePlanDate(complaintCardObject.endDate, false)}</span>
		</div>
		{!hideDowntime && 
	    	<>
	        	<div>
	        		<span>Период простоя</span>
	        		<span>{handlePlanDate(complaintCardObject.downtimeStartDate, false)}</span>
	        		<span className="symbol__mdash--before"></span>
	        		<span>{handlePlanDate(complaintCardObject.downtimeEndDate, false)}</span>
	        	</div>
	        	<div title="Продолжительность простоя">
	        		<span>Простой</span>
	        		<span className="symbol__mdash--before"></span>
	        		<span>
	        			<span>{complaintCardObject.downtime}</span> <span>дн.</span>
	        		</span>
	        	</div>
	    	</>
        }
	</div>
  )
}

export default StatusPanel
