import { COMPLAINTCODES, type TCOMPLAINTCODES, getComplaintsTitle } from '../../utils/getComplaints'
import { ROLECODES, getRoleTitle } from '../../utils/getRoleName'
import { type ListCaptionItem, type FilterPanelParams, type TComplaintTopic } from './Types'
import DataRangePicker from '../DateRangePicker/DateRangePicker'
import { type ChangeEventHandler } from 'react'

const TextInput = (captionItem: ListCaptionItem, changeHandler: ChangeEventHandler): JSX.Element => {
  return (<input
	className="live-search"
	key={captionItem.captionId}
	name={`filter__${captionItem.captionId}`}
	type="text"
	placeholder="(начните вводить текст)"
	onChange={changeHandler}/>)
}

const StatusInput = (captionItem: ListCaptionItem, changeHandler: ChangeEventHandler): JSX.Element => {
  const statusItems = Object.keys(COMPLAINTCODES)
  statusItems.splice(statusItems.indexOf('INIT'), 1)
  const statusArr = statusItems.map(status =>
    ({ status, title: getComplaintsTitle(COMPLAINTCODES[status as keyof TCOMPLAINTCODES]) })
  )
  return (
	<select name={`filter__${captionItem.captionId}`}
			onChange={changeHandler}
			className="selected"
			key={captionItem.captionId}>
		<option value="all-statuses" key="all">(все)</option>
		{statusArr.map(statusItem => (
			<option value={COMPLAINTCODES[statusItem.status as keyof TCOMPLAINTCODES]}
					key={statusItem.status}
					className={['ARBITRATE', 'STOP'].includes(statusItem.status) ? 'important--item' : ''}>
					{statusItem.title}
			</option>
		))}
	</select>
  )
}

const ComplaintTopicInput = (captionItem: ListCaptionItem, changeHandler: ChangeEventHandler, complaintTopics: TComplaintTopic[]): JSX.Element => {
  if (!complaintTopics) return (<></>)
  return (
		<select name={`filter__${captionItem.captionId}`}
				onChange={changeHandler}
				key={captionItem.captionId}
				className="selected">
			<option value="all-buildings" key="all">(все)</option>
			{complaintTopics.filter(complaintTopic => complaintTopic.roleId === ROLECODES.FOREMAN).map((complaint: TComplaintTopic) => (
				<option value={complaint.code}
						key={complaint.id}>
						{complaint.name}
				</option>
			))}
		</select>
  )
}

const PeriodInput = (captionItem: ListCaptionItem,
					 dateFrom: Date | undefined,
					 dateTo: Date | undefined,
					 changeHandler: any): JSX.Element => {
  return DataRangePicker({
    className: `date-picker--${captionItem.captionId}`,
  						  startDate: (dateFrom != null) ? dateFrom : null,
						  endDate: (dateTo != null) ? dateTo : null,
						  onChangeDates: changeHandler,
						  index: captionItem.captionId
  })
}

const ResponderInput = (captionItem: ListCaptionItem, changeHandler: ChangeEventHandler): JSX.Element => {
  	return (
  		<select name={`filter__${captionItem.captionId}`}
				onChange={changeHandler}
				key={captionItem.captionId}
				className="selected">
  			<option value="all-statuses">(все)</option>
  			<option value={ROLECODES.FOREMAN}>{getRoleTitle(ROLECODES.FOREMAN)}</option>
  			<option value={ROLECODES.BRIGADIER}>{getRoleTitle(ROLECODES.BRIGADIER)}</option>
		</select>
  )
}

const FilterPanel = (params: FilterPanelParams): JSX.Element => {
  const { listCaptions, changeHandler, complaintTopics, dateFrom, dateTo } = params
  return (
	<div className="bar__filters">
		{listCaptions.map((captionItem: ListCaptionItem) => {
		  switch (captionItem.filtertype) {
		    case 'text': return TextInput(captionItem, changeHandler)
		    case 'status': return StatusInput(captionItem, changeHandler)
		    case 'complaintTopic': return ComplaintTopicInput(captionItem, changeHandler, complaintTopics)
		    case 'period': return PeriodInput(captionItem, dateFrom, dateTo, changeHandler)
		    case 'responder': return ResponderInput(captionItem, changeHandler)
		    default: return (<></>)
		  }
		})}
	</div>
  )
}
export default FilterPanel
