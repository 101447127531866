import {
  GET_BRIGADES_LIST_ERROR,
  GET_BRIGADES_LIST_START,
  GET_BRIGADES_LIST_SUCCESS,
} from './brigadesListActions';

const initialState = {
  brigades: null,
  error: false,
  loading: false,
  /*filters: {
    buildingFilter: '',
    estimateFilter: '',
    orderFilter: '',
    brigadeFilter: '',
    dateFrom: null,
    dateTo: null,
    orderStatus: -1,
    hasOpenComplaints: false,
    withDowntimeType: -1
  },*/
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BRIGADES_LIST_START:
      return {...state, loading: true};

    case GET_BRIGADES_LIST_SUCCESS:
      return {...state, loading: false, brigades: payload};

    case GET_BRIGADES_LIST_ERROR:
      return {...state, loading: false, error: true};

    default:
      return { ...state };
  }
};

export default reducer;