// import React, {useEffect, useState} from 'react';
import style from '../../pages/EstimateManagement/History/history.module.scss'
import { parseDateHistory } from '../../utils/estimateUtils'
import { getRoleTitle } from '../../utils/getRoleName'

const HistoryList = ({ arr }: any) => {
  return (
      <div className={style.openMenu}>
        {arr && arr.map((el: any, index: number) =>
            <div
                style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}
                className={`message__event ${style.blockMessage}`}
                key={index}>
              <div style={{ display: 'flex' }}>
                <div className={style.date}>{parseDateHistory(el?.message?.createdAt)}</div>
                <div className={style.contractor}>{getRoleTitle(el?.message?.authorRole)}</div>
              </div>
              <div style={{ fontSize: '12px' }}>
                {el?.message?.text}
              </div>
            </div>
        )}
      </div>
  )
}

export { HistoryList }
