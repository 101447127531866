import type React from 'react'
import { useState } from 'react'
import styles from './Invoices.module.scss'
import { IUnpaidBill } from '../../../../types/Billing'
import { formatPrice } from '../../../../utils/formatPrice'
import { handleDate } from '../../../../utils/estimateUtils'
import { downloadFileItem } from '../../../../utils/downloadItem'
import { NotificationSign } from '../../../../components/NotificationSign/notificationSign'

interface IInvoices {
  billingData: IUnpaidBill[]
}

const getInvoiceDocument = (e: React.MouseEvent<HTMLElement>): void => {
  e.preventDefault();
  e.stopPropagation();
  const element = e.target as HTMLElement;
  const id = element.getAttribute('href') as string;
  const name = element.innerHTML as string;
  downloadFileItem(id, `${name}.pdf`)
}

function Invoices ({ billingData }: IInvoices) {
  const [selectedItem, setSelectedItem] = useState<string>('')

  const handleSelectItem = (itemId: string) => (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedItem(itemId)
  }

  return (
    <div>
      <div className={styles.invoicesCaptions}>
        <div title="Номер и дата документа">Счёт</div>
        <div title="Дата документа">Дата</div>
        <div>Сумма</div>
        <div title="Документ, на основании которого выставлен Счёт">Основание</div>
      </div>

      {billingData?.map((invoice) => (
        <div
          className={
            `${styles.invoice} 
             ${selectedItem === invoice.entityId ? styles.selected : ''}`
          }
          onClick={handleSelectItem(invoice.entityId)}
          key={invoice.entityId}
        >
          <div className={styles.notificationContainer}>
            <a href={invoice.entityId} onClick={getInvoiceDocument} title="Открыть файл PDF">{invoice.name}</a>
            <NotificationSign
              hasNotification={invoice.hasNotification}
              notificationText={invoice.notificationText}
              page='billing-list'
            />
          </div>
          <div>{handleDate(invoice.date)}</div>
          <div>{formatPrice(invoice.amount)}</div>
          <div>{invoice.comment}</div>
        </div>
      ))}
    </div>
  )
}

export default Invoices
