import type React from 'react'
import { useCallback, useState } from 'react'
import styles from './PlanningListEstimate.module.scss'
import { type IPaymentEstimate } from '../../../../../../types/Billing'
import { formatPrice } from '../../../../../../utils/formatPrice'
import { parseDateString, parseInputDate } from '../../../../../../utils/estimateUtils'
import { useSelector } from 'react-redux'
import { type IStatus } from '../../../../../../types/Status'
import { Link } from 'react-router-dom'

interface IPlanningListEstimate {
  estimate: IPaymentEstimate
}

function PlanningListEstimate ({ estimate }: IPlanningListEstimate): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const statuses = useSelector<any>(state => state.statuses.orderStatuses.data) as IStatus[]

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev)
  }, [])

  return (
    <div className={styles.estimateSection}>
      <div className={`${styles.estimateRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <Link to={`/estimate-management/${estimate.entityId}`} title="Карточка сметы">{estimate.name}</Link>
        </div>
        <div></div>
        <div></div>
        <div>{formatPrice(estimate.toPrepay)}</div>
        <div>{formatPrice(estimate.planTotal)}</div>
      </div>

      {isOpen && (
        <div className={styles.contractList}>
          {estimate.dogovors.map(item => (
            <div className={styles.contractRow} key={item.entityId}>
              <div className="symbol__bull--before"></div>
              <div>
                <Link to={`/order/${item.entityId}`} title="Карточка наряда">{item.name}</Link>
                <span>{item.comment}</span>
              </div>
              <div title={parseInputDate(item.date)}>{parseDateString(item.date)}</div>
              <div>{item.orderStatus}</div>
              <div>{formatPrice(item.toPrepay)}</div>
              <div>{formatPrice(item.planTotal)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default PlanningListEstimate
