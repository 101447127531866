import { IBankAccounts } from "./types";
import style from './modalAddBreakdown.module.scss';

const BankAccounts = ({accounts, withEscrow, values, onChange}: IBankAccounts) => {
    const filteredAccounts = accounts.filter((item: any) => item.escrow === withEscrow && !item.isBlocked);
    return (
      <select name="bankAccountId" 
              onChange={onChange} 
              style={{width: '240px', height: '24px'}} 
              value={values.bankAccountId} 
              className={`${filteredAccounts.length ? style.selected : style.nonSelect}`} disabled={false}>
        {!filteredAccounts.length ? <option>Нет р/счёта. Добавьте р/счёт в Профиле.</option> :
        <>
          <option hidden disabled selected value = {""}> -- Выберите счёт -- </option>
          {
            filteredAccounts.map((item: any) => 
            <option key={item.id} 
                    value={item.id} 
                    selected={false}>{item.bankName} ...{item.paymentAccount.slice(item.paymentAccount.length - 4)}
            </option>)
          }
        </>
        }
      </select>
    )
  }

  export default BankAccounts;