import { useEffect } from "react";
import { IApplyGroup, TAddBreakdownValid } from "./types";
import { useSelector, useDispatch } from "react-redux";
import { getJobplanContractId } from "../../../../redux/estimate/actionEstimate";
import { downloadFileItem } from "../../../../utils/downloadItem";

const ApplyGroup = (props: IApplyGroup) => {
    const contractId = useSelector((state: any) => state.estimateReducer.contractId);
    const dispatch = useDispatch<any>();
    const {onChange, saveEstimate, setModal, values, valid} = props;
    const handleDisabledButton = () => {
        return !Object.keys(valid).every((item) => valid[item as keyof TAddBreakdownValid]);
    }
    useEffect(() => {
        dispatch(getJobplanContractId(values.id));
    }, [values.id, dispatch]);

    return (				
        <fieldset className="modal__content--apply">
        <button
            name="close__apply"
            type="button"
            disabled={handleDisabledButton()}
            onClick={saveEstimate}
        >Запросить Разбивку</button>
        <label>
            Нажимая кнопку «Запросить Разбивку»<br />вы соглашаетесь с условиями
            <span className="link-normal"
                onClick={() => downloadFileItem(contractId?.documentId, "Договор на разбивку.pdf")}
                title="Скачать договор"
            > оферты</span>
        </label>
        <label>
            <input name="offert" 
                   type="checkbox" 
                   checked={values.offert}
                   onChange={onChange} />
            <span>Согласен</span>
        </label>
        <button
            name="close__cancel"
            type="button"
            onClick={() => setModal(false)}
        >Отмена</button>
    </fieldset>
    )
}

export default ApplyGroup;
