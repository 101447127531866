import { EstimatePositionType } from '../../pages/EstimateManagement/Equipment/types'
import style from '../../pages/EstimateManagement/Equipment/equipment.module.scss'

const SystemList = ({ arr }: any) => {
  return (
      <div className={`group__list--system list__childs--highlight ${style.openContainer}`}>

        <div className={`${style.firstHeader} list__row--headers`}>
          <span style={{ width: '50px', paddingLeft: '6px' }} title="Номер позиции по спецификации">№</span>
          <span style={{ width: '464px' }}>Оборудование / Материалы</span>
          <span style={{ width: '70px' }} title="Единица измерения">Ед. изм.</span>
          <span style={{ width: '60px' }} title="Количество штук">Кол-во</span>
          <span style={{ width: '260px' }}>Работа</span>
          <span style={{ width: '80px' }} title="Цена работы">Цена</span>
          <span style={{ width: '80px', display: 'flex', justifyContent: 'end', paddingLeft: '4px' }}
                title="Стоимость работ">Стоимость</span>
        </div>

        {arr.map((el: any, index: number) => {
          const isPriceCostInvisible = (el.typeId === EstimatePositionType.GroupPrice) || (el.typeId === EstimatePositionType.Kit)
          return <div className={`list__row--equip-1 ${style.mapSubitems}`} key={index}>
              <span className={style.mapOneItem} style={{ width: '50px', paddingLeft: '6px' }}>{el.position}</span>
              <span className={style.mapOneItem} style={{ width: '464px' }}>{el.equipmentName}</span>
              <span className={style.mapOneItem} style={{ width: '70px' }}>{el.matUnit}</span>
              <span className={style.mapOneItem} style={{ width: '60px' }}>{el.matCount}</span>
              <span className={style.mapOneItem} style={{ width: '260px' }}>{el.workName}</span>
              <span className={style.mapOneItem} style={{ width: '80px' }}>
                { isPriceCostInvisible ? '' : (el.cost / el.matCount).toFixed(2) }
              </span>
              <span className={style.mapOneItem} style={{ width: '80px' }}>
                { isPriceCostInvisible ? '' : el.cost}
              </span>
            </div>
        }
        )}
      </div>
  )
}

export { SystemList }
