import styles from './notificationSign.module.scss'
interface INotificationSign {
  hasNotification: boolean
  notificationText: string
  page: 'object-list' | 'estimate-list' | 'order-list' | 'complaint-list' | 'billing-list' | 'billing-tab'
}
export const NotificationSign = (props: INotificationSign): JSX.Element => {
  const {hasNotification, notificationText, page} = props
  if (!hasNotification) return <></>
  const notificationStyle =
    page === 'object-list' ? { top: '18px', left: '-10px'} :
    page === 'estimate-list' ||
    page === 'order-list' ||
    page === 'complaint-list' ? { top: '0', left: '-12px'} :
    page === 'billing-list' ? { top: '0', left: '-18px'} :
    page === 'billing-tab'  ? { top: '6px', left: '140px'} : {}
  return <div className={styles.notification} title={notificationText} style={notificationStyle}>!</div>
}
