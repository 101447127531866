import {
  SET_ESTIMATE_SUCCESS,
  SET_ESTIMATE_START,
  SET_MODE_SUCCESS,
  SET_MODAL_CHANGE_REQUISITES_SUCCESS, SET_NEW_FILES_SUCCESS,
  DELETE_ITEM_SUCCESS,
  SET_DESCRIPTION_ITEM_SUCCESS,
  SET_ERROR,
  CHANGE_FILES_TO_CURRENT,
  SET_ADDED_FILES_SUCCESS,
  CLEAR_ESTIMATE_FILES,
  SET_MARK_FILE_REMOVE,
  SET_NEW_MARK_FILE_REMOVE,
  SET_STATUSES_SUCCESS,
  SET_MESSAGE_ITEM_SUCCESS,
  SET_UPLOAD_PROGRESS,
  SET_ADD_MESSAGE_TO_MAIN,
  SET_ESTIMATE_WORK_FLOW,
  SET_JOBPLAN_WORK_FLOW,
  SET_JOBPLAN_CONTRACT_ID,
  SET_DOCUMENT_TYPES,
  SET_ACTIVE_TAB
} from './actionEstimate';

const initialState = {
  modal: false,
  estimate: {
    info: {},
    documents: []
  },
  isLoading: true,
  inProgress: false,
  progressPercents: {},
  changeModal: false,
  filesAdded: [],
  filesRemoved: [],
  statuses: [],
  addMessage: null,
  mode: false,
  modeEstimator: [],
  modeJobplan: [],
  documentTypes: [],
  fileSections: [],
  contractId: null,
  error: null,
  activeTab: 0
}

const getFileSections = (docTypes) => {
  const sectionsObj = docTypes.reduce((acc, docType) => {
    if(!docType.sectionName) return acc;
    if(!acc[docType.sectionName]) {
      return {...acc, [docType.sectionName]: {
        docCodes: [docType.code],
        status: docType.status,
        sectionOrder: docType.sectionOrder,
      }}
    }
    acc[docType.sectionName].docCodes.push(docType.code)
    return acc
  }, {})
  return Object.entries(sectionsObj).map(([key, value]) => ({
    ...value, name: key
  })).sort((a, b) => a - b)
}

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_ESTIMATE_START:
      return {...state, isLoading: true, error: null}
    case SET_MODAL_CHANGE_REQUISITES_SUCCESS:
      return {...state, changeModal: payload}
    case SET_MODE_SUCCESS:
      return {...state, mode: payload}
    case SET_ESTIMATE_WORK_FLOW:
      return {...state, modeEstimator: payload}
    case SET_JOBPLAN_WORK_FLOW:
      return {...state, modeJobplan: payload}
    case SET_MESSAGE_ITEM_SUCCESS:
      return {...state, addMessage: payload}
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        inProgress: payload.inProgress,
        progressPercents: payload.inProgress ? {...state.progressPercents, [payload.index]: payload.percentCompleted} : {}
      }
    case SET_STATUSES_SUCCESS: {
      return {
        ...state, statuses: payload,
        estimate:
            {
              ...state.estimate,
              info: {...state.estimate.info, estimateStatus: payload.newState}
            }
      }
    }
    case SET_ERROR:
      return {...state, error: payload}
    case SET_DESCRIPTION_ITEM_SUCCESS:
      return {
        ...state,
        estimate: {
          ...state.estimate,
          documents: state.estimate.documents.map((el) => {
            if (el.document.id === payload.id) {
              el.document.description = payload.description
            }
            return el
          })
        }
      }
    case SET_ADD_MESSAGE_TO_MAIN:
      return {
        ...state,
        estimate: {...state.estimate, info: {...state.estimate.info, lastMessages: [payload, ...state.estimate.info.lastMessages]}}
      }
    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        filesAdded: state.filesAdded.filter((el) => el.id !== payload),
        estimate: {...state.estimate, documents: state.estimate.documents.filter((el) => el.document.id !== payload)}
      }
    case CHANGE_FILES_TO_CURRENT:
      return {
        ...state,
        estimate: {
          ...state.estimate, documents: state.estimate.documents.map((el) => {
            if (el.document.added) {
              if (payload.find((item) => item.document.originalFileName === el.document.originalFileName)) {
                el = payload.find((item) => item.document.originalFileName === el.document.originalFileName)
              }
            }
            return el
          })
        }
      }
    case SET_ADDED_FILES_SUCCESS:
      return {
        ...state,
        filesAdded: payload
      }
    case CLEAR_ESTIMATE_FILES:
      return {
        ...state,
        estimate: {...state.estimate, documents: []}
      }
    case SET_MARK_FILE_REMOVE:
      return {
        ...state,
        filesRemoved: payload
      }
    case SET_NEW_MARK_FILE_REMOVE:
      return {
        ...state,
        filesRemoved: [...state.filesRemoved, payload],
      }
    case SET_NEW_FILES_SUCCESS:
      return {
        ...state,
        filesAdded: [...state.filesAdded, ...payload],
        estimate: {...state.estimate, documents: [...state.estimate.documents, ...payload.map(item => ({document: item}))]}
      }
    case SET_ESTIMATE_SUCCESS:
      return {...state, estimate: payload, isLoading: false}
    case SET_JOBPLAN_CONTRACT_ID:
      return {...state, contractId: payload}
    case SET_DOCUMENT_TYPES:
      return {
        ...state, 
        documentTypes: payload,
        fileSections: getFileSections(payload)
      }
    case SET_ACTIVE_TAB:
      return {...state, activeTab: payload}
    default:
      return {...state};
  }
};

export default reducer;