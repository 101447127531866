import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { NewFooter } from "../../components/footer/NewFooter";
import ObjectPageHead from '../../components/ObjectPageHead/ObjectPageHead';
import BuildingInfoBar from "../../components/BuildingCard/BuildingInfoBar/BuildingInfoBar";
import BuildingStatusBar from "../../components/BuildingCard/BuildingStatusBar/BuildingStatusBar";
import BuildingData from "../../components/BuildingCard/BuildingData/BuildingData";
import { getBuilding, getEstimateTypes } from '../../redux/actions/actionContractor';
import { ROLECODES } from "../../utils/getRoleName";
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';

const BuildingsListPage = () => {
    const { id } = useParams<any>();
    const dispatch = useDispatch<any>();
    
    useEffect(() => {
        if(id) {
            dispatch(getBuilding(id));
        }
    },[id, dispatch]);

    useEffect(() => {
        dispatch(getEstimateTypes());
    }, [dispatch]);

    const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number;
    const {error} = useSelector((state: any) => state.contractorReducer);
    if(error){
        return <ErrorPanel errorMsg="Не удалось загрузить карточку объекта" marginTop="100px"/>
    }
    return (
        <>
            <main>
                <ObjectPageHead title="Карточка объекта" roleCode={userRoleCode}/>
                <BuildingInfoBar/>
                {(userRoleCode === ROLECODES.CONTRACTOR) && <BuildingStatusBar/>}
                <BuildingData/>
            </main>
            <NewFooter />
        </>
    );
}

export default BuildingsListPage;