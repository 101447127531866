import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { parseHumanInputDate } from '../../../utils/estimateUtils'
import { setBuildingListScrollY } from '../../../redux/buildingList/buildingListActions'

interface ICommonRow {
  building: any
  buildingId: string
  isOpen: boolean
  setItemState: Function
  setModalBuildingId?: Function
}

export const CommonRow = ({ building, buildingId, isOpen, setItemState, setModalBuildingId }: ICommonRow) => {
  const dispatch = useDispatch<any>()
  const history = useHistory()
  const gotoBuildingCardHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(setBuildingListScrollY(window.scrollY))
    const buildingRowElement = event.target as HTMLElement
    history.push(buildingRowElement.getAttribute('href') as string)
  }

  return (
        <>
            <span
                className="circle-triangle"
                title="Развернуть / Свернуть"
                onClick={(e) => {
                  e.stopPropagation()
                  setItemState(buildingId, !isOpen)
                }}
            />

            <div className="limit__text--length">
                <a href={`/building-card/${building.building.id}`}
                title={`Карточка объекта (переход): ${building.building.buildingName}`}
                onClick={gotoBuildingCardHandler}>
                {building.building.buildingName}
                </a>
            </div>

            <div className="limit__text--length">{building.building.buildingAddress}</div>

            <div>
                <span title="Старт самой ранней Разбивки">{building.veryFirstJobplan ? parseHumanInputDate(building.veryFirstJobplan) : '...'}</span>
                <span className="symbol__mdash--before"></span>
                <span title="Окончание самой поздней Разбивки">{building.veryLastJobplan ? parseHumanInputDate(building.veryLastJobplan) : '...'}</span>
            </div>

            <div>
                <span
                className="symbol__colon--after"
                title="Общая стоимость всех Разбивок"
                >
                Всего
                </span>
                <span>{building.signedEstimatesCost}</span>
            </div>

            <div>
                <span
                className="symbol__colon--after"
                title="«Всего» минус «В работе»"
                >
                Остаток
                </span>
                <span>{(building.signedEstimatesCost - building.prepaymentCost)}</span>
            </div>

            <div>
                <span
                className="symbol__colon--after"
                title="Cумма авансов по всем договорам (Нарядам) СМР"
                >
                В работе
                </span>
                <span>{building.prepaymentCost}</span>
            </div>

            <div>
                <span
                className="symbol__colon--after"
                title="Сумма по всем закрытым договорам (Нарядам) СМР"
                >
                Выполнено
                </span>
                <span>{building.payedCost}</span>
            </div>

            <div>
                <span className="symbol__colon--after" title="Всего / В работе">
                Смет
                </span>
                <span style={{ gridColumn: `${isOpen ? '4/5' : '2/3'}`, justifySelf: `${isOpen ? 'end' : 'center'}` }}>
                <span>{building.estimatesTotal}</span>
                <span className="symbol__slash--before"></span>
                <span>{building.estimatesOnwork}</span>
                </span>
            </div>
            {(setModalBuildingId != null) && <button className="bttn__small control__creating--estimate"
                    onClick={
                        (e) => {
                          e.stopPropagation()
                          setModalBuildingId(buildingId)
                        }
                    } >
                Новая смета
            </button>}
        </>
  )
}
