import { useDispatch, useSelector } from 'react-redux';
import styles from './estimateJob.module.scss';
import FooterList from "../../components/BuildingsList/FooterList";
import { EstimateManagement } from '../EstimateManagement/EstimateManagement';
import JobPlanPage from '../JobPlanPage/JobPlanPage';
import { setActiveTab } from '../../redux/estimate/actionEstimate';
import { estimateTabs } from './consts';

const EstimateJob = () => {
  const state = useSelector((state: any) => state.estimateReducer);
  const activeTab = state.activeTab;
  const dispatch = useDispatch<any>()
  const buildings = useSelector((state: any) => state.contractorReducer);
  const { buildingsObject } = buildings;

  const handleChangeTab = (tab: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setActiveTab(tab));
  };

  const isTabsDisabled = !state.estimate?.info?.jobPlanId;
  return (
      <>
        <main>
          <div className={styles.tabs}>
            {estimateTabs.map((tab, index) => (
              <button
                key={index}
                className={`${styles.tab} ${activeTab === tab.id ? styles.isActive : ''}`}
                onClick={handleChangeTab(tab.id)}
                disabled={tab.id !== 0 && isTabsDisabled}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className={styles.content}>
            {activeTab === estimateTabs[0].id && (
              <EstimateManagement/>
            )}
            {activeTab === estimateTabs[1].id && (
              <JobPlanPage/>
            )}
          </div>
        </main>
        <FooterList buildings={buildingsObject.buildings} />
      </>
  )
}

export {EstimateJob};