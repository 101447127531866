import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import Reducers from "./Reducers";

export const createStoreInstance = (InitialState) => {
  return createStore(
    Reducers,
    InitialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
};

