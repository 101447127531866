import { useEffect } from 'react';
import { NewFooter } from '../../components/footer/NewFooter';
import { useParams } from 'react-router';
import Order from '../../components/Order/Order';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder, clearOrder } from '../../redux/order/orderActions';
import { IOrderState } from '../../redux/order/types';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { getDocumentTypes } from '../../redux/estimate/actionEstimate';

function OrderPage() {
  const { id } = useParams<any>();
  const dispatch = useDispatch<any>();
  const order = useSelector<any>(state => state.order) as IOrderState;

  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
      dispatch(getDocumentTypes());
    }
  }, [id, dispatch]);

  useEffect(() => () => dispatch(clearOrder()), [dispatch]);

  if(!order.loading && (order.error || !order.order || !order.jobPlan)) {
    return (
      <ErrorPanel errorMsg="Не удалось загрузить карточку наряда" marginTop="100px"/>
    );
  }

  return (
    <>
      <main>
        {order.order && order.jobPlan && (
          <Order/>
        )}
      </main>
      <NewFooter/>
    </>
  );
}

export default OrderPage;