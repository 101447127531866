export const ROLECODES = {
  CONTRACTOR:          1,  // Подрядчик
  CONSTRUCTIONMANAGER: 2,  // Начальник строительства
  FOREMAN:             3,  // Прораб
  OPERATOR:            4,  // Оператор платформы
  ARBITRATE:           5,  // Сметчик/арбитр
  BRIGADIER:           6,  // Бригадир
  FITTER:              7   // Монтажник
}

export type RoleName = "contractor" | "foreman" | "brigadier" | "arbitrate" | "operator" | "none";

export const getRoleName = (roleCode: number) : RoleName => {
  switch(roleCode) {
    case ROLECODES.CONTRACTOR: return "contractor";
    case ROLECODES.FOREMAN:    return "foreman";
    case ROLECODES.BRIGADIER:  return "brigadier";
    case ROLECODES.ARBITRATE:  return "arbitrate";
    case ROLECODES.OPERATOR:   return "operator";
    default:                   return "none";
  }
}

export const getRoleTitle = (roleCode: number | string) : string => {
  if (typeof roleCode === 'string') roleCode = parseInt(roleCode);
  switch(roleCode) {
    case ROLECODES.CONTRACTOR: return "Подрядчик";
    case ROLECODES.FOREMAN:    return "Прораб";
    case ROLECODES.BRIGADIER:  return "Бригадир";
    case ROLECODES.ARBITRATE:  return "Сметчик";
    case ROLECODES.OPERATOR:   return "Оператор";
    default:                   return "";
  }
}