import axios from 'axios'
import Config from '../../../../config'
import { Buffer } from 'buffer'

export const getBase64Image = async (id: string) => {
  try {
    const token = JSON.parse(localStorage.getItem('user') as string)
    const result = await axios.get(Config.BACKEND_ADDRESS + `File/GetFileByDocumentId?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token.token}`
      },
      responseType: 'arraybuffer'
    })
    return Buffer.from(result.data, 'binary').toString('base64')
  } catch (e) {
    console.log(e)
    return ''
  }
}