import {EMAIL_REGEXP, 
		PHONE_REGEXP, 
		OKTMP_REGEXP, 
		CORRESPONDENT_ACCOUNT_REGEXP, 
		PAYMENT_ACCOUNT_REGEXP,
		BIK_REGEXP} from "../constants/regexp";
import {IAccountData} from "../components/Profile/components/BankAccounts/components/CreateBankAccount/CreateBankAccount";
 
export const isValidPhone = (phone: string) => {
  return PHONE_REGEXP.test(phone);
};

export const isValidEmail = (email: string) => {
  return EMAIL_REGEXP.test(email);
};

export const isValidOktmo = (oktmo: string) =>{
  return OKTMP_REGEXP.test(oktmo);
};

type Error = {
  code: number,
  message: string,
}

const checkDigit = (test: string, coefficients: number[], retOne: boolean = true) => {
  let n = 0;
  for (let i in coefficients) {
    n += coefficients[i] * parseInt(test[i]);
  }
  if(retOne) return n % 11 % 10;
  return n % 11;
};

export const isValidInn = (inn: string) => {
  let result = false;
  const error: Error = {
    code: 0,
    message: ""
  };
  if (typeof inn !== 'string') {
		inn = '';
	}
	if (!inn.length) {
		error.code = 1;
		error.message = 'ИНН пуст';
	} else if (/[^0-9]/.test(inn)) {
		error.code = 2;
		error.message = 'ИНН может состоять только из цифр';
	} else if (inn.length !== 10) {
		error.code = 3;
		error.message = 'ИНН может состоять только из 10 цифр';
	} else {
		const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
		if (n10 === parseInt(inn[9])) {
			result = true;
		}
		if (!result) {
			error.code = 4;
			error.message = 'Неправильное контрольное число';
		}
	}
	return result;
}

export const isValidKpp = (kpp: string | null) => {
	let result = false;
	const error: Error = {
		code: 0,
		message: "",
	};
	if (typeof kpp !== 'string') {
		kpp = '';
	}
	if (!kpp.length) {
		error.code = 1;
		error.message = 'КПП пуст';
	} else if (kpp.length !== 9) {
		error.code = 2;
		error.message = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)';
	} else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
		error.code = 3;
		error.message = 'Неправильный формат КПП';
	} else {
		result = true;
	}
	return result;
}

export const isValidOkpo = (okpo: string | null) => {
	let result = false;
	const error: Error = {
		code: 0,
		message: "",
	};
	if (typeof okpo !== 'string') {
		okpo = '';
	}
	if (!okpo.length) {
		error.code = 1;
		error.message = 'ОКПО пуст';
	} else if ((okpo.length !== 8) && (okpo.length !== 10)) {
		error.code = 2;
		error.message = 'ОКПО может состоять только из 8 или 10 цифр';
	} else {
		switch (okpo.length) {
			case 8:
				let n8 = checkDigit(okpo, [1, 2, 3, 4, 5, 6, 7], false);
				if(n8 === 10) n8 = checkDigit(okpo, [3, 4, 5, 6, 7, 8, 9]);
				if (n8 === parseInt(okpo[7])) {
					result = true;
				}
				break;
			case 10:
				let n10 = checkDigit(okpo, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], false);
				if(n10 === 10) n10 = checkDigit(okpo, [3, 4, 5, 6, 7, 8, 9, 10, 1]);
				if (n10 === parseInt(okpo[9])) {
					result = true;
				}
				break;
		}
	}
	return result;
}

export const isValidOgrn = (ogrn: string | null) => {
	let result = false;
	const error: Error = {
		code: 0,
		message: "",
	};
	if (typeof ogrn !== 'string') {
		ogrn = '';
	}
	if (!ogrn.length) {
		error.code = 1;
		error.message = 'ОГРН пуст';
	} else if (/[^0-9]/.test(ogrn)) {
		error.code = 2;
		error.message = 'ОГРН может состоять только из цифр';
	} else if (ogrn.length !== 13) {
		error.code = 3;
		error.message = 'ОГРН может состоять только из 13 цифр';
	} else if (ogrn[0] === "0"){
		error.code = 5;
		error.message = 'ОГРН не может начинаться с 0';
	} else {
		let mod = (parseInt(ogrn.slice(0, -1)) % (ogrn.length - 2));
		if(mod < 0) mod += (ogrn.length - 2);
		const n = parseInt(mod.toString().slice(-1));
		if (n === parseInt(ogrn[ogrn.length - 1])) {
			result = true;
		}else{
			error.code = 4;
			error.message = 'Неправильное контрольное число';
		}
	}
	return result;
}

export const isValidBik = (bik: string) => {
	return BIK_REGEXP.test(bik);
}

const getAccountCheckArr = () => {
	const res = [];
	for(let i = 0; i < 23; i++) {
		res.push([7, 1, 3][i % 3]);
	}
	return res;
}

const isAccountValid = (checkValue: string): boolean => {
	const accountCheckArr = getAccountCheckArr();
	let idx = 0;
	const weightSum = checkValue.split("").reduce((acc: number, item: string) => 
		acc + parseInt(item) * accountCheckArr[idx++]
	, 0);
	return weightSum % 10 === 0;
}

export const isValidPaymentAccount = (accountData: IAccountData) => {
	if(!PAYMENT_ACCOUNT_REGEXP.test(accountData.paymentAccount)) return false;
	if(!isValidBik(accountData.bik)) return false;
	const checkValue = `${accountData.bik.slice(6)}${accountData.paymentAccount}`;
	return isAccountValid(checkValue);
}

export const isValidCorrespondentAccount = (accountData: IAccountData) => {
	if(!CORRESPONDENT_ACCOUNT_REGEXP.test(accountData.correspondentAccount)) return false;
	if(!isValidBik(accountData.bik)) return false;
	const checkValue = `0${accountData.bik.slice(4, 6)}${accountData.correspondentAccount}`;
	return isAccountValid(checkValue);
}

export const isLoginWrong = (value: string): boolean => {
	if (value) {
		const re = /^[a-zA-Z0-9]*$/;
		if (re.test(value)) {
			return value.length < 6
		} else {
			return true
		}
	} else {
		return false
	}
}

export const isPasswordWrong = (value: string): boolean => {
	if (value) {
		const re = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
		if (re.test(value)) {
			return value.length < 8
		} else {
			return true
		}
	} else {
		return false
	}
}

