import { SET_ERROR, SET_USER_SUCCESS } from './actionUser';

const initialState = {
  user: null,
  error: null,
}

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_ERROR: {
      return {...state, error: payload}
    }
    case SET_USER_SUCCESS: {
      return {...state, user: payload}
    }
    default:
      return {...state}
  }
}

export default reducer