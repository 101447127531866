import { useEffect, useState } from 'react';
import { NewFooter } from '../../components/footer/NewFooter';
import "../../styles/role_foreman.css";
import { useDispatch, useSelector } from "react-redux";
import { getComplaintTopics, getComplaintList } from "../../redux/actions/actionContractor";
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { ListCaptionItem } from "../../components/ComplainList/Types";
import Caption from "../../components/ComplainList/Caption";
import ListCaption from "../../components/ListCaption/ListCaption";
import FilterPanel from "../../components/ComplainList/FilterPanel";
import ComplaintListPanel from "../../components/ComplainList/ComplaintListPanel";

type Filters = {
  building_address?: string,
  estimate_order?: string,
  theme?: number,
  replica?: string,
  status?: number,
  dateFrom?: Date,
  dateTo?: Date,
  responder?: number,
  [key: string]: any,
}

const textFilterTimeoutDelay = 300;
let textFilterTimeout: any = null;
let prevTextFilter: any = null;
let localFilters: Filters = {};

const ComplaintList = () => {
  const [filters, setFilters] = useState<Filters>({});
  
  const changeHandler = (e: any) => {
    localFilters = {...localFilters};
    if(e.target){   
      const filterField = e.target.name.replace("filter__","");   
      localFilters[filterField] = e.target.value;
      if(e.target.type === "text"){
        if((prevTextFilter === filterField) && textFilterTimeout) clearTimeout(textFilterTimeout);
        prevTextFilter = filterField;
        textFilterTimeout = setTimeout(() => setFilters(localFilters), textFilterTimeoutDelay);
      }else{
        setFilters(localFilters);
      }
    }else{
      const newDateFrom = e[0];
      const newDateTo = e[1];
      localFilters.dateFrom = newDateFrom;
      localFilters.dateTo = newDateTo;   
      setFilters(localFilters);
    }
  }

  const { complaintList, 
          complaintTopics, 
          error} = useSelector((state: any) => state.contractorReducer);
  const listCaptions: ListCaptionItem[] = 
                      [{captions:["Название объекта", "Адрес объекта"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"building_address"},
                       {captions:["Название сметы", "Название наряда"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"estimate_order"},
                       {captions:["Тема спора"], 
                        title:"", 
                        filtertype:"complaintTopic", 
                        captionId:"theme"},
                       {captions:["Претензия (начало)"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"replica"},
                       {captions:["Статус", "Инициатор"], 
                        title:"", 
                        filtertype:"status", 
                        captionId:"status"},
                       {captions:["Ответчик ФИО"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"respondentFio"},
                       {captions:["Период спора", "Период простоя"], 
                        title:"", 
                        filtertype:"period", 
                        captionId:"date"},
                       {captions:["Ответчик", "Время"], 
                        title:"По чьей вине простой / Продолжительность", 
                        filtertype:"responder", 
                        captionId:"responder"}
                      ];
  
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getComplaintTopics());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getComplaintList(filters));
   },[filters, dispatch]);

  if (error) {
    return <ErrorPanel errorMsg="Не удалось загрузить список споров" marginTop="100px"/>
  }

  return (
    <>
      <main>
        <Caption/>
        <ListCaption listCaptions = {listCaptions}/>
        <FilterPanel listCaptions = {listCaptions} 
                     changeHandler = {changeHandler} 
                     complaintTopics = {complaintTopics}
                     dateFrom = {filters.dateFrom}
                     dateTo = {filters.dateTo}/>
        <ComplaintListPanel complaintList = {complaintList} complaintTopics = {complaintTopics}/>
      </main>
      <NewFooter/>
    </>
  )
}     
export {ComplaintList};
