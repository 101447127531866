import { type SyntheticEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { handleDate } from '../../../utils/estimateUtils'
import { EstimateRowInfo } from '../Components/EstimateRowInfo'
import getHtmlParentId from '../../../utils/getHtmlParentId'
import { setBuildingListScrollY } from '../../../redux/buildingList/buildingListActions'
import styles from './estimate.module.scss'
import { type IEstimate } from '../Types'
import { NotificationSign } from '../../../components/NotificationSign/notificationSign'
import { setActiveTab } from '../../../redux/estimate/actionEstimate'
import { estimateTabs } from '../../../pages/estimateJob-page/consts'

interface IWideEstimate {
  estimate: IEstimate
}

export const WideEstimate = ({ estimate }: IWideEstimate): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()

  const goToPage = (event: SyntheticEvent): void => {
    event.stopPropagation()
    event.preventDefault()
    const estimateRowElement = event.target as HTMLElement
    dispatch(setBuildingListScrollY(window.scrollY))
    if (estimateRowElement.tagName === 'A') {
      dispatch(setActiveTab(estimateTabs[1].id))
      history.push(estimateRowElement.getAttribute('href') as string)
      return
    }
    dispatch(setActiveTab(estimateTabs[0].id))
    const id = getHtmlParentId(estimateRowElement)
    if (id !== null) history.push(`/estimate-management/${id}`)
  }

  return (
        <div
            key={estimate.id}
            id={estimate.id}
            onClick={goToPage}
            className={`
            ${styles.estimateRow} 
            status__jobplan--done animate__clickable`}>
            <div className="limit__text--length">
                { estimate.jobPlanId !== ''
                  ? <a href={`/estimate-management/${estimate.id}`} title="Карточка разбивки">
                    {estimate.title}
                </a>
                  : <span title="Карточка сметы">
                    {estimate.title}
                </span>
                }
                <NotificationSign
                  hasNotification={estimate.hasNotification}
                  notificationText={estimate.notificationText}
                  page='estimate-list'
                />
            </div>

            <div className="limit__text--length">
                {estimate.estimateNote}
            </div>

            <div className="limit__text--length" title="Прораб (текущий)">
                {estimate.foremanName}
            </div>

            <div className="limit__text--length" title="Тип Сметы">
                {estimate.type}
            </div>

            <div>
                {estimate.estimateStatusText}
            </div>

            <div>
                {`${handleDate(estimate.workStartPlan)} - ${handleDate(estimate.workEndPlan)}`}
            </div>

            <div>
                <span title="Общая стоимость Разбивки сметы">Всего</span>
                <span>{estimate.signedEstimatesCost}</span>
            </div>

            <div>
                <span title="«Всего» минус «В работе»">Остаток</span>
                <span>{estimate.signedEstimatesCost - estimate.prepaymentCost}</span>
            </div>

            <div>
                <span title="Cумма авансов по договорам (Нарядам) СМР">В работе</span>
                <span>{estimate.prepaymentCost}</span>
            </div>

            <div>
                <span title="Сумма по закрытым договорам (Нарядам) СМР">Выполнено</span>
                <span>{estimate.payedCost}</span>
            </div>

            <div>
                <span>
                    <EstimateRowInfo estimate = {estimate}/>
                </span>
            </div>
        </div>
  )
}
