import { useEffect, useState, useMemo } from 'react';
import "../../styles/role_foreman.css";
import { useDispatch, useSelector } from "react-redux";
import { 
  getComplaintCard, 
  getParentInfoByOrderId, 
  getComplaintTopics,
  clearComplaint
} from "../../redux/actions/actionContractor";
import { useLocation } from "react-router-dom";
import { getRoleName, ROLECODES, RoleName } from "../../utils/getRoleName";
import { getComplaintStatus, COMPLAINTCODES, COMPLAINTTOPICS } from "../../utils/getComplaints";
import { IProfileState } from "../../redux/profile/types";
import { StatusPanelParams, TComplaintCardObject } from '../../components/ComplaintCard/types';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import Caption from "../../components/ComplaintCard/Caption";
import ParentPanel from "../../components/ComplaintCard/ParentPanel";
import StatusPanel from "../../components/ComplaintCard/StatusPanel";
import Parties from "../../components/ComplaintCard/Parties";
import Replicas from "../../components/ComplaintCard/Replicas";
import ManagePanel from "../../components/ComplaintCard/ManagePanel";
import { NewFooter } from "../../components/footer/NewFooter";

const ComplaintCard = () => {
  const [roleName, setRoleName] = useState<RoleName>("none");
  const [rootClass, setRootClass] = useState<string>("");
  const [complaintTopic, setComplaintTopic] = useState<number>(0);
  const [openControlPanel, setOpenControlPanel] = useState<boolean>(false);
  const [canControlOpen, setCanControlOpen] = useState<boolean>(false);
  const [roleObject, setRoleObject] = useState<StatusPanelParams["roleObject"]>({roleId : 0, roleName : "none"})
  const user = useSelector((state: any) => state.userReducer.user);
  const { complaintCardObject, 
          complaintTopics, 
          error } = useSelector((state: any) => state.contractorReducer);
  const profile = useSelector<any>(state => state.profile) as IProfileState;
  const dispatch = useDispatch<any>();
  const id = new URLSearchParams(useLocation().search).get("id");
  const createId = id ? null : new URLSearchParams(useLocation().search).get("createId");
  const remark = id ? null : new URLSearchParams(useLocation().search).get("remark");

  useEffect(() => {
    dispatch(getParentInfoByOrderId(createId, profile));
    dispatch(getComplaintCard(id));
    dispatch(getComplaintTopics());
  }, [createId, id, profile?.data?.responsible, dispatch]);

  useEffect(() => {
    if(complaintCardObject.topicId){
      setComplaintTopic(complaintCardObject.topicId);
    }
    if(remark){
      setComplaintTopic(COMPLAINTTOPICS.REMARK)
    }
  }, [complaintCardObject, remark])

  useEffect(() => {
    setRoleName(getRoleName(user?.role));
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch(clearComplaint())
    }
  }, [])

  const hasOtherComplaints = useMemo(() => {
    return (complaintCardObject as TComplaintCardObject)?.complaints?.length > 0 
    ? (complaintCardObject as TComplaintCardObject).complaints.some(complaint => 
      ![COMPLAINTCODES.END, COMPLAINTCODES.SATISFIED, COMPLAINTCODES.OVERRULE].includes(complaint.status) &&
      complaint.id !== id &&
      complaint.topicId === COMPLAINTTOPICS.STOP
      ) 
    : false
  },[complaintCardObject?.complaints])

  const element = document.getElementById("root");
  if (element !== null) element.classList.value = rootClass;
  useEffect(() => {
    const getRootClassList = (roleName: string, complaintCardObject: any, createId: any, complaintTopic: number): string => {
      const userRole = user?.role || "";
      let res = `page__complaint--card role__${roleName}`;
      res += 
        createId ? " status__complaint--initiate" : " status__complaint--" + getComplaintStatus(complaintCardObject.status);
      if(((complaintCardObject.status === COMPLAINTCODES.START) || (complaintCardObject.status === COMPLAINTCODES.STOP)) && 
         (complaintCardObject.respondentRole === userRole))
        res += " complaint__party--responder";
      if(complaintCardObject.status === COMPLAINTCODES.RESPOND) {
        res += " complaint__party--initiator";
        if(complaintTopic === COMPLAINTTOPICS.BREAK) res += "-break"
      }
      if((complaintCardObject.status === COMPLAINTCODES.ARBITRATE) || (complaintCardObject.status === COMPLAINTCODES.PARTYRESPOND)){
        res += " complaint__party--arbitrator";
        if(complaintTopic === COMPLAINTTOPICS.BREAK) res += "-break"
        if(complaintTopic === COMPLAINTTOPICS.FINE) res += "-fine"
        if(complaintTopic === COMPLAINTTOPICS.REMARK) res += "-remark"
      }
      if((complaintCardObject.status === COMPLAINTCODES.QUERY) || (complaintCardObject.status === COMPLAINTCODES.RESTART)){
        if(userRole === ROLECODES.ARBITRATE) {
          res += " complaint__party--arbitrator";
        }else{
          res += " complaint__party--initiator";
        }
      }
      if((userRole === ROLECODES.ARBITRATE) && (complaintCardObject.status === COMPLAINTCODES.RESTART)) {
        res += " status__complaint--arbitrate-downtime";
      }
      return res;
    }

    const getCanControlOpen = (roleName: string, isCreate: boolean, complaintCardObject: any): boolean => {
      if((roleName === "foreman") && isCreate) return true;
      return complaintCardObject.canAnswer;
    }
  
    setRootClass(getRootClassList(roleName, complaintCardObject, createId, complaintTopic));
    setRoleObject({roleId : user?.role, roleName : roleName});
    setCanControlOpen(getCanControlOpen(roleName, !!createId, complaintCardObject));
  },[roleName, complaintCardObject, createId, user.role, complaintTopic]);

  if (error) {
    return <ErrorPanel errorMsg="Не удалось загрузить карточку спора" marginTop="100px"/>
  }

  if(createId && !((user?.role === ROLECODES.FOREMAN) || (user?.role === ROLECODES.BRIGADIER))){
    return <ErrorPanel errorMsg="Этот пользователь не может создавать спор" marginTop="100px"/>
  }
  
  return (
    <>
      <main>
        <Caption/>
        <ParentPanel   complaintCardObject = {complaintCardObject} contractorInfo = {profile.data}/>
        <StatusPanel   complaintTopics = {complaintTopics} 
                       complaintCardObject = {complaintCardObject}
                       remark = {!!remark}
                       roleObject = {roleObject}
                       complaintTopic={complaintTopic}
                       setComplaintTopic = {setComplaintTopic}
                       openControlPanelButtonEnable = {!openControlPanel && canControlOpen && !createId}
                       setOpenControlPanelHandler = {setOpenControlPanel}
                       hasOtherComplaints = {hasOtherComplaints}
                       />
        <Parties complaintCardObject = {complaintCardObject}/>
        <Replicas complaintCardObject = {complaintCardObject}/>
        {canControlOpen &&
          <ManagePanel openStatus = {(openControlPanel || createId) ? "open" : ""}
                       complaintCardObject = {complaintCardObject}
                       setOpenControlPanelHandler = {setOpenControlPanel}
                       createId = {createId}
                       id = {id}
                       complaintTopic = {complaintTopic}
                       hasOtherComplaints = {hasOtherComplaints}
           />
        }
      </main>
      <NewFooter/>
    </>
  )
}     
export {ComplaintCard};