import type React from 'react'
import { useState } from 'react'
import rateStyles from './HourlyRate.module.scss'
import koefStyles from './IncreaseKoef.module.scss'
import Modal from '../../../../../Modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { type IProfileState } from '../../../../../../redux/profile/types'
import { saveProfile } from '../../../../../../redux/profile/profileActions'
import { baseNotification } from '../../../../../../utils/estimateUtils'
import { smsSend } from '../../../../../../utils/sendSms'

interface IChangeRate {
  onClose: VoidFunction
  dialogType: 'hourlyRate' | 'increaseKoef'
}

const ChangeRate = ({ onClose, dialogType }: IChangeRate): JSX.Element => {
  const [newRate, setNewRate] = useState<string>('')
  const [errRate, setErrRate] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [smsCode, setSmsCode] = useState<string>('')
  const profile = useSelector<any>(state => state.profile) as IProfileState
  const dispatch = useDispatch<any>()
  const styles = dialogType === 'hourlyRate' ? rateStyles : koefStyles

  const handleChangeNewRate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setErrRate(isNaN(parseFloat(e.target.value)))
    setNewRate(e.target.value)
  }

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (saving) {
      return
    }
    setSaving(true)
    const saveInfo = dialogType === 'hourlyRate' ? { hourlyRate: parseInt(newRate) } : { increaseKoef: parseFloat(newRate) }
    dispatch(saveProfile({
      responsible: { ...profile.data?.responsible, smsCode },
      contractor: { ...profile.data?.contractor, ...saveInfo }
    })).then(() => {
      setSaving(false)
      baseNotification('Успешно', 'Значение успешно изменено', 'success')
      onClose()
    }).catch(() => {
      baseNotification('Ошибка', 'Произошла ошибка', 'danger')
      setSaving(false)
    })
  }

  const handleChangeSmsCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSmsCode(e.target.value)
  }

  const askForSmsHandler = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    await smsSend(e, profile.data?.responsible.login)
      .catch(() => {
        baseNotification('Ошибка', 'Произошла ошибка', 'danger')
        setSaving(false)
      })
  }

  const modalTitle = dialogType === 'hourlyRate' ? 'Изменение ставки нормо-часа' : 'Изменение коэф. расчета КП'
  const currentTitle = dialogType === 'hourlyRate' ? 'Текущая ставка' : 'Текущее значение'
  const currentValue = dialogType === 'hourlyRate' ? profile.data?.contractor.hourlyRate : profile.data?.contractor.increaseKoef
  const newTitle = dialogType === 'hourlyRate' ? 'Новая ставка' : 'Новое значение'
  const buttonTitle = dialogType === 'hourlyRate' ? 'Изменить ставку' : 'Изменить коэф.'

  return (
    <Modal title={modalTitle}>
      <div className={styles.rate}>
        <form className={styles.rateContent}>
          {dialogType === 'hourlyRate' &&
          <>
            <label>Рекомендуемая ставка</label>
            <input type="text" placeholder="" value={profile.data?.recomendedHourlyRate} disabled/>
          </>
          }
          <label>{currentTitle}</label>
          <input type="text" placeholder="" value={currentValue} disabled/>

          <label>{newTitle}</label>
          <input
            type="text"
            className={errRate ? 'error__box' : ''}
            placeholder="(введите значение)"
            value={newRate}
            onChange={handleChangeNewRate}
          />

          <p className={styles.rateCodeTitle}>Код подтверждения</p>
          <button name="sms__query"
                  type="button"
                  disabled={errRate || saving}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={askForSmsHandler}>
                  Прислать СМС
          </button>
          <input name="sms__check" type="password" placeholder="(код из СМС)" disabled={errRate} onChange={handleChangeSmsCode}/>
          <hr/>
          <button type="button" disabled={smsCode === '' || saving || errRate} onClick={handleSave}>{buttonTitle}</button>
          <button type="button" onClick={onClose}>Отмена</button>
        </form>
      </div>
    </Modal>
  )
}

export default ChangeRate
