import type React from 'react'
import { useState, useEffect } from 'react'
import { COMPLAINTCODES } from '../../../utils/getComplaints'
interface ComplaintInitiatorParams {
  setOpenControlPanelHandler: Function
  changeComplaintStateHandler: Function
  setDowntimeDateHandler: Function
  downtimeDate: string
}

const ComplaintInitiator = (params: ComplaintInitiatorParams): JSX.Element => {
  const { setOpenControlPanelHandler, setDowntimeDateHandler, changeComplaintStateHandler, downtimeDate } = params
  const [downtimeBtnDisable, setDowntimeBtnDisable] = useState<boolean>(false)
  const downtimeDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value
    setDowntimeBtnDisable(!date)
    setDowntimeDateHandler(date)
  }

  useEffect(() => {
    const nowDate = new Date().toISOString().slice(0, 10)
    setDowntimeDateHandler(nowDate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="manage-complaint__initiator">
        <button
            name="control__complaint--arbitrate"
            type="button"
            title="К решению Спора подключается Арбитр"
            onClick={() => changeComplaintStateHandler(COMPLAINTCODES.ARBITRATE)}
        >Арбитраж</button>
        <div className="block__downtime--date-end">
            <label className="symbol__colon--after">Окончание простоя</label>
            <input
                name="input__downtime--date-end"
                type="date"
                title="Дата окончания простоя"
                value={downtimeDate}
                onChange={downtimeDateHandler}
                />
        </div>
        <button
            name="control__complaint--end"
            type="button"
            title="Закрытие Спора, вопрос решён"
            onClick={() => changeComplaintStateHandler(COMPLAINTCODES.END)}
        >Закрыть спор</button>
        <button
            name="control__complaint--satisfied"
            type="button"
            disabled={downtimeBtnDisable}
            title="Завершение Спора и окончание простоя"
            onClick={() => changeComplaintStateHandler(COMPLAINTCODES.SATISFIED, downtimeDate)}
        >Завершить спор</button>
        <button
            name="control__manage-close"
            type="button"
            title="Сворачивание сектора без сохранения изменений"
            onClick={() => setOpenControlPanelHandler(false)}
        >Отмена</button>
    </div>
  )
}

export default ComplaintInitiator
