import {GET_ORDER_ERROR, GET_ORDER_START, GET_ORDER_SUCCESS, CLEAR_ORDER} from './orderActionTypes';

const initialState = {
  order: null,
  jobPlan: null,
  estimateStatuses: null,
  orderStatuses: null,
  complaintStatuses: null,
  error: false,
  loading: false,
  documentTypes: null,
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDER_START:
      return {...state, loading: true, error: false};

    case GET_ORDER_SUCCESS:
      return {...state, loading: false, error: false, ...payload};

    case GET_ORDER_ERROR:
      return {...state, loading: false, error: true};
    
    case CLEAR_ORDER:
      return {...state, loading: false, error: false, order: null, jobPlan: null}

    default:
      return { ...state };
  }
};

export default reducer;