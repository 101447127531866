import { ChangeEventHandler, MouseEventHandler } from "react"

export interface IModalAddBreakdown {
    isOpen: boolean,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    estimate: any, 
    filesRemoved: string[],
}

export interface IBankAccounts {
    values: TAddBreakdownState,
    accounts: any,
    withEscrow: boolean,
    onChange: any
}

export interface IFilesSelector {
    estimate: any,
    filesRemoved: string[],
    docType: number,
    files: any,
}

export type TAddBreakdownState = {
    id: string,
    title: string,
    foremanId: string,
    workStartPlan: string,
    typeId: number,
    workEndPlan: string,
    note: string,
    prepayment: string,
    detailing: number,
    offert: boolean,
    bankAccountId: string
}

export type TAddBreakdownValid = {
    foremanId: boolean,
    bankAccountId: boolean,
    workStartPlan: boolean,
    workEndPlan: boolean,
    detailing: boolean,
    offert: boolean
}

export interface IForemanSelector {
    employees: any,
    values: TAddBreakdownState,
    valid: TAddBreakdownValid,
    onChange: ChangeEventHandler,
}

export interface IPaymentSelector {
    bankAccounts: any,
    values: TAddBreakdownState,
    onChange: ChangeEventHandler,
}

export enum ECheckType {
    withoutVolume = 0,
    withVolume = 1,
}

export interface IAmountSelector {
    estimate: any,
    values: TAddBreakdownState,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
}

export interface IApplyGroup {
    setModal: Function,
    valid: TAddBreakdownValid,
    values: TAddBreakdownState,
    saveEstimate: MouseEventHandler,
    onChange: ChangeEventHandler,
}