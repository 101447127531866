import axios from 'axios';
import Config from '../../config';
import { baseNotification } from '../../utils/estimateUtils';
import {GET_ORDER_ERROR, GET_ORDER_START, GET_ORDER_SUCCESS, CLEAR_ORDER} from './orderActionTypes';

const getOrderStart = () => ({
  type: GET_ORDER_START
});

const getOrderSuccess = (payload) => ({
  type: GET_ORDER_SUCCESS,
  payload,
});

const getOrderError = () => ({
  type: GET_ORDER_ERROR
});

export const clearOrder = () => ({
  type: CLEAR_ORDER
});

export const getOrder = (id) => async (dispatch) => {
  dispatch(getOrderStart());
    const token = JSON.parse(localStorage.getItem("user"));
    if(!token){
      baseNotification("Пожалуйста войдите в свой аккаунт", "", "danger");
    }
    const requestPromise = (url) => new Promise(async(resolve, reject) => {
      try{    
        const res = await axios.get(`${Config.BACKEND_ADDRESS}${url}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          }
        });
        if(!res || (res.status !== 200)) throw new Error("Wrong response");
        resolve(res);
      }catch(err){
        reject(err);
      }
    });

  try {
    const urls = [`Foreman/GetOrderById?ID=${id}`,
                  `Order/GetWorkFlow/Card`,
                  `Estimate/GetWorkFlow/EstimateCard`,
                  `Complaint/GetWorkFlow/Card`,
                  `Contractor/GetDocumentTypes`];

    const [order, 
           orderStatuses, 
           estimateStatuses, 
           complaintStatuses, 
           documentTypes] = await Promise.all(urls.map(url => requestPromise(url)));

    const jobPlan = await axios.get(Config.BACKEND_ADDRESS + `Foreman/GetJobPlanByEstimateId?ID=${order.data.estimateId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(getOrderSuccess({
      order: order.data,
      jobPlan: jobPlan.data,
      estimateStatuses: estimateStatuses.data,
      orderStatuses: orderStatuses.data,
      documentTypes: documentTypes.data,
      complaintStatuses: complaintStatuses.data,
    }));
  } catch (e) {
    dispatch(getOrderError());
  }
};