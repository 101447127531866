import { useState } from 'react'
import styles from './AccountData.module.scss'
import { useSelector } from 'react-redux'
import { type IProfileState } from '../../../../redux/profile/types'
import ChangePassword from './components/ChangePassword/ChangePassword'
import { type IUser } from '../../../../types/User'
import { getShortFIO } from '../../../../utils/estimateUtils'
import { getProfileStorage } from './ProfileStorage'

const FormanAccountData = (): JSX.Element => {
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false)
  const profile = useSelector<any>(state => state.profile) as IProfileState
  const user = useSelector((state: any) => state.userReducer.user) as IUser

  const renderValue = (value: string | number | undefined): JSX.Element | string | number => {
    if (value !== undefined) {
      return value
    } else {
      return (
        <span className={styles.noValue}>(не указано)</span>
      )
    }
  }

  const handleOpenChangePassword = (): void => {
    setChangePasswordOpen(true)
  }

  const handleCloseChangePassword = (): void => {
    setChangePasswordOpen(false)
  }

  let data = profile.data
  const contractorId = profile.data?.contractor.id ?? ''
  const profileStorage = getProfileStorage()
  if (profileStorage !== null) {
    const parsedData = profileStorage[contractorId]
    if (parsedData?.contractor?.id === profile.data?.contractor.id) {
      data = parsedData
    }
  }

  return (
    <div className={styles.accountData}>
      <div className={styles.col1}>
        <section className={styles.colSection}>
          <h6>Аккаунт</h6>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Логин</span>
            <span>{renderValue(user?.login)}</span>
          </div>
          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Телефон</span>
            <span>{renderValue(user?.phone)}</span>
          </div>
          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">E-mail</span>
            <span>{renderValue(user?.email)}</span>
          </div>
        </section>
      </div>

      <div className={styles.col2}>
        <section className={styles.colSection}>
          <h6>Пользователь</h6>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Фамилия</span>
            <span>{renderValue(user?.lastName)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Имя</span>
            <span>{renderValue(user?.firstName)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Отчество</span>
            <span>{renderValue(user?.secondName)}</span>
          </div>

        </section>
      </div>

      <div className={styles.col3}>
        <section className={styles.colSection}>
          <h6>Подрядчик</h6>

          <div className={`${styles.colSectionItem} ${styles.noMargin}`}>
            <span className="symbol__colon--after">Ответственный</span>
            <span>{renderValue(getShortFIO(data?.responsible))}</span>
          </div>

          <div className={`${styles.colSectionItem} ${styles.noMargin}`}>
            <span className="symbol__colon--after">Телефон</span>
            <span>{renderValue(data?.responsible.phone)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Организация</span>
            <span>{renderValue(data?.contractor.name)}</span>
          </div>

        </section>
      </div>

      <div className={styles.bottom}>
        <button
          className="symbol__hellip--after"
          name="pass__changing"
          type="button"
          title="Открыть окно смены пароля"
          onClick={handleOpenChangePassword}
        >
          Смена пароля
        </button>
      </div>

      {changePasswordOpen && (
        <ChangePassword onClose={handleCloseChangePassword}/>
      )}

    </div>
  )
}

export default FormanAccountData
