import { useState } from 'react'
import { UploadFiles } from './UploadFiles'
import { type IListFiles, type TFilesError } from './types'
import { ModalFileSizeError } from '../ModalFileSizeError/ModalFileSizeError'
import ListFilesTable from './ListFilesTable'

const ListFilesRev = ({ files, fileButtons, estimate, filesRemoved, type, height, maxHeight }: IListFiles) => {
  const [openModalFileSizeError, setOpenModalFileSizeError] = useState<TFilesError>(
    { oneFileSizeError: false, totalFilesSizeError: false, maxSize: 0, currentFilesSize: 0 }
  )
  const firstType = Array.isArray(type) ? type[0] : type
  return (
    <div>
      {fileButtons.addFileBtn && fileButtons.chooseFileBtn &&
        <UploadFiles
            estimate={estimate}
            filesRemoved={filesRemoved}
            type={firstType}
            setOpenModalFileSizeError={setOpenModalFileSizeError}/>
      }
      <ListFilesTable files={files} fileButtons={fileButtons} height={height} maxHeight={maxHeight}/>
      {(openModalFileSizeError.oneFileSizeError || openModalFileSizeError.totalFilesSizeError) &&
        <ModalFileSizeError openModalFileSizeError={openModalFileSizeError}
                            setOpenModalFileSizeError={setOpenModalFileSizeError}/>
      }
    </div>
  )
}

export { ListFilesRev }
