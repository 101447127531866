export const GET_JOB_PLAN_START = 'GET_JOB_PLAN_START';
export const GET_JOB_PLAN_SUCCESS = 'GET_JOB_PLAN_SUCCESS';
export const GET_JOB_PLAN_FAIL = 'GET_JOB_PLAN_FAIL';

export const SET_ORDER_STATUSES = 'SET_ORDER_STATUSES';

export const UPDATE_ENTITY_STATUS_START = 'UPDATE_ENTITY_STATUS_START';
export const UPDATE_ENTITY_STATUS_SUCCESS = 'UPDATE_ENTITY_STATUS_SUCCESS';
export const UPDATE_ENTITY_STATUS_FAIL = 'UPDATE_ENTITY_STATUS_FAIL';

export const UPDATE_ENTITY_COPY_STATUS_START = 'UPDATE_ENTITY_COPY_STATUS_START';
export const UPDATE_ENTITY_COPY_STATUS_SUCCESS = 'UPDATE_ENTITY_COPY_STATUS_SUCCESS';
export const UPDATE_ENTITY_COPY_STATUS_FAIL = 'UPDATE_ENTITY_COPY_STATUS_FAIL';

export const UPDATE_ORDER_DATA = 'UPDATE_ORDER_DATA';
export const UPDATE_ORDER_COPY_DATA = 'UPDATE_ORDER_COPY_DATA';
export const UPDATE_CHAIN_COPY_DATA = 'UPDATE_CHAIN_COPY_DATA';

export const START_EDIT_JOB_PLAN = 'START_EDIT_JOB_PLAN';
export const CANCEL_EDIT_JOB_PLAN = 'CANCEL_EDIT_JOB_PLAN';

export const SAVE_NEW_DATES_START = 'SAVE_NEW_DATES_START';
export const SAVE_NEW_DATES_SUCCESS = 'SAVE_NEW_DATES_SUCCESS';
export const SAVE_NEW_DATES_ERROR = 'SAVE_NEW_DATES_ERROR';

export const OPEN_CREATE_CHAIN = 'OPEN_CREATE_CHAIN';
export const CLOSE_CREATE_CHAIN = 'CLOSE_CREATE_CHAIN';

export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';

export const SET_OPENED_CHAINS = 'SET_OPENED_CHAINS'
export const SET_OPENED_ORDERS = 'SET_OPENED_ORDERS'
export const RESET_OPENED_CHAINS = 'RESET_OPENED_CHAINS'
export const RESET_OPENED_ORDERS = 'RESET_OPENED_ORDERS'