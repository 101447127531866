import styles from './styles.module.scss'

export const BrigadierInfoPage = (): JSX.Element => {
  const mobAppUrl = 'https://drive.google.com/file/d/1WqkGpwcm3M5XQhx8ld_t5ApvESM1m8Ms/view?usp=sharing'
  return (
    <div className={styles.infoPage}>
      <div className={styles.caption}>
        Для продолжения работы с платформой пожалуйста воспользуйтесь мобильным приложением: 
      </div>
      <a className={styles.link} href={mobAppUrl}>{mobAppUrl}</a>
      <div className={styles.qr}></div>
    </div>
  )
}