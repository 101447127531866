import {
  GET_PROFILE_DATA_ERROR,
  GET_PROFILE_DATA_START,
  GET_PROFILE_DATA_SUCCESS,
  GET_PROFILE_STATUSES_ERROR, GET_PROFILE_STATUSES_START, GET_PROFILE_STATUSES_SUCCESS
} from "./profileActionsTypes";

const initialState = {
  data: null,
  statuses: [],
  statusesLoading: false,
  isLoading: false,
  isError: false
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROFILE_DATA_START:
      return {...state, isLoading: true, isError: false};
    case GET_PROFILE_DATA_SUCCESS:
      if(payload.contractor) {
        payload.contractor.legalAddress = payload.contractor.legalAddress ?? '';
        payload.contractor.actualAddress = payload.contractor.actualAddress ?? '';
        payload.contractor.signatoryLastName = payload.contractor.signatoryLastName ?? '';
        payload.contractor.signatoryFirstName = payload.contractor.signatoryFirstName ?? '';
        payload.contractor.signatorySecondName = payload.contractor.signatorySecondName ?? '';
        payload.contractor.signatoryPost = payload.contractor.signatoryPost ?? '';
        payload.contractor.signatoryReason = payload.contractor.signatoryReason ?? '';
      }
      return {...state, isLoading: false, data: payload};
    case GET_PROFILE_DATA_ERROR:
      return {...state, isLoading: false, isError: true};

    case GET_PROFILE_STATUSES_START:
      return {...state, statusesLoading: true, isError: false};
    case GET_PROFILE_STATUSES_SUCCESS:
      return {...state, statusesLoading: false, statuses: payload};
    case GET_PROFILE_STATUSES_ERROR:
      return {...state, statusesLoading: false};
    default:
      return { ...state };
  }
};

export default reducer;