import { useCallback, useEffect, useRef, useState } from 'react';
import { NewFooter } from '../../components/footer/NewFooter';
import { useDispatch, useSelector } from 'react-redux';
import OrdersList from '../../components/OrdersList/OrdersList';
import { getOrdersList, setOrdersFilters } from '../../redux/ordersList/ordersListActions';
import { IOrdersListState } from '../../redux/ordersList/types';
import { useDebounced } from '../../hooks/useDebounced';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';


function OrdersListPage() {

  const [page, setPage] = useState<number>(1);
  const prevPage = useRef<number>();

  const ordersList = useSelector<any>(state => state.ordersList) as IOrdersListState;
  const filters = ordersList.filters;
  const dispatch = useDispatch<any>();

  const filtersDebounced = useDebounced(filters, 1000);

  const changeFilterField = useCallback((fieldName: string, fieldValue: string | boolean) => {
    dispatch(setOrdersFilters({[fieldName]: fieldValue}));
  }, [dispatch]);

  useEffect(() => {
    if (prevPage.current !== page) {
      prevPage.current = page;
      dispatch(getOrdersList(page, filtersDebounced));
    } else {
      if (page !== 1) {
        setPage(1);
      } else {
        dispatch(getOrdersList(page, filtersDebounced));
      }
    }
  }, [page, filtersDebounced, dispatch]);

  if(!ordersList.loading && ordersList.error){
    return (
      <ErrorPanel errorMsg="Не удалось загрузить список нарядов" marginTop="100px"/>
    );
  }

  return (
    <>
      <main>
        {ordersList.orders !== null && (
          <OrdersList
            changeFilterField={changeFilterField}
            page={page}
            changePage={setPage}
          />
        )}
      </main>
      <NewFooter />
    </>
  );
}

export default OrdersListPage;