import { SET_WAITING_START, SET_WAITING_STOP } from './actionWaiting';

const initialState = {
  waiting: false
}

const reducer = (state = initialState, {type}) => {
  switch (type) {
    case SET_WAITING_START: {
      return {waiting: true}
    }
    case SET_WAITING_STOP: {
      return {waiting: false}
    }
    default:
      return {...state}
  }
}

export default reducer