import { useMemo } from 'react'
import styles from './Filters.module.scss'
import { useSelector } from 'react-redux'
import { type IStatus } from '../../../../types/Status'
import { type IOrderListItem } from '../../../../types/Order'
import DateRangePicker from '../../../DateRangePicker/DateRangePicker'
import { type IOrdersListFilters } from '../../../../redux/ordersList/types'
import { ROLECODES, getRoleTitle } from '../../../../utils/getRoleName'

interface IFilters {
  filters: IOrdersListFilters
  changeFilterField: (fieldName: string, fieldValue: any) => void
}

const Filters = ({ filters, changeFilterField }: IFilters): JSX.Element => {
  const orders = useSelector<any>(state => state.ordersList.orders) as IOrderListItem[]
  const orderStatuses = useSelector<any>(state => state.ordersList.orderStatuses) as IStatus[]
  const currentStatuses = useSelector<any>(state => state.ordersList.currentStatuses) as number[]

  const getStatusText = (code: number): string => {
    if (!Array.isArray(orderStatuses) || (orderStatuses.length === 0)) {
      return ''
    }
    const statusData = orderStatuses.filter(item => item.currentStateCode === code)?.[0]
    return statusData?.currentStateText ?? ''
  }

  const selectStatuses = useMemo(() => {
    const statuses = [
      {
        code: -1,
        text: '(все)'
      }
    ]

    currentStatuses.forEach(statusCode => {
      const text = getStatusText(statusCode)
      if (text !== '') {
        statuses.push({
          code: statusCode,
          text
        })
      }
    })
    return statuses
  }, [orders, orderStatuses, currentStatuses])

  const selectRoles = [
    {
      code: -1,
      text: '(все)'
    },
    {
      code: ROLECODES.CONTRACTOR,
      text: getRoleTitle(ROLECODES.CONTRACTOR)
    },
    {
      code: ROLECODES.BRIGADIER,
      text: getRoleTitle(ROLECODES.BRIGADIER)
    }
  ]

  return (
    <div className={styles.filters}>

      <input
        name="filter__building_address"
        type="text"
        placeholder="(начните вводить текст)"
        value={filters.buildingFilter}
        onChange={e => { changeFilterField('buildingFilter', e.target.value) }}
      />

      {/* '<!-- */}
      {/*  Фильтр: Смета (по названиям Смет). */}
      {/*  Подразумевается фильтр с «живым поиском»: */}
      {/*  при вводе текста в поле в списке остаются только те строки, */}
      {/*  в тексте которых присутствует вводимое значение. */}
      {/* -->' */}
      <input
        name="filter__estimate"
        type="text"
        placeholder="(начните вводить текст)"
        value={filters.estimateFilter}
        onChange={e => { changeFilterField('estimateFilter', e.target.value) }}
      />

      {/* '<!-- */}
      {/*  Фильтр: Наряд (по названиям Нарядов). */}
      {/*  Подразумевается фильтр с «живым поиском»: */}
      {/*  при вводе текста в поле в списке остаются только те строки, */}
      {/*  в тексте которых присутствует вводимое значение. */}
      {/* -->' */}
      <input
        name="filter__order"
        type="text"
        placeholder="(начните вводить текст)"
        value={filters.orderFilter}
        onChange={e => { changeFilterField('orderFilter', e.target.value) }}
      />

      {/* '<!-- */}
      {/*  Фильтр: Даты Спора (диапазон). */}
      {/*  Подразумевается открытие «апплета» выбора диапазона дат. */}
      {/* -->' */}
      <DateRangePicker
        className={styles.date}
        startDate={filters.dateFrom}
        endDate={filters.dateTo}
        onChangeDates={(dates: [Date | null, Date | null]) => {
          changeFilterField('dateFrom', dates[0])
          changeFilterField('dateTo', dates[1])
        }}
      />

      {/* '<!-- */}
      {/*  Фильтр: Бригадиры (по фамилии). */}
      {/*  Подразумевается фильтр с «живым поиском»: */}
      {/*  при вводе текста в поле в списке остаются только те строки, */}
      {/*  в тексте которых присутствует вводимое значение. */}
      {/* -->' */}
      <input
        name="filter__brigadier"
        type="text" placeholder="(начните вводить текст)"
        value={filters.brigadeFilter}
        onChange={e => { changeFilterField('brigadeFilter', e.target.value) }}
      />

      {/* '<!-- Фильтр: Статус Наряда. -->' */}
      <select
        name="filter__status"
        className="selected"
        value={filters.orderStatus}
        onChange={e => { changeFilterField('orderStatus', parseInt(e.target.value)) }}
      >
        {/* '<!-- Пункты подгружаются в соответствии с загружаемыми Нарядами. -->' */}
        {selectStatuses.map(item => (
          <option key={item.code} value={item.code}>{item.text}</option>
        ))}
      </select>

      {/* '<!-- Фильтр: Споры. -->' */}
      <label title="Только нерешённые">
        <input
          type="checkbox"
          name="filter__complaint"
          checked={filters.hasOpenComplaints}
          onChange={e => { changeFilterField('hasOpenComplaints', e.target.checked) }}
        />н/р
      </label>

      {/* '<!-- Фильтр: Ответчик. -->' */}
      <select
        name="filter__responder"
        className="selected"
        value={filters.withDowntimeType}
        onChange={e => { changeFilterField('withDowntimeType', parseInt(e.target.value)) }}
      >
        {selectRoles.map(item => (
          <option key={item.code} value={item.code}>{item.text}</option>
        ))}
      </select>

    </div>
  )
}

export default Filters
