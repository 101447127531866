import InputMask from 'react-input-mask'
import Datetime from 'react-datetime'
import 'moment/locale/ru'
import 'react-datetime/css/react-datetime.css'

const defaultDateMask = {
  mask: '79.69.8999',
  formatChars: {
    9: '[0-9]',
    8: '[1-9]',
    7: '[0-3]',
    6: '[0-1]'
  }
}

export const DateInput = ({ onChange, field, value, valid, wrongOrder = false }) => {
  const handleDate = (e) => {
    if (typeof e === 'object') {
      onChange({ target: { name: field, value: e.format('DD.MM.YYYY') } })
    } else onChange({ target: { name: field, value: e } })
  }
  return (
        <Datetime
            locale="ru"
            className={'inputDate'}
            dateFormat={'DD.MM.YYYY'}
            timeFormat={false}
            value={value}
            closeOnSelect={true}
            onChange={handleDate}
            renderInput={(props) => (
                <span>
                    <InputMask
                        style={{ width: '120px', padding: '3px', border: (!valid[field] && value) || wrongOrder ? '1px solid red' : '' }}
                        name={field}
                        mask={defaultDateMask.mask}
                        onChange={onChange}
                        value={value}
                        maskChar={'_'}
                        formatChars={defaultDateMask.formatChars}
                        {...props}
                    />
                </span>
            )}
        />
  )
}
