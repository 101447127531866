import style from '../../pages/EstimateManagement/Equipment/equipment.module.scss'
import React, { useState, useEffect, useMemo } from 'react'
import { SystemList } from './SystemList'
import { JobList } from './JobList'
import { useFilter } from '../../hooks/useFilter'
import { EstimateListType, type Calc } from '../../pages/EstimateManagement/Equipment/types'

interface ITitleEquipment {
  title: string
  estimate: any[]
  listType: EstimateListType
  openAll: boolean
}

const TitleEquipment = ({ title, estimate, listType, openAll }: ITitleEquipment) => {
  const [open, setOpen] = useState(false)
  const filtered = useFilter(estimate, title, listType)
  useEffect(() => {
    setOpen(openAll)
  }, [openAll])

  const calc = useMemo(() => filtered.reduce((sum: Calc, item: any): Calc => {
    return { count: sum.count + item.count, totalSum: sum.totalSum + item.cost }
  }, { count: 0, totalSum: 0 }), [filtered])

  const groupTitle = listType === EstimateListType.System ? title : filtered[0]?.workName

  return (
    <React.Fragment>
      <div className={`group__header--system ${style.headerContainer}`}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '6px', paddingLeft: '4px' }}
            className={`${open ? 'open' : ''}`}
            onClick={() => { setOpen(!open) }}>
          <span className="circle-triangle" title="Развернуть / Свернуть"></span>
        </div>
        <div className={`${style.headerSystem}`}>{groupTitle}</div>
        <div className={style.subitems}>
          {listType === EstimateListType.Job && <div style={{ marginLeft: '20px', width: '116px', marginRight: '-20px' }}>
            <span style={{ marginRight: '16px' }} className="symbol__colon--after">Ед. изм</span>
            <span>{filtered[0]?.matUnit}</span>
          </div>}
          {listType === EstimateListType.System && <div style={{ marginLeft: '20px', width: '116px' }}>
            <span style={{ marginRight: '16px' }} className="symbol__colon--after">Позиций</span>
            <span>{filtered.length}</span>
          </div>}
          <div style={{ marginLeft: '40px', width: '135px' }}>
            <span style={{ marginRight: '16px' }} className="symbol__colon--after">Кол-во</span>
            <span>{calc.count.toFixed(2)}</span>
          </div>
          {listType === EstimateListType.Job && <div style={{ width: '135px' }}>
            <span style={{ marginRight: '16px' }} className="symbol__colon--after">Цена</span>
            <span>{(calc.totalSum / calc.count).toFixed(2)}</span>
          </div>}
          <div style={{ marginRight: '10px', width: '150px' }}>
            <span style={{ marginRight: '16px' }} className="symbol__colon--after">Сумма</span>
            <span>{calc.totalSum.toFixed(2)}</span>
          </div>
        </div>
      </div>
      { open && listType === EstimateListType.System && <SystemList arr={filtered}/> }
      { open && listType === EstimateListType.Job && <JobList arr={filtered}/> }
    </React.Fragment>
  )
}
export { TitleEquipment }
