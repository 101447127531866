import type React from 'react'
import { useState } from 'react'
import styles from './ChangeBlockConfirm.module.scss'
import Modal from '../../../../../Modal/Modal'
import { type IProfileEmployee } from '../../../../../../types/Profile'
import { useDispatch } from 'react-redux'
import { saveEmployee, sendFormanConfirmation } from '../../../../../../redux/profile/profileActions'
import { baseNotification } from '../../../../../../utils/estimateUtils'

interface IChangeBlockConfirm {
  employee: IProfileEmployee
  onClose: VoidFunction
}

function ChangeBlockConfirm ({ employee, onClose }: IChangeBlockConfirm): JSX.Element {
  const [changing, setChanging] = useState<boolean>(false)
  const dispatch = useDispatch<any>()

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    onClose()
  }

  const handleChange = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (changing) {
      return
    }

    setChanging(true)
    const verify = employee.isBlocked ? { isVerified: false } : {}
    const isBlocked = employee.isBlocked
    dispatch(saveEmployee({ ...employee, ...verify, isBlocked: !isBlocked })).then(() => {
      setChanging(false)
      baseNotification('Успешно', `Прораб ${employee.login} ${!isBlocked ? 'заблокирован' : 'разблокирован'}`, 'success')
      if (isBlocked) dispatch(sendFormanConfirmation({ ...employee, isVerified: true }))
      onClose()
    }).catch(() => {
      baseNotification('Ошибка', 'Произошла ошибка', 'danger')
      setChanging(false)
    })
  }

  return (
    <Modal title={`${employee.isBlocked ? 'Разблокировка' : 'Блокировка'} Прораба`}>
      <div className={styles.changeBlockModal}>
        <div className={styles.changeBlockModalNotification}>
          {employee.isBlocked
            ? (
            <>
              <p>
                Внимание!
              </p>
              <p>
                После разблокировки<br/>
                учётная запись Прораба<br/>
                должна быть подтверждена.
              </p>
            </>
              )
            : (
            <div className={styles.changeBlockModalUsername}>
              {employee.lastName} {employee.firstName} {employee.secondName}
            </div>
              )}
        </div>
        <div className={styles.changeBlockModalContent}>
          {employee.isBlocked
            ? (
            <div className={styles.changeBlockModalUsername}>
              {employee.lastName} {employee.firstName} {employee.secondName}
            </div>
              )
            : (
            <div className={styles.changeBlockModalText}>
              <p>Подтвердите блокировку учётной записи Прораба.</p>
            </div>
              )}
          <hr/>
          <div className={styles.changeBlockModalFooter}>
            <button
              type="button"
              disabled={changing}
              onClick={handleChange}
            >
              {employee.isBlocked ? 'Разблокировать' : 'Блокировать'}
            </button>
            <button type="button" onClick={handleClose}>Отмена</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChangeBlockConfirm
