import axios from "axios";
import { setWaitingStart, setWaitingStop } from "./actionWaiting";
import * as Sentry from '@sentry/react'

const axiosWithInterceptors = (dispatch) => {

    let requestCount = 0;
    let resetTimer = null;
    const resetTimeOut = 600000;

    const reset = () => {
        requestCount = 0;
        dispatch(setWaitingStop());
    }

    const killTimer = () => {
        if(resetTimer){
            clearTimeout(resetTimer);
            resetTimer = null;
        }
    }

    const stop = () => {
        requestCount--;
        if(requestCount <= 0){
            requestCount = 0;
            killTimer();
            dispatch(setWaitingStop());
        }
    }

    const start = () => {
        dispatch(setWaitingStart());
        killTimer();
        resetTimer = setTimeout(reset, resetTimeOut);
    }

    axios.interceptors.request.use((config) => { 
        if(requestCount === 0) start();
        requestCount++;
        return config;
    }, (error) => {
        reset();
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        stop();
        return response;
    }, (error) => {
        stop();
        Sentry.captureException(error);
        return Promise.reject(error);
    });
}

export default axiosWithInterceptors;