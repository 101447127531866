import axios from "axios";
import Config from "../../config";
import { baseNotification } from "../../utils/estimateUtils";

export const GET_INVITE_REGISTRATION_START = 'GET_INVITE_REGISTRATION_START';
export const GET_INVITE_REGISTRATION_FAIL = 'GET_INVITE_REGISTRATION_FAIL';
export const GET_INVITE_REGISTRATION_SUCCESS = 'GET_INVITE_REGISTRATION_SUCCESS';

export const FINISH_INVITE_REGISTRATION_START = 'FINISH_INVITE_REGISTRATION_START';
export const FINISH_INVITE_REGISTRATION_FAIL = 'FINISH_INVITE_REGISTRATION_FAIL';
export const FINISH_INVITE_REGISTRATION_SUCCESS = 'FINISH_INVITE_REGISTRATION_SUCCESS';

const fetchInviteRegistrationSuccess = (payload) => ({
    type: GET_INVITE_REGISTRATION_SUCCESS,
    payload,
})

const fetchInviteRegistrationStart = () => ({
    type: GET_INVITE_REGISTRATION_START
})

const fetchInviteRegistrationFail = (payload) => ({
    type: GET_INVITE_REGISTRATION_FAIL,
    payload,
})

const finishInviteRegistrationSuccess = (payload) => ({
    type: FINISH_INVITE_REGISTRATION_SUCCESS,
    payload,
})

const finishInviteRegistrationStart = () => ({
    type: FINISH_INVITE_REGISTRATION_START
})

const finishInviteRegistrationFail = (payload) => ({
    type: FINISH_INVITE_REGISTRATION_FAIL,
    payload,
})

export const fetchInviteRegistrationData = (registrationId) => async (dispatch) => {
    dispatch(fetchInviteRegistrationStart());
    try {
        const result = await axios.get(Config.BACKEND_ADDRESS + 'api/Auth/GetRegistration',{
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                id: registrationId
            }
        });
        dispatch(fetchInviteRegistrationSuccess(result.data));
    } catch (e) {
        baseNotification("Ошибка", `Не удалось подтвердить аккаунт`,"danger");
        dispatch(fetchInviteRegistrationFail(e));
    }
}

export const finishInviteRegistration = ({ code, password, login, phone, registrationId }) => async (dispatch, getState) => {
    dispatch(finishInviteRegistrationStart());
    try {
        const result = await axios.post(Config.BACKEND_ADDRESS + 'api/Auth/ConfirmPassword', {
                code,
                password,
                login,
                phone,
                registrationId
            },
            {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(finishInviteRegistrationSuccess({ code, password, login, phone }));
        return result;
    } catch (e) {
        dispatch(finishInviteRegistrationFail(e));
    }
}