import { EstimateListType } from "../pages/EstimateManagement/Equipment/types";

interface UseFilter {
  document: any,
  documentType: { type: number; };
}

export const useFilter = (arr: any, type: any, params: string) => {
  if (params === 'type') {
    return arr.filter((el: UseFilter) => el.document.documentType === type 
                       && el.document.id !== '00000000-0000-0000-0000-000000000000');
  }
  if (params === 'types') {
    return arr.filter((el: UseFilter) => type.includes(el.document.documentType)
                       && el.document.id !== '00000000-0000-0000-0000-000000000000');
  }
  if (params === EstimateListType.System) {
    return arr.filter((el: any) => el.systemName === type);
  }
  if (params === EstimateListType.Job) {
    return arr.filter((el: any) => el.workId === type);
  }
}

export const useFilterRemoved = (documents: any[], docType: number | number[], filesRemoved: string[]) => {
  const docTypeType = Array.isArray(docType) ? "types" : "type";
  const arrWithRemoved = useFilter(documents, docType, docTypeType);
  return arrWithRemoved.filter((el: UseFilter) => !filesRemoved.includes(el.document.id))
}

export const useSystemFilter = (arr: any): any[] => {
  if (arr) {
    const newArray = new Set(arr.map((el: any) => el.systemName));
    return Array.from(newArray);    
  }
  return [];
}

export const useJobFilter = (arr: any): any[] => {
  if (arr) {
    const newArray = new Set(arr.map((el: any) => el.workId));
    return Array.from(newArray);
  }
  return [];
}