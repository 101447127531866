import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import Modal from '../../../Modal/Modal'
import styles from './NewChain.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { calculateNewChainDates, closeCreateChainWindow, saveNewChain } from '../../../../redux/jobPlan/jobPlanActions'
import { type IJobPlanChain } from '../../../../types/JobPlan'
import { type ISelectedJobPlanItem } from '../../../../redux/jobPlan/types'
import { baseNotification, getDaysDiff, parseInputDate } from '../../../../utils/estimateUtils'

function NewChain () {
  const [data, setData] = useState<IJobPlanChain | null>(null)
  const [name, setName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const selectedOrder = useSelector<any>(state => state.jobPlan.selectedItem) as ISelectedJobPlanItem
  const chains = useSelector<any>(state => state.jobPlan.data.chains) as IJobPlanChain[]
  const jobPlanId = useSelector<any>(state => state.jobPlan.data.jobPlan.id) as string
  const dispatch = useDispatch<any>()

  const getData = (jobPlanId: string, chainId: string, orderId: string, workStartPlan: string) => {
    if (loading) {
      baseNotification('Выполняется обновление', '', 'danger')
      return
    }

    setLoading(true)

    dispatch(calculateNewChainDates(jobPlanId, chainId, orderId, workStartPlan))
      .then((res: IJobPlanChain[]) => {
        const newChain = res.filter(item => item.id === '')[0]
        if (newChain) setData(newChain)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleClose = useCallback(() => {
    dispatch(closeCreateChainWindow())
  }, [dispatch])

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  const handleChangeDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    getData(jobPlanId, selectedOrder.parentId, selectedOrder.id, parseInputDate(e.target.value))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder.id, selectedOrder.parentId, jobPlanId])

  const handleSaveChain = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(saveNewChain(jobPlanId, selectedOrder.parentId, selectedOrder.id, data?.workStartPlan, name))
  }, [selectedOrder.id, selectedOrder.parentId, jobPlanId, data?.workStartPlan, name, dispatch])

  useEffect(() => {
    if (selectedOrder.id && selectedOrder.parentId && jobPlanId && (chains.length > 0)) {
      const workStartPlan = chains
        .filter(chainItem => chainItem.id === selectedOrder.parentId)?.[0]?.orders
        .filter(orderItem => orderItem.id === selectedOrder.id)?.[0]?.workStartPlan
      if (workStartPlan) {
        getData(jobPlanId, selectedOrder.parentId, selectedOrder.id, workStartPlan)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chains, selectedOrder.id, selectedOrder.parentId, jobPlanId])

  return (
    <Modal title={'Создание Цепочки'}>
      <div className={styles.window}>
        {(data == null) ? (

          <h3 className={styles.loading}>Loading...</h3>
        ) : (
          <>
            <div className={styles.orders}>

              {data.orders.map(order => (
                <div className={styles.orderRow} key={order.id}>
                  <span className="symbol__bull--before"></span>
                  <span>{order.orderName}</span>
                  <span>{getDaysDiff(order.workStartPlan, order.workEndPlan)}</span>
                  <span>дн.</span>
                </div>
              ))}
            </div>

            <form className={styles.form}>

              {/* Изначально поле пустое (value="").
                !!! Значения указано только для демонстрации на этой странице. */}
              <label>Цепочка</label>
              <input
                name="chain-new__name"
                type="text"
                placeholder="(введите название Цепочки)"
                value={name}
                onChange={handleChangeName}
              />

              <label>Плановые сроки</label>

              <label
                className="symbol__colon--after"
                title="При вводе даты начала дата окончания рассчитается автоматически"
              >
                Начало
              </label>

              {/* Изначально поле пустое (value="").
                !!! Значения указано только для демонстрации на этой странице. */}
              <input
                name="chain-new__date-start"
                type="date"
                title="При вводе даты начала дата окончания рассчитается автоматически"
                value={parseInputDate(data.workStartPlan)}
                onChange={handleChangeDate}
                disabled={loading}
              />

              <label
                className="symbol__colon--after"
                title="Авторасчёт: дата «Начало» + общая продолжительность"
              >
                Окончание
              </label>

              {/* Поле всегда «disabled».
                Обновляется автоматически из расчёта:
                дата, введённая в «Начало» + продолжительность Цепочки.
                Изначально поле пустое (value=""), значение не рассчитано.
                !!! Значения указано только для демонстрации на этой странице. */}
              <input
                name="chain-new__date-end"
                type="date"
                title="Авторасчёт: дата «Начало» + общая продолжительность"
                value={parseInputDate(data.workEndPlan)}
                disabled
              />

              <hr/>

              {/* Изначально кнопка недоступна (disabled).
                Предполагается, что доступна при заполнении полей «Цепочка» и «Начало».
                !!! Кнопка доступна только для демонстрации на этой странице. */}
              <button
                name="close__apply"
                type="button"
                title="Должны быть заполнены поля «Цепочка» и «Начало»"
                disabled={!name}
                onClick={handleSaveChain}
              >
                Создать
              </button>

              <button name="close__cancel" type="button" onClick={handleClose}>Отмена</button>
            </form>
          </>
        )}

      </div>

    </Modal>
  )
}

export default NewChain
