import 'moment/locale/ru'
import { useDispatch } from 'react-redux'
import { setBuildingListScrollY } from '../../../redux/buildingList/buildingListActions'
import { useHistory } from 'react-router-dom'
import styles from './estimate.module.scss'
import { type SyntheticEvent } from 'react'
import getHtmlParentId from '../../../utils/getHtmlParentId'
import { EstimateRowInfo } from './EstimateRowInfo'
import { type IEstimate } from '../Types'
import { NotificationSign } from '../../../components/NotificationSign/notificationSign'
import { setActiveTab } from '../../../redux/estimate/actionEstimate'
import { estimateTabs } from '../../../pages/estimateJob-page/consts'

interface IShortEstimate {
  estimate: IEstimate
}

const ShortEstimate = ({ estimate }: IShortEstimate): JSX.Element => {
  const dispatch = useDispatch<any>()
  const history = useHistory()
  const goToPage = (event: SyntheticEvent): void => {
    event.stopPropagation()
    event.preventDefault()
    dispatch(setBuildingListScrollY(window.scrollY))
    const estimateRowElement = event.target as HTMLElement
    if (estimateRowElement.tagName === 'A') {
      dispatch(setActiveTab(estimateTabs[1].id))
      history.push(estimateRowElement.getAttribute('href') as string)
      return
    }
    dispatch(setActiveTab(estimateTabs[0].id))
    const id = getHtmlParentId(estimateRowElement)
    if (id !== null) {
      history.push(`/estimate-management/${id}`)
    }
  }
  return (
          <div
            onClick={goToPage}
            key={estimate.id}
            id={estimate.id}
            className={`${styles.container} 
                        ${styles.shortEstimateRow} 
                        ${styles.container__offer} `}
          >
          <div className={`${styles.name} ${styles.borderLine}`}>
            { estimate.jobPlanId !== ''
              ? <a href={`/estimate-management/${estimate.id}`}
                   className={`limit__text--length ${styles.container__url}`}
                   title="Карточка разбивки">
                    {estimate.title}
                </a>
              : <div className="limit__text--length">{estimate.title}</div>
            }
            <div className="limit__text--length">{estimate.estimateNote}</div>
            <NotificationSign
              hasNotification={estimate.hasNotification}
              notificationText={estimate.notificationText}
              page='estimate-list'
            />
          </div>

          <div className={`${styles.types} ${styles.borderLine}`}>
            <div className="limit__text--length" title="Тип Сметы">
              {estimate.type}
            </div>
            <div className="text__status--estimate">{estimate.estimateStatusText}</div>
          </div>

          <div className={`${styles.sumContainer} ${styles.borderLine}`} >
            <EstimateRowInfo estimate = {estimate}/>
          </div>
        </div>

  )
}

export default ShortEstimate
