import type React from 'react'
import { useCallback, useState } from 'react'
import styles from './PlanningListBuilding.module.scss'
import { type IPaymentObject } from '../../../../../../types/Billing'
import { formatPrice } from '../../../../../../utils/formatPrice'
import PlanningListEstimate from '../PlanningListEstimate/PlanningListEstimate'
import { Link } from 'react-router-dom'

interface PlanningListBuildingProps {
  building: IPaymentObject
}

function PlanningListBuilding ({ building }: PlanningListBuildingProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev)
  }, [])

  return (
    <div className={styles.buildingSection}>
      <div className={`${styles.buildingRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <Link to={`/building-card/${building.entityId}`} title="Карточка объекта">{building.name}</Link>
          <span>{building.comment}</span>
        </div>
        <div></div>
        <div></div>
        <div>{formatPrice(building.toPrepay)}</div>
        <div>{formatPrice(building.planTotal)}</div>
      </div>

      {isOpen && (
        <>
          {building.estimates.map(estimate => (
            <PlanningListEstimate key={estimate.entityId} estimate={estimate}/>
          ))}
        </>
      )}
    </div>
  )
}

export default PlanningListBuilding
