import { ListFilesRev } from "../../../../components/EstimateManagement/ListFilesRev";
import { IFilesSelector } from "./types";

const FilesSelector = (props: IFilesSelector) => {
    const { estimate, filesRemoved, docType, files } = props;
    return (				
    <fieldset className="modal__content--files">
        <legend className="header__sector">Загрузка файлов Технического задания на Разбивку</legend>
        <ListFilesRev files={files} 
                   fileButtons={{chooseFileBtn: true, addFileBtn: true, removeFileBtn: true}} 
                   estimate={estimate} 
                   filesRemoved={filesRemoved}
                   type={docType} 
                   height={200}/>
    </fieldset>)
}

export default FilesSelector;