import { useState } from 'react'
import { Document, Page } from 'react-pdf';
import styles from './ModalImageView.module.scss'
import Modal from '../Modal/Modal'
import { IGalleryImg } from '../Order/components/OrderBox/types'
import { PDFDocumentProxy } from 'pdfjs-dist';

interface IModalImageView {
  img: IGalleryImg
  setOpenImageView: Function
}

export const ModalImageView = ({ img, setOpenImageView }: IModalImageView) => {
  const ImageView = (): JSX.Element => {
    const [zoom, setZoom] = useState<number>(100)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(1)
    const maxZoom = 300
    const minZoom = 10
    const deltaZoom = img.fileType === 'pdf' ? 50 : 10
    const zoomIn = () => {
      setZoom(Math.min(zoom + deltaZoom, maxZoom))
    }
    const zoomOut = () => {
      setZoom(Math.max(zoom - deltaZoom, minZoom))
    }
    const loadSuccessHandler = (props: unknown) => {
      setTotalPages((props as PDFDocumentProxy)?._pdfInfo?.numPages ?? 1)
    }
    const errorHandler = (e: any) => {
      console.log('error=', e)
    }
    const prevPage = () => {
      if (currentPage > 1) setCurrentPage(currentPage - 1)
    }
    const nextPage = () => {
      if (currentPage < totalPages) setCurrentPage(currentPage + 1)
    }
    const changePageHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const pageNum = parseInt(e.target.value)
      if(pageNum >= 1 && pageNum <= totalPages) setCurrentPage(pageNum)
    }
    return (
            <div className={styles.imageView}>
                <div className={styles.toolsContainer}>
                    <div className={styles.zoomTools}>
                        <button onClick={zoomIn}>+</button>
                        <button onClick={zoomOut}>-</button>
                    </div>
                    {
                      img.fileType === 'pdf' && 
                      <div className={styles.zoomTools}>
                        <div className={styles.pageLabel}>Страница</div>
                        <button onClick={prevPage}>-</button>
                        <input className={styles.pageNumInput} type='number' value={currentPage} onChange={changePageHandler}></input>
                        <button onClick={nextPage}>+</button>
                      </div>
                    }
                    <button onClick={() => setOpenImageView(false)}>Закрыть</button>
                </div>
                <div className={styles.imgContainer}>
                    { img.fileType === 'pdf' 
                        ? (<Document
                            file={{ data: atob(img.src) }}
                            onLoadSuccess={loadSuccessHandler}
                            onSourceError={errorHandler}
                            onLoadError={errorHandler}>
                            <Page 
                              pageNumber={currentPage}
                              scale={zoom / 100}
                              renderAnnotationLayer={false}
                              renderTextLayer={false} />
                          </Document>)
                        : <img src={img.src} alt="document" style={{ width: `${zoom}%` }}></img>
                    }
                </div>

            </div>
    )
  }
  return <Modal title="Просмотр документа" children={<ImageView/>}/>
}
