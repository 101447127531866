import type React from 'react'
import { useState } from 'react'
import Modal from '../../../../../Modal/Modal'
import styles from './CreateEmployee.module.scss'
import { isLoginWrong, isValidEmail, isValidPhone } from '../../../../../../utils/validators'
import { saveEmployee } from '../../../../../../redux/profile/profileActions'
import { baseNotification } from '../../../../../../utils/estimateUtils'
import { useDispatch } from 'react-redux'

interface IEmployeeData {
  lastName: string
  firstName: string
  secondName: string
  email: string
  phone: string
  login: string
}

interface ICreateEmployeeProps {
  contractorId: string
  onClose: VoidFunction
}

interface IErrorReason {
  data: string
}

function CreateEmployee ({ contractorId, onClose }: ICreateEmployeeProps): JSX.Element {
  const [data, setData] = useState<IEmployeeData>({
    lastName: '',
    firstName: '',
    secondName: '',
    email: '',
    phone: '',
    login: ''
  })
  const [creating, setCreating] = useState<boolean>(false)
  const dispatch = useDispatch<any>()

  const handleChaneField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const getIsDataValid = (): boolean => {
    return (
      data.lastName !== '' &&
      data.firstName !== '' &&
      data.secondName !== '' &&
      !isLoginWrong(data.login) &&
      isValidEmail(data.email) &&
      isValidPhone(data.phone)
    )
  }

  const handleCreate = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (creating && !getIsDataValid()) {
      return
    }
    setCreating(true)
    dispatch(saveEmployee({ contractorId, ...data })).then(() => {
      setCreating(false)
      baseNotification('Успешно', 'Профиль успешно создан', 'success')
      onClose()
    }).catch((e: IErrorReason) => {
      const reason = e.data !== '' ? `: ${e.data}` : ''
      baseNotification('Ошибка', `Произошла ошибка ${reason}`, 'danger')
      setCreating(false)
    })
  }

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    onClose()
  }

  return (
    <Modal title="Регистрация Прораба">
      <div className={styles.createEmployeeModal}>
        <div className={styles.createEmployeeModalNotification}>
          <p>
            Все поля с учётными данными Прораба<br/>
            должны быть заполнены.
          </p>
          <p>
            При нажатии на кнопку «Отправить»<br/>
            на указанный e-mail будет отправлена ссылка,<br/>
            по которой Прораб подтвердит свою регистрацию.
          </p>
        </div>

        <form className={styles.createEmployeeModalContent}>
          <label>Фамилия</label>
          <input
            name="lastName"
            type="text"
            placeholder="(обязательное)"
            value={data.lastName}
            onChange={handleChaneField}
          />

          <label>Имя</label>
          <input
            name="firstName"
            type="text"
            placeholder="(обязательное)"
            value={data.firstName}
            onChange={handleChaneField}
          />

          <label>Отчество</label>
          <input
            name="secondName"
            type="text"
            placeholder="(обязательное)"
            value={data.secondName}
            onChange={handleChaneField}
          />

          <label>Логин</label>
          <input
            className={(data.login !== '') && isLoginWrong(data.login) ? styles.error : ''}
            name="login"
            type="text"
            placeholder="(обязательное)"
            value={data.login}
            onChange={handleChaneField}
          />

          <label>E-mail</label>
          <input
            className={(data.email !== '') && !isValidEmail(data.email) ? styles.error : ''}
            name="email"
            type="text"
            placeholder="(обязательное)"
            value={data.email}
            onChange={handleChaneField}
          />

          <label>Телефон</label>
          <input
            className={(data.phone !== '') && !isValidPhone(data.phone) ? styles.error : ''}
            name="phone"
            type="text"
            placeholder="+7 (___) ___-__-__"
            value={data.phone}
            onChange={handleChaneField}
          />

          <hr/>

          <button
            type="button"
            disabled={!getIsDataValid() || creating}
            onClick={handleCreate}
          >
            Отправить
          </button>
          <button
            type="button"
            onClick={handleClose}
          >
            Отмена
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default CreateEmployee
