import {
  GET_BILLING_ERROR,
  GET_BILLING_START,
  GET_BILLING_SUCCESS,
  GET_UNPAID_BILL_SUCCESS,
  GET_PLAN_PAYMENT_SUCCESS
} from "./billingActionTypes";

const initialState = {
  data: null,
  unpaidData: null,
  planPaymentData: null,
  error: false,
  loading: false,
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BILLING_START:
      return {...state, loading: true};

    case GET_BILLING_SUCCESS:
      return {...state, data: payload};

    case GET_UNPAID_BILL_SUCCESS:
      return {...state, loading: false, unpaidData: payload};

    case GET_PLAN_PAYMENT_SUCCESS:
      return {...state, loading: false, planPaymentData: payload};

    case GET_BILLING_ERROR:
      return {...state, loading: false, error: true};

    default:
      return { ...state };
  }
};

export default reducer;