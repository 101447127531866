import { handlePlanDate, getShortFIO } from '../../utils/estimateUtils'
import { useHistory } from 'react-router'

interface ParentPanelParams {
  complaintCardObject: any
  contractorInfo: any
}

const ParentPanel = (params: ParentPanelParams): JSX.Element => {
  const {
    buildingName,
    buildingId,
    estimateId,
    orderId,
    buildingAddress,
    estimateTitle,
    orderName,
    startDate,
    endDate,
    workStartPlan,
    workEndPlan
  } = params.complaintCardObject
  const contractor = params?.contractorInfo?.contractor
  const contractorNameObject = contractor?.employees ? contractor.employees[0] : {}
  const contractorFIO = getShortFIO(contractorNameObject)
  const commonStartDate = startDate || workStartPlan
  const commonEndDate = endDate || workEndPlan
  const history = useHistory()
  const gotoPage = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const element = event.target as HTMLElement
    if (element.tagName === 'A') {
      history.push(element.getAttribute('href') as string)
	}
  }
  return (
    <div className="bar__parent-data">
		<div className="limit__text--length">
			<a
				className="link-light"
				href={`/building-card/${buildingId}`}
				onClick={gotoPage}
				title="Карточка объекта"
			>{buildingName}</a>
		</div>

		<div className="limit__text--length">{buildingAddress}</div>

		<div className="limit__text--length">
			<a
				className="link-light"
				href={`/estimate-management/${estimateId}`}
				onClick={gotoPage}
				title="Страница Сметы"
			>{estimateTitle}</a>
		</div>

		<div className="limit__text--length">
			<a
				className="link-light"
				href={`/order/${orderId}`}
				onClick={gotoPage}
				title="Страница Наряда"
			>{orderName}</a>
		</div>

		<div className="limit__text--length">
			<a
				className="link-light"
				href="/_"
				target="_blank"
				title="Карточка подрядчика"
			>{contractor?.name}</a>
		</div>

		<div>
			<span>{contractor?.phone}</span>
			<span className="limit__text--length">{contractorFIO}</span>
		</div>

		<div>
			<span title="Даты: Начало — Окончание">Плановые сроки</span>
			<span>{handlePlanDate(commonStartDate)}</span>
			<span className="symbol__mdash--before"></span>
			<span>{handlePlanDate(commonEndDate)}</span>
		</div>

	</div>
  )
}

export default ParentPanel
