import moment from "moment";
import { Store } from "react-notifications-component";

export const handleDate = (date) => {
    if (date) {
      return moment(date).format("DD MMM YY");
    } else {
      return '-'
    }
}

export const parseDateTimeString = (date) => {
    return parseInputDate(date) + "T" + moment(date).format("HH:mm:ss") + ".000Z";
}

export const parseDateString = (date) => {
    return moment(date).format("DD MMM");
}

export const handleTime = (date) => {
    return moment(date).format("HH:mm");
}

export const handlePlanDate = (date, withYear = true) => {
    if(!date) return "..."; 
    if(withYear) return handleDate(new Date(date)).replace(".","");
    return parseDateString(new Date(date)).replace(".","");
}

export const parseDateHistory = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm");
}

export const parseInputDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
}

export const parseHumanInputDate = (date) => {
    return moment(date).format("DD.MM.YYYY");
}

export const getDaysDiff = (date1, date2) => {
    const a = moment(date1);
    const b = moment(date2);
    return a > b ? a.diff(b, 'days') + 1 : b.diff(a, 'days') + 1;
}

export const getDowntimeDays = (date) => {
    const d = moment(date);
    const now = moment(moment.now());

    if (now <= d) {
        return  '_';
    }

    return getDaysDiff(d, now);
};

export const baseNotification = (title, message, type = "default") => {
    Store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 2500,
            onScreen: true,
            showIcon: true,
        },
    });
};

export const parseDate = (date) => {
    const arrDate = date.split(".");
    return arrDate.reverse().join("-");
}

export const getEstimateCount = (buildings) => {
    if(!Array.isArray(buildings)) return "-";
    let count = 0;
    buildings?.forEach(el => {
      if(el.estimates) count += el.estimates.length
    })
    return count
}

export const getNameOfEstimate = (arr, id) => {
    let test = '';
    if (arr?.buildings) {
        arr?.buildings.forEach((el) => {
            if (el.estimates.find(item => item.id === id)) {
                test = el
            }
        })
        return test
    } else {
        return null
    }
}

export const getShortFIO = (contractorNameObject) => {
    if(!contractorNameObject) return "";
    const {lastName, firstName, secondName} = contractorNameObject;
    if(!lastName) return "";
    let res = lastName;
    if(firstName){
        res += " " + firstName[0] + ".";
    }else{
        return res;
    }
    if(secondName){
        res += " " + secondName[0] + ".";
    }
    return res;
}

export const getStartEndOfMonth = () => {
    const workMonth = moment().endOf("month").add(1,"day");
    const endDate = workMonth.clone().endOf("month").add(1,"day");
    return({start: parseHumanInputDate(workMonth), end: parseHumanInputDate(endDate), endDate: endDate})
}

export const humanFileSize = (size) => {
    if(!size) return "";
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(1).toString() + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}