import { useSelector } from 'react-redux'
import ObjectPageHead from '../ObjectPageHead/ObjectPageHead'
import CaptionsBar from './components/CaptionsBar/CaptionsBar'
import Filters from './components/Filters/Filters'
import OrderRow from './components/OrderRow/OrderRow'
import { type IOrderListItem } from '../../types/Order'
import { type IOrdersListFilters } from '../../redux/ordersList/types'
import { type IMeta } from '../../types/Meta'
import Pagination from '../Pagination/Pagination'
import styles from './OrdersList.module.scss'

interface IOrdersList {
  changeFilterField: (fieldName: string, fieldValue: string | boolean) => void
  page: number
  changePage: (page: number) => void
}

const OrdersList = ({ changeFilterField, page, changePage }: IOrdersList): JSX.Element => {
  const ordersLoading = useSelector<any>(state => state.ordersList.loading) as boolean
  const orders = useSelector<any>(state => state.ordersList.orders) as IOrderListItem[]
  const meta = useSelector<any>(state => state.ordersList.meta) as IMeta
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number
  const filters = useSelector<any>(state => state.ordersList.filters) as IOrdersListFilters

  return (
    <div className={`${styles.ordersList} ${ordersLoading ? styles.loading : ''}`}>
      <ObjectPageHead title={'Список нарядов'} roleCode={userRoleCode}/>
      <CaptionsBar/>
      <Filters
        filters={filters}
        changeFilterField={changeFilterField}
      />
      {orders.map(order => (
        <OrderRow key={order.id} order={order}/>
      ))}
      <Pagination
        totalRecords={meta?.total}
        pageLimit={meta?.count}
        pageNeighbours={2}
        currentPage={page}
        onPageChanged={changePage}
        disabled={ordersLoading}
      />
    </div>
  )
}

export default OrdersList
