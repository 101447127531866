import { useState } from 'react'
import { DateInput } from '../../DateInput/DateInput'
import { ModalEstimateType } from '../../../pages/EstimateManagement/StatusBar/ModalTypeEstimate/ModalEstimateType'

export const ModalDataEstimate = ({ data, onChange, types, submit, valid, handleDisabledButton, closeFormHandle, selectedTypeValue, setSelectedTypeValue }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
        <>
            <label>Название</label>
            <input type="text" name="estimateName" value={data.estimateName} onChange={onChange} placeholder="(обязательное)"/>

            <label>Примечание</label>
            <input name="note" value={data.note} onChange={onChange} type="text" placeholder="(не обязательное)"/>

            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="bttn__small symbol__hellip--after"
              name="estimate__type">
              Тип сметы
            </button>
            <input type="text" disabled value={selectedTypeValue?.name}/>

            <label>Плановые сроки</label>
            <label className="symbol__colon--after">Начало</label>
            <DateInput valid={valid} onChange={onChange} value={data.workStartPlan} field={'workStartPlan'}/>
            <label className="symbol__colon--after">Окончание</label>
            <DateInput valid={valid} onChange={onChange} value={data.workEndPlan} field={'workEndPlan'} />

            <button type="button" className="control__modal--close" onClick={submit} disabled={handleDisabledButton()}>Создать</button>
            {valid.workEndPlan && valid.workStartPlan && !valid.workData && (
                <span style={{ gridColumn: '2/5', justifySelf: 'start' }}>Выберите корректный период</span>
            )}
            <button type="button" className="control__modal--close" onClick={closeFormHandle}>Отмена</button>
            <ModalEstimateType
              setSelectedTypeValue={setSelectedTypeValue}
              selectedTypeValue={selectedTypeValue}
              types={types}
              isOpen={isOpen}
              setOpen={setIsOpen}
            />
        </>
  )
}
