import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './ChainBox.module.scss'
import { type IJobPlanChain, type IJobPlanChainOrder } from '../../../../types/JobPlan'
import { formatPrice } from '../../../../utils/formatPrice'
import { getDaysDiff, parseDateString, parseInputDate } from '../../../../utils/estimateUtils'
import moment, { type Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { type IStatus, type IButton } from '../../../../types/Status'
import { changeChainStartDate, resetOpenedChains, resetOpenedOrders, setOpenedChains, setOpenedOrders, setSelectedItem, updateEntityStatus } from '../../../../redux/jobPlan/jobPlanActions'
import { estimateCodes, orderCodes } from '../../../../utils/statusCodes'
import { type TAjustText } from '../../types'

interface IChainBox {
  startEndDates: [Moment, Moment]
  chains: IJobPlanChain[]
  jobPlanStatus: number
  orderAjusts: Record<string, TAjustText>
  isAdjustPresent: Function
  setOrderAjusts: Function
  canEditPlan: boolean
  actions: IButton[]
  setBlockNewChain: Function
  setDisableNewChain: Function
}

function ChainBox ({
  startEndDates,
  chains,
  jobPlanStatus,
  orderAjusts,
  isAdjustPresent,
  setOrderAjusts,
  canEditPlan,
  actions,
  setBlockNewChain,
  setDisableNewChain
}: IChainBox) {
  const dateDifference = startEndDates[1]?.valueOf() - startEndDates[0]?.valueOf()
  // const [openedOrders, setOpenedOrders] = useState<string[]>([])

  const editMode = useSelector<any>(state => state.jobPlan.editMode) as boolean
  const orderStatuses = useSelector<any>(state => state.jobPlan.orderStatuses) as IStatus[]
  const updatingCopyEntitiesId = useSelector<any>(state => state.jobPlan.updatingCopyEntitiesId) as string[]
  const selectedItem = useSelector<any>(state => state.jobPlan.selectedItem?.id) as string
  const selectedPos = useSelector<any>(state => state.jobPlan.selectedItem?.itemId) as number
  const openedChains = useSelector<any>(state => state.jobPlan.openedChains) as string[]
  const openedOrders = useSelector<any>(state => state.jobPlan.openedOrders) as string[]
  const dispatch = useDispatch<any>()
  const history = useHistory()

  const goToPage = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    const el = event.target as HTMLElement
    if (el.tagName === 'A') {
      history.push(el.getAttribute('href') as string)
    }
  }

  const handleToggleChain = (chainId: string) => (e: React.MouseEvent<HTMLElement>) => {
    if (openedChains.includes(chainId)) {
      dispatch(resetOpenedChains(chainId))
    } else {
      dispatch(setSelectedItem(chainId, null, null))
      dispatch(setOpenedChains(chainId))
    }
  }

  const handleClickChainRow = (chainId: string) => (e: React.MouseEvent<HTMLElement>) => {
    setDisableNewChain(true)
    dispatch(setSelectedItem(chainId, null, null))
  }

  const handleToggleOrder = (orderId: string, orderPos: number) => (e: React.MouseEvent<HTMLElement>) => {
    if (openedOrders.includes(orderId)) {
      dispatch(resetOpenedOrders(orderId))
      // setOpenedOrders(prev => ([...prev].filter(item => item !== orderId)))
    } else {
      dispatch(setSelectedItem(orderId, orderPos))
      dispatch(setOpenedOrders(orderId))
      // setOpenedOrders(prev => ([...prev, orderId]))
    }
  }

  const handleClickOrderRow = (orderId: string, chainId: string, orderPos: number) => (e: React.MouseEvent<HTMLElement>) => {
    setDisableNewChain(orderPos === 0)
    dispatch(setSelectedItem(orderId, null, orderPos, chainId, 'order'))
  }

  const handleChangeDate = (chainId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setBlockNewChain(true)
    dispatch(changeChainStartDate(chainId, parseInputDate(e.target.value)))
  }

  const handleClickPosRow = (orderId: string, posId: number) => (e: React.MouseEvent<HTMLElement>) => {
    setDisableNewChain(true)
    dispatch(setSelectedItem(orderId, posId, null))
  }

  const getGraphParams = (startDate: string, endDate: string) => {
    const oneDayAddon = 24 * 60 * 60 * 1000
    const start = 100 * (moment(startDate).valueOf() - startEndDates[0].valueOf()) / (dateDifference + oneDayAddon)
    const end = 100 * (startEndDates[1].valueOf() - moment(endDate).valueOf()) / (dateDifference + oneDayAddon)
    let className = ''

    if (start < 5 && end > 70) {
      className = styles.alignLeft
    } else if (start > 70 && end < 5) {
      className = styles.alignRight
    }

    return { start, end, className }
  }

  const getOrderStatusText = (order: IJobPlanChainOrder) => {
    const ajustText = orderAjusts[order.id]?.ajustText
    if (ajustText) {
      return 'Замечания по наряду'
    }
    if (!orderStatuses) {
      return ''
    }

    const statusData = orderStatuses.filter(item => item.currentStateCode === order.status)?.[0]

    if (statusData) {
      return statusData.currentStateText
    }
  }

  const isErrorStatus = (status: number) =>
    status === orderCodes.runOverdue ||
    status === orderCodes.hasAjust ||
    status === orderCodes.noBrigade

  const isWarningStatus = (status: number) =>
    status === orderCodes.runReady

  const getAjustStatus = (chain: IJobPlanChain) => {
    if (chain.orders.some(item => isAdjustPresent(item, chain))) {
      return 'Замечания'
    }
    return null
  }

  const getChainStatusClassname = (chain: IJobPlanChain) => {
    if (chain.orders.some(item => isErrorStatus(item.status) || isAdjustPresent(item, chain))) {
      return styles.error
    }

    if (chain.orders.some(item => isWarningStatus(item.status)) && 
       !chain.orders.some(item => item.status === orderCodes.noBrigade)) {
      return styles.warning
    }

    return ''
  }

  const getOrderStatusClassname = (order: IJobPlanChainOrder, chain: IJobPlanChain) => {
    if (isErrorStatus(order.status) || isAdjustPresent(order, chain)) {
      return styles.error
    }

    if (isWarningStatus(order.status)) {
      return styles.warning
    }

    return ''
  }

  const handleClickAction = useCallback((chainId: string) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(updateEntityStatus({
      entityId: chainId,
      newState: orderCodes.runReady
    }, true))
  }, [dispatch])

  const renderChainAction = (chainId: string, chainOrders: IJobPlanChainOrder[]) => {
    if (editMode) {
      return null
    }
    const runChainBtn = actions.filter(actionItem => actionItem.type === 'runChainBtn')[0]
    if (runChainBtn && (chainOrders.some(item => item.status === orderCodes.runOverdue) ||
          chainOrders.filter(item => item.status !== orderCodes.approved).length === 0)) {
      return (
        <button
          className={`bttn__small ${styles.chainRowBtn}`}
          type="button"
          onClick={handleClickAction(chainId)}
        >
          {runChainBtn.title}
        </button>
      )
    }

    return null
  }

  const ajustChangeHandler = (e: any) => {
    if (e?.target?.id) {
      const newAjust: any = {}
      newAjust[e.target.id] = {
        ajustText: e.target.value,
        chainId: e.target.dataset.chainid,
        workStartPlan: e.target.dataset.workstartplan,
        workEndPlan: e.target.dataset.workendplan
      }
      orderAjusts = { ...orderAjusts, ...newAjust }
      localStorage.setItem('orderAjusts', JSON.stringify(orderAjusts))
      setOrderAjusts(orderAjusts)
    }
  }

  const ajustFocusHandler = (e: any) => {
    if (e?.target?.value === '') {
      e.target.value = `Цепочка: ${e.target.dataset.chainname}\rНаряд: ${e.target.dataset.ordername}\r`
      ajustChangeHandler(e)
    }
  }

  return (
    <>
      {chains?.map(chain => {
        const graphParams = getGraphParams(chain.workStartPlan, chain.workEndPlan)
        return (
          <div className={styles.chainBox} key={chain.id}>
            {/* Цепочка: Строка. */}
            <div className={`${styles.chainRow} 
                             ${getChainStatusClassname(chain)} 
                             item__to-highlight 
                             ${openedChains.includes(chain.id) ? 'open' : ''} 
                             ${selectedItem === chain.id ? 'highlighted' : ''}`
                           }
                 onClick={handleClickChainRow(chain.id)}>

              {/* Кнопка раскрытия Цепочки. */}
              <div>
                <span
                    className="circle-triangle"
                    title="Развернуть / Свернуть"
                    onClick={handleToggleChain(chain.id)}
                />
              </div>

              {/* Название. */}
              <div>
                {chain.name}
                {/* Алгоритм вывода по статусам определён в CSS. */}
                {canEditPlan && renderChainAction(chain.id, chain.orders)}
              </div>

              {/* Состояние Цепочки (состояние Нарядов), текст в CSS. */}
              <div className={styles.chainRowStatus}>{getAjustStatus(chain) || chain.statusText}</div>

              {/* Сумма. */}
              <div>{formatPrice(chain.cost)}</div>

              {/* Область вывода диаграммы Цепочки (1). */}
              <div className={styles.graphBoxWrap}>

                {/* Бокс диаграммы Цепочки. */}
                <div className={styles.graphBox} style={{ left: `${graphParams.start}%`, right: `${graphParams.end}%` }}>

                  {/* Блок дат Цепочки. */}
                  <div className={`${styles.graphBoxDates} ${graphParams.className}`}>

                    {/* Дата начала в режиме чтения. */}
                    {!editMode && (
                      <span className="chain__date-start">{parseDateString(chain.workStartPlan)}</span>
                    )}

                    {/* Дата начала в режиме редактирования. */}
                    {editMode && (
                      <input
                        type="date"
                        name="chain__date-start"
                        className={styles.dateSelect}
                        onChange={handleChangeDate(chain.id)}
                        value={parseInputDate(chain.workStartPlan)}
                        disabled={updatingCopyEntitiesId.includes(chain.id)}
                      />
                    )}

                    {/* Дата окончания: автоматический расчёт. */}
                    <span className="symbol__mdash--before">{parseDateString(chain.workEndPlan)}</span>

                  </div>

                  {/* Размер и положение Индикатора. */}
                  <div className={styles.graphBoxLine}></div>

                </div>

              </div>

            </div>

            {/* Цепочка: Наряды. */}
            {openedChains.includes(chain.id) && (
              <div className={styles.chainBoxOrders}>

                {chain.orders.map((order, index) => {
                  const orderGraphParams = getGraphParams(order.workStartPlan, order.workEndPlan)
                  const orderAjustText = orderAjusts[order.id]?.ajustText
                  return (
                    <React.Fragment key={order.id}>
                      {/* Наряд (1): Строка. */}
                      <div
                        className={`${styles.orderRow} 
                                    ${getOrderStatusClassname(order, chain)} 
                                    item__to-highlight 
                                    ${openedOrders.includes(order.id) ? 'open' : ''} 
                                    ${selectedItem === order.id ? 'highlighted' : ''}
                                  `}
                        onClick={handleClickOrderRow(order.id, chain.id, index)}
                      >

                        {/* Кнопка раскрытия Наряда. */}
                        <div>
                          <span
                            className="circle-triangle"
                            title="Развернуть / Свернуть"
                            onClick={handleToggleOrder(order.id, index)}
                          />
                        </div>

                        <div>
                          <a href={`/order/${order.id}`}
                             title="Карточка наряда"
                             onClick={goToPage}>{order.orderName}</a>
                        </div>

                        <div className={styles.areaDayBrigade}>
                          <div><span>{getDaysDiff(order.workStartPlan, order.workEndPlan)}</span> дн.</div>
                          {/* Статус Наряда. */}
                          <div className={styles.orderRowStatus}>{getOrderStatusText(order)}</div>
                          <div>{order.brigadeFIO ? order.brigadeFIO : '(бригада не подобрана)'}</div>
                        </div>

                        <div>{formatPrice(order.cost)}</div>

                        {/* Область вывода диаграммы Наряда. */}
                        <div className={styles.graphBoxWrap}>

                          {/* Бокс диаграммы Наряда. */}
                          <div className={styles.graphBox} style={{ left: `${orderGraphParams.start}%`, right: `${orderGraphParams.end}%` }}>

                            {/* Блок дат. */}
                            <div className={`${styles.graphBoxDates} ${orderGraphParams.className}`}>
                              <span className="symbol__mdash--after">{parseDateString(order.workStartPlan)}</span>
                              <span>{parseDateString(order.workEndPlan)}</span>
                            </div>

                            {/* Полоса Индикатора: ширина и положение. */}
                            <div className={`${styles.graphBoxLine} ${styles.light}`}></div>

                          </div>

                        </div>

                      </div>

                      {/* Наряд (1): Работы. */}
                      {openedOrders.includes(order.id) && (
                        <div className={styles.jobPrices}>

                          {/* Наряд (1): Замечания. */}
                          {/*
                            Содержание области выводится в статусах Разбивки:
                              «Внесение замечаний»
                              для всех Нарядов (доступно внесение замечаний).
                              «Замечания по разбивке»
                              для Нарядов со статусом «Замечания по наряду» (доступен только просмотр).
                          */}

                          <div className={styles.ajustArea}>
                            {((jobPlanStatus === estimateCodes.jobPlanRemarks) ||
                             ((jobPlanStatus === estimateCodes.remarksInProgress) && !canEditPlan)) && order.ajustText && (
                              <>
                                <div className={styles.ajustAreaHead}>Замечания по Наряду</div>
                                <textarea className={styles.ajustAreaField} readOnly value={order.ajustText}/>
                              </>
                            )}
                            {(jobPlanStatus === estimateCodes.remarksInProgress) && canEditPlan && (
                              <>
                                <div className={styles.ajustAreaHead}>Замечания по Наряду</div>
                                <textarea className={styles.ajustAreaField}
                                          id={order.id}
                                          data-ordername = {order.orderName}
                                          data-workstartplan = {order.workStartPlan}
                                          data-workendplan = {order.workEndPlan}
                                          data-chainid = {chain.id}
                                          data-chainname = {chain.name}
                                          value = {orderAjustText}
                                          onChange = {ajustChangeHandler}
                                          onFocus = {ajustFocusHandler}/>
                              </>
                            )}
                          </div>

                          {/* Наряд (1): Список Работ. */}
                          <div className="list__childs--highlight">

                            {order.jobPrice.map((item, index) => {
                              return (
                                <div className={`${styles.jobPricesRow} 
                                                 ${(selectedItem === order.id) && (selectedPos === index) ? 'highlighted' : ''}`}
                                     key={index}
                                     onClick={handleClickPosRow(order.id, index)}
                                >
                                  <div className="symbol__bull--before"></div>
                                  <div>{item.workName}</div>
                                  <div>{item.count}</div>
                                  <div>{item.unit}</div>
                                  <div>{item.price}</div>
                                  <div>{formatPrice(item.cost)}</div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}

                    </React.Fragment>
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

export default ChainBox
