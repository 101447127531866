import { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { createObject, saveBuilding } from '../../../redux/actions/actionContractor'
import { useDispatch, useSelector } from 'react-redux'
import { DateInput } from '../../DateInput/DateInput'
import { parseDate, baseNotification, getStartEndOfMonth } from '../../../utils/estimateUtils'
import { contractorObjectRoles } from '../../../utils/statusCodes'
import { useHistory } from 'react-router-dom'

const initialObjectData = {
  estimateName: '',
  estimateTypeId: '',
  estimateTypeName: '',
  workStartPlan: '',
  workEndPlan: '',
  buildingName: '',
  buildingAddress: '',
  note: '',
  developer: '',
  customer: '',
  mainContractor: '',
  coordinates: '',
  longitude: 0,
  latitude: 0,
  objectRole: 4
}

const objectValid = {
  estimateName: false,
  estimateTypeId: false,
  workStartPlan: false,
  workEndPlan: false,
  workData: false,
  buildingName: false,
  buildingAddress: false,
  objectRole: false,
  longitude: true,
  latitude: true,
  coordinates: true
}

const editModeValidation = [
  'buildingName',
  'buildingAddress',
  'objectRole',
  'coordinates',
  'developer',
  'customer',
  'mainContractor'
]

const getConvertedBuildingState = (building) => {
  const geo = building.latitude && building.longitude ? `${building.latitude} ${building.longitude}` : ''
  return {
    ...initialObjectData,
    ...building,
    coordinates: geo
  }
}

export const ModalObject = ({ isOpen, selectedTypeValue, setModal, setEstimateType, editMode = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { buildingsObject } = useSelector((state) => state.contractorReducer)
  const { building } = buildingsObject
  const [data, setData] = useState(initialObjectData)
  const [copyData, setCopyData] = useState(initialObjectData)
  const [valid, setValid] = useState(objectValid)
  const [collapseOptions, setCollapseOptions] = useState(true)
  const [addonOptionsMandatory, setAddonOptionsMandatory] = useState(false)
  useEffect(() => {
    const { workStartPlan, workEndPlan } = data
    if (workEndPlan && workStartPlan) {
      setValid({
        ...valid,
        workData: moment(parseDate(workEndPlan)).isAfter(parseDate(workStartPlan), [
          'day',
          'month',
          'year'
        ]) || editMode
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.workStartPlan, data.workEndPlan])

  const isSameData = () => {
    return Object.entries(data).every(([key, value]) => copyData[key] === value)
  }

  const validate = (itemsObj) => {
    const currValid = { ...valid }
    Object.entries(itemsObj).forEach(([name, value]) => {
      switch (name) {
        case 'buildingName':
        case 'buildingAddress':
        case 'estimateName':
        case 'estimateTypeId':
        case 'objectRole':
        case 'developer':
        case 'mainContractor':
        case 'customer':
          currValid[name] = !!('' + value).trim()
          break
        case 'longitude':
          currValid[name] = (value <= 180 && value >= -180) || (value === '')
          break
        case 'latitude':
          currValid[name] = (value <= 90 && value >= -90) || (value === '')
          break
        case 'coordinates':
          const valueDate = value.split(' ')
          currValid[name] = ((valueDate[0] >= -180) &&
                                       (valueDate[0] <= 180) &&
                                       (valueDate[1] <= 90) &&
                                       (valueDate[1] >= -90)) || (value === '')
          break
        case 'workStartPlan':
        case 'workEndPlan':
          currValid[name] = moment(value, 'DD.MM.YYYY', true).isValid() || editMode
          break
        default:
      }
    })
    setValid(currValid)
  }

  useEffect(() => {
    if (editMode && building) {
      const convertedBuildingState = getConvertedBuildingState(building)
      setData(convertedBuildingState)
      setCopyData(convertedBuildingState)
      validate(convertedBuildingState)
    }
    if (!editMode) {
      let initData = { ...initialObjectData }
      const defaultWorkPlan = getStartEndOfMonth()
      initData = { ...initData, workStartPlan: defaultWorkPlan.start, workEndPlan: defaultWorkPlan.end }
      setData(initData)
      setCopyData(initData)
      validate(initData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building, isOpen, editMode])

  const onChange = (e) => {
    const { name, value } = e.target
    if (data.hasOwnProperty(name)) {
      setData({
        ...data,
        [name]: value
      })
    }
    if (name === 'objectRole') {
      if (value !== '4') {
        setCollapseOptions(false)
        setAddonOptionsMandatory(true)
      } else {
        setAddonOptionsMandatory(false)
      }
    }
    validate({ [name]: value })
  }

  const submit = async () => {
    if (editMode) {
      await dispatch(saveBuilding(data))
      setModal(false)
    } else {
      const createResult = await dispatch(createObject(data))
      if (createResult?.status === 200) {
        if (createResult.data?.estimates) {
          history.push(`/estimate-management/${createResult.data.estimates[0].id}`)
          setModal(false)
        } else {
          baseNotification('Не удалось создать смету объекта', '', 'danger')
        }
      }
    }
  }

  useEffect(() => {
    if (data.coordinates) {
      setData({
        ...data,
        latitude: +data.coordinates.split(' ')[0],
        longitude: +data.coordinates.split(' ')[1]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.coordinates])

  useEffect(() => {
    if (selectedTypeValue?.id) {
      setData({
        ...data,
        estimateTypeId: selectedTypeValue?.id,
        estimateTypeName: selectedTypeValue?.name
      })
      setValid({
        ...valid,
        estimateTypeId: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypeValue])

  const isAllValid = useMemo(() => {
    const validationItem = editMode ? editModeValidation : Object.keys(valid)
    return validationItem.every(item => {
      if (['developer', 'mainContractor', 'customer'].includes(item)) {
        return !addonOptionsMandatory || valid[item]
      }
      return valid[item]
    })
  }, [valid, addonOptionsMandatory, editMode])

  const handleDisabledButton = () => {
    if (!isAllValid) return true
    return isSameData()
  }

  if (!isOpen) return null

  const wrongDateOrder = valid.workEndPlan && valid.workStartPlan && !valid.workData
  const createBuildingStyle = collapseOptions ? 'creating__building creating__building-collapse' : 'creating__building'
  const editBuildingStyle = collapseOptions ? 'editing__building editing__building-collapse' : 'editing__building'
  const inputStyle = collapseOptions ? 'creating__building_input-collapse' : ''
  const geoInputStyle = collapseOptions ? 'creating__building_geoinput-collapse' : 'creating__building_geoinput'

  const estimateTypeHandler = (e) => {
    e.preventDefault()
    setEstimateType(true)
  }

  return ReactDOM.createPortal(
        <div id="modal__box">
            <div className="modal__back"/>
            <div className={`modal__window ${editMode ? editBuildingStyle : createBuildingStyle}`}>

                <h3>{editMode ? 'Изменение данных Объекта' : 'Создание Объекта'}</h3>

                <div style={{ minHeight: '35px' }}>
                    {isAllValid ? '' : 'Все обязательные поля должны быть заполнены.'}
                </div>

                <form>
                    <label>Название</label>
                    <input type="text" name="buildingName" value={data.buildingName} onChange={onChange} placeholder="(обязательное)"/>

                    <label>Адрес</label>
                    <input type="text" name={'buildingAddress'} value={data.buildingAddress} onChange={onChange} placeholder="(обязательное)"/>

                    <p onClick={() => { setCollapseOptions(!collapseOptions) }}
                       className={collapseOptions ? '' : 'open'}>
                        <span className="circle-triangle" title="Развернуть / Свернуть"></span>
                        <label>Дополнительно</label>
                    </p>

                    <label className={inputStyle}>Застройщик</label>
                    <input className={inputStyle}
                           value={data.developer}
                           name="developer"
                           onChange={onChange}
                           type="text"
                           placeholder={`(${addonOptionsMandatory ? '' : 'не '}обязательное)`}/>

                    <label className={inputStyle}>Генподрядчик</label>
                    <input className={inputStyle}
                           value={data.mainContractor}
                           name="mainContractor"
                           onChange={onChange}
                           type="text"
                           placeholder={`(${addonOptionsMandatory ? '' : 'не '}обязательное)`}/>

                    <label className={inputStyle}>Заказчик</label>
                    <input className={inputStyle}
                           value={data.customer}
                           name="customer"
                           onChange={onChange}
                           type="text"
                           placeholder={`(${addonOptionsMandatory ? '' : 'не '}обязательное)`}/>

                    <section className={geoInputStyle}>
                        <label style={{ marginRight: '38px' }}>Геокоординаты</label>
                        <section style={{ display: 'flex' }}>
                            <input style={{ border: !valid.coordinates ? '1px solid red' : '', width: '400px' }}
                                   type="text"
                                   onChange={onChange}
                                   name="coordinates"
                                   placeholder="(не обязательное)"
                                   value={data.coordinates}/>
                        </section>
                    </section>
                    <label htmlFor="" />
                    <label title="Ваши полномочия на этом Объекте">Роль на Объекте</label>
                    <select value={data.objectRole} name="objectRole" onChange={onChange} className={data.objectRole ? 'selected' : ''}>
                        <option value="" hidden>(обязательное)</option>
                        {Object.entries(contractorObjectRoles).map(([code, title]) =>
                            <option value={code} key={code}>{title}</option>
                        )}
                    </select>
                    {!editMode && <>
                        <p>Смета</p>
                        <label>Название</label>
                        <input type="text" name="estimateName" value={data.estimateName} onChange={onChange} placeholder="(обязательное)"/>

                        <label>Примечание</label>
                        <input name="note" value={data.note} onChange={onChange} type="text" placeholder="(не обязательное)"/>

                        <button className="bttn__small" onClick={estimateTypeHandler}>Тип сметы</button>
                        <input type="text" value={data.estimateTypeName} disabled name="estimateTypeId" onChange={onChange} />

                        <label>Плановые сроки</label>
                        <div className="date-container">
                            <label className="symbol__colon" style={{ fontFamily: 'thin', display: 'flex', alignItems: 'center' }}>Начало:
                            </label>
                            <DateInput
                                    valid={valid}
                                    onChange={onChange}
                                    value={data.workStartPlan}
                                    field={'workStartPlan'}
                                    wrongOrder={wrongDateOrder}
                            />
                        </div>
                        <div className="date-container">
                            <label className="symbol__colon" style={{ fontFamily: 'thin', display: 'flex', alignItems: 'center' }}>Окончание:
                            </label>
                            <DateInput
                                valid={valid}
                                onChange={onChange}
                                value={data.workEndPlan}
                                field={'workEndPlan'}
                                wrongOrder={wrongDateOrder}
                            />
                        </div>
                    </>}

                    <button
                        type="button"
                        className="control__modal--close"
                        onClick={submit}
                        disabled={handleDisabledButton()}>
                        {editMode ? 'Изменить' : 'Создать'}
                    </button>
                    <button
                        type="button"
                        className="control__modal--close"
                        onClick={() => setModal(false)}>
                        Отмена
                    </button>
                </form>
            </div>
        </div>,
        document.getElementById('root'))
}
