import axios from "axios";
import Config from "../../config";
import {
  GET_BILLING_ERROR,
  GET_BILLING_START,
  GET_BILLING_SUCCESS,
  GET_UNPAID_BILL_SUCCESS,
  GET_PLAN_PAYMENT_SUCCESS
} from "./billingActionTypes";
import moment from "moment/moment";

const getBillingStart = () => ({
  type: GET_BILLING_START
});

const getBillingError = () => ({
  type: GET_BILLING_ERROR
});

const getBillingSuccess = (payload) => ({
  type: GET_BILLING_SUCCESS,
  payload
});

const getUnpaidBillSuccess = (payload) => ({
  type: GET_UNPAID_BILL_SUCCESS,
  payload
});

const getPlanPaymentSuccess = (payload) => ({
  type: GET_PLAN_PAYMENT_SUCCESS,
  payload
});

const getToken = () => {
  const token = JSON.parse(localStorage.getItem("user"));
  return `Bearer ${token.token}`
}


export const getBilling = (startDate, endDate) => (dispatch) => {
  dispatch(getBillingStart());
    Promise.all([
      billingPromise(startDate, endDate),
      unpaidBillPromise(),
      planPaymentsPromise()])
    .then(res => {
      dispatch(getBillingSuccess(res[0].data));
      dispatch(getUnpaidBillSuccess(res[1].data));
      dispatch(getPlanPaymentSuccess(res[2].data))
    })
    .catch( _ => {
      dispatch(getBillingError());
    })
};

const billingPromise = (startDate, endDate) => {
  return axios.post(Config.BACKEND_ADDRESS + `Contractor/GetFactPayments`, {
    dateFrom: startDate ? moment(startDate).format(moment.HTML5_FMT.DATE) : null,
    dateTo: endDate ? moment(endDate).format(moment.HTML5_FMT.DATE) : null
  }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    }
  });
}

const unpaidBillPromise = () => {
  return axios.get(Config.BACKEND_ADDRESS + `Contractor/GetUnpaidBills`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    }
  });
}

const planPaymentsPromise = () => {
  return axios.get(Config.BACKEND_ADDRESS + `Contractor/GetPlanPayments`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    }
  });
}