import { ECheckType, IAmountSelector } from "./types";

const AmountSelector = (props: IAmountSelector) => {
    const {estimate, values, onChange} = props;

    const getCalculatedCost = (checkType: ECheckType) => {
        const result:number = (checkType === ECheckType.withVolume) ?
          estimate?.info?.calculationWithCheckCost
          :
          estimate?.info?.calculationWithoutCheckCost;
        return result.toFixed(2);
    }

    return (				
    <fieldset className="modal__content--amount">
        <label>Стоимость<br />составления Разбивки</label>
        <label>
            <input
                name="detailing"
                type="radio"
                value={ECheckType.withVolume}
                checked={values.detailing === ECheckType.withVolume}
                onChange={onChange}
            />
            <span className="symbol__colon--after">С проверкой объёмов</span>
            <span>{getCalculatedCost(ECheckType.withVolume)}</span>
        </label>
        <label>
            <input
                name="detailing"
                type="radio"
                value={ECheckType.withoutVolume}
                checked={values.detailing === ECheckType.withoutVolume}
                onChange={onChange} 
            />
            <span className="symbol__colon--after">Без проверки объёмов</span>
            <span>{getCalculatedCost(ECheckType.withoutVolume)}</span>
        </label>
    </fieldset>
    )
}

export default AmountSelector;