import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { saveEstimateSuccess } from "../../../../redux/estimate/actionEstimate";
import ReactDOM from "react-dom";
import { ModalEstimateType } from "../ModalTypeEstimate/ModalEstimateType";
import moment from "moment";
import { parseDate } from "../../../../utils/estimateUtils";
import { DateInput } from "../../../../components/DateInput/DateInput";
import { parseHumanInputDate } from "../../../../utils/estimateUtils";
import { getEstimateTypes } from "../../../../redux/actions/actionContractor";

interface Value {
  id: string;
  title: string;
  typeId: number;
  workStartPlan: string;
  workEndPlan: string;
  note: string;
  prepayment: number;
  detailing: number;
  foremanId: string,
  bankAccountId: string,
}

const initialState = { id: 0, name: '', code: '0', items: null }

const objectValid = {
  title: false,
  workStartPlan: true,
  workEndPlan: true,
}

const ModalChangeRequisites = ({isOpen, setModal}: any) => {
  const estimate = useSelector((state: any) => state.estimateReducer.estimate.info);
  const estimateTypes = useSelector((state: any) => state.contractorReducer.estimateTypes);
  const [selectedTypeValue, setSelectedTypeValue] = useState(initialState);
  const dispatch = useDispatch<any>();
  const [valid, setValid] = useState<any>(objectValid);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<Value>({
    id: estimate.id,
    title: estimate.title,
    typeId: selectedTypeValue?.id,
    workStartPlan: parseHumanInputDate(estimate.workStartPlan),
    workEndPlan: parseHumanInputDate(estimate.workEndPlan),
    note: estimate.estimateNote,
    prepayment: estimate.prepayment,
    detailing: estimate.detailing,
    foremanId: estimate.foremanId,
    bankAccountId: estimate.bankAccountId,
  })

  useEffect(() => {
    const getInitialType = () => {
      if (estimateTypes?.length) {
        for (let i = 0; i < estimateTypes.length; i++) {
          const findItem = estimateTypes[i]?.items?.find((el: any) => el.name === estimate.type);
          if (findItem) {
            setValues({...values, typeId: findItem.id})
            setSelectedTypeValue(findItem);
          }
        }
      }
    }
    getInitialType();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [estimateTypes, estimate.type]);

  useEffect(() => {
    if (!estimateTypes) {
      dispatch(getEstimateTypes());
    }
  },[estimateTypes, dispatch]);

  let element = document.getElementById("root");
  if (element !== null && isOpen) {
    element.classList.value = "role__contractor status__estimate--new mode__edit modal__open--creating-estimate";
  }

  const saveEstimate = () => {
    dispatch(saveEstimateSuccess(values))
    setModal(false);
  }

  useEffect(() => {
    setValues({...values, typeId: selectedTypeValue?.id});
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedTypeValue]);

  const setNewValue = (e: any) => {
    const {name, value} = e.target;
    if (values.hasOwnProperty(name)) {
        setValues({
          ...values,
          [name]: value
        })
    }

    const currErrors = {...valid}
    switch (name) {
      case 'title':
        currErrors[name] = !!value.trim();
        break;
      case 'workStartPlan':
      case 'workEndPlan':
        currErrors[name] = moment(value, 'DD.MM.YYYY', true).isValid();
        break;
      default:
        return;
    }
    setValid(currErrors);
  }

  useEffect(() => {
    const {workStartPlan, workEndPlan} = values;
    if (workEndPlan && workStartPlan){
      setValid({
        ...valid,
        // @ts-ignore
        workData: moment(parseDate(workEndPlan)).isAfter(parseDate(workStartPlan), [
          "day",
          "month",
          "year"
        ])
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.workStartPlan, values.workEndPlan])

  if (!isOpen) return null;

  return ReactDOM.createPortal(
      <div id="modal__box">
        <div className="modal__back"/>
        <div className="modal__window creating__estimate">

          <h3>Реквизиты Сметы</h3>

          <div>
            Реквизиты можно менять только в статусе <span className="symbol__quotes">Редактируется</span>.
          </div>
          <form>

            <label>Название</label>
            <input name="title" type="text" onChange={setNewValue} value={values.title}/>

            <label>Примечание</label>
            <input name="note" type="text" onChange={setNewValue} value={values.note}/>

            <button 
              type="button" 
              onClick={() => setOpen(true)} 
              className="bttn__small symbol__hellip--after" 
              name="estimate__type">
              Тип сметы
            </button>
            <input onChange={setNewValue} type="text" value={selectedTypeValue?.name}/>

            <label>Плановые сроки</label>
            <label className="symbol__colon--after">Начало</label>
            <DateInput valid={valid} onChange={setNewValue} value={values.workStartPlan} field={"workStartPlan"}/>     
            <label className="symbol__colon--after">Окончание</label>
            <DateInput valid={valid} onChange={setNewValue} value={values.workEndPlan} field={"workEndPlan"}/>

            <button type="button" onClick={saveEstimate} className="control__modal--close">Изменить</button>
            <button onClick={() => setModal(false)} type="button" className="control__modal--close">Отмена</button>

          </form>
          <ModalEstimateType
            setSelectedTypeValue={setSelectedTypeValue}
            selectedTypeValue={selectedTypeValue}
            types={estimateTypes}
            isOpen={open}
            setOpen={setOpen}
          />
        </div>
      </div>,
      document.getElementById("root")!
  )
}

export {ModalChangeRequisites};