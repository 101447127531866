import React, { useMemo } from "react";
import axios from "axios";
import Config from "../../../config";
import { IReportButtons } from "../../../types/Status";
import style from "./estimateReports.module.scss"
import { downloadFile } from "../../../utils/downloadItem";

interface IEstimateReports {
  reportButtons: IReportButtons
  id: string
}

const EstimateReports = ({reportButtons, id}: IEstimateReports) => {

  const downloadReportItem = async (typeId: number, fileName: string) => {
    try {
      const token = JSON.parse(localStorage.getItem("user") as string);
      const result = await axios.post(`${Config.BACKEND_ADDRESS}Estimate/GetContentFile`, {
        "id": id,
        "typeid": typeId
      },{ 
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
        responseType: "arraybuffer",
      })
      downloadFile(result.data, fileName);
    } catch (e) {
      console.log(e);
    }
  };

  const reportTypes = useMemo(() => 
    Object.keys(reportButtons),
    [reportButtons]
  )

  const reportHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const typeId = reportTypes.findIndex(item => item === (e.target as HTMLElement).dataset.specType);
    const fileName = (e.target as HTMLElement).innerText;
    if(typeId >= 0) {
      downloadReportItem(typeId, `${fileName}.xlsx`);
    }
  }

  return (
    <div className={style.specContainer}>
      {(reportTypes as (keyof IReportButtons)[]).map((reportType) => {
        return (
          reportButtons[reportType] && 
          <button 
            className={style.specButton} 
            data-spec-type={reportButtons[reportType]?.type}
            onClick={reportHandler}
            key={reportType}>
              {reportButtons[reportType]?.title}
          </button>
        )
      })}
    </div>
  )
}

export {EstimateReports}