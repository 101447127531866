import axios from "axios";
import Config from "../../config";
import { baseNotification, parseDate } from "../../utils/estimateUtils";
import { estimateCodes } from "../../utils/statusCodes";
import { getProfileData } from "../../redux/profile/profileActions";

export const SET_ESTIMATE_SUCCESS = 'SET_ESTIMATE_SUCCESS';
export const SET_ESTIMATE_START = 'SET_ESTIMATE_START';
export const SET_MODE_SUCCESS = 'SET_MODE_SUCCESS';
export const SET_MODAL_CHANGE_REQUISITES_SUCCESS = 'SET_MODAL_CHANGE_REQUISITES_SUCCESS';
export const SET_NEW_FILES_SUCCESS = 'SET_NEW_FILES_SUCCESS';
export const CHANGE_FILES_TO_CURRENT = 'CHANGE_FILES_TO_CURRENT';
export const SET_STATUSES_SUCCESS = 'SET_STATUSES_SUCCESS';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const SET_DESCRIPTION_ITEM_SUCCESS = 'SET_DESCRIPTION_ITEM_SUCCESS';
export const SET_ADDED_FILES_SUCCESS = 'SET_ADDED_FILES_SUCCESS';
export const SET_MARK_FILE_REMOVE = 'SET_MARK_FILE_REMOVE';
export const SET_NEW_MARK_FILE_REMOVE = 'SET_NEW_MARK_FILE_REMOVE';
export const SET_MESSAGE_ITEM_SUCCESS = 'SET_MESSAGE_ITEM_SUCCESS';
export const SET_ADD_MESSAGE_TO_MAIN = 'SET_ADD_MESSAGE_TO_MAIN';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_ESTIMATE_WORK_FLOW = 'SET_ESTIMATE_WORK_FLOW';
export const SET_JOBPLAN_WORK_FLOW = 'SET_JOBPLAN_WORK_FLOW';
export const SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES';
export const SET_JOBPLAN_CONTRACT_ID = 'SET_JOBPLAN_CONTRACT_ID';
export const CLEAR_ESTIMATE_FILES = 'CLEAR_ESTIMATE_FILES';
export const SET_ERROR = 'SET_ERROR';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const setMessageItem = (payload) => ({
  type: SET_MESSAGE_ITEM_SUCCESS,
  payload
})

const setAddMessageToMain = (payload) => ({
  type: SET_ADD_MESSAGE_TO_MAIN,
  payload
})

export const setDescriptionItem = (payload) => ({
  type: SET_DESCRIPTION_ITEM_SUCCESS,
  payload
})

const setEstimateStart = () => ({
  type: SET_ESTIMATE_START,
})

export const setModeSuccess = (payload) => ({
  type: SET_MODE_SUCCESS,
  payload
})

export const setModalChangeRequisites = (payload) => ({
  type: SET_MODAL_CHANGE_REQUISITES_SUCCESS,
  payload
})

export const deleteItemSuccess = (payload) => ({
  type: DELETE_ITEM_SUCCESS,
  payload
})

export const setAddedFiles = (payload) => ({
  type: SET_ADDED_FILES_SUCCESS,
  payload
});

export const clearEstimateFiles = () => ({
  type: CLEAR_ESTIMATE_FILES,
})

export const setMarkFileRemove = (payload) => ({
  type: SET_MARK_FILE_REMOVE,
  payload
});

export const setNewMarkFileRemove = (payload) => ({
  type: SET_NEW_MARK_FILE_REMOVE,
  payload
});

const setError = (payload) => ({
  type: SET_ERROR,
  payload
})

const setStatusesSuccess = (payload) => ({
  type: SET_STATUSES_SUCCESS,
  payload
})

const setEstimate = (payload) => ({
  type: SET_ESTIMATE_SUCCESS,
  payload
})

const changeFilesToCurrent = (payload) => ({
  type: CHANGE_FILES_TO_CURRENT,
  payload
});

const setEstimateWorkFlow = (payload) => ({
  type: SET_ESTIMATE_WORK_FLOW,
  payload
})


const setJobplanWorkFlow = (payload) => ({
  type: SET_JOBPLAN_WORK_FLOW,
  payload
})

const setJobplanContractId = (payload) => ({
  type: SET_JOBPLAN_CONTRACT_ID,
  payload
})

const setDocumentTypes = (payload) => ({
  type: SET_DOCUMENT_TYPES,
  payload
})

export const setActiveTab = (payload) => ({
  type: SET_ACTIVE_TAB,
  payload
})

export const getEstimateById = (id) => async (dispatch) => {
  dispatch(setEstimateStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetEstimateByID?ID=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    
    if(result.status !== 200) {
      dispatch(setError(new Error("Ошибка загрузки")));
      return;
    }
    dispatch(setEstimate(result.data));
    return result.data;
  } catch (e) {
    dispatch(setError(e))
  }
}

export const getEstimatorEstimate = (id) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.get(Config.BACKEND_ADDRESS + `Estimator/GetOrderById?Id=${id}`, {
      headers: {
        Authorization: `Bearer ${token.token}`,
      }
    })
    dispatch(setEstimate(result.data));
  } catch (e) {
    dispatch(setError(e))
  }
}

export const saveEstimateSuccess = (data) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/SaveEstimate', {
      ...data,
      workStartPlan: new Date(parseDate(data.workStartPlan)).toISOString(),
      workEndPlan: new Date(parseDate(data.workEndPlan)).toISOString()
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setEstimate(result.data));
  } catch (e) {
    dispatch(setError(e))
  }
}

export const saveEstimateBreakdownSuccess = (data) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/SaveEstimate', {
      ...data,
      workStartPlan: new Date(parseDate(data.workStartPlan)).toISOString(),
      workEndPlan: new Date(parseDate(data.workEndPlan)).toISOString()
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setEstimate(result.data));
    dispatch(updateStatusEstimate({entityId: data.id, newState: estimateCodes.jobPlanRequire}));
  } catch (e) {
    dispatch(setError(e))
  }
}

const setUploadProgress = (payload) => ({
  type: SET_UPLOAD_PROGRESS,
  payload
})

export const saveEstimateFile = (data, index) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + `File/UploadFile`, data, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token.token}`,
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch(setUploadProgress({inProgress: true, percentCompleted, index}))
      }
    });
    dispatch(setUploadProgress({inProgress: false}));
    return result.data;
  } catch (e) {
    baseNotification(`Ошибка загрузки: ${e}`, "","danger");
    dispatch(setUploadProgress({inProgress: false}));
  }
}

export const setNewFiles = (payload) => ({
  type: SET_NEW_FILES_SUCCESS,
  payload
});

export const saveNewMessage = (data) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    await axios.post(Config.BACKEND_ADDRESS + `Contractor/AddEstimateMessage`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`
      },
    })
  } catch (e) {
    dispatch(setError(e))
  }
}

export const saveDocumentsAndState = (filesAdded, values) => async (dispatch, getState) => {
  await saveEstimateDocument(filesAdded)(dispatch, getState);
  saveEstimateBreakdownSuccess(values)(dispatch);
}

export const saveEstimateDocument = (data, newStatusData) => async (dispatch, getState) => {
  const state = getState();
  const addMessage = state.estimateReducer.addMessage
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const arr = [];
    await Promise.all(data.map(async (el) => {
      const result = await axios.post(Config.BACKEND_ADDRESS + `Estimate/SaveDocument`, {
        description: el.description,
        documentType: el.documentType,
        url: el.url,
        originalFileName: el.originalFileName,
        fileId: el.fileId,
        contentType: el.contentType,
        entityid: el.estimateId,
        json: el.json
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        }
      })
      const documentResult = {};
      documentResult.document = result.data;
      arr.push(documentResult);
      baseNotification("Успешно", `файл ${documentResult.document?.originalFileName} добавлен`, "success");
    }));
    if(newStatusData){
      dispatch(updateStatusEstimate(newStatusData));
    }
    dispatch(changeFilesToCurrent(arr));
    dispatch(setMessageAddFile(addMessage));
    dispatch(setAddedFiles([]));
  } catch (e) {
    console.log(e);
    dispatch(setError(e));
    baseNotification("Ошибка", e?.response?.data, "danger");
  }
}

export const removeEstimateDocuments = (data) => async(dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    await axios.post(Config.BACKEND_ADDRESS + `Estimate/MarkDeletedDocs`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`
      },
    })
  } catch (e) {
    dispatch(setError(e))
  }
}

export const deleteEstimateDocument = (data) => async (dispatch) => {
  try {
    dispatch(deleteItemSuccess(data.documentId));
    baseNotification("Файл удален", "", "success");
  } catch (e) {
    baseNotification("Ошибка", "", "danger");
  }

}

const setMessageAddFile = (addMessage) => (dispatch) => {
  try {
    if (addMessage) {
      dispatch(saveNewMessage(addMessage))
      const newMessage = {
        document: null,
        message: {
          text: addMessage.messageText,
          estimateId: addMessage.estimateId,
          createdAt: new Date().toISOString()
        }
      }
      dispatch(setAddMessageToMain(newMessage))
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateStatusEstimate = (data) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    await axios.post(Config.BACKEND_ADDRESS + 'Estimate/SaveNewStatus', data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    })
    dispatch(setStatusesSuccess(data));
    dispatch(getEstimateById(data.entityId));
    dispatch(getProfileData());
  } catch (e) {
    baseNotification("Ошибка", e?.message, "danger");
    dispatch(setError(e))
  }
}

export const getStatusesEstimate = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const response =  await axios.get(Config.BACKEND_ADDRESS + `Estimate/GetWorkFlow/EstimateCard`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setEstimateWorkFlow(response.data));
  } catch (e) {
    dispatch(setError(e))
  }
}

export const getStatusesJobplan = () => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const response =  await axios.get(Config.BACKEND_ADDRESS + `Estimate/GetWorkFlow/JobplanCard`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setJobplanWorkFlow(response.data));
  } catch (e) {
    dispatch(setError(e))
  }
}

export const getJobplanContractId = (id) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const response =  await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetJobPlanOffer/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setJobplanContractId(response.data));
  } catch (e) {
    dispatch(setError(e))
  }
}

export const getDocumentTypes = () => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetDocumentTypes`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    })
    dispatch(setDocumentTypes(response.data))
    } catch (e) {
      dispatch(setError(e))
    }
}
