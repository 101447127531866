import axios from "axios";
import Config from "../config";

export const downloadFileItem = async (id: string, fileName: string) => {
  try {
    const token = JSON.parse(localStorage.getItem("user") as string);
    const result = await axios.get(Config.BACKEND_ADDRESS + `File/GetFileByDocumentId?id=${id}`, { 
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
      responseType: "arraybuffer",
    })
    downloadFile(result.data, fileName);
  } catch (e) {
    console.log(e);
  }
};

export const downloadFile = (data: ArrayBuffer, filename: any) => {
  const a: HTMLAnchorElement = document.createElement("a");
  a.href = window.URL.createObjectURL(new Blob([data], {type: "application/octet-stream"}));
  a.download = filename || "download";
  const clickHandler = () => {
    a.removeEventListener("click", clickHandler);
  };
  a.addEventListener("click", clickHandler, false);
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
}