import { TBrigade } from "./types"

interface IBrigadeRow {
    brigade: TBrigade,
}

export const BrigadeRow = ({brigade}: IBrigadeRow) => {
    return (
      <div className="row__brigade">
        <div>{brigade.brigadierName}</div>
        <div>{brigade.brigadierPhone}</div>
        <div>{brigade.memberCount}</div>
        <div>
            {brigade.specializations.map(specialization => (
              <span>{specialization}</span>
            ))}
        </div>
        <div>{`${brigade.finishedOrdersCount} / ${brigade.workOrdersCount}`}</div>
        <div>{brigade.rate}</div>
      </div>
    )
}