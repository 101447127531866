import { combineReducers } from "redux";

import contractorReducer from "./reducers/ContractorReducer";
import estimateReducer from './estimate/estimateReducer';
import userReducer from "./user/userReducer";
import jobPlan from "./jobPlan/jobPlanReducer";
import order from './order/orderReducer';
import brigadesList from './brigadesList/brigadesListReducer';
import authReducer from "./auth/authReducer";
import ordersList from './ordersList/ordersListReducer';
import buildingList from './buildingList/buildingListReducer';
import billing from './billing/billingReducer';
import statuses from './statuses/statusesReducer';
import profile from './profile/profileReducer';
import waiting from './waiting/waitingReducer';

const Reducers = combineReducers({
  contractorReducer,
  estimateReducer,
  authReducer,
  userReducer,
  jobPlan,
  order,
  brigadesList,
  ordersList,
  buildingList,
  billing,
  statuses,
  profile,
  waiting,
});

export default Reducers;
