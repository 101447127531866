type EstimateJobParams = {
    id: string,
    activeTabNum: number;
}
const estimateJobParams = (params: string): EstimateJobParams => {
    if(!params) return {id: "", activeTabNum: 0};
    const paramsSplit = params.split("&");
    const res: EstimateJobParams = { id: paramsSplit[0], activeTabNum: paramsSplit[1] === "job" ? 1 : 0 };
    return res;
}

export default estimateJobParams;