import { useEffect } from "react";
import Table from "../../components/BuildingsList/Table";
import FooterList from "../../components/BuildingsList/FooterList";
import "../../styles/role_contractor.css";
import { useDispatch, useSelector } from "react-redux";
import { getBuildings, getEstimateWork } from "../../redux/actions/actionContractor";
import ObjectPageHead from "../../components/ObjectPageHead/ObjectPageHead";
import { getEstimateTypes } from "../../redux/actions/actionContractor";
import { ErrorPanel } from "../../components/ErrorPanel/ErrorPanel";
import { IBuilding } from "../../components/BuildingsList/Types";

const BuildingsListPage = () => {
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number;
  const estimateTypes = useSelector((state: any) => state.contractorReducer.estimateTypes);
  const scrollPosition = useSelector((state: any) => state.buildingList.scrollPosition);
  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.value = "page__building--list role__contractor";
  }
  const dispatch = useDispatch<any>();
  const {buildingsObject, estimateWorkFlow, isLoading, error} = useSelector(
    (state: any) => state.contractorReducer
  );

  useEffect(() => {
    if (!estimateWorkFlow) {
      dispatch(getEstimateWork());
    }
  }, [estimateWorkFlow, dispatch]);

  useEffect(() => {
    if (!estimateTypes) {
      dispatch(getEstimateTypes());
    }
  },[estimateTypes, dispatch]);

  useEffect(() => {
    dispatch(getBuildings());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [buildingsObject, scrollPosition]);

  if (isLoading || !userRoleCode) return null;
  if (!isLoading && error?.code ){
    return <ErrorPanel errorMsg="Не удалось загрузить список объектов" marginTop="100px"/>
  }

  const buildings = buildingsObject.buildings as IBuilding[];

  return (
    <>
      <main style={{marginBottom: "30px"}}>
        <ObjectPageHead title="Список объектов" roleCode={userRoleCode}/>
        <Table buildings={buildings}/>
      </main>
      <FooterList buildings={buildingsObject.buildings}/>
    </>
  );
};

export default BuildingsListPage;
