
export const SET_WAITING_START = "SET_WAITING_START";
export const SET_WAITING_STOP = "SET_WAITING_STOP";

export const setWaitingStart = () => ({
  type: SET_WAITING_START
})

export const setWaitingStop = () => ({
  type: SET_WAITING_STOP
})