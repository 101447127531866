import { type IPaymentObject } from '../../../../types/Billing'
import PlanningListBuilding from './components/PlanningListBuilding/PlanningListBuilding'

interface IPlanningList {
  items: IPaymentObject[]
}

function PlanningList ({ items }: IPlanningList): JSX.Element {
  return (
    <>
      {items?.map(building => (
        <PlanningListBuilding key={building.entityId} building={building}/>
      ))}
    </>
  )
}

export default PlanningList
