import { type TListCaptionItem, type IFilterPanelParams } from './Types'
import { type ChangeEventHandler } from 'react'

const TextInput = (captionItem: TListCaptionItem, changeHandler: ChangeEventHandler): JSX.Element => {
  return (<input
    className="live-search"
    key={captionItem.captionId}
    name={`filter__${captionItem.captionId}`}
    type="text"
    placeholder="(начните вводить текст)"
    onChange={changeHandler}/>)
}

const getFilterSelect = (items: string[], caption: string, changeHandler: ChangeEventHandler): JSX.Element => {
  return (
    <select name={`filter__${caption}`}
      onChange={changeHandler}
      key={caption}
      className="selected">
      <option value="all-statuses" key="all">(все)</option>
      {items.map((item, index) => (
        <option value={index}
          key={index}>
          {item}
        </option>
      ))}
    </select>
  )
}

const membersInput = (captionItem: TListCaptionItem, changeHandler: ChangeEventHandler): JSX.Element => {
  const orderFilterValues = [
    'Кол-во чел. в бригаде (по убыванию)',
    'Кол-во чел. в бригаде (по возрастанию)'
  ]
  return getFilterSelect(orderFilterValues, captionItem.captionId, changeHandler)
}

const OrdersInput = (captionItem: TListCaptionItem, changeHandler: ChangeEventHandler): JSX.Element => {
  const orderFilterValues = [
    'Выполненные (по убыванию)',
    'Выполненные (по возрастанию)',
    'Невыполненные (по убыванию)',
    'Невыполненные (по возрастанию)'
  ]
  return getFilterSelect(orderFilterValues, captionItem.captionId, changeHandler)
}

const RateInput = (captionItem: TListCaptionItem, changeHandler: ChangeEventHandler): JSX.Element => {
  const orderFilterValues = ['По убыванию', 'По возрастанию']
  return getFilterSelect(orderFilterValues, captionItem.captionId, changeHandler)
}

const FilterPanel = ({ listCaptions, changeHandler }: IFilterPanelParams): JSX.Element => {
  return (
    <div className="bar__filters">
      {listCaptions.map((captionItem: TListCaptionItem) => {
        switch (captionItem.filtertype) {
          case 'text': return TextInput(captionItem, changeHandler)
          case 'members': return membersInput(captionItem, changeHandler)
          case 'orders': return OrdersInput(captionItem, changeHandler)
          case 'rate': return RateInput(captionItem, changeHandler)
          default: return (<></>)
        }
      })}
    </div>
  )
}
export default FilterPanel
