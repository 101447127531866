import { IDocumentsCodes, IEstimateCodes, IOrderCodes } from "../types/Documents"

export const profileStatusCodes = {
    signIn:     71,
    onCheck:    72,
    isChecked:  73,
    notChecked: 74,
};

export const estimateDocumentCodes: IDocumentsCodes = {
    specification: 3,                   // Спецификация
    technicalTask: 1,                   // ТЗ
    workDraft: 2,                       // Рабочая документация
    workFlow: 10,                       // Наряд / График производства работ /
    workFlowImage: 11,                  // Эскиз наряда
    jobPlanTask: 4,                     // ТЗ на разбивку
    jobPlanContract: 5,                 // Договор на разбивку
    buildContract: 6,                   // Договор СМР
    followContract: 7,                  // Договор сопровождения
    completionCert: 8,                  // Акт выполненных работ
    bill: 9,                            // Cчет на оплату
    complaintDoc: 14,                   // Документ спора
    selfemployedCheck: 15               // Чек самозанятого
}

export const estimateCodes: IEstimateCodes = {
    jobPlanRequire: 35,                 // Запрос разбивки
    jobPlanPaidRequire: 49,             // Запрос разбивки (оплачен)
    moreJobPlanDataRequire: 75,         // Дозапрос по разбивке
    jobPlanDataRequireEdit: 79,         // Дозапрос данных (редактирование)
    jobPlanDataRequireSave: 81,         // Дозапрос данных (сохранено)
    jobPlanDataRequireDone: 80,         // Данные предоставлены
    jobPlanDone: 50,                    // Разбивка выполнена
    remarksInProgress: 51,              // Внесение замечаний
    jobPlanRemarks: 52,                 // Замечания по разбивке
    jobPlanAccepted: 53,                // Разбивка утверждена
    inEdit: 25,                         // Редактируется
    newEstimate: 26,                    // Новая
    offerRequest: 27,                   // Запрос КП
    offerCalculation: 28,               // Расчет КП
    moreOfferDataRequire: 29,           // Дозапрос по КП
    dataRequireEdit: 77,                // Дозапрос (редакт)
    repeatOfferRequest: 31,             // Запрос КП повторно
    canceled: 30,                       // Отклонено Н/Д
    offerOnAccept: 32,                  // КП на согласовании
    offerAccepted: 33,                  // КП принято
    offerCanceled: 34,                  // КП отклонено
}

export const contractorObjectRoles = {
    "1": "Застройщик",
    "2": "Генподрядчик",
    "3": "Заказчик",
    "4": "Подрядчик"
};

export const orderCodes: IOrderCodes = {
    created: 36,                        // Сформирован
    hasAjust: 37,                       // Замечания по наряду
    approved: 38,                       // Утверждён
    runOverdue: 39,                     // Просрочен запуск
    runReady: 40,                       // К запуску
    noBrigade: 57,                      // Бригада не подобрана
    offer: 41,                          // Оферта
    waitPayment: 59,                    // К оплате
    prePayied: 60,                      // Предоплачен
    inWork: 61,                         // В работе
    doneOverdue: 58,                    // Просрочена сдача
    complaint: 62,                      // Спор
    terminated: 63,                     // Расторгнут
    readyDone: 64,                      // Сдача работы
    workOnRemarks: 65,                  // Исправление замечаний
    done: 66,                           // Работы приняты
    closed: 67                          // Закрыт
  }

