import BuildingDataLeftPanel from './BuildingDataLeftPanel'
import BuildingDataRightPanel from './BuildingDataRightPanel'
import style from './buildingdata.module.scss'

const BuildingData = (): JSX.Element => {
  return (
    <div className={style.area__data}>
      <BuildingDataLeftPanel/>
      <BuildingDataRightPanel/>
    </div>
  )
}

export default BuildingData
