import type React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import './header.scss'
import styles from './header.module.scss'
import { ModalObject } from '../BuildingsList/Modals/ModalObject'
import { useDispatch, useSelector } from 'react-redux'
import { ModalEstimateType } from '../../pages/EstimateManagement/StatusBar/ModalTypeEstimate/ModalEstimateType'
import { type IProfileState } from '../../redux/profile/types'
import { profileStatusCodes } from '../../utils/statusCodes'
import { getEstimateCount } from '../../utils/estimateUtils'
import { ROLECODES, getRoleName } from '../../utils/getRoleName'
import { type IUser } from '../../types/User'

interface ITab {
  id: number
  path: string
  name: string
  visible: boolean
  notifications: number
}

const generateTabs = (
  buildings: number,
  orders: number,
  complaints: number,
  billingCount: number,
  userRoleCode: number
): ITab[] => {
  const commonVisible = ((userRoleCode === ROLECODES.FOREMAN) || (userRoleCode === ROLECODES.CONTRACTOR))
  return [
    {
      id: 0,
      path: '/list-contractor',
      name: 'Объекты',
      visible: commonVisible,
      notifications: buildings
    },
    {
      id: 1,
      path: '/orders',
      name: 'Наряды',
      visible: commonVisible,
      notifications: orders
    },
    {
      id: 2,
      path: '/complaint-list',
      name: 'Споры',
      visible: commonVisible,
      notifications: complaints
    },
    {
      id: 3,
      path: '/billing',
      name: 'Начисления',
      visible: userRoleCode === ROLECODES.CONTRACTOR,
      notifications: billingCount
    },
    {
      id: 4,
      path: '/brigade-list',
      name: 'Бригады',
      visible: commonVisible,
      notifications: 0
    }
  ]
}

interface IHeader {
  tab?: number
}

export default function Header ({ tab = -1 }: IHeader): JSX.Element {
  const buildings = useSelector((state: any) => state.contractorReducer.buildingsObject)
  const [login, setLogin] = useState(false)
  const [modal, setModal] = useState(false)
  const [isList, setIsList] = useState(false)
  const [modalTypes, setModalTypes] = useState(false)
  const dispatch = useDispatch<any>()
  const location = useLocation()
  const [selectedTypeValue, setSelectedTypeValue] = useState({})
  const profile = useSelector<any>(state => state.profile) as IProfileState
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number
  const estimateTypes = useSelector((state: any) => state.contractorReducer.estimateTypes)
  const user = useSelector((state: any) => state.userReducer.user) as IUser
  const history = useHistory()

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const buildingsCount = buildings.buildings?.length || profile.data?.statistics?.buildingsTotal || 0
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const buildingsNotificationCount = profile.data?.notifications?.buildings ?? 0
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const ordersNotificationCount = profile.data?.notifications?.orders ?? 0
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const complaintsNotificationCount = profile.data?.notifications?.complain ?? 0
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const billingNotificationCount = profile.data?.notifications?.billings ?? 0

  const tabs = useMemo(
    () => generateTabs(buildingsNotificationCount,
      ordersNotificationCount,
      complaintsNotificationCount,
      billingNotificationCount,
      userRoleCode),
    [buildingsNotificationCount, ordersNotificationCount, complaintsNotificationCount, billingNotificationCount, userRoleCode]
  )

  useEffect(() => {
    if (location.pathname !== '/' &&
        location.pathname !== '/login' &&
        location.pathname !== '/register' &&
        location.pathname !== '/invite' &&
        (Boolean(user))) {
      setLogin(true)
    } else {
      setLogin(false)
    }
    setIsList((location.pathname === '/list-contractor') || location.pathname.includes('/building-card'))
  }, [login, dispatch, location, user])

  const upperFirstLetter = (word: string): string => {
    return word.length > 0 ? word[0]?.toUpperCase() : ''
  }

  const getRootClassList = (roleName: string, pathname: string): string => {
    switch (pathname) {
      case '/complaint-list': return `page__complaint--list role__${roleName}`
      case '/brigade-list': return `page__brigade--list role__${roleName}`
      default: return 'page__building--list role__contractor modal__open--creating-building'
    }
  }

  const goToPage = (event: React.MouseEvent): void => {
    event.stopPropagation()
    event.preventDefault()
    const el = event.target as HTMLElement
    if (el.tagName === 'A') {
      history.push(el.getAttribute('href') as string)
    }
  }

  if (login) {
    const element = document.getElementById('root')
    if (element !== null) {
      element.classList.value = getRootClassList(getRoleName(user?.role), location.pathname)
    }
  }

  return (
    <>
      {login
        ? <header className="header">
          { isList && <ModalObject selectedTypeValue={selectedTypeValue} setEstimateType={setModalTypes}
                       isOpen={modal} setModal={setModal}/>
          }
          <ModalEstimateType selectedTypeValue={selectedTypeValue} setSelectedTypeValue={setSelectedTypeValue}
                             types={estimateTypes} isOpen={modalTypes} setOpen={setModalTypes}/>
          <nav>
            {tabs.filter(item => item.visible).map((item: any) => (
              <Link
                key={item.id}
                className={`
                  nav__tab-box 
                  ${tab === item.id ? 'current' : ''} 
                  ${((profile.data?.contractor?.status) !== undefined) && profile.data?.contractor?.status !== profileStatusCodes.isChecked
                      ? styles.disabledTab
                      : ''} 
                  tabs
                `}
                to={item.path}
              >
                <span>{item.name}</span>
                {item.notifications > 0 && <span>{item.notifications}</span>}
              </Link>
            ))}
            {isList && (userRoleCode === ROLECODES.CONTRACTOR) && (
              <button
                className="control__creating--building"
                title="Окно создания Объекта"
                disabled={ profile.data?.contractor?.status !== profileStatusCodes.isChecked }
                onClick={() => { setModal(true) }}
              >
                Новый объект
              </button>
            )}
          </nav>
          <div></div>
          <div className="top-bar__user--box current">
          <span className={`limit__text--length ${styles.contractorNameContainer}`}>
            <span className="symbol__quotes">{profile.data?.contractor?.name}</span>
          </span>
            {user?.firstName !== ''
              ? <a href="/profile" onClick={goToPage} title="Открыть страницу профиля">
                {`${user?.lastName} ${upperFirstLetter(user?.firstName)}. ${upperFirstLetter(user?.secondName)}.`}
              </a>
              : <a href="/profile" onClick={goToPage} title="Открыть страницу профиля">
                {user?.login}
              </a>
            }
            {userRoleCode === ROLECODES.CONTRACTOR
              ? <span>
                <span className="symbol__colon--after">Счёт</span>
                <span className="symbol__space-thin--after">{profile.data?.amount}</span>руб.
              </span>
              : <span>
                <span className="symbol__colon--after">Объекты</span>{buildingsCount} |
                <span className="symbol__space-thin--before symbol__colon--after">Сметы</span>{getEstimateCount(buildings?.buildings)}
              </span>
            }
          </div>
        </header>
        : <header>
          <div className="logo__box">
            <p>Монтаж</p>
            <p>платформа</p>
            <p>Оплата</p>
          </div>
          <div></div>
          <h1>Качественный монтаж <span style={{ fontFamily: 'bold-italic' }} className="symbol__mdash--before">гарантированная оплата</span>
          </h1>
        </header>}
    </>
  )
}
