import type React from 'react'
import { useState } from 'react'
import styles from './ReplaceForemanConfirm.module.scss'
import Modal from '../../../../../Modal/Modal'
import { type IProfileEmployee } from '../../../../../../types/Profile'
import { useDispatch, useSelector } from 'react-redux'
import { saveEmployee, changeForeman } from '../../../../../../redux/profile/profileActions'
import { baseNotification } from '../../../../../../utils/estimateUtils'
import { type IProfileState } from '../../../../../../redux/profile/types'

interface IReplaceForemanConfirm {
  employee: IProfileEmployee
  onClose: VoidFunction
}

const ReplaceForemanConfirm = ({ employee, onClose }: IReplaceForemanConfirm): JSX.Element => {
  const [newForemanId, setNewForemanId] = useState<string>('')
  const [changing, setChanging] = useState<boolean>(false)
  const profile = useSelector<any>(state => state.profile) as IProfileState
  const dispatch = useDispatch<any>()

  const goodEmployees = profile.data?.employees.filter(
    item => item.isVerified && !item.isBlocked && item.contractorEmployeeId !== employee.contractorEmployeeId
  )

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    onClose()
  }

  const handleChange = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    if (changing) {
      return
    }
    setChanging(true)
    try {
      await changeForeman(employee.contractorEmployeeId, newForemanId)
      await dispatch(saveEmployee({ ...employee, isBlocked: true }))
      setChanging(false)
      baseNotification('Успешно', 'Прораб заблокирован', 'success')
      onClose()
    } catch {
      baseNotification('Ошибка', 'Произошла ошибка', 'danger')
      setChanging(false)
    };
  }

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.target
    setNewForemanId(value)
  }

  return (
    <Modal title="Блокировка Прораба">
      <div className={styles.replaceFormanModal}>
        <div className={styles.replaceFormanModalNotification}>
            <div className={styles.replaceFormanModalUsername}>
              {employee.lastName} {employee.firstName} {employee.secondName}
            </div>
        </div>
        <div className={styles.replaceFormanModalContent}>

            <div className={styles.replaceFormanModalText}>
              <p>
                Внимание!<br/>
                Прораб назначен на действующие Сметы.<br/>
                Для возможности блокировки учётной записи<br/>
                замените Прораба на действующих Сметах.
              </p>
            </div>

          <hr/>

          <div className={styles.replaceFormanModalText}>Заменить на Прораба:</div>
          <select className={`${styles.replaceFormanModalSelect} ${newForemanId !== '' ? 'selected' : ''}`}
                  onChange={onChange}>
            <option value="" hidden>(обязательное)</option>
            {goodEmployees?.map(item => (
              <option
                value={item.contractorEmployeeId}
                key={item.contractorEmployeeId}>
                {`${item.lastName} ${item.firstName} ${item.secondName}`}
              </option>
            ))}
          </select>

          <div className={styles.replaceFormanModalFooter}>
            <button
              type="button"
              disabled={newForemanId === ''}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleChange}
            >
              Блокировать
            </button>
            <button type="button" onClick={handleClose}>Отмена</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ReplaceForemanConfirm
