import type React from 'react'
import { Link } from 'react-router-dom'
import { useCallback, useState } from 'react'
import styles from './MutualListBuilding.module.scss'
import { type IObject } from '../../../../../../types/Billing'
import { formatPrice } from '../../../../../../utils/formatPrice'
import MutualListEstimate from '../MutualListEstimate/MutualListEstimate'

interface IMutualListBuilding {
  building: IObject
}

function MutualListBuilding ({ building }: IMutualListBuilding): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev)
  }, [])

  return (
    <div className={styles.buildingSection}>
      <div className={`${styles.buildingRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <Link to={`/building-card/${building.entityId}`} title='Карточка объекта'>{building.name}</Link>
          <span>{building.buildingAddress}</span>
        </div>
        <div></div>
        <div>{building.paidPlatform as unknown as boolean ? formatPrice(building.paidPlatform) : ''}</div>
        <div>{building.paidBrigade as unknown as boolean ? formatPrice(building.paidBrigade) : ''}</div>
        <div>{building.actPlatform as unknown as boolean ? formatPrice(building.actPlatform) : ''}</div>
        <div>{building.actBrigade as unknown as boolean ? formatPrice(building.actBrigade) : ''}</div>
      </div>

      {isOpen && (
        <>
          {building.estimates.map(estimate => (
            <MutualListEstimate key={estimate.entityId} estimate={estimate}/>
          ))}
        </>
      )}
    </div>
  )
}

export default MutualListBuilding
