import {GET_ORDER_STATUSES_ERROR, GET_ORDER_STATUSES_START, GET_ORDER_STATUSES_SUCCESS} from "./statusesActionTypes";

const initialState = {
  orderStatuses: {
    data: null,
    error: false,
    loading: false,
  }
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDER_STATUSES_START:
      return {...state, orderStatuses: {...state.orderStatuses, loading: true}};

    case GET_ORDER_STATUSES_SUCCESS:
      return {...state, orderStatuses: {...state.orderStatuses, loading: false, data: payload}};

    case GET_ORDER_STATUSES_ERROR:
      return {...state, orderStatuses: {...state.orderStatuses, loading: false, error: true}};

    default:
      return { ...state };
  }
};

export default reducer;