export const getInitialForeman = (foremans, id) => {
  if (!foremans) {
    return '-'
  }
  const findedForeman = foremans?.find((el) => el.contractorEmployeeId === id);
  if (findedForeman) {
    return `${findedForeman.lastName} ${findedForeman.firstName.split('')[0]}. ${findedForeman.secondName.split('')[0]}.`
  } else {
    return '-'
  }
}