/* eslint-disable no-constant-condition */
import { getComplaintStatus } from '../../utils/getComplaints'
import { handlePlanDate } from '../../utils/estimateUtils'
import { getRoleTitle } from '../../utils/getRoleName'
import { useHistory } from 'react-router-dom'
import { type IComplaint, type TComplaintTopic } from './Types'
import { NotificationSign } from '../../components/NotificationSign/notificationSign'

interface IComplaintListPanel {
  complaintList: {
    complaints: IComplaint[]
  }
  complaintTopics: TComplaintTopic[]
}

const ComplaintListPanel = ({ complaintList, complaintTopics }: IComplaintListPanel): JSX.Element => {
  const history = useHistory()
  const getTopicName = (topicId: number): string => {
    const result = complaintTopics.filter(topic => topic.code === topicId)[0]
    return result?.name ?? ''
  }

  if (complaintList?.complaints === undefined) return (<></>)

  const goToPage = (id: string): void => {
    history.push(`/complaint-card?id=${id}`)
  }

  return (
    <>
      {
        complaintList.complaints.map((complaintItem) => {
          const complaintStatusName = getComplaintStatus(complaintItem.status)
          const topicName = getTopicName(complaintItem.topicId)
          const respondentName = getRoleTitle(complaintItem.respondentRole)
          return (
            <div onClick={() => { goToPage(complaintItem.id) }} key={complaintItem.id}>
              <div className={`row__complaint animate__clickable 
                               status__complaint--${complaintStatusName}`}>
                <div className="limit__text--length">
                  {complaintItem.buildingName}
                  <NotificationSign
                    hasNotification={complaintItem.hasNotification}
                    notificationText={complaintItem.notificationText}
                    page='complaint-list'
                  />
                </div>
                <div className="limit__text--length">{complaintItem.buildingAddress}</div>
                <div className="limit__text--length">{complaintItem.estimateTitle}</div>
                <div className="limit__text--length">{complaintItem.orderName}</div>
                <div>{topicName}</div>

                <div>
                  {complaintItem?.messages[0]?.message?.text ?? null}
                </div>

                <div className="text__status--complaint"></div>

                <div className="limit__text--length">{complaintItem.authorFio}</div>

                <div className="limit__text--length">{complaintItem.respondentFio}</div>

                <div>
                  <span>{handlePlanDate(complaintItem.startDate, false)}</span>
                  <span className="symbol__mdash--before"></span>
                  <span className="symbol__hellip--before"></span>
                  <span>{handlePlanDate(complaintItem.endDate, false)}</span>
                </div>

                <div>
                  <span>{handlePlanDate(complaintItem.downtimeStartDate, false)}</span>
                  <span className="symbol__mdash--before"></span>
                  <span className="symbol__hellip--before"></span>
                  <span>{handlePlanDate(complaintItem.downtimeEndDate, false)}</span>
                </div>

                <div>
                  <span>{respondentName}</span>
                </div>

                <div title="Продолжительность простоя">
                  <span>
                    <span>{complaintItem.downtime}</span><span>дн.</span>
                  </span>
                </div>
              </div>
            </div>
          )
        })}
     </>
  )
}
export default ComplaintListPanel
