import moment from "moment";

export const incrementTabsCount = () => {
    let tabsCount = JSON.parse(localStorage.getItem("tabsCount") as string);
    if(tabsCount){
      tabsCount++;
    }else{
      tabsCount = 1;
    }
    localStorage.setItem("tabsCount", tabsCount);
    if(tabsCount === 1) checkExpiration();
}

export const decrementTabsCount = () => {
    let tabsCount = JSON.parse(localStorage.getItem("tabsCount") as string);
    if(tabsCount){
      tabsCount--;
    }else{
      tabsCount = 0;
    }
    if(tabsCount === 0){
      addUserLastActiveMark();
    }
    localStorage.setItem("tabsCount", tabsCount);
}

const addUserLastActiveMark = () => {
  const lastActive = moment().format();
  localStorage.setItem("lastActive", lastActive);
}

const checkExpiration = () => {
    const maxReloadTime = 10000;
    const user = JSON.parse(localStorage.getItem("user") as string);
    if(user?.isRemember) return;
    const lastActive = localStorage.getItem("lastActive");
    if(lastActive){
      const diff = moment().diff(lastActive);
      if(diff > maxReloadTime){
        localStorage.removeItem("user");
      }
    }
}