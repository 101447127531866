import React from 'react'
import style from '../../pages/EstimateManagement/Equipment/equipment.module.scss'

const JobList = ({ arr }: any) => {
  return (
      <div className={`group__list--system list__childs--highlight ${style.openContainer}`}>

        <div className={`${style.firstHeader} list__row--headers`}>
          <span style={{ width: '50px', paddingLeft: '6px' }} title="Номер позиции по спецификации">№</span>
          <span style={{ width: '260px' }}>Система</span>
          <span style={{ width: '464px' }}>Оборудование / Материалы</span>
          <span style={{ width: '70px' }} title="Единица измерения">Ед. изм.</span>
          <span style={{ width: '60px' }} title="Количество штук">Кол-во</span>
        </div>

        {arr.map((el: any, index: number) =>
            <div className={`list__row--equip-1 ${style.mapSubitems}`} key={index}>
              <span className={style.mapOneItem} style={{ width: '50px', paddingLeft: '6px' }}>{el.position}</span>
              <span className={style.mapOneItem} style={{ width: '260px' }}>{el.systemName}</span>
              <span className={style.mapOneItem} style={{ width: '464px' }}>{el.equipmentName}</span>
              <span className={style.mapOneItem} style={{ width: '70px' }}>{el.matUnit}</span>
              <span className={style.mapOneItem} style={{ width: '60px' }}>{el.matCount}</span>
            </div>)}
      </div>
  )
}

export { JobList }
