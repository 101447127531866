interface IErrorPanel {
  errorMsg: string
  marginTop?: string
}

export const ErrorPanel = ({ errorMsg, marginTop }: IErrorPanel) => {
  return (
        <div style={{ marginTop: marginTop || '0', width: '400px' }}>
            <h1>Ошибка</h1>
            {errorMsg && <p style={{ marginTop: '20px' }}>{errorMsg}</p>}
        </div>
  )
}
