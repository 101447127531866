import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './MutualListContract.module.scss'
import { type IContract } from '../../../../../../types/Billing'
import { formatPrice } from '../../../../../../utils/formatPrice'
import { parseDateString, parseInputDate } from '../../../../../../utils/estimateUtils'

interface MutualListContractProps {
  contract: IContract
}

function MutualListContract ({ contract }: MutualListContractProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev)
  }, [])

  return (
    <div className={styles.contractSection}>
      <div className={`${styles.contractRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          {contract.entityId 
            ? <Link to={`/order/${contract.entityId}`} title="Карточка наряда">{contract.name}</Link> 
            : <div>{contract.name}</div>
          }
        </div>
        <div></div>
        <div>{contract.paidPlatform as unknown as boolean ? formatPrice(contract.paidPlatform) : ''}</div>
        <div>{contract.paidBrigade as unknown as boolean ? formatPrice(contract.paidBrigade) : ''}</div>
        <div>{contract.actPlatform as unknown as boolean ? formatPrice(contract.actPlatform) : ''}</div>
        <div>{contract.actBrigade as unknown as boolean ? formatPrice(contract.actBrigade) : ''}</div>
      </div>

      {isOpen && (
        <div className={styles.billsList}>
          {contract.documents.map(item => (
            <div className={styles.billsRow} key={item.entityId}>
              <div className="symbol__bull--before"></div>
              <div>{item.name}</div>
              <div title={parseInputDate(item.date)}>{parseDateString(item.date)}</div>
              <div>{item.paidPlatform as unknown as boolean ? formatPrice(item.paidPlatform) : ''}</div>
              <div>{item.paidBrigade as unknown as boolean ? formatPrice(item.paidBrigade) : ''}</div>
              <div>{item.actPlatform as unknown as boolean ? formatPrice(item.actPlatform) : ''}</div>
              <div>{item.actBrigade as unknown as boolean ? formatPrice(item.actBrigade) : ''}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MutualListContract
