import { downloadFileItem } from '../../utils/downloadItem'
const FileCard = (params: any): JSX.Element => {
  if (!params.fileCardObject) return (<></>)
  const { id, originalFileName, description } = params.fileCardObject?.document
  const downloadFile = () => {
	  downloadFileItem(id, originalFileName)
  }
  return (
		<div>
			<span className="symbol__bull--before"></span>
			<span className="limit__text--length"><span className="link-normal" onClick={downloadFile}>{originalFileName}</span></span>
			<span className="limit__text--length">{description}</span>
		</div>
  )
}

export default FileCard
