import { useSelector } from 'react-redux'
import style from './buildinginfobar.module.scss'
import { parseHumanInputDate } from '../../../utils/estimateUtils'

const BuildingInfoBar = () => {
  const { buildingsObject } = useSelector((state: any) => state.contractorReducer)
  if (!buildingsObject) return <></>
  return (
        <div className={style.bar__requisites}>
            <div>
                <span title="Начало самой ранней Разбивки">
                    {buildingsObject.veryFirstJobplan ? parseHumanInputDate(buildingsObject.veryFirstJobplan) : '...'}
                </span>
                <span className="symbol__mdash--before"></span>
                <span title="Окончание самой поздней Разбивки">
                    {buildingsObject.veryLastJobplan ? parseHumanInputDate(buildingsObject.veryLastJobplan) : '...'}
                </span>
            </div>

            <div>
                <span title="Общая стоимость всех Разбивок">Всего</span>
                <span className="mark__curr-ruble--after">{buildingsObject.signedEstimatesCost ?? 0}</span>
            </div>

            <div>
                <span title="«Всего» минус «В работе»">Остаток</span>
                <span className="mark__curr-ruble--after">{(buildingsObject.signedEstimatesCost ?? 0) - (buildingsObject.prepaymentCost ?? 0)}</span>
            </div>

            <div>
                <span title="Cумма авансов по всем договорам (Нарядам) СМР">В работе</span>
                <span className="mark__curr-ruble--after">{buildingsObject.prepaymentCost ?? 0}</span>
            </div>

            <div>
                <span title="Сумма по всем закрытым договорам (Нарядам) СМР">Выполнено</span>
                <span className="mark__curr-ruble--after">{buildingsObject.payedCost ?? 0}</span>
            </div>

            <div>
                <span className="symbol__colon--after" title="Всего / В работе">Смет</span>
                <span>{buildingsObject.estimatesTotal}</span>
                <span className="symbol__slash--before"></span>
                <span>{buildingsObject.estimatesOnwork}</span>
            </div>

        </div>
  )
}

export default BuildingInfoBar
