import React, { SyntheticEvent, useEffect, useState } from "react";
import lineart from "../../assets/lineart.svg";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import { NewFooter } from "../../components/footer/NewFooter";
import "../../styles/na_invite.css";
import { useQuery } from "../../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { fetchInviteRegistrationData, finishInviteRegistration } from "../../redux/auth/authActions";
import { Box, Modal } from "@mui/material";
import { getUser } from "../../redux/user/actionUser";
import { smsSend } from "../../utils/sendSms";
import { baseNotification } from "../../utils/estimateUtils";
import { ErrorPanel } from "../../components/ErrorPanel/ErrorPanel";
import style from './invite-page.module.scss';

interface State {
  password: string;
  code: string;
  passwordRepeat: string;
  showPassword: boolean;
}

const validatePassword = (password: string): boolean => {
  if (password) {
    const re = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
    if (re.test(password)) {
      return password.length < 8;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export default function Invite(): JSX.Element | null {
  const dispatch = useDispatch<any>();
  const query = useQuery();
  const history = useHistory();
  const registerData = useSelector((state: any) => state.authReducer.data);
  const errorData = useSelector((state: any) => state.authReducer.error);
  const isLoading = useSelector((state: any) => state.authReducer.isLoading);
  const [values, setValues] = useState<State>({
    password: '',
    code: '',
    passwordRepeat: '',
    showPassword: false
  });
  
  const registrationId: string | null = query.get('id');
  
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [smsError, setSMSError] = useState<boolean>(false);
  
  useEffect(() => {
    if (registrationId) {
      dispatch(fetchInviteRegistrationData(registrationId))
    }
  }, [registrationId, dispatch]);

  useEffect(() => {
    if(errorData?.response?.status === 400) setSMSError(true)
  },[errorData])
  
  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { password, code } = values;
    if(!registerData) return
    const { login, phone } = registerData;
    const res = await dispatch(finishInviteRegistration({ code, password, login, phone, registrationId }));
    if (res?.status === 200) {
      setShowSuccess(true);
      if(res.data) localStorage.setItem("user", JSON.stringify(res.data)); 
      dispatch(getUser());
      setTimeout(() => history.push("/profile"), 5000)
    }
  }

  const askForSmsHandler = async (e: React.MouseEvent<unknown>) => {
    await smsSend(null, registerData.login)
    .catch(() => {
      baseNotification("Ошибка", `Произошла ошибка`,"danger");
    })
  }
  
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.value = "page__invite non-authorized";
  }
  
  const passError: boolean = validatePassword(values.password);
  const passRepeatError: boolean = validatePassword(values.passwordRepeat);

  if(!registerData && isLoading) {
    return (
      <ErrorPanel errorMsg="Не удалось загрузить страницу регистрации" marginTop="200px"/>
    );
  }
  
  return (
    <>
      <Navbar />
      <main>
        <Modal
          open={showSuccess}>
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
          }}>
            Регистрация успешна!
            Вы будете перенаправлены на страницу профиля через 5 секунд.
          </Box>
        </Modal>
        <h2>Завершение регистрации</h2>
            <form className="account__mode--pass">
              <div className={style.items}>
                <h3>Логин</h3>
                <input
                  type="text"
                  autoComplete="off"
                  value={registerData?.login}
                  disabled
                />
              </div>
      
              <div className={style.items}>
                <h3>Телефон</h3>
                <input
                  type="text"
                  autoComplete="off"
                  value={registerData?.phone}
                  disabled
                />
              </div>
      
              <div className={style.items}>
                <h3>Код из СМС</h3>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="(не указано)"
                  onChange={handleChange("code")}
                  value={values.code}
                />
              </div>
      
              <div className={style.items}>
                <h3>Пароль</h3>
                <input
                  type="password"
                  autoComplete="off"
                  placeholder="(не указано)"
                  className={passError ? "error__box" : ""}
                  onChange={handleChange("password")}
                  value={values.password}
                  disabled={!values.code}
                />
              </div>
      
              <div className={style.items}>
                <h3>Пароль (проверка)</h3>
                <input
                  type="password"
                  placeholder="(не указано)"
                  className={passRepeatError ? "error__box" : ""}
                  onChange={handleChange("passwordRepeat")}
                  value={values.passwordRepeat}
                  disabled={!values.password}
                />
              </div>
      
              <button
                disabled={
                  !values.password || !values.code || !values.passwordRepeat || passError || passRepeatError || values.password !== values.passwordRepeat
                }
                onClick={submit}
                className={style.registerBtn}
              >
                Зарегистрироваться
              </button>
            </form>
    
            <div className="pic__back">
              <img src={lineart} alt="Фоновая картинка" />
            </div>
    
            <div
              className={
                smsError ? "info__box error__text" : "info__box"
              }
            >
              {smsError && (
                <>
                  <h3>Неверный код</h3>
                  <p>
                    Код не соответствует отправленному в СМС!
                    <br />
                    Введите код из СМС или{" "}
                    <span className="link-normal" onClick={askForSmsHandler} title="Отправка нового кода">
                      запросите новый код
                    </span>
                    .
                  </p>
                </>
              )}
              {!smsError && <>
                <h3>Код</h3>
                <p>
                  Код подтверждения регистрации действителен 10 минут.
                  <br/>
                  Если СМС не получено в течение нескольких минут, нажмите на
                  <span className="link-normal" onClick={askForSmsHandler} title="Инициация отправки повторного СМС"> эту ссылку</span>.
                </p>
        
                <h3>Пароль</h3>
                <p>
                  Длина: Не менее 8-и символов.
                  <br/>
                  Должен содержать: буквы обоих регистров, цифры и любые символы (например, пунктуации).
                  <br/>
                  Пароль в обоих полях должен быть одинковым.
                </p>
              </>}
            </div>
      </main>
      <NewFooter />
    </>
  );
}
