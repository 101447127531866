import type React from 'react'
import { useState, useMemo } from 'react'
import styles from './Employees.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type IProfileState } from '../../../../redux/profile/types'
import ChangeBlockConfirm from './components/ChangeBlockConfirm/ChangeBlockConfirm'
import ReplaceForemanConfirm from './components/ReplaceForemanConfirm/ReplaceForemanConfirm'
import CreateEmployee from './components/CreateEmployee/CreateEmployee'
import { sendFormanConfirmation } from '../../../../redux/profile/profileActions'
import { baseNotification } from '../../../../utils/estimateUtils'
import axios from 'axios'
import { type IProfileEmployee } from '../../../../types/Profile'
import Config from '../../../../config'

const Employees = (): JSX.Element => {
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null)
  const [changeBlockOpen, setChangeBlockOpen] = useState<boolean>(false)
  const [replaceForemanOpen, setReplaceForemanOpen] = useState<boolean>(false)
  const [createEmployeeOpen, setCreateEmployeeOpen] = useState<boolean>(false)
  const [verifiedIds, setVerifiedIds] = useState<string[]>([])
  const profile = useSelector<any>(state => state.profile) as IProfileState
  const dispatch = useDispatch<any>()

  const handleSelectRow = (id: string) => (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedRowId(id)
  }

  const selectedEmployee = useMemo(() => {
    if (profile.data?.employees.length === 0 || selectedRowId === null) {
      return null
    }

    return profile.data?.employees.find(item => item.userId === selectedRowId)
  }, [profile.data?.employees, selectedRowId])

  const getFormanEstimatesCount = async (foreman: IProfileEmployee | null | undefined): Promise<number> => {
    if (foreman == null) return -1
    try {
      const token = JSON.parse(localStorage.getItem('user') as string)
      const result = await axios.post(`${Config.BACKEND_ADDRESS as string}Foreman/GetActualEstimates`, {
        foremanId: foreman?.contractorEmployeeId
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.token as string}`
        }
      })
      return result?.data?.count ?? 0
    } catch (e) {
      baseNotification(`Ошибка запроса: ${e as string}`, '', 'danger')
      return -1
    }
  }

  const handleChangeBlock = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    if (selectedEmployee?.isBlocked as boolean) {
      setChangeBlockOpen(true)
    } else {
      const formanEstimatesCount = await getFormanEstimatesCount(selectedEmployee)
      if (formanEstimatesCount === 0) {
        setChangeBlockOpen(true)
      } else {
        setReplaceForemanOpen(true)
      }
    }
  }

  const handleCloseChangeBlock = (): void => {
    setChangeBlockOpen(false)
  }

  const handleCloseReplaceForeman = (): void => {
    setReplaceForemanOpen(false)
  }

  const handleOpenCreateEmployee = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setCreateEmployeeOpen(true)
  }

  const handleCloseCreateEmployee = (): void => {
    setCreateEmployeeOpen(false)
  }

  const handleVerify = (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    if (verifiedIds.includes(id)) {
      return
    }

    const employee = profile.data?.employees.find(item => item.userId === id)

    if (employee == null) {
      return
    }

    setVerifiedIds(prev => [...prev, id])
    dispatch(sendFormanConfirmation({ ...employee, isVerified: true })).then(() => {
      setVerifiedIds(prev => [...prev].filter(item => item !== id))
      baseNotification('Успешно', `Аккаунт ${employee.login} подтвержден`, 'success')
    }).catch(() => {
      setVerifiedIds(prev => [...prev].filter(item => item !== id))
      baseNotification('Ошибка', 'Произошла ошибка', 'danger')
    })
  }

  return (
    <div className={styles.employees}>

      <div className={styles.employeesCaptions}>

        <div>
          <span>Фамилия Имя Отчество</span>

          <button
            className="bttn__small"
            type="button"
            title="Регистрация пользователя с ролью «Прораб»"
            onClick={handleOpenCreateEmployee}
          >
            Добавить
          </button>
          <div>
            {(selectedEmployee != null) && (
              <button
                className="bttn__small"
                type="button"
                title={`${selectedEmployee.isBlocked ? 'Разблокировка' : 'Блокировка'} учётной записи Прораба`}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleChangeBlock}
              >
                {selectedEmployee.isBlocked ? 'Разблокировать' : 'Блокировать'}
              </button>
            )}
          </div>
        </div>
        <span>Логин</span>
        <span>E-mail</span>
        <span>Телефон</span>
        <span>Аккаунт</span>
      </div>

      <div className={styles.employeesData}>
        {profile.data?.employees.map(employee => (
          <div
            key={employee.userId}
            className={`
              ${styles.employeeItem} 
              ${employee.userId === selectedRowId ? styles.selected : ''} 
              ${employee.isBlocked ? styles.locked : ''}
            `}
            title="Для выбора кликните по строке"
            onClick={handleSelectRow(employee.userId)}
          >
            <span>{employee.lastName} {employee.firstName} {employee.secondName}</span>
            <span>{employee.login}</span>
            <span className={!employee.isVerified ? styles.notVerified : ''}>{employee.email}</span>
            <span className={!employee.isVerified ? styles.notVerified : ''}>{employee.phone}</span>
            <span className={styles.employeeItemStatus}>
              {
                employee.isBlocked
                  ? (<span className={styles.locked}>(заблокирован)</span>)

                  : employee.isVerified
                    ? (
                    <span className={styles.verified}>Подтвержден</span>
                      )
                    : (
                <button
                  className="bttn__small"
                  type="button"
                  title="Запросить подтверждение"
                  onClick={handleVerify(employee.userId)}
                  disabled={verifiedIds.includes(employee.userId)}
                >
                  Подтвердить
                </button>
                      )

              }
            </span>
          </div>
        ))}
      </div>

      {changeBlockOpen && (selectedEmployee != null) && (
        <ChangeBlockConfirm
          employee={selectedEmployee}
          onClose={handleCloseChangeBlock}
        />
      )}

      {replaceForemanOpen && (selectedEmployee != null) && (
        <ReplaceForemanConfirm
          employee={selectedEmployee}
          onClose={handleCloseReplaceForeman}
        />
      )}

      {createEmployeeOpen && (profile.data?.contractor.id !== '') && (
        <CreateEmployee contractorId={profile.data?.contractor.id as string} onClose={handleCloseCreateEmployee}/>
      )}
    </div>
  )
}

export default Employees
