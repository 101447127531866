import { useSelector } from 'react-redux'
import style from './buildingdataleftpanel.module.scss'

const BuildingDataLeftPanel = () => {
  const { buildingsObject } = useSelector((state: any) => state.contractorReducer)
  const { building } = buildingsObject
  if (!building) return <></>

  const showField = (title: string | null): string => {
    return title ?? '(не указано)'
  }

  const geo = building.latitude && building.longitude ? `${building.latitude}, ${building.longitude}` : null

  return (
        <div className={style.areaDataLeft}>

            <div className="symbol__colon--after">Название</div>
            <div>{showField(building.buildingName)}</div>

            <div className="symbol__colon--after">Адрес</div>
            <div>{showField(building.buildingAddress)}</div>

            <div className="symbol__colon--after">Застройщик</div>
            <div>{showField(building.developer)}</div>

            <div className="symbol__colon--after">Генподрядчик</div>
            <div>{showField(building.mainContractor)}</div>

            <div className="symbol__colon--after">Заказчик</div>
            <div>{showField(building.customer)}</div>

            <div className="symbol__colon--after">Геокоординаты</div>
            <div>{showField(geo)}</div>

        </div>
  )
}

export default BuildingDataLeftPanel
