"use client";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import lineart from "../../assets/lineart.svg";
import { useHistory } from "react-router-dom";
import { NewFooter } from "../../components/footer/NewFooter";
import { IUser } from '../../types/User';
import { profileStatusCodes } from "../../utils/statusCodes";
import "../../styles/na_home.css";
import { ROLECODES } from "../../utils/getRoleName";

export default function Home() {
  const profile = useSelector<any>((state) => state.profile);
  const {user} = useSelector<any>((state) => state.userReducer) as {user: IUser};
  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.value = "page__home non-authorized";
  }
  const history = useHistory();

  useEffect(() => {
    const profileStatus = (profile as any)?.data?.contractor?.status ?? null;
    if(user?.login){
      if(user.role === ROLECODES.ARBITRATE){
        history.push("/complaint-list");
        return;
      }
      if([profileStatusCodes.onCheck, profileStatusCodes.isChecked].includes(profileStatus)){
        history.push("/list-contractor");
      }
      if([profileStatusCodes.notChecked, profileStatusCodes.signIn].includes(profileStatus) ||
         (user.role === ROLECODES.BRIGADIER)){
        history.push("/profile");
      }
    }
  }, [profile, user?.login, user?.role, history])

  return (
    <>
      <main>
        <div>
          <p>
            Найди работу
            <br />и оформи
            <br />
            надежную сделку
            <br />
            онлайн
          </p>
          <p>
            Платформа для оформления заказов
            <br />
            на выполнение строительных работ
            <br />
            для заказчиков и исполнителей
          </p>
        </div>

        <button
          title="Откроется страница входа в систему"
          onClick={() => {
            history.push("/login");
          }}
        >
          Войти
        </button>
        <button
          title="Откроется страница регистрации"
          onClick={() => {
            history.push("/register");
          }}
        >
          Зарегистрироваться
        </button>
        <div className="pic__back">
          <img src={lineart} alt="Фоновая картинка" />
        </div>
      </main>
      <NewFooter hideExitButton={true}/>
    </>
  );
}
