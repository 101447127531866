import React from "react";
import { Link } from "react-router-dom";

export default function NoMatch() {
  return (
    <>
      <div
        style={{ marginTop: "100px", gridColumn: "1/3", gap: "10px" }}
        className="flex flex-center"
      >
        <div>
          <h3>
            :( Запрашиваемая страница не найдена
          </h3>
        </div>
        <h5 style={{marginTop: "50px"}}>
          <Link to={"/"}>На главную страницу</Link>
        </h5>
      </div>
    </>
  );
}
