import style from '../../pages/EstimateManagement/FilesSection/filesSection.module.scss'
import type React from 'react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveEstimateFile, setNewFiles } from '../../redux/estimate/actionEstimate'
import { type TFilesError } from './types'
import { maxFileSize, maxEstimateSize, maxJobplanSize } from './consts'
import { estimateDocumentCodes } from '../../utils/statusCodes'

interface IUploadFiles {
  estimate: any
  filesRemoved: string[]
  type: number
  setOpenModalFileSizeError: Function
}

const UploadFiles = ({ estimate, filesRemoved, type, setOpenModalFileSizeError }: IUploadFiles) => {
  const dispatch = useDispatch<any>()
  const fileRef = useRef<any>()
  const state = useSelector((state: any) => state.estimateReducer)

  const getSectionsSize = (sections: number[]) => {
    if (!estimate?.documents) return 0
    return estimate.documents.reduce((sum: number, document: any) => {
      if (!document?.document) return sum
      const { documentType, id, filesize } = document.document
      if (sections.includes(documentType) && !filesRemoved.includes(id)) {
        return sum + filesize
      }
      return sum
    }, 0)
  }

  const getFilesError = (files: File[]): TFilesError => {
    let oneFileSizeError = false
    let totalFilesSizeError = false
    const filesSize = files.reduce((sum: number, file: File) => {
      if (file.size > maxFileSize) oneFileSizeError = true
      return sum + file.size
    }, 0)

    const estimateDocTypes = [estimateDocumentCodes.specification,
      estimateDocumentCodes.technicalTask,
      estimateDocumentCodes.workDraft,
      estimateDocumentCodes.workFlow]
    const jobplanDocTypes = [estimateDocumentCodes.jobPlanTask]

    if (estimateDocTypes.includes(type)) {
      const currentFilesSize = getSectionsSize(estimateDocTypes)
      totalFilesSizeError = ((filesSize + currentFilesSize) > maxEstimateSize)
      return { oneFileSizeError, totalFilesSizeError, currentFilesSize, maxSize: maxEstimateSize }
    }
    if (jobplanDocTypes.includes(type)) {
      const currentFilesSize = getSectionsSize(jobplanDocTypes)
      totalFilesSizeError = ((filesSize + currentFilesSize) > maxJobplanSize)
      return { oneFileSizeError, totalFilesSizeError, currentFilesSize, maxSize: maxJobplanSize }
    }

    return { oneFileSizeError: false, totalFilesSizeError: false, currentFilesSize: 0, maxSize: 0 }
  }

  const addNewFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null) return
    const files = Object.values(e.target.files)
    const filesError = getFilesError(files)

    if (filesError.oneFileSizeError || filesError.totalFilesSizeError) {
      setOpenModalFileSizeError(filesError)
      fileRef.current.value = ''
      return
    }
    const filesResponse = await Promise.all(files.map(async (file, index) => await setNewFile(file, index)))
    addDocument(files, filesResponse)
  }

  const setNewFile = async (file: File, index: number) => {
    const formData = new FormData()
    formData.append('files', file)
    const result = await dispatch(saveEstimateFile(formData, index))
    return result
  }

  function randomString (len: number) {
    const charSet =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomString = ''
    for (let i = 0; i < len; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length)
      randomString += charSet.substring(randomPoz, randomPoz + 1)
    }
    return randomString
  }

  const addDocument = async (files: File[], filesResponse: any[]) => {
    if (files.length !== filesResponse.length) return
    const data = []
    for (let i = 0; i < files.length; i++) {
      if (filesResponse[i]) {
        data.push({
          description: '',
          documentType: type,
          originalFileName: files[i].name,
          filesize: files[i].size,
          fileId: filesResponse[i].fileId,
          contentType: filesResponse[i].contentType,
          estimateId: `${estimate?.info.id}`,
          added: true,
          id: randomString(36)
        })
      }
    }
    dispatch(setNewFiles(data))
    fileRef.current.value = ''
  }

  return (
      <div>
        <form className={style.form}>
          <input type="file" ref={fileRef} multiple onChange={addNewFile} />
        </form>
      </div>
  )
}

export { UploadFiles }
