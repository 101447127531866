import React, {useState} from 'react';
import style from "./history.module.scss";
import {HistoryList} from "../../../components/EstimateManagement/HistoryList";

const History = (props: any) => {

  const [open, setOpen] = useState<boolean>(false);

  const arr = props?.estimate?.lastMessages

  const openInfo = () => {
    setOpen(!open);
  }

  return (
      <React.Fragment>
        <div className={`section__files--spec ${style.size} ${open ? "open" : ""}`}>
          <div className={`${open ? style.activeContainer : style.container}`}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span className={`circle-triangle`} onClick={openInfo}
                    title="Развернуть / Свернуть"></span>
              <span style={{marginLeft: '8px'}}>История</span>
            </div>
            <div>
              <span className={`symbol__colon--after`}>Сообщений</span>
              <span style={{marginLeft: '12px', fontWeight: 'bold'}}>{arr?.length}</span>
            </div>
          </div>
          {open
              &&
              <HistoryList arr={arr}/>
          }
        </div>
      </React.Fragment>
  )
}

export {History};