import { useEffect, useState, useMemo } from 'react';
import { FilesSection } from "./FilesSection/FilesSection";
import { History } from "./History/History";
import { StatusBar } from "./StatusBar/StatusBar";
import { useHistory } from "react-router-dom";
import style from './estimateManagement.module.scss';
import { Equipment } from "./Equipment/Equipment";
import { ModalAddBreakdown } from "./StatusBar/ModalAddBreakdown/ModalAddBreakdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getEstimateById,
  getDocumentTypes,
  setModeSuccess,
  getStatusesEstimate} from "../../redux/estimate/actionEstimate";
import { useParams } from "react-router";
import { ModalChangeRequisites } from "./StatusBar/ModalChangeRequisites/ModalChangeRequisites";
import { getNameOfEstimate, parseDateString, humanFileSize } from "../../utils/estimateUtils";
import { getBuildings } from "../../redux/actions/actionContractor";
import { EstimateReports } from './EstimateReports/EstimateReports';
import { getRoleTitle } from "../../utils/getRoleName";
import estimateJobParams from '../estimateJob-page/estimateJobParams';
import { IFileButtons, IReportButtons, IButton } from "../../types/Status";
import { IProfileState } from "../../redux/profile/types";
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { IDocumentType } from '../../types/Documents';
import { estimateDocumentCodes } from '../../utils/statusCodes';

const EstimateManagement = () => {
  const history = useHistory();
  const state = useSelector((state: any) => state.estimateReducer);
  const buildings = useSelector((state: any) => state.contractorReducer);
  const user = useSelector((state: any) => state.userReducer.user);
  const profile = useSelector<any>(state => state.profile) as IProfileState;
  const {buildingsObject} = buildings
  const {isLoading, estimate, mode, filesAdded, filesRemoved, modeEstimator, error } = state
  const dispatch = useDispatch<any>()
  const {params} = useParams<any>();
  const [modalChangeRequisites, setModalChangeRequisites] = useState(false);
  const [modalAddBreakdown, setModalAddBreakdown] = useState(false);
  const [object, setObject] = useState<any>(null);
  const [errorEstimate, setErrorEstimate] = useState<boolean>(false);
  const [fileButtons, setFileButtons] = useState<IFileButtons>({chooseFileBtn: false, addFileBtn: false, removeFileBtn: false})
  const [reportButtons, setReportButtons] = useState<IReportButtons>({
    getOriginalSpecBtn: null, 
    getEditedSpecBtn: null,
    getOfferBtn: null
  });
  const [sectionSizes, setSectionSizes] = useState<object>({});
  const id = estimateJobParams(params).id;

  useEffect(() => {
      dispatch(getDocumentTypes());
      dispatch(getStatusesEstimate());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (!estimate?.info?.length) {
      dispatch(getEstimateById(id));
      dispatch(getBuildings());
    }
  }, [dispatch, id, estimate?.info?.length]);

  useEffect(() => {
    if (estimate?.info?.id && buildingsObject.length !== 0) {
      setObject(getNameOfEstimate(buildingsObject, estimate.info.id));
    }
  }, [estimate, buildingsObject])

  useEffect(() => {
    if (estimate) {
      const findMode = modeEstimator.find((el: { currentStateCode: number }) => el.currentStateCode === estimate?.info?.estimateStatus);
      dispatch(setModeSuccess(findMode));
    }else{
      setErrorEstimate(true);
    }
  }, [mode, estimate, modeEstimator, dispatch])

  useEffect(() => {
    setSectionSizes(estimate.documents.reduce((acc: number[], document: any) => {
      if(document?.document){
        const {documentType, filesize, id} = document.document;
        if(documentType && filesize && !filesRemoved.includes(id)) {
          if(acc[documentType]){
            acc[documentType] += filesize
          }else{
            acc[documentType] = filesize
          }
        }
      }
      return acc;
    }, {}))
  },[estimate.documents, filesRemoved]);

  useEffect(() => {
    if(mode?.buttons){
      const {buttons} = mode;
      setFileButtons({
        chooseFileBtn: buttons?.filter((button: IButton) => button.type === "fileSelectBtn").length > 0, 
        addFileBtn: buttons?.filter((button: IButton) => button.type === "addFileBtn").length > 0, 
        removeFileBtn: buttons?.filter((button: IButton) => button.type === "addFileBtn").length > 0, 
      });
      setReportButtons({
        getOriginalSpecBtn: buttons?.filter((button: IButton) => button.type === "getOriginalSpecBtn")[0] ?? null,
        getEditedSpecBtn: buttons?.filter((button: IButton) => button.type === "getEditedSpecBtn")[0] ?? null,
        getOfferBtn: buttons?.filter((button: IButton) => button.type === "getOfferBtn")[0] ?? null,
      })
    }else{
      setFileButtons({
        chooseFileBtn: false, 
        addFileBtn: false, 
        removeFileBtn: false, 
      });
    }
  }, [mode])


  useEffect(() => {
    if(error){
      setErrorEstimate(true);
    }
  },[error])

  const leftFileSection = useMemo(() => {
    const fileSections = state.fileSections as IDocumentType[];
    return fileSections.filter(fileSection => 
      fileSection.status === "" && 
      !fileSection.docCodes.includes(estimateDocumentCodes.workFlowImage)
    )
  },[state.fileSections])

  const rightFileSection = useMemo(() => {
    const fileSections = state.fileSections as IDocumentType[];
    return fileSections.filter(fileSection => fileSection.status !== "")
  },[state.fileSections])

  if(errorEstimate ?? !isLoading) {
    return (
      <ErrorPanel errorMsg={"Не удалось загрузить смету"}/>
    );
  }

  if (isLoading || !mode) {
    return <></>;
  }

  const gotoBuildingCardHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const buildingRowElement = event.target as HTMLElement;
    history.push(buildingRowElement.getAttribute("href") as string);
  }

  const getSectionFilesSize = (docCodes: number[]) => {
    const filesSize = docCodes.reduce((acc, docCode) => {
      return acc + (sectionSizes[docCode as keyof object] ?? 0)
    }, 0);
    return humanFileSize(filesSize);
  }

  return (
        <div className={style.main}>
          <div className={style.header}>
            <span className={style.blockName}>Карточка сметы{mode?.isEdit && ': редактирование'}</span>
            <span className={style.blockNameSecond}>{getRoleTitle(user?.role)}</span>
          </div>
          <div className={style.barRequest}>
            <div className={style.barName}>
              <div className="limit__text--length">
                <a className={`link-light ${style.linkObject}`} 
                   href={`/building-card/${object?.building?.id}`}
                   title="Карточка объекта (переход)"
                   onClick={gotoBuildingCardHandler}
                >{object?.building?.buildingName || ''}
                </a>
              </div>
              <div className={`limit__text--length ${style.addressObject}`}>
                {object?.building?.buildingAddress || ''}
              </div>
            </div>
            <div>
              <div className={style.nameEstimate}>
                {estimate.info.title}
              </div>
              <div className={style.typeEstimate}>
                {estimate?.info?.types || estimate?.info?.type}
              </div>
            </div>
            <div title="Примечание к Смете" className={style.note}>
              {estimate.info.estimateNote}
            </div>
            <div title="Даты: Старт — Финиш" className={style.PlannedDates}>
              <span style={{paddingLeft: '10px'}}>Плановые сроки</span>
              <div>
                <span style={{fontSize: '14px', fontWeight: 'bold'}}>{parseDateString(estimate.documents ? estimate.info?.workStartPlan : "")}</span>
                <span style={{paddingLeft: '10px', fontWeight: 'bold', paddingRight: '10px'}}
                      className="symbol__mdash--before"></span>
                <span style={{fontSize: '14px', fontWeight: 'bold'}}>{parseDateString(estimate.documents ? estimate.info?.workEndPlan : "")}</span>
              </div>
            </div>
          </div>
          <div>
            <StatusBar
                data={estimate.info}
                employees={profile.data?.employees}
                setModalChangeRequisites={setModalChangeRequisites}
                setModalAddBreakdown={setModalAddBreakdown}
                filesAdded={filesAdded}
                filesRemoved={filesRemoved}
                id={id}
                modeStatus={mode}
                user={user}
            />
          </div>
          <div className={style.blocks}>
            <div>
              {leftFileSection.map(fileSection => 
                <FilesSection fileButtons={fileButtons} 
                              estimate={estimate} 
                              filesRemoved={filesRemoved}
                              sectionSize={getSectionFilesSize(fileSection.docCodes)}
                              title={fileSection.name}
                              docType={fileSection.docCodes}
                              accept={true}
                              key={fileSection.name}/>
              )}
            </div>
            <div>
              {rightFileSection.map(fileSection => 
                <FilesSection fileButtons={{chooseFileBtn: false, addFileBtn: false, removeFileBtn: false}} 
                              estimate={estimate} 
                              filesRemoved={filesRemoved}
                              sectionSize={getSectionFilesSize(fileSection.docCodes)}
                              title={fileSection.name}
                              docType={fileSection.docCodes}
                              accept={estimate.info.estimateStatus >= fileSection.status}
                              key={fileSection.name}/>
              )}
              <History estimate={estimate}/>
            </div>
          </div>
          <EstimateReports reportButtons={reportButtons} id={id}/>
          <Equipment estimate={estimate.content ? estimate.content : []}/>
          <ModalAddBreakdown estimate={estimate} 
                             filesRemoved={filesRemoved}
                             isOpen={modalAddBreakdown} 
                             setModal={setModalAddBreakdown}/>
          <ModalChangeRequisites isOpen={modalChangeRequisites} setModal={setModalChangeRequisites}/>
        </div>
  )
}

export {EstimateManagement};