import BankAccounts from "./BankAccounts";
import {IPaymentSelector} from "./types";

const PaymentSelector = (props: IPaymentSelector) => {
	const { onChange, bankAccounts, values } = props;
    return (
        <fieldset className="modal__content--payment">
			<label>Форма авансирования<br />для договоров СМР</label>
			<label>
				<input
					name="advance-payment__type"
					type="radio"
				/>
                <span>Эскроу-счёт</span>
				<BankAccounts values={values}
							  onChange={onChange}
							  accounts={bankAccounts} 
							  withEscrow={true} />
			</label>
			<label>
				<input
					name="advance-payment__type"
					type="radio"
					/>
                <span className="symbol__quotes">Платформа</span>
				<BankAccounts values={values}
							  onChange={onChange} 
							  accounts={bankAccounts} 
							  withEscrow={false} />
			</label>
		</fieldset>
    )
}

export default PaymentSelector;