import { useLayoutEffect, useEffect } from 'react'
import { ReactNotifications } from 'react-notifications-component'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Register from './pages/register-page/register'
import Home from './pages/home-page/home-page'
import Login from './pages/login-page/login'
import NoMatch from './pages/404/404'
import BuildingsListPage from './pages/BuildingsListPage/BuildingsListPage'
import BuildingCard from './pages/BuildingCard/BuildingCardPage'
import 'react-notifications-component/dist/theme.css'
import { EstimateJob } from './pages/estimateJob-page/EstimateJob'
import { ComplaintCard } from './pages/complaintcard-page/ComplaintCard'
import { ComplaintList } from './pages/complaintlist-page/ComplaintList'
import Header from './components/header/header'
import { getUser } from './redux/user/actionUser'
import { getProfileData } from './redux/profile/profileActions'
import Invite from './pages/invite'
import OrderPage from './pages/OrderPage/OrderPage'
import OrdersListPage from './pages/OrdersListPage/OrdersListPage'
import BillingPage from './pages/BillingPage/BillingPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import { BrigadeList } from './pages/BrigadeListPage/BrigadeList'
import axiosWithInterceptors from './redux/waiting/axiosWithInterceptors'
import Waiting from './components/Waiting/Waiting'
import { incrementTabsCount, decrementTabsCount } from './utils/tabsCount'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback'
import { pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

const App = (): JSX.Element => {
  const dispatch = useDispatch<any>()
  const user = useSelector<any>((state) => state.userReducer.user)
  const waiting = useSelector<any>((state) => state.waiting.waiting) as boolean
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
    window.addEventListener('unload', decrementTabsCount)
    return () => {
      window.removeEventListener('unload', decrementTabsCount)
    }
  }, [])

  useLayoutEffect(() => {
    axiosWithInterceptors(dispatch)
    if (!(user as boolean)) {
      incrementTabsCount()
      dispatch(getUser())
      dispatch(getProfileData())
    }
  }, [dispatch, user])

  if (!(user as boolean)) {
    return (
      <>
        <ReactNotifications />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
              <Header />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/invite" component={Invite} />
                <Route path="/register" component={Register} />
                <Route component={Home} />
              </Switch>
              <Waiting isWaiting={waiting} />
            </BrowserRouter>
          </ErrorBoundary>
      </>
    )
  }

  return (
    <>
      <ReactNotifications />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BrowserRouter>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/invite" component={Invite} />
            <Route path="/register" component={Register} />

            <Route exact path="/profile" component={ProfilePage} />

            <Route path="/list-contractor" component={BuildingsListPage} />
            <Route exact path="/building-card/:id" component={BuildingCard} />

            <Route exact path="/orders" component={OrdersListPage} />
            <Route exact path="/order/:id" component={OrderPage} />

            <Route exact path="/estimate-management/:params" component={EstimateJob} />

            <Route exact path="/complaint-list" component={ComplaintList} />
            <Route path="/complaint-card" component={ComplaintCard} />

            <Route exact path="/billing" component={BillingPage} />

            <Route exact path="/brigade-list" component={BrigadeList} />

            <Route component={NoMatch} />
          </Switch>
          <Waiting isWaiting={waiting} />
        </BrowserRouter>
      </ErrorBoundary>
    </>
  )
}

export default App
