import React from 'react'
import { Link } from 'react-router-dom'
import { getEstimateCount } from '../../utils/estimateUtils'

const FooterList = ({ buildings }: any) => {
  const logoutHandler = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('tabsCount')
  }

  return (
    <footer>
      <Link className="link-light" to="/help" target="_blank">
        Помощь
      </Link>
      <div>
        <span className="symbol__colon--after">Объекты</span>
        <span>{buildings?.length}</span>
        <span className="symbol__vertical--before"></span>
        <span className="symbol__colon--after">Сметы</span>
        <span>{getEstimateCount(buildings)}</span>
      </div>
      <a className="link-light" onClick={logoutHandler} title="Выйти из учётной записи" href="/">
        Выход
      </a>
    </footer>
  )
}

export default FooterList
