import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ModalObject } from '../../../components/BuildingsList/Modals/ModalObject'
import { profileStatusCodes } from '../../../utils/statusCodes'
import { type IProfileState } from '../../../redux/profile/types'
import style from './buildingstatusbar.module.scss'

const BuildingStatusBar = () => {
  const [modal, setModal] = useState<boolean>(false)
  const profile = useSelector<any>(state => state.profile) as IProfileState
  return (
        <>
            <div className={style.bar__status}>
                <button
                    className="symbol__hellip--after"
                    name="building__data"
                    type="button"
                    title="Открыть окно изменения данных"
                    disabled={ profile.data?.contractor?.status !== profileStatusCodes.isChecked }
                    onClick={() => { setModal(true) }}
                >Изменить данные</button>
            </div>
            <ModalObject
                selectedTypeValue={{}}
                setEstimateType={() => {}}
                editMode={true}
                isOpen={modal}
                setModal={setModal}/>
        </>
  )
}

export default BuildingStatusBar
