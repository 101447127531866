import contractorStyles from './ContractorStatistics.module.scss'
import formanStyles from './FormanStatistics.module.scss'
import { useSelector } from 'react-redux'
import { type IProfileData } from '../../../../types/Profile'
import { type IUser } from '../../../../types/User'
import { ROLECODES } from '../../../../utils/getRoleName'

const Statistics = (): JSX.Element => {
  const profile = useSelector<any>(state => state.profile.data) as IProfileData
  const user = useSelector((state: any) => state.userReducer.user) as IUser
  const isContractor = user?.role === ROLECODES.CONTRACTOR
  const styles = isContractor ? contractorStyles : formanStyles
  return (
    <div className={styles.statistics}>
      <div className={styles.statisticsLeft}>
        <div className={`${styles.statisticsRow} ${styles.statisticsCaptions}`}>
          <span title="Объект бизнес-процесса">Объект БП</span>
          <span>Всего</span>
          <span>В работе</span>
          <span>Завершено</span>
        </div>
        <div className={styles.statisticsRow}>
          <span>Объекты (стройки)</span>
          <span>{profile.statistics?.buildingsTotal ?? ''}</span>
          <span>{profile.statistics?.buildingsActive ?? ''}</span>
          <span>{profile.statistics?.buildingsDone ?? ''}</span>
        </div>
        <div className={styles.statisticsRow}>
          <span>Разбивки</span>
          <span>{profile.statistics?.jobPlansTotal ?? ''}</span>
          <span>{profile.statistics?.jobPlansActive ?? ''}</span>
          <span>{profile.statistics?.jobPlansDone ?? ''}</span>
        </div>
        <div className={styles.statisticsRow}>
          <span>Наряды</span>
          <span>{profile.statistics?.ordersTotal ?? ''}</span>
          <span>{profile.statistics?.ordersActive ?? ''}</span>
          <span>{profile.statistics?.ordersDone ?? ''}</span>
        </div>
      </div>
      { !isContractor &&
        <div className={styles.statisticsRigthTop}>
          <div className={`${styles.statisticsRowRight} ${styles.statisticsCaptions}`}>
            <span title="Объект бизнес-процесса">Объект БП</span>
            <span>Всего</span>
            <span>Инициатор</span>
            <span>Ответчик</span>
            <span>Выиграно</span>
            <span>Проиграно</span>
          </div>
          <div className={styles.statisticsRowRight}>
            <span>Споры</span>
            <span>{profile.statistics?.complainsTotal ?? ''}</span>
            <span>{profile.statistics?.complainsInitial ?? ''}</span>
            <span>{profile.statistics?.complainsAnswer ?? ''}</span>
            <span>{profile.statistics?.complainsWin ?? ''}</span>
            <span>{profile.statistics?.complainsLose ?? ''}</span>
          </div>
        </div>
      }
      <div className={styles.statisticsRight}>
        <span>Рейтинг</span>
        <span className="symbol__bracket--round">высший 5.0</span>
        {/*
            Класс вкладки подставляется согласно рейтингу:
              низкий  — от 0.1 до 3.0 — low
              средний — от 3.1 до 4.1 — medium
              высокий — от 4.2 до 5.0 — high
        */}
        <span className={`
          ${profile.contractor.rating <= 3 ? styles.low : ''}
          ${profile.contractor.rating > 3 && profile.contractor.rating <= 4.1 ? styles.medium : ''}
          ${profile.contractor.rating > 4.1 ? styles.high : ''}
        `}>
          {profile.contractor.rating}
        </span>
      </div>
    </div>
  )
}

export default Statistics
