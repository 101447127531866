import { useSelector } from 'react-redux'
import { type IFormanInfo } from './types'
import { contractorObjectRoles } from '../../../utils/statusCodes'
import style from './buildingdatarightpanel.module.scss'

const BuildingDataRightPanel = () => {
  const { buildingsObject } = useSelector((state: any) => state.contractorReducer)
  const { building, employees } = buildingsObject
  if (!building) return <></>
  const objectRole = contractorObjectRoles[building.objectRole as keyof typeof contractorObjectRoles] ?? '(не выбрано)'
  return (
        <div className={style.areaDataRight}>
            <div className="symbol__colon--after">Роль на Объекте</div>
            <div>{objectRole}</div>
            <div title="Прорабы, назначенные на Сметы текущего Объекта">Прорабы</div>
            <div>
                {employees.map((forman: IFormanInfo) => (
                    <div className={style.foreman_row}>
                        <span>{forman.lastName}</span>
                        <span>{`${forman.firstName} ${forman.secondName}`}</span>
                        <span>{forman.email}</span>
                        <span>{forman.phone}</span>
                    </div>
                ))}
            </div>
        </div>
  )
}

export default BuildingDataRightPanel
