import type React from 'react'
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import styles from './ParentDataBar.module.scss'
import { useSelector } from 'react-redux'
import { type IStatus } from '../../../../types/Status'
import { parseDateString } from '../../../../utils/estimateUtils'
import { type IJobPlanData, type IJobPlanChainOrder } from '../../../../types/JobPlan'

const ParentDataBar = (): JSX.Element => {
  const jobPlan = useSelector<any>(state => state.order.jobPlan.jobPlan) as IJobPlanData
  const order = useSelector<any>(state => state.order.order) as IJobPlanChainOrder
  const estimateStatuses = useSelector<any>(state => state.order.estimateStatuses) as IStatus[]
  const history = useHistory()
  const gotoPage = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    const element = event.target as HTMLElement
    if (element.tagName === 'A') {
      history.push(element.getAttribute('href') as string)
    }
  }
  const statusText = useMemo(() => {
    if (estimateStatuses.length === 0) {
      return ''
    }
    const status = estimateStatuses.filter(item => item.currentStateCode === jobPlan.status)?.[0]
    if (status !== undefined) {
      return status.currentStateText
    }
  }, [estimateStatuses, jobPlan.status])

  return (
    <div className={styles.bar}>

      <div className="limit__text--length">
        <a className="link-light"
           href={`/building-card/${jobPlan.buildingId}`}
           onClick={gotoPage}
           title="Карточка объекта">{jobPlan.buildingName}</a>
      </div>

      <div className="limit__text--length">{jobPlan.buildingAddress}</div>

      <div className="limit__text--length">
        <a className="link-light"
           href={`/estimate-management/${order.estimateId}`}
           onClick={gotoPage}
           title="Страница Сметы">{jobPlan.estimateTitle}</a>
      </div>

      <div>
        <span className="symbol__colon--after">
            <a className="link-light"
               href={`/estimate-management/${order.estimateId}&job`}
               onClick={gotoPage}
               title="Страница Разбивки">Статус</a>
        </span>
        <span>{statusText}</span>
      </div>

      <div className="limit__text--length">
        <a className="link-light" href="/_" title="Карточка подрядчика">{jobPlan.contractorCompany}</a>
      </div>

      <div>
        <span>{jobPlan.contractorPhone}</span>
        <span className="limit__text--length">{jobPlan.contractorName}</span>
      </div>

      <div>
        <span title="Даты: Начало — Окончание">Плановые сроки</span>
        <span>{jobPlan.workStartPlan !== '' ? parseDateString(jobPlan.workStartPlan) : '.. ... ..'}</span>
        <span className="symbol__mdash--before"></span>
        <span>{jobPlan.workEndPlan !== '' ? parseDateString(jobPlan.workEndPlan) : '.. ... ..'}</span>
      </div>

    </div>
  )
}

export default ParentDataBar
