import axios from "axios";
import Config from "../config.js";
import { SyntheticEvent } from "react";

export type UserDetails = {
    phone: any,
    code: string,
    login: string,
}

export async function smsSend(e: SyntheticEvent | null, login: string | undefined) {
    if(!login) throw new Error("User undefined");
    if(e) e.preventDefault()
    try {
        await axios.post(Config.BACKEND_ADDRESS + 'api/auth/sendsms', JSON.stringify({
                login
            },
        ),{
            headers: {'Content-Type': 'application/json'},
        })
    } catch (e) {
        throw new Error("Error SMS sending");
    }
}
