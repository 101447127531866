import axios from 'axios';
import Config from '../../config';

export const GET_BRIGADES_LIST_ERROR = "GET_BRIGADES_LIST_ERROR";
export const GET_BRIGADES_LIST_START = "GET_BRIGADES_LIST_START";
export const GET_BRIGADES_LIST_SUCCESS = "GET_BRIGADES_LIST_SUCCESS";

const getBrigadesListStart = () => ({
  type: GET_BRIGADES_LIST_START
});

const getBrigadesListSuccess = (payload) => ({
  type: GET_BRIGADES_LIST_SUCCESS,
  payload,
});

const getBrigadesListError = () => ({
  type: GET_BRIGADES_LIST_ERROR
});

export const getBrigadesList = (filters) => async (dispatch) => {
  dispatch(getBrigadesListStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const brigadesList = await axios.post(Config.BACKEND_ADDRESS + `Estimator/GetBrigades`, {
      filters
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(getBrigadesListSuccess(brigadesList.data));
  } catch (e) {
    dispatch(getBrigadesListError());
  }
};