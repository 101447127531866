export const SET_BUILDING_ITEM_OPENSTATE = 'SET_BUILDING_ITEM_OPENSTATE';
export const SET_BUILDING_LIST_SCROLL_Y = 'SET_BUILDING_LIST_SCROLL_Y';

export const setBuildingItemOpenState = (payload) => ({
  type: SET_BUILDING_ITEM_OPENSTATE,
  payload
});

export const setBuildingListScrollY = (payload) => ({
  type: SET_BUILDING_LIST_SCROLL_Y,
  payload
});

