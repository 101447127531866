import { type TAllProfileData } from '../../../../types/Profile'
export const getProfileStorage = (): TAllProfileData | null => {
  const storageData = localStorage.getItem('profileChangeData')
  try {
    const res = storageData !== null ? JSON.parse(storageData) as TAllProfileData : null
    return res
  } catch (e) {
    return null
  }
}
