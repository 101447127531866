import { useEffect, useState } from 'react';
import Billing from "../../components/Billing/Billing";
import { useDispatch, useSelector } from "react-redux";
import { getBilling } from "../../redux/billing/billingActions";
import { IBillingState } from "../../redux/billing/types";
import { useDebounced } from "../../hooks/useDebounced";
import { NewFooter } from "../../components/footer/NewFooter";
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';

function BillingPage() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const billing = useSelector<any>(state => state.billing) as IBillingState;
  const dispatch = useDispatch<any>();

  const debouncedStartDate = useDebounced(startDate, 1000);
  const debouncedEndDate = useDebounced(endDate, 1000);

  useEffect(() => {
    dispatch(getBilling(debouncedStartDate, debouncedEndDate));
  }, [debouncedStartDate, debouncedEndDate, dispatch]);

  if(!billing.loading && (billing.error || !billing.data)){
    return <ErrorPanel errorMsg="Не удалось загрузить страницу начислений" marginTop="100px"/>
  }

  return (
    <>
      <main>
        {billing.data && (
          <Billing
            billing={billing}
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={setStartDate}
            onChangeEndDate={setEndDate}
          />
        )}
      </main>
      <NewFooter/>
    </>
  );
}

export default BillingPage;