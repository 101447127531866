const Caption = (): JSX.Element => {
  return (
    <h2>
        <span className="text__page--name"></span>
        <span className="text__role--name"></span>
    </h2>
  )
}

export default Caption
