import { useState } from 'react'
import { getComplaintSide } from '../../utils/getComplaints'
import FileCard from './FileCard'
import { type IMessage } from './types'
import { ROLECODES } from '../../utils/getRoleName'

interface IParties {
  complaintCardObject: {
    authorFio: string
    respondentFio: string
    messages: IMessage[]
    respondentRole: number
  }
}

const Parties = ({ complaintCardObject }: IParties): JSX.Element => {
  const [openFilePanel, setOpenFilePanel] = useState<boolean>(false)
  const { authorFio, respondentFio, messages, respondentRole } = complaintCardObject
  const toggleFilePanelHandler = () => {
    setOpenFilePanel(!openFilePanel)
  }

  const initiatorDocuments = messages?.filter(
    item => getComplaintSide(item.message.authorRole, respondentRole).includes('initiator')
  ) ?? []

  const respondentDocuments = messages?.filter(
    item => getComplaintSide(item.message.authorRole, respondentRole).includes('responder')
  ) ?? []

  const initOnLeft = respondentRole === ROLECODES.BRIGADIER

  const InitHead = () => (
		<div className="complaint__init-part">
			<span>{`${initOnLeft ? 'Прораб' : 'Бригадир'}: ${authorFio}`}</span>
			<span>{'(инициатор)'}</span>
		</div>
  )

  const RespHead = () => (
    <div className="complaint__resp-part">
      <span>{`${initOnLeft ? 'Бригадир' : 'Прораб'}: ${respondentFio}`}</span>
      <span>{'(ответчик)'}</span>
    </div>
  )

  const InitFiles = () => (
    <div className="section__files--init">
    {initiatorDocuments.map(message =>
      message.documents.map((document: any, key: number) =>
        (<FileCard fileCardObject = {document} key = {key}></FileCard>)
      )
    )}
  </div>
  )

  const RespFiles = () => (
    <div className="section__files--resp">
    {respondentDocuments.map(message =>
      message.documents.map((document: any, key: number) =>
        (<FileCard fileCardObject = {document} key = {key}></FileCard>)
      )
    )}
  </div>
  )

  return (
	<div className="complaint__parties">
    {initOnLeft
      ? <>
        <InitHead/>
        <RespHead/>
      </>
      : <>
        <RespHead/>
        <InitHead/>
      </>
    }
		<div className={`complaint__files ${openFilePanel ? 'open' : ''}`}>
			<div className="section__header" onClick={toggleFilePanelHandler}>
				<span className="circle-triangle" title="Развернуть / Свернуть"></span>
				<span>Файлы сторон</span>
			</div>
      {initOnLeft
        ? <>
          <InitFiles/>
          <RespFiles/>
        </>
        : <>
          <RespFiles/>
          <InitFiles/>
        </>
      }
		</div>
	</div>
  )
}

export default Parties
