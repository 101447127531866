import { useEffect, useState } from 'react';
import { NewFooter } from '../../components/footer/NewFooter';
import "../../styles/role_foreman.css";
import { useDispatch, useSelector } from "react-redux";
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { TListCaptionItem } from "../../components/BrigadeList/Types";
import Caption from "../../components/ComplainList/Caption";
import ListCaption from "../../components/ListCaption/ListCaption";
import FilterPanel from "../../components/BrigadeList/FilterPanel";
import { getBrigadesList } from '../../redux/brigadesList/brigadesListActions'; 
import { TBrigade } from './types';
import { BrigadeRow } from './BrigadeRow';

type Filters = {
  [key: string]: any,
}

const textFilterTimeoutDelay = 300;
let textFilterTimeout: any = null;
let prevTextFilter: any = null;
let localFilters: Filters = {};

const BrigadeList = () => {
  const [filters, setFilters] = useState<Filters>({});
  const changeHandler = (e: any) => {
    localFilters = {...localFilters};
    if(e.target){   
      const filterField = e.target.name.replace("filter__","");   
      if(e.target.value && e.target.value !== "all-statuses"){
        localFilters[filterField] = e.target.value;
      }else{
        delete localFilters[filterField]; 
      }
      if(e.target.type === "text"){
        if((prevTextFilter === filterField) && textFilterTimeout) clearTimeout(textFilterTimeout);
        prevTextFilter = filterField;
        textFilterTimeout = setTimeout(() => setFilters(localFilters), textFilterTimeoutDelay);
      }else{
        setFilters(localFilters);
      }
    }
  }

  const {error, brigades} = useSelector((state: any) => state.brigadesList);
  const listCaptions: TListCaptionItem[] = 
                      [{captions:["Бригадир"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"name"},

                       {captions:["Телефон"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"phone"},

                       {captions:["Кол-во"], 
                        title:"Количество человек в бригаде", 
                        filtertype:"members", 
                        captionId:"members"},

                       {captions:["Специализация"], 
                        title:"Наличие профильных специалистов", 
                        filtertype:"text", 
                        captionId:"specialization"},

                       {captions:["Наряды"], 
                        title:"Выполненные / В работе", 
                        filtertype:"orders", 
                        captionId:"orders"},

                        {captions:["Рейтинг"], 
                        title:"5-ти бальный, дробный (высший 5.0)", 
                        filtertype:"rate", 
                        captionId:"rate"}
                      ];
  
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getBrigadesList(filters));
  },[filters, dispatch]);

  if (error) {
    return <ErrorPanel errorMsg="Не удалось загрузить список бригад" marginTop="100px"/>
  }

  return (
    <>
      <main>
        <Caption/>
        <ListCaption listCaptions = {listCaptions}/>
        <FilterPanel listCaptions = {listCaptions} 
                     changeHandler = {changeHandler} />
        {brigades && brigades.map((brigade: TBrigade) => (
          <BrigadeRow key={brigade.brigadierName} brigade={brigade}/>
        ))}
      </main>
      <NewFooter/>
    </>
  )
}     
export {BrigadeList};
