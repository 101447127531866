import { useSelector } from 'react-redux'
import styles from './Waiting.module.scss'

interface IWaiting {
  isWaiting: boolean
}
const Waiting = ({ isWaiting }: IWaiting): JSX.Element => {
  let { inProgress, progressPercents } = useSelector((state: any) => state.estimateReducer)
  let progressPercent = 0
  const progressLength = Object.keys(progressPercents).length
  if (progressLength === 0) {
    inProgress = false
  } else {
    const percentSum = Object.values(progressPercents).reduce((sum: number, percent) => {
      return sum + (percent as number)
    }, 0)
    progressPercent = (percentSum / progressLength)
  }
  return (
        <>
            <div className={isWaiting ? styles.waitIcon : styles.waitIconDisabled}></div>
            {inProgress as boolean &&
                <div className={styles.percentPanel}>
                    <div className={styles.progressBar} style={{ width: `${progressPercent}%` }}>
                        <span className={styles.percentText}>
                            {`${progressPercent.toFixed(0)}% - ${progressPercent < 100 ? 'Загрузка...' : 'Ожидание сервера'}`}
                        </span>
                    </div>
                </div>
            }
        </>
  )
}

export default Waiting
