import type React from 'react'
import { useMemo, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import styles from './OrderBox.module.scss'
import { useSelector } from 'react-redux'
import { type IJobPlanChainOrder, type IJobPlanDocument } from '../../../../types/JobPlan'
import { type IDocumentType } from '../../../../types/Documents'
import { handlePlanDate } from '../../../../utils/estimateUtils'
import { type IStatus } from '../../../../types/Status'
import { getRoleTitle } from '../../../../utils/getRoleName'
import { downloadFileItem } from '../../../../utils/downloadItem'
import { getBase64Image } from './GetImages'
import { ModalImageView } from '../../../ModalImageView/ModalImageView'
import { estimateDocumentCodes } from '../../../../utils/statusCodes'
import { IGalleryImg } from './types'
import { DocImage } from './docImage'

const OrderBox = (): JSX.Element => {
  const order = useSelector<any>(state => state.order.order) as IJobPlanChainOrder
  const complaintStatuses = useSelector<any>(state => state.order.complaintStatuses) as IStatus[]
  const [activeImg, setActiveImg] = useState<number>(0)
  const [openedSections, setOpenedSections] = useState<string[]>([])
  const [galleryImages, setGalleryImages] = useState<IGalleryImg[]>([])
  const [openImageView, setOpenImageView] = useState<boolean>(false)
  const state = useSelector((state: any) => state.estimateReducer)
  const documentTypes = state.fileSections as IDocumentType[]
  const history = useHistory()
  const gotoPage = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    let element = event.target as HTMLElement
    if (element.tagName === 'SPAN') {
      element = element.parentElement as HTMLElement
    }
    if (element.tagName === 'A') {
      history.push(element.getAttribute('href') as string)
    }
    if (element.tagName === 'BUTTON') {
      history.push(element.getAttribute('data-href') as string)
    }
  }

  const documentsSections = useMemo(() => {
    return documentTypes.map(documentTypeItem => {
      return {
        type: documentTypeItem,
        items: order.documents.filter(item => documentTypeItem.docCodes.includes(item.document?.documentType))
      }
    }).filter(item => item.items.length !== 0)
  }, [documentTypes, order.documents])

  const complaintsDowntime = useMemo(() => {
    const value = order.complaints?.map(item => item.downtime)?.reduce((prev, next) => prev + next, 0)

    return value > 0 ? <span>{value}</span> : <span className="symbol__mdash--before"></span>
  }, [order.complaints])

  const getImages = async (images: IGalleryImg[]): Promise<void> => {
    const result = await Promise.all(images.map(async image =>
      await getBase64Image(image.id)
    ))
    const populateImages = images.map((image, index) => (
      {
        ...image,
        src: image.fileType === 'pdf' ? result[index] : `data:image/png;base64, ${result[index]}`
      }))
    setGalleryImages(populateImages)
  }

  useEffect(() => {
    const images: IGalleryImg[] = []
    order?.documents?.forEach(item => {
      if (item.document?.documentType === estimateDocumentCodes.workFlowImage && item.document?.id !== '') {
        const fileExt = item.document?.originalFileName.slice(-3)
        const fileType = 
          ['jpg', 'png', 'JPG', 'PNG'].includes(fileExt)
            ? 'image' 
            : fileExt === 'pdf'
              ? 'pdf'
              : 'unknown'
        images.push({
          id: item.document?.id,
          alt: item.document?.originalFileName,
          src: '',
          fileType
        })
      }
    })
    setGalleryImages(images)
    void getImages(images)
  }, [order.documents])

  const handleClickPrevImg = (e: React.MouseEvent<HTMLElement>): void => {
    setActiveImg(prev => prev - 1 < 0 ? 0 : prev - 1)
  }

  const handleClickNextImg = (e: React.MouseEvent<HTMLElement>): void => {
    setActiveImg(prev => prev + 1 > galleryImages.length - 1 ? prev : prev + 1)
  }

  const handleToggleSection = (code: string) => (e: React.MouseEvent<HTMLElement>) => {
    if (openedSections.includes(code)) {
      setOpenedSections(prev => ([...prev].filter(item => item !== code)))
    } else {
      setOpenedSections(prev => ([...prev, code]))
    }
  }

  const getComplaintStatusText = (status: number): string => {
    const statusData = complaintStatuses.filter(item => item.currentStateCode === status)?.[0]
    return statusData?.currentStateText ?? ''
  }

  const downloadFile = async (file: IJobPlanDocument): Promise<void> => {
    await downloadFileItem(file.document.id, file.document.originalFileName)
  }

  const jobGroup = useMemo(() => {
    return order.jobPrice.reduce((res, job) => {
      if(res[job.workName] === undefined) {
        res[job.workName] = {
          cost: job.cost,
          count: job.count,
          hours: job.hours,
          unit: job.unit,
          equipment: [{
            equipmentName: job.equipmentName,
            count: job.count,
            unit: job.unit
          }]
        }
      } else {
        res[job.workName].cost += job.cost
        res[job.workName].count += job.count
        res[job.workName].hours += job.hours
        res[job.workName].equipment.push({
          equipmentName: job.equipmentName,
          count: job.count,
          unit: job.unit
        })
      }
      return res
    }, {} as {[key: string]: {
      cost: number
      count: number
      hours: number
      unit: string
      equipment:{
        equipmentName: string
        count: number
        unit: string
      }[]
    }})
  },[order.jobPrice])

  return (
    <>
      {openImageView && <ModalImageView img={galleryImages[activeImg]} setOpenImageView={setOpenImageView}/>}
      <div className={styles.orderBox}>
        <div>
          {!(galleryImages.length === 0) && (
            <>
              <div className={styles.previewSelect}>
                <span className={styles.previewSelectPrev} onClick={handleClickPrevImg}/>
                <div className={styles.previewSelectImages}>
                  {
                    galleryImages.map((img, index) => 
                      <DocImage 
                        key={index}
                        galaryImg={img}
                        onChoose={setActiveImg}
                        index={index}
                        activeImg={activeImg}
                      />)
                  }
                </div>
                <span className={styles.previewSelectNext} onClick={handleClickNextImg}/>
              </div>
              <div className={styles.preview}>
                <DocImage 
                  galaryImg={galleryImages[activeImg]}
                  onOpen={setOpenImageView}
                />
              </div>
            </>
          )}
        </div>

        <div>
          {documentsSections.map((section, index) => (
            <div className={styles.section} key={index}>
              <div className={`${styles.sectionHeader} ${openedSections.includes(section.type.name) ? 'open' : ''}`}>
                <span
                  className="circle-triangle"
                  title="Развернуть / Свернуть"
                  onClick={handleToggleSection(section.type.name)}
                />
                <span>{section.type.name}</span>
                <span className="symbol__colon--after">Файлов</span>
                <span>{section.items.length}</span>
              </div>
              {openedSections.includes(section.type.name) && (
                <div className={`${styles.sectionFiles} ${styles.sectionContent}`}>
                  {section.items.map(file => (
                    <div key={file.document.id}>
                      <span className="symbol__bull--before"></span>
                        <span>
                          <span
                            className="link-normal"
                            onClick={() => {
                              void downloadFile(file)
                            }}>
                            {file.document.originalFileName ?? 'Нет названия'}
                          </span>
                        </span>
                      <span>{file.document.description}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          <div className={styles.section}>
            <div className={`${styles.sectionHeader} ${openedSections.includes('complaints') ? 'open' : ''}`}>
              <span
                className="circle-triangle"
                title="Развернуть / Свернуть"
                onClick={handleToggleSection('complaints')}
              />
              <span>Споры</span>
              <span className="symbol__colon--after">Всего</span>
              <span>{order.complaints?.length ?? 0}</span>
            </div>
            {openedSections.includes('complaints') && (
              <div className={`${styles.sectionComplaints} ${styles.sectionContent}`}>
                {order.complaints?.length > 0
                  ? (
                  <>
                    <div className={styles.captions}>
                      <span>Даты споров</span>
                      <span>Статус</span>
                      <span>Ответчик</span>
                      <span>Простой</span>
                    </div>

                    {order.complaints.map(complaintItem => (
                      <div
                        className={styles.complaintRow}
                        key={complaintItem.id}>
                        <a href={`/complaint-card?id=${complaintItem.id}`}
                          title="Карточка спора"
                          onClick={gotoPage}>
                          <span>{handlePlanDate(complaintItem.startDate, false)}</span>
                          <span className="symbol__mdash--before"></span>
                          <span>{handlePlanDate(complaintItem.endDate, false)}</span>
                        </a>

                        <span>{getComplaintStatusText(complaintItem.status)}</span>
                        <span>{getRoleTitle(complaintItem.respondentRole)}</span>

                        {complaintItem.downtime > 0
                          ? (
                          <span>{complaintItem.downtime}</span>
                            )
                          : (
                          <span className="symbol__mdash--before"></span>
                            )
                        }
                        <span>дн.</span>
                      </div>
                    ))}
                  </>
                    )
                  : (
                  <div className={styles.complaintRow}>
                    <span>Нет споров</span>
                  </div>
                    )}
                <div className={styles.totalRow}>
                  {order.canStartComplaint && <button
                    data-href={`/complaint-card?createId=${order.id}`}
                    className="button bttn__small"
                    type="button"
                    title="Откроется страница создания Спора"
                    onClick={gotoPage}
                  >
                    Начать спор
                  </button>}
                  <span className="symbol__colon--after">Всего в простое</span>

                  {complaintsDowntime}

                  <span>дн.</span>
                </div>

              </div>
            )}
          </div>

          <div className={styles.prices}>
            <div className={styles.pricesHeader}>
              Работы (состав/расценки)
            </div>
            <div>
              {Object.entries(jobGroup).map(([workName, item], index) => (
                <div key={index}>
                  <div className={`${styles.pricesRow} ${openedSections.includes(`jobPrice${index}`) ? 'open' : ''}`}>
                    <div>
                      <span
                        className="circle-triangle"
                        title="Развернуть / Свернуть"
                        onClick={handleToggleSection(`jobPrice${index}`)}
                      />
                    </div>
                    <div>{workName}</div>
                    <div>{item.count}</div>
                    <div>{item.unit}</div>
                    <div>{(item.cost / item.count).toFixed(2)}</div>
                    <div>{item.cost}</div>

                    <div>
                      {item.hours > 0
                        ? (
                        <span>{Math.ceil(item.hours / 8)}</span>
                          )
                        : (
                        <span className="symbol__mdash--before"/>
                          )}
                      <span title="Продолжительность: дней">дн.</span>
                    </div>
                  </div>
                  {openedSections.includes(`jobPrice${index}`) && (
                    item.equipment.map(equipObj => (
                      <div className={styles.pricesStructure}>
                        <div className={styles.position}>
                          <div className="symbol__bull--before"></div>
                          <div>{equipObj.equipmentName}</div>
                          <div>{equipObj.count}</div>
                          <div>{equipObj.unit}</div>
                        </div>
                      </div>)
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderBox
