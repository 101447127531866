import styles from './ModalFileSizeError.module.scss'
import Modal from '../Modal/Modal'
import { type TFilesError } from '../EstimateManagement/types'
import { humanFileSize } from '../../utils/estimateUtils'
import { maxFileSize } from '../EstimateManagement/consts'
interface IModalFileSizeError {
  openModalFileSizeError: TFilesError
  setOpenModalFileSizeError: Function
}

export const ModalFileSizeError = ({ openModalFileSizeError, setOpenModalFileSizeError }: IModalFileSizeError) => {
  const FileSizeErrorView = (): JSX.Element => {
    const { oneFileSizeError, totalFilesSizeError } = openModalFileSizeError
    return (
            <div className={styles.fileSizeErrorView}>
                {totalFilesSizeError &&
                    <div className={styles.messageContainer}>
                        <p>{`Превышен допустимый размер загружаемых файлов — ${humanFileSize(openModalFileSizeError.maxSize)}`}</p>
                        <p>{`Текущий доступный лимит — ${humanFileSize(openModalFileSizeError.maxSize - openModalFileSizeError.currentFilesSize)}`}</p>
                    </div>
                }
                {oneFileSizeError &&
                    <div className={styles.messageContainer}>
                        <p>{`Размер одного файла не должен превышать ${humanFileSize(maxFileSize)}`}</p>
                    </div>}
                <div className={styles.buttonContainer}>
                    <button className={styles.button} onClick={() => setOpenModalFileSizeError(
                      { oneFileSizeError: false, totalFilesSizeError: false })}>
                        Закрыть
                    </button>
                </div>
            </div>
    )
  }
  return <Modal title="Ошибка добавления файлов" children={<FileSizeErrorView/>}/>
}
