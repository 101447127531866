export enum EstimateListType {
    System = "system",
    Job = "job",
}

export type Calc = {count: number, totalSum: number};

export enum EstimatePositionType {
    KitPart = 9,
    Kit = 10,
    GroupPrice = 11,
    GroupPricePart = 12
}