import Config from "../../config.js";
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Navbar from "../../components/navbar/navbar";
import lineart from "../../assets/lineart.svg";
import { Link, useHistory } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import Input from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import style from './register-page.module.scss';
import { NewFooter } from "../../components/footer/NewFooter";
import "../../styles/na_register.css"
import { baseNotification } from "../../utils/estimateUtils";
import { ROLECODES } from "../../utils/getRoleName";
import { setWaitingStart, setWaitingStop } from "../../redux/waiting/actionWaiting";
import { userLogin, userRegister, getUser } from "../../redux/user/actionUser";
import { getProfileData } from "../../redux/profile/profileActions";
import { EyePassword } from "../../components/EyePassword/EyePassword";
import { isPasswordWrong, isLoginWrong, isValidPhone } from "../../utils/validators";


interface State {
  role: string;
  phone: any;
  username: string;
  email: string;
  password: string;
  passwordCheck: string;
  userAgreement: boolean;
  code: string;
}

const Register = () => {
	const history = useHistory()
	const [values, setValues] = React.useState<State>({
		role: '',
		phone: '+7',
		username: '',
		email: '',
		password: '',
		passwordCheck: '',
		userAgreement: false,
		code: '',
	});
	const [phoneErr, setPhoneErr] = useState<boolean>(false)
	const [registerErr, setRegisterErr] = useState<boolean>(false)
	const [showSuccess, setShowSuccess] = useState<boolean>(false)
	const [showNextStage, setStage] = useState<boolean>(false)
	const [usernameErr, setUsrnmErr] = useState<boolean>(false)
	const [smsErr, setSMSErr] = useState<boolean>(false)
	const [waiting, setWaiting] = useState<boolean>(false)

	const dispatch = useDispatch<any>();

	let element = document.getElementById("root")
	if (element !== null) {
		showNextStage ? element.classList.value = "page__register--2 non-authorized" : element.classList.value = "page__register--1 non-authorized"
	}

	const handleChange = (prop: keyof State) => (event: any) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleRadioChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleCheckboxChange = (prop: keyof State) => () => {
		setValues({ ...values, [prop]: !values[prop] });
	};

	async function sendSMS(e: SyntheticEvent) {
		e.preventDefault()
		const phone = values.phone;
		const code = values.code;
		try {
			await axios.post(Config.BACKEND_ADDRESS + 'api/auth/sendsms', JSON.stringify({
				phone,
				code,
			}),
			{
				headers: {'Content-Type': 'application/json'},
			})
			setStage(true);
			setRegisterErr(false);
		} catch (e) {
			// @ts-ignore
			const error = typeof e?.response?.data === "string" ? e?.response?.data : "Ошибка регистрации";
			setRegisterErr(error);
			if(error.includes("Пользователь с телефоном")) {
				setPhoneErr(true);
			}else{
				baseNotification("Ошибка регистрации", error,"danger");
			}
	
		}
	}

	const submit = async (e: SyntheticEvent) => {
		setPhoneErr(false);
		setUsrnmErr(false);
		setSMSErr(false);
		e.preventDefault();
		const password = values.password
		let role
		if (values.role === "contr") {
			role = ROLECODES.CONTRACTOR
		} else {
			role = ROLECODES.BRIGADIER
		}
		const phone = values.phone
		const login = values.username
		const code = values.code
		setWaiting(true)
		dispatch(setWaitingStart());
		const response = await userRegister(phone, login, password, role, code);
		dispatch(setWaitingStop());
		setWaiting(false);
		if (response.ok) {
			const loginResponse = await userLogin(login, password);
			if(loginResponse.ok){
				dispatch(getUser());
				dispatch(getProfileData());
				setShowSuccess(true);
				setTimeout(() => history.push("/profile"), 5000);
			}else{
				baseNotification(`Ошибка при автологине ${loginResponse.statusText}`, "", "danger");
			}
		} else {
			const data = await response.text();
			if(data.includes("уже зарегистрирован")) {
				setUsrnmErr(true);
			}else{
				setSMSErr(true);
			}
		}
	}

	const passError = isPasswordWrong(values.password)
	const checkError = values.passwordCheck ? values.password !== values.passwordCheck : false
	const usernameSpellingError = isLoginWrong(values.username)
	const phoneCodeError = values.code ? values.code.length > 4 : false;
	async function checkPhone() {
		const phone = values.phone
		const response = await fetch(Config.BACKEND_ADDRESS + 'api/auth/checkPhone', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			credentials: "include",
			body: JSON.stringify({
					phone,
				},
			)
		});
		setPhoneErr(!response.ok)
		return response
	}

	const handlePhone = (value:any) => {
		setValues({ ...values, phone: value});
		if (registerErr) setRegisterErr(false)
	}

	const someError = phoneErr || usernameErr || smsErr;

	return (
		<>
			<Navbar/>
			<main>
				<Modal
					open={showSuccess}>
					<Box sx={{
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
					}}>
						Регистрация успешна!
						Вы будете перенаправлены на страницу профиля через 5 секунд.
					</Box>
				</Modal>

				<h2>Регистрация пользователя</h2>
				<form name="register__form">

					{!showNextStage &&
							<>
						<h3>Тип<br/>профиля</h3>
						<label className="clickable">
							<input
								type="radio"
								name="role-switch"
								onChange={handleRadioChange("role")}
								value="contr"/>Подрядчик
						</label>
						<label className="clickable">
							<input
								type="radio"
								name="role-switch"
								onChange={handleRadioChange("role")}
								value="brig"/>Бригадир
						</label>

						<h3>Мобильный<br/>телефон</h3>
						<Input
							name="tel"
							country="RU"
							className={phoneErr ? "error__box" : ""}
							withCountryCallingCode={true}
							international={true}
							onBlur={checkPhone}
							disabled={!values.role}
							onChange={(value) => handlePhone(value)}
							placeholder="8 (000) 000-00-00"/>

              <h3>
                Соглашение
                <br />
                пользователя
              </h3>
              <label className="clickable">
                <div style={{display: 'flex', width: '190px', alignItems: 'center'}}>
                  <input
                      style={{paddingTop: "10px"}}
                      type="checkbox"
                      name="agree-accept"
                      onChange={handleCheckboxChange("userAgreement")}
                  />
                  Принимаю (
                  <a href="/_" title="Страница Пользовательского соглашения">
                    ознакомлен
                  </a>
                  )
                </div>
              </label>

              <h3>Код регистрации</h3>
              <button onClick={sendSMS} disabled={!values.userAgreement || !isValidPhone(values.phone)}>
                Прислать СМС
              </button>
            </>
          }

          {showNextStage && (
            <>
              <div className={style.container}>
                <legend className={style.name}>Первичные данные</legend>

                <div className={style.items}>
                  <h3 className={style.descItem}>Профиль</h3>
                  <input
                      type="text"
                      name="role"
                      value={values.role === "brig" ? "Бригадир" : "Подрядчик"}
                      disabled
                  />
                </div>

                <div className={style.items}>
                  <h3 className={style.descItem}>Телефон</h3>
                  <input type="text" name="tel" value={values.phone} disabled />
                </div>

                <div className={style.items}>
                  <h3 className={style.descItem}>Код из СМС</h3>
                  <input
                      type="text"
                      name="sms"
                      value={values.code}
                      onChange={handleChange("code")}
                      placeholder="91827"
                  />
                </div>
              </div>
              <div className={style.container}>
                <legend className={style.name}>Учётная запись</legend>

                <div className={style.items}>
                  <h3 className={style.descItem}>Логин</h3>
                  <input
                      type="text"
                      name="login"
                      className={usernameSpellingError || usernameErr ? "error__box" : ""}
                      pattern="[a-zA-Z0-9]{6}"
                      placeholder="(не указано)"
                      onChange={handleChange("username")}
                      value={values.username}
                      disabled={!phoneCodeError}
                  />
                </div>

                <div className={style.items}>
                  <h3 className={style.descItem}>Пароль</h3>
				  <EyePassword 
					  disabled={!values.username || usernameSpellingError || !phoneCodeError} 
                      onChange={handleChange("password")}
                      className={passError ? "error__box" : ""}/>
                </div>

                <div className={style.items}>
                  <h3 className={style.descItem}>Пароль (проверка)</h3>
				  <EyePassword 
					  disabled={!values.password ||
						  		  passError ||
								  usernameSpellingError ||
								  !phoneCodeError} 
                      onChange={handleChange("passwordCheck")}
                      className={checkError ? "error__box" : ""}/>
                </div>
              </div>
              <h3 className={style.descItem}>Регистрация</h3>
              <button
                disabled={
                  !values.passwordCheck ||
                  checkError ||
                  passError ||
                  usernameSpellingError ||
                  !phoneCodeError ||
				  waiting
                }
                onClick={submit}
              >
                Зарегистрироваться
              </button>
            </>
          )}
        </form>
        <div className="pic__back">
          <img src={lineart} alt="Фоновая картинка" />
        </div>
				<div className={`info__box ${someError ? "error__text": ""}`}>
					{!showNextStage && !phoneErr && <>
						<h3>Уже зарегистрированы?</h3>
						<p>
							Войдите <Link to="/login" title="Страница входа">в свой профиль</Link>.
						</p>

						<h3>Что означает «тип профиля»</h3>
						<p>
							<strong>Подрядчик</strong> &mdash; Работодатель.
							<br/>
							Представитель организации (юридическое лицо), размещает Заказы на производство работ.
							<br/>
							<strong>Бригадир</strong> &mdash; Исполнитель.
							<br/>
							Частное лицо или ИП, предлагает услуги по выполнению работ.
						</p>
						<h3>Зачем указывать номер телефона?</h3>
						<p>
							Сервисом пользуются честные работодатели и исполнители,
							которые ценят свою репутацию.
							<br/>
							Указание номера &mdash; подтверждение серьёзности намерений.
							<br/>
							На него будет выслан код регистрации, при завершении которой
							номер закрепляется за Пользователем.
							<br/>
							Пользователь может использовать его для входа в Личный кабинет.
						</p>

						<h3>Приватность</h3>
						<p>
							Номер телефона не будет доступен публично и не будет передан третьим лицам.
						</p>
					</>}

					{phoneErr && <>
						<h3>Телефон уже используется</h3>
						<p>
							Указанный номер уже закреплён за другим пользователем.
							<br />
							Введите другой номер.
							<br />
							Если хотите использовать этот номер, обратитесь к администратору сайта.
						</p>

						<h3>Уже зарегистрированы?</h3>
						<p>
							Войдите <Link to="/login" title="Страница входа">в свой профиль</Link>.
						</p>
					</>}

					{showNextStage && !usernameErr && !smsErr && <>
						<>
							<h3>Код</h3>
							<p>
								Код подтверждения регистрации действителен 10 минут.
								<br/>
								Если СМС не получено в течение нескольких минут, нажмите на
								<a href="/_" title="Инициация отправки повторного СМС"> эту ссылку</a>.
							</p>

							<h3>Логин</h3>
							<p>
								Длина имени: не менее 6-и символов.
								<br/>
								Допустимые символы: буквы латиницы (регистр любой) и цифры
								(другие небуквенные символы недопустимы).
							</p>

							<h3>Пароль</h3>
							<p>
								Длина: Не менее 8-и символов.
								<br/>
								Должен содержать: буквы обоих регистров, цифры и любые символы (например, пунктуации).
								<br/>
								Пароль в обоих полях должен быть одинковым.
							</p>
						</>
					</>}

					{usernameErr && <>
						<h3>Логин занят</h3>
						<p>
							Введите другой логин и нажмите кнопку «Зарегистрироваться».
						</p>
					</>}

					{smsErr && <>
						<h3>Неверный код</h3>
						<p>
							Код не соответствует отправленному в СМС!
							<br />
							Введите код из СМС или
							<a href="/_" title="Отправка нового кода">запросите новый код</a>.
						</p>
					</>}
				</div>
			</main>
			<NewFooter/>
		</>
	)
};

export default Register