import Config from "../../config.js";
import React, { ChangeEvent, SyntheticEvent, useState, useEffect } from "react";
import lineart from "../../assets/lineart.svg";
import { Link, useHistory } from "react-router-dom";
import { NewFooter } from "../../components/footer/NewFooter";
import "../../styles/na_login.css";
import { getUser } from "../../redux/user/actionUser";
import { useDispatch, useSelector } from "react-redux";
import { baseNotification } from "../../utils/estimateUtils";
import { setWaitingStart, setWaitingStop } from "../../redux/waiting/actionWaiting";
import { getProfileData } from "../../redux/profile/profileActions";
import { profileStatusCodes } from "../../utils/statusCodes";
import { IUser } from '../../types/User';
import { userLogin } from '../../redux/user/actionUser';
import { EyePassword } from "../../components/EyePassword/EyePassword";
import { ROLECODES } from "../../utils/getRoleName";

interface State {
  username: string;
  password: string;
  code: string;
  mode: string;
  showPassword: boolean;
  remember: boolean;
}

export default function Login(): JSX.Element {
  const history = useHistory();
  const [values, setValues] = useState<State>({
    username: "",
    password: "",
    code: "",
    mode: "password",
    showPassword: false,
    remember: false,
  });
  const tokenLifetimeDefault = 7;

  const waiting = useSelector<any>((state) => state.waiting.waiting) as boolean;
  const {user, error} = useSelector<any>((state) => state.userReducer) as {user: IUser, error: any};
  const profile = useSelector<any>((state) => state.profile);
  const [passError, setPassError] = useState<boolean>(false);
  const [smsError, setSMSError] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    const profileStatus = getProfileStatus(profile);
    if(user?.login){
      if(user.role === ROLECODES.ARBITRATE){
        history.push("/complaint-list");
        return;
      }
      if([profileStatusCodes.onCheck, profileStatusCodes.isChecked].includes(profileStatus)){
        history.push("/list-contractor");
      }
      if([profileStatusCodes.notChecked, profileStatusCodes.signIn].includes(profileStatus) || 
        (user.role === ROLECODES.BRIGADIER)){
        history.push("/profile");
      }
    }
  }, [profile, user?.login, user?.role, history])

  useEffect(() => {
    const errorMessage = error?.response?.data;
    if(errorMessage && (error.request?.status !== 402)) baseNotification(errorMessage, "", "danger");
  }, [error])

  const getProfileStatus = (profile: any) => {
    return profile?.data?.contractor?.status ?? null;
  }

  async function submit(e: SyntheticEvent) {
    e.preventDefault();
    const login = values.username;
    const password = values.password;
    const code = values.code;
    const tokenLifetime = values.remember ? tokenLifetimeDefault : 0;
    dispatch(setWaitingStart());
    try{
      const response = await userLogin(login, password, code, tokenLifetime);
      
      if (response.ok) {
        dispatch(getUser());
        dispatch(getProfileData());
        dispatch(setWaitingStop());
      } else {
        if(![200, 400, 401].includes(response.status)) throw new Error("Внутренняя ошибка сервиса. Пожалуйста обратитесь в поддержку");
        if (values.password) {
          setPassError(true);
        } else if (values.code) {
          setSMSError(true);
        }
      }
    }catch(err: any){
      baseNotification(err.message, "", "danger");
    };
    dispatch(setWaitingStop());
  }

  async function sendSMS(e: SyntheticEvent) {
    e.preventDefault();
    const login = values.username;
    await fetch(Config.BACKEND_ADDRESS + "api/auth/sendsms", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        login,
      }),
    });
  }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleRadioChange =
    (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
      
      if((prop === "mode") && (event.target.value === "sms")){
        setValues({ ...values, [prop]: event.target.value, password: "" });
      }else{
        setValues({ ...values, [prop]: event.target.value });
      }
    };

  const usernameSpellingError = ((): boolean => {
    if (values.username) {
      const re = /^[a-zA-Z0-9]*$/;
      if (re.test(values.username)) {
        return values.username.length < 6;
      } else {
        return true;
      }
    } else {
      return false;
    }
  })();
  const passSpellingError = ((): boolean => {
    if (values.password) {
      const re = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
      if (re.test(values.password)) {
        return values.password.length < 8;
      } else {
        return true;
      }
    } else {
      return false;
    }
  })();
  const codeSpellingError = values.code ? values.code.length < 5 : true;

  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.value = "page__login non-authorized";
  }

  return (
    <>
      <main>
        <h2>Вход в систему</h2>

        <form name="account__modes--switches">
          <h3>
            Режим
            <br />
            входа
          </h3>
          <label className="clickable">
            <input
              type="radio"
              name="mode-switch"
              value="password"
              defaultChecked
              onChange={handleRadioChange("mode")}
            />
            Пароль
          </label>
          <label className="clickable">
            <input
              type="radio"
              name="mode-switch"
              onChange={handleRadioChange("mode")}
              value="sms"
            />
            Код СМС
          </label>
        </form>

        {values.mode === "password" && (
          <form name="account__mode--pass">
            <h3>Логин</h3>
            <input
              type="text"
              name="login"
              onChange={handleChange("username")}
              value={values.username}
              placeholder="(не указано)"
            />

            <h3>Пароль</h3>
            <EyePassword disabled={!values.username || usernameSpellingError} 
                         onChange={handleChange("password")}
                         className={passError ? "error__box" : ""}/>

            <h3>Авторизация</h3>
            
            <label htmlFor="remember"><input
              id = "remember"
              type="checkbox"
              name="remember"
              onChange={handleChange("remember")}></input>Помнить 7 дней</label>

            <button
              disabled={
                !values.password || passSpellingError || usernameSpellingError || waiting
              }
              onClick={submit}
            >
              Войти
            </button>
          </form>
        )}
        {values.mode === "sms" && (
          <form name="account__mode--sms">
            <h3>Логин</h3>
            <input
              type="text"
              name="login"
              onChange={handleChange("username")}
              value={values.username}
              placeholder="(не указано)"
            />

            <h3>Получение кода</h3>
            <button
              disabled={!values.username || usernameSpellingError}
              onClick={sendSMS}
            >
              Отправить в СМС
            </button>

            <h3>Код из СМС</h3>
            <input
              type="text"
              name="sms"
              placeholder="(не указано)"
              onChange={handleChange("code")}
              disabled={false}
            />

            <button disabled={codeSpellingError || waiting} onClick={submit}>
              Войти
            </button>
          </form>
        )}

        <div className="pic__back">
          <img src={lineart} alt="Фоновая картинка" />
        </div>

        <div
          className={
            passError ? "info__box error__text" : "info__box"
          }
        >
          {!passError && (
            <>
              <h3>Еще не зарегистрированы?</h3>
              <p>
                <Link to="/register" title="Страница регистрации">
                  Зарегистрироваться!
                </Link>
              </p>
            </>
          )}
          {passError && (
            <>
              <h3>Неверный пароль</h3>
              <p>
                Возможно, вы допустили опечатку.
                <br />
                Если забыли пароль, войдите по коду СМС.
              </p>
            </>
          )}
          {smsError && (
            <>
              <h3>Неверный код</h3>
              <p>
                Код не соответствует отправленному в СМС!
                <br />
                Введите код из СМС или{" "}
                <span className="link-normal" onClick={sendSMS} title="Отправка нового кода">
                  запросите новый код
                </span>
                .
              </p>

              <h3>СМС не приходит</h3>
              <p>Отправить код повторно.</p>
            </>
          )}
        </div>
      </main>
      <NewFooter hideExitButton={true}/>
    </>
  );
}
