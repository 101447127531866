import styles from './estimate.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { sendFlowEstimate } from '../../../redux/actions/actionContractor'
import { setBuildingListScrollY } from '../../../redux/buildingList/buildingListActions'
import { type IEstimateWork } from '../Types'
import { parseHumanInputDate } from '../../../utils/estimateUtils'

export const EstimateRowInfo = (props: { estimate: any }) => {
  const { estimate } = props
  const dispatch = useDispatch<any>()
  const estimateWorkFlow: IEstimateWork[] = useSelector((state: any) => state.contractorReducer.estimateWorkFlow)
  const getEstimateItem = (status: any) => {
    return estimateWorkFlow?.filter((item) => item.currentStateCode === status) ?? []
  }
  const handleClick = (e: React.MouseEvent<HTMLElement>, id: string, newState: number) => {
    e.stopPropagation()
    dispatch(setBuildingListScrollY(window.scrollY))
    dispatch(sendFlowEstimate({ entityId: id, newState }))
  }

  let sumAndDate = ''

  const message = estimate.estimatorMessage || estimate.estimateMessage

  if (!estimate.estimatorMessage) {
    if (estimate?.estimateSum !== undefined) sumAndDate += estimate?.estimateSum
    if (estimate?.noteDate !== undefined) {
      const humDate = parseHumanInputDate(estimate.noteDate)
      if (sumAndDate) {
        sumAndDate = `${sumAndDate}; ${humDate}`
      } else {
        sumAndDate = humDate
      }
    }
  }

  const leftPart = (
      <div className={estimate.estimatorMessage ? styles.estMessage : styles.sum}>
        <span>{message}</span>
        {sumAndDate && <span>{sumAndDate}</span>}
      </div>
  )

  let rightPart: JSX.Element | null = <></>

  const estimateItem = getEstimateItem(estimate.estimateStatus)[0]
  if (estimateItem && (estimateItem.buttons != null)) {
    rightPart = <>
        <div className={`${styles.buttons} ${styles.borderLine}`}>
          <span style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {estimateItem.buttons.map((item) =>
              <button className="btn-small"
                      style={{ height: 'auto' }}
                      key={item.statusCode}
                      onClick={ (e) => { handleClick(e, estimate.id, item.statusCode) }}>
                {item.title}
              </button>)
            }
          </span>
        </div>
    </>
  }

  return <>{leftPart}{rightPart}</>
}
