import { type IObject } from '../../../../types/Billing'
import MutualListBuilding from './components/MutualListBuilding/MutualListBuilding'

interface IMutualList {
  items: IObject[]
}

function MutualList ({ items }: IMutualList): JSX.Element {
  return (
    <>
      {items.map(building => (
        <MutualListBuilding building={building} key={building.entityId}/>
      ))}
    </>
  )
}

export default MutualList
