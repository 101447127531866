import axios from "axios";

import Config from "../../config";
import { baseNotification, parseDate, parseInputDate } from "../../utils/estimateUtils";
import { estimateDocumentCodes } from "../../utils/statusCodes";
import { ROLECODES } from "../../utils/getRoleName";
import { getProfileData } from "../../redux/profile/profileActions";

export const GET_BUILDINGS_START = "GET_BUILDINGS_START";
export const GET_BUILDINGS_SUCCESS = "GET_BUILDINGS_SUCCESS";
export const GET_BUILDINGS_FAIL = "GET_BUILDINGS_FAIL";

export const GET_ESTIMATETYPES_START = "GET_ESTIMATETYPES_START";
export const GET_ESTIMATETYPES_SUCCESS = "GET_ESTIMATETYPES_SUCCESS";
export const GET_ESTIMATETYPES_FAIL = "GET_ESTIMATETYPES_FAIL";

export const GET_ESTIMATE_WORK_FLOW_START = "GET_ESTIMATE_WORK_FLOW_START";
export const GET_ESTIMATE_WORK_FLOW_SUCCESS = "GET_ESTIMATE_WORK_FLOW_SUCCESS";
export const GET_ESTIMATE_WORK_FLOW_FAIL = "GET_ESTIMATE_WORK_FLOW_FAIL";

export const GET_COMPLAINTCARD_START = "GET_COMPLAINTCARD_START";
export const GET_COMPLAINTCARD_SUCCESS = "GET_COMPLAINTCARD_SUCCESS";
export const GET_COMPLAINTCARD_FAIL = "GET_COMPLAINTCARD_FAIL";

export const GET_COMPLAINTLIST_START = "GET_COMPLAINTLIST_START";
export const GET_COMPLAINTLIST_SUCCESS = "GET_COMPLAINTLIST_SUCCESS";
export const GET_COMPLAINTLIST_FAIL = "GET_COMPLAINTLIST_FAIL";

export const GET_PARENT_INFO_BY_ORDERID_START = "GET_PARENT_INFO_BY_ORDERID_START";
export const GET_PARENT_INFO_BY_ORDERID_SUCCESS = "GET_PARENT_INFO_BY_ORDERID_SUCCESS";
export const GET_PARENT_INFO_BY_ORDERID_FAIL = "GET_PARENT_INFO_BY_ORDERID_FAIL";

export const GET_COMPLAINT_TOPICS_START = "GET_COMPLAINT_TOPICS_START";
export const GET_COMPLAINT_TOPICS_SUCCESS = "GET_COMPLAINT_TOPICS_SUCCESS";
export const GET_COMPLAINT_TOPICS_FAIL = "GET_COMPLAINT_TOPICS_FAIL";

export const SET_ERROR = 'SET_ERROR';

export const SET_COMPLAINT_DATA_SEND_START = "SET_COMPLAINT_DATA_SEND_START";
export const SET_COMPLAINT_DATA_SEND_SUCCESS = "SET_COMPLAINT_DATA_SEND_SUCCESS";
export const SET_COMPLAINT_DATA_SEND_FAIL = "SET_COMPLAINT_DATA_SEND_FAIL";

export const getBuildings = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));
  dispatch(fetchBuildingStart());
  try {
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/GetBuildings', {
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(fetchBuildingSuccess(result.data));
  } catch (e) {
    if(!token){
      baseNotification("Пожалуйста войдите в свой аккаунт", "", "danger");
    }
    dispatch(fetchBuildingFail(e));
  }
}

export const getBuilding = (buildingId) => async (dispatch) => {
  dispatch(fetchBuildingStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetBuilding?ID=${buildingId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(fetchBuildingSuccess(result.data));
  } catch (e) {
    dispatch(fetchBuildingFail(e));
  }
}

export const getEstimateWork = () => async (dispatch) => {
  dispatch(fetchEstimateWorkStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.get(Config.BACKEND_ADDRESS + 'Estimate/GetWorkFlow/List', {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(fetchEstimateWorkSuccess(result.data));
  } catch (e) {
    dispatch(fetchEstimateWorkFail(e));
  }
}

export const getEstimateTypes = () => async (dispatch) => {
  dispatch(fetchEstimateTypesStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.get(Config.BACKEND_ADDRESS + 'Contractor/GetEstimateTypes', {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    return dispatch(fetchEstimateTypesSuccess(result.data));
  } catch (e) {
    dispatch(fetchEstimateTypesFail(e));
  }
}

export const createEstimate = (body) => async (dispatch) => {
    const {estimateName, note, estimateTypeId, workStartPlan, workEndPlan, buildingId} = body;
    const token = JSON.parse(localStorage.getItem("user"));
    try {
      const res = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/CreateEstimate', {
        buildingId,
        estimateName,
        note,
        estimateTypeId: Number(estimateTypeId),
        workStartPlan: new Date(parseDate(workStartPlan)).toISOString(),
        workEndPlan: new Date(parseDate(workEndPlan)).toISOString()
      },{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        }
      });
      baseNotification("Смета создана", '',"success");
      return res;
    } catch (e) {
      baseNotification("Не удалось создать смету", "","danger");
    }
}

export const createObject = (body) => async () => {
  const token = JSON.parse(localStorage.getItem("user"));
  const { estimateTypeId, workStartPlan, workEndPlan, longitude, latitude } = body;

  try {
    const res = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/CreateBuilding', {
      ...body,
      longitude: Number(longitude),
      latitude: Number(latitude),
      estimateTypeId: Number(estimateTypeId),
      workStartPlan: new Date(parseDate(workStartPlan)).toISOString(),
      workEndPlan: new Date(parseDate(workEndPlan)).toISOString()
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    baseNotification("Объект создан", '',"success");
    return res;
  } catch (e) {
    baseNotification("Не удалось создать объект", "","danger");
  }
}

export const saveBuilding = (body) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));
  const { longitude, latitude, id } = body;
  const data = ["id", 
                "buildingName", 
                "buildingAddress", 
                "developer", 
                "customer", 
                "mainContractor", 
                "objectRole"
               ].reduce((acc, key) => {
                  acc[key] = body[key]
                  return acc;
               }, {});
  try {
    const res = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/SaveBuilding', {
      ...data,
      longitude: Number(longitude),
      latitude: Number(latitude)
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    baseNotification("Объект сохранен", '',"success");
    dispatch(getBuilding(id));
    return res;
  } catch (e) {
    baseNotification("Не удалось сохранить объект", "","danger");
  }
}

export const sendFlowEstimate = (body) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    await axios.post(Config.BACKEND_ADDRESS + 'Estimate/SaveNewStatus', {
      ...body
        }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    baseNotification("Успешно", '',"success");
    dispatch(getBuildings());
    dispatch(getProfileData())
  } catch (e) {
    baseNotification("Произошла ошибка", "","danger");
  }
}

export const loginEstimator = async () => {
  const response = await fetch(Config.BACKEND_ADDRESS + "api/auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    // credentials: "include",
    body: JSON.stringify({
      login:"estimator",
      password:"G@hjkmLkzGkfnajhvs22"
    }),
  });
  const result = await response.json();
  return result.token;
}

export const getComplaintCard = (id) => async (dispatch) => {
  if(!id) return;
  dispatch(fetchComplaintCardStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Foreman/GetComplaint', 
    { 
      id 
    }, 
    { 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    });
    const orderId = result.data.orderId
    const orderResult = orderId ? await axios.get(Config.BACKEND_ADDRESS + 'Foreman/GetOrderById?ID=' + orderId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    }) : null;
    
    dispatch(fetchComplaintCardSuccess({
      ...result.data,
      complaints: orderResult?.data.complaints,
      cost: orderResult?.data.cost,
      fines: orderResult?.data.fines
    }));
  } catch (e) {
    dispatch(fetchComplaintCardFail(e))
  }
}

export const getComplaintTopics = () => async (dispatch) => {
  dispatch(fetchComplaintTopicsStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + 'Estimator/GetComplaintTopics', { 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    });
    dispatch(fetchComplaintTopicsSuccess(result.data))
  } catch (e) {
    dispatch(fetchComplaintTopicsFail(e))
  }
}

export const getComplaintList = (filters) => async (dispatch) => {
  dispatch(getComplaintListStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const {estimate_order, building_address, theme, replica, status, respondentFio, dateFrom, dateTo, responder} = filters;
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Foreman/GetComplaints', {
      estimateOrderFilter: estimate_order,
      buildingFilter: building_address,
      topicId: parseInt(theme) || 0,
      messageFilter: replica,
      status: parseInt(status) || 0,
      respondentFioFilter: respondentFio,
      dateFrom: dateFrom ? parseInputDate(dateFrom) : null,
      dateTo: dateTo ? parseInputDate(dateTo) : null,
      respondentRoleFilter: parseInt(responder) || 0
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(getComplaintListSuccess(result.data));
  } catch (e) {
    if(!token){
      baseNotification("Пожалуйста войдите в свой аккаунт", "", "danger");
    }
    dispatch(getComplaintListFail(e));
  }
}

export const getParentInfoByOrderId = (orderId, profile) => async (dispatch) => {
  if(!orderId) return;
  dispatch(fetchParentInfoByOrderIdStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + 'Foreman/GetOrderById?ID=' + orderId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    const payload = result.data
    const author = profile?.data?.responsible
    const authorFio = author ? `${author.lastName} ${author.firstName} ${author.secondName}` : ''
    payload.authorFio = payload.authorFio ?? authorFio
    payload.respondentFio = payload.respondentFio ?? payload.brigadeFIO
    payload.respondentRole = payload.respondentRole ?? ROLECODES.BRIGADIER
    payload.topicId = 0
    dispatch(fetchParentInfoByOrderIdSuccess(payload))
  } catch (e) {
    dispatch(fetchParentInfoByOrderIdFail(e))
  }
}

export const clearComplaint = () => async (dispatch) => {
  dispatch(fetchParentInfoByOrderIdSuccess({}))
}

export const createComplaint = (createId, topicId, messageText, fileIds) => async (dispatch) => {
  dispatch(setComplaintDataSendStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Foreman/CreateComplaint', {
      orderId: createId,
      topicId,
      messageText,
      fileIds
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setComplaintDataSendSuccess());
    return result.data;
  } catch (e) {
    dispatch(setComplaintDataSendFail(e));
  }
}

export const joinFilesToMessage = (messageId, filesAdded) => async (dispatch) => {
  if(!messageId) return;
  dispatch(setComplaintDataSendStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    for(let i = 0; i < filesAdded.length; i++){
      await axios.post(Config.BACKEND_ADDRESS + `Message/SaveDocument`, {
        description: filesAdded[i].description,
        url: null,
        documentType: estimateDocumentCodes.complaintDoc,
        originalFileName: filesAdded[i].originalFileName,
        fileId: filesAdded[i].fileId,
        contentType: "application/octet-stream",
        entityid: messageId
      }, {headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }});
    };
    dispatch(setComplaintDataSendSuccess());
  } catch (e) {
    dispatch(setComplaintDataSendFail(e))
  }
}

export const addMessage = (id, newStatus, message, fileIds = [], date, questionForRole, brigPayValue, fineValue) => async (dispatch) => {
  dispatch(setComplaintDataSendStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const brigPay = brigPayValue || undefined
    const fine = fineValue || undefined
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Complaint/AddMessage', 
      {
        id,
        newStatus,
        message,
        fileIds: fileIds.map(item => ({fileId: item.fileId, 
                                       description: item.description,
                                       originalFileName: item.originalFileName})),
        date,
        questionForRole,
        brigPay,
        fine
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        }
      }
    );
    dispatch(setComplaintDataSendSuccess());
    return result.data;
  } catch (e) {
    dispatch(setComplaintDataSendFail(e));
  }
}

export const changeComplaintState = (complaintId, newState) => async (dispatch) => {
  dispatch(setComplaintDataSendStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Complaint/SaveNewStatus', {
      entityId: complaintId,
      newState,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setComplaintDataSendSuccess());
    dispatch(getProfileData());
    return result.data;
  } catch (e) {
    dispatch(setComplaintDataSendFail(e));
  }
}

const fetchBuildingSuccess = (payload) => ({
  type: GET_BUILDINGS_SUCCESS,
  payload,
})

const fetchBuildingStart = () => ({
  type: GET_BUILDINGS_START
})

const fetchBuildingFail = (payload) => ({
  type: GET_BUILDINGS_FAIL,
  payload,
})

const fetchEstimateTypesSuccess = (payload) => ({
  type: GET_ESTIMATETYPES_SUCCESS,
  payload,
})

const fetchEstimateTypesStart = () => ({
  type: GET_ESTIMATETYPES_START
})

const fetchEstimateTypesFail = (payload) => ({
  type: GET_ESTIMATETYPES_FAIL,
  payload,
})

const fetchEstimateWorkSuccess = (payload) => ({
  type: GET_ESTIMATE_WORK_FLOW_SUCCESS,
  payload,
})

const fetchEstimateWorkStart = () => ({
  type: GET_ESTIMATE_WORK_FLOW_START
})

const fetchEstimateWorkFail = (payload) => ({
  type: GET_ESTIMATE_WORK_FLOW_FAIL,
  payload,
})

const fetchComplaintCardSuccess = (payload) => ({
  type: GET_COMPLAINTCARD_SUCCESS,
  payload,
})

const fetchComplaintCardStart = () => ({
  type: GET_COMPLAINTCARD_START,
})

const fetchComplaintCardFail = (payload) => ({
  type: GET_COMPLAINTCARD_FAIL,
  payload,
})

const fetchParentInfoByOrderIdSuccess = (payload) => ({
  type: GET_PARENT_INFO_BY_ORDERID_SUCCESS,
  payload,
})

const fetchParentInfoByOrderIdStart = () => ({
  type: GET_PARENT_INFO_BY_ORDERID_START,
})

const fetchParentInfoByOrderIdFail = (payload) => ({
  type: GET_PARENT_INFO_BY_ORDERID_FAIL,
  payload,
})

const fetchComplaintTopicsSuccess = (payload) => ({
  type: GET_COMPLAINT_TOPICS_SUCCESS,
  payload,
})

const fetchComplaintTopicsStart = () => ({
  type: GET_COMPLAINT_TOPICS_START,
})

const fetchComplaintTopicsFail = (payload) => ({
  type: GET_COMPLAINT_TOPICS_FAIL,
  payload,
})

const setComplaintDataSendSuccess = (payload) => ({
  type: SET_COMPLAINT_DATA_SEND_SUCCESS,
  payload,
})

const setComplaintDataSendStart = () => ({
  type: SET_COMPLAINT_DATA_SEND_START,
})

const setComplaintDataSendFail = (payload) => ({
  type: SET_COMPLAINT_DATA_SEND_FAIL,
  payload,
})

const getComplaintListSuccess = (payload) => ({
  type: GET_COMPLAINTLIST_SUCCESS,
  payload,
})

const getComplaintListStart = () => ({
  type: GET_COMPLAINTLIST_START,
})

const getComplaintListFail = (payload) => ({
  type: GET_COMPLAINTLIST_FAIL,
  payload,
})
