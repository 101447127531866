import type React from 'react'
import { useEffect, useMemo, useState } from 'react'
import ObjectPageHead from '../ObjectPageHead/ObjectPageHead'
import { ErrorPanel } from '../ErrorPanel/ErrorPanel'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Profile.module.scss'
import ContractorAccountData from './components/AccountData/ContractorAccountData'
import FormanAccountData from './components/AccountData/FormanAccountData'
import { getProfileStatuses } from '../../redux/profile/profileActions'
import { type IProfileState } from '../../redux/profile/types'
import Statistics from './components/Statistics/Statistics'
import Employees from './components/Employees/Employees'
import BankAccounts from './components/BankAccounts/BankAccounts'
import { profileStatusCodes } from '../../utils/statusCodes'
import { type IUser } from '../../types/User'
import { ROLECODES } from '../../utils/getRoleName'
import { BrigadierInfoPage } from '../../pages/BrigadierInfoPage/BrigadierInfoPage'

const Profile = (): JSX.Element => {
  const user = useSelector((state: any) => state.userReducer.user) as IUser
  const isContractor = user?.role === ROLECODES.CONTRACTOR
  const TABS = isContractor
    ? [
        { id: 0, title: 'Учетные данные' },
        { id: 1, title: 'Прорабы' },
        { id: 2, title: 'Банковские счета' },
        { id: 3, title: 'Статистика' }
      ]
    : [
        { id: 0, title: 'Учетные данные' },
        { id: 1, title: 'Статистика' }
      ]
  const [activeTab, setActiveTab] = useState<number>(TABS[0].id)
  const profile = useSelector<any>(state => state.profile) as IProfileState

  const userRoleCode = user?.role
  const dispatch = useDispatch<any>()

  const profileStatus = useMemo(() => {
    if ((profile.statuses.length === 0) || profile.data?.contractor?.status === undefined) {
      return null
    }
    return profile.statuses.find(item => item.currentStateCode === profile.data?.contractor?.status)
  }, [profile.statuses, profile.data?.contractor?.status])

  const isTabsDisabled = profileStatus?.currentStateCode !== profileStatusCodes.isChecked

  const handleChangeTab = (tab: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    dispatch(getProfileStatuses())
  }, [dispatch])

  if(user?.role === ROLECODES.BRIGADIER) {
    return <BrigadierInfoPage/>
  }

  if (![ROLECODES.CONTRACTOR, ROLECODES.FOREMAN].includes(user?.role)) {
    return (
      <ErrorPanel errorMsg={'Тип пользователя не поддерживается'}/>
    )
  }

  if (profile.isError) {
    return (
      <ErrorPanel errorMsg={'Не удалось загрузить профиль'}/>
    )
  }

  if ((profile.data == null) && profile.isLoading) {
    return <></>
  }

  return (
    <>
      <ObjectPageHead title="Профиль пользователя" roleCode={userRoleCode}/>
      <div className={styles.tabs}>
        <span className={`${styles.statusIcon} 
          ${profileStatus?.currentStateCode === profileStatusCodes.isChecked ? styles.verified : ''}`}>!
        </span>
        {TABS.map((tab, index) => (
          <button
            key={index}
            className={`${styles.tab} ${activeTab === tab.id ? styles.isActive : ''}`}
            onClick={handleChangeTab(tab.id)}
            disabled={tab.id !== 0 && isTabsDisabled}
          >
            {tab.title}
            {tab.id === 0 && (
              <span className={`${styles.statusText} 
                ${profileStatus?.currentStateCode === profileStatusCodes.isChecked ? styles.verified : ''}`}>{profileStatus?.currentStateText}
              </span>
            )}
          </button>
        ))}
      </div>
      {isContractor && <div className={styles.content}>
        {activeTab === TABS[0].id && (
          <ContractorAccountData/>
        )}
        {activeTab === TABS[1].id && (
          <Employees/>
        )}
        {activeTab === TABS[2].id && (
          <BankAccounts/>
        )}
        {activeTab === TABS[3].id && (
          <Statistics/>
        )}
      </div>}
      {!isContractor && <div className={styles.content}>
        {activeTab === TABS[0].id && (
          <FormanAccountData/>
        )}
        {activeTab === TABS[1].id && (
          <Statistics/>
        )}
      </div>}
    </>
  )
}

export default Profile
