import { NewFooter } from "../../components/footer/NewFooter";
import Profile from "../../components/Profile/profile";

function ProfilePage() {

  return (
    <>
      <main>
        <Profile/>
      </main>
      <NewFooter />
    </>
  );
}

export default ProfilePage;