import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getComplaintCard, addMessage, changeComplaintState } from '../../redux/actions/actionContractor'
import ComplaintMessage from '../../components/ComplaintCard/ManagePanels/ComplaintMessage'
import ComplaintInitiator from '../../components/ComplaintCard/ManagePanels/ComplaintInitiator'
import ComplaintArbitrator from '../../components/ComplaintCard/ManagePanels/ComplaintArbitrator'
import { COMPLAINTCODES } from '../../utils/getComplaints'

interface ManagePanelParams {
  openStatus: string
  complaintCardObject: any
  setOpenControlPanelHandler: Function
  createId: string | null
  id: string | null
  complaintTopic: number
  hasOtherComplaints: boolean
}

const ManagePanel = (params: ManagePanelParams): JSX.Element => {
  const {
    openStatus,
    complaintCardObject,
    setOpenControlPanelHandler,
    createId,
    id,
    complaintTopic,
    hasOtherComplaints
  } = params
  const dispatch = useDispatch<any>()
  const [downtimeDate, setDowntimeDate] = useState<string>('')
  const [brigpay, setBrigpay] = useState<string>('')
  const [fine, setFine] = useState<string>('')
  const changeComplaintStateHandler = async (newState: number,
    date: string | null = null,
    message: string | null = null,
    fileIds: any[] = [],
    questionForRole: number | null = null,
    brigpay: number | null = null,
    fine: number | null = null) => {
    if (newState === COMPLAINTCODES.ARBITRATE) {
      await dispatch(changeComplaintState(id, newState))
    } else {
      await dispatch(addMessage(id, newState, message, fileIds, date, questionForRole, brigpay, fine))
    }
    await dispatch(getComplaintCard(id))
  }

  const setDowntimeDateHandler = (date: string) => {
    setDowntimeDate(date)
  }

  return (
    <div id="box__manage-complaint" className={openStatus}>
        <ComplaintMessage setOpenControlPanelHandler = {setOpenControlPanelHandler}
                             createId = {createId}
                             id = {id}
                             complaintTopic = {complaintTopic}/>
        <ComplaintInitiator setOpenControlPanelHandler = {setOpenControlPanelHandler}
                             changeComplaintStateHandler = {changeComplaintStateHandler}
                             setDowntimeDateHandler = {setDowntimeDateHandler}
                             downtimeDate = {downtimeDate}/>
        <ComplaintArbitrator setOpenControlPanelHandler = {setOpenControlPanelHandler}
                             complaintCardObject = {complaintCardObject}
                             changeComplaintStateHandler = {changeComplaintStateHandler}
                             setDowntimeDateHandler = {setDowntimeDateHandler}
                             downtimeDate = {downtimeDate}
                             brigpay={brigpay}
                             setBrigpay={setBrigpay}
                             fine={fine}
                             setFine={setFine}
                             hasOtherComplaints={hasOtherComplaints}/>
    </div>
  )
}

export default ManagePanel
