import React, { memo, useState } from 'react';
import style from './equipment.module.scss';
import { EstimateList } from "../EstimateList/EstimateList";
import { useSystemFilter, useJobFilter } from "../../../hooks/useFilter";
import { EstimateListType } from './types'; 

const Equipment = memo(({estimate}: any) => {
  const [tab, setTab] = useState<EstimateListType>(EstimateListType.System);

  const systems = useSystemFilter(estimate);
  const jobs = useJobFilter(estimate);

  return (
      <div style={{marginTop: '12px'}}>
        <div className={style.container}>
          <div onClick={() => setTab(EstimateListType.System)} className={`${tab === EstimateListType.System ? style.activeTab : style.tab}`}>
            <label className={style.label} htmlFor="tab__grouping--1">
              <span>Состав сметы по Системам</span>
            </label>
          </div>
          <div onClick={() => setTab(EstimateListType.Job)} className={`${tab === EstimateListType.Job ? style.activeTab : style.tab}`}>
            <label className={style.label} htmlFor="tab__grouping--2">
              <span>Состав сметы по Работам</span>
            </label>
          </div>
        </div>
        {tab === EstimateListType.System && <EstimateList all={estimate} arr={systems} type={EstimateListType.System}/>}
        {tab === EstimateListType.Job && <EstimateList all={estimate} arr={jobs} type={EstimateListType.Job}/>}
      </div>
  )
})

export {Equipment}