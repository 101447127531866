import type React from 'react'
import { useState, useMemo, useEffect } from 'react'
import ObjectPageHead from '../ObjectPageHead/ObjectPageHead'
import ParentDataBar from './components/ParentDataBar/ParentDataBar'
import StatusBar from './components/StatusBar/StatusBar'
import ChainBox from './components/ChainBox/ChainBox'
import { type IJobPlan, type IJobPlanChain, type IJobPlanChainOrder } from '../../types/JobPlan'
import { type TAjustText } from './types'
import moment, { type Moment } from 'moment'
import { useSelector } from 'react-redux'
import NewChain from './components/NewChain/NewChain'
import { ROLECODES } from '../../utils/getRoleName'
import { type IStatus, type IButton } from '../../types/Status'

interface JobPlanProps {
  jobPlan: IJobPlan
}

function JobPlan ({ jobPlan }: JobPlanProps) {
  const [orderAjusts, setOrderAjusts] = useState<Record<string, TAjustText>>({})
  const [blockNewChain, setBlockNewChain] = useState<boolean>(false)
  const [disableNewChain, setDisableNewChain] = useState<boolean>(false)
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number
  const updatingEntitiesId = useSelector<any>(state => state.jobPlan.updatingEntitiesId) as string[]
  const editMode = useSelector<any>(state => state.jobPlan.editMode) as boolean
  const savingNewDates = useSelector<any>(state => state.jobPlan.savingNewDates) as boolean
  const createChainOpen = useSelector<any>(state => state.jobPlan.createChainOpen) as boolean
  const state = useSelector((state: any) => state.estimateReducer)

  useEffect(() => {
    const ajusts = localStorage.getItem('orderAjusts')
    if (!ajusts) return
    try {
      const ajustsObj = JSON.parse(ajusts)
      setOrderAjusts(ajustsObj)
    } catch {

    }
  }, [])

  const canEditPlan = userRoleCode === ROLECODES.FOREMAN
  const startEndDates = useMemo((): [Moment, Moment] => {
    const dates = jobPlan.chains?.map(chain => {
      return [moment(chain.workStartPlan), moment(chain.workEndPlan)]
    })
    const startDate = dates?.sort((a, b) => {
      if (a[0].valueOf() < b[0].valueOf()) {
        return -1
      }
      if (a[0].valueOf() > b[0].valueOf()) {
        return 1
      }

      return 0
    })[0][0]

    const endDate = dates?.sort((a, b) => {
      if (a[1].valueOf() > b[1].valueOf()) {
        return -1
      }
      if (a[1].valueOf() < b[1].valueOf()) {
        return 1
      }

      return 0
    })[0][1]

    return [startDate, endDate]
  }, [jobPlan])

  const titlePart = useMemo(() => {
    if (updatingEntitiesId.length > 0) {
      return ': обновление...'
    }

    if (savingNewDates) {
      return ': сохранение...'
    }

    return editMode ? ': редактирование' : ''
  }, [editMode, updatingEntitiesId, savingNewDates])

  const actions = ((): IButton[] => {
    const estimateStatuses = state.modeJobplan as IStatus[]
    if (!estimateStatuses) {
      return []
    }
    const estimateStatusesFiltered = estimateStatuses.filter(item => item.currentStateCode === jobPlan.jobPlan.status)
    return estimateStatusesFiltered[0]?.buttons ?? []
  })()

  const getAutoText = (order: IJobPlanChainOrder, chain: IJobPlanChain) =>
    `Цепочка: ${chain.name}Наряд: ${order.orderName}`

  const isAdjustPresent = (order: IJobPlanChainOrder, chain: IJobPlanChain) => {
    let ajustText = orderAjusts[order.id]?.ajustText
    if (!ajustText) return false
    ajustText = ajustText.replace(/\r?\n|\r/g, '')
    return !!ajustText && (ajustText !== getAutoText(order, chain))
  }

  const isSomeAdjustPresent = () => {
    return Object.keys(orderAjusts).some(orderId => {
      const chain = jobPlan.chains.filter(chain => chain.id === orderAjusts[orderId].chainId)[0]
      if (!chain) return false
      const order = chain.orders.filter(order => order.id === orderId)[0]
      return isAdjustPresent(order, chain)
    })
  }

  return (
    <>
      <ObjectPageHead title={`Карточка разбивки${titlePart}`} roleCode={userRoleCode}/>
      <ParentDataBar jobPlanData={jobPlan.jobPlan}/>
      <StatusBar
        startEndDates={startEndDates}
        jobPlanData={jobPlan.jobPlan}
        canEditPlan={canEditPlan}
        orderAjusts = {orderAjusts}
        isSomeAdjustPresent = {isSomeAdjustPresent}
        setOrderAjusts = {setOrderAjusts}
        actions = {actions}
        blockNewChain = {blockNewChain}
        setBlockNewChain = {setBlockNewChain}
        disableNewChain = {disableNewChain}
      />
      <ChainBox
        startEndDates={startEndDates}
        chains={jobPlan.chains}
        jobPlanStatus={jobPlan.jobPlan.status}
        orderAjusts = {orderAjusts}
        isAdjustPresent = {isAdjustPresent}
        setOrderAjusts = {setOrderAjusts}
        canEditPlan={canEditPlan}
        actions = {actions}
        setBlockNewChain = {setBlockNewChain}
        setDisableNewChain = {setDisableNewChain}
      />
      {createChainOpen && (
        <NewChain/>
      )}
    </>
  )
}

export default JobPlan
