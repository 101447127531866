import { IProfileData } from '../../../../../../types/Profile'
import { isValidEmail, isValidInn, isValidKpp, isValidOgrn, isValidOkpo, isValidPhone } from '../../../../../../utils/validators'
import styles from './ChangeData.module.scss'
/*    newData.responsible.lastName !== '' &&
      newData.responsible.firstName !== '' &&
      newData.responsible.secondName !== '' &&
      isValidEmail(newData.responsible.email) &&
      newData.contractor.name !== '' &&
      isValidInn(newData.contractor.inn) &&
      !innPresentFlag &&
      isValidKpp(newData.contractor.kpp) &&
      isValidOkpo(newData.contractor.okpo) &&
      isValidOgrn(newData.contractor.ogrn) &&
      newData.contractor.legalAddress !== '' &&
      newData.contractor.actualAddress !== '' &&
      isValidPhone(newData.contractor.phone) &&
      newData.contractor.signatoryLastName !== '' &&
      newData.contractor.signatoryFirstName !== '' &&
      newData.contractor.signatorySecondName !== '' &&
      newData.contractor.signatoryPost !== '' &&
      newData.contractor.signatoryReason !== '' */

export const getValidStyle = (fieldName: string, newData: string, tryreg: boolean, errFlag?: boolean): string => {
  switch (fieldName) {
    case 'lastName':
    case 'firstName':
    case 'secondName':
    case 'name':
    case 'legalAddress':
    case 'actualAddress':
    case 'signatoryLastName':
    case 'signatoryFirstName':
    case 'signatorySecondName':
    case 'signatoryPost':
    case 'signatoryReason':
      return tryreg && ((newData === '') || (newData === undefined)) ? styles.error : ''
    case 'email':
      return tryreg && !isValidEmail(newData) ? styles.error : ''
    case 'inn':
      return tryreg && (!isValidInn(newData) || errFlag) ? styles.error : ''
    case 'kpp':
      return tryreg && !isValidKpp(newData) ? styles.error : ''
    case 'okpo':
      return tryreg && !isValidOkpo(newData) ? styles.error : ''
    case 'ogrn':
      return tryreg && !isValidOgrn(newData) ? styles.error : ''
    case 'phone':
      return tryreg && !isValidPhone(newData) ? styles.error : ''  
    default: return ''
  }

  /*             className={
              profile.data?.contractor.phone === '' &&
              !isValidPhone(newData.contractor.phone) &&
              newData.contractor.phone !== ''
                ? styles.error
                : ''
            } */

  /*             className={
              profile.data?.contractor.ogrn === '' &&
              !isValidOgrn(newData.contractor.ogrn) &&
              newData.contractor.ogrn !== ''
                ? styles.error
                : ''
            } */

  /*             className={
              profile.data?.contractor.okpo === '' &&
              !isValidOkpo(newData.contractor.okpo) &&
              newData.contractor.okpo !== ''
                ? styles.error
                : ''
            } */
}
 /*
               profile.data?.responsible.email === '' &&
              !isValidEmail(newData.responsible.email) &&
              newData.responsible.email !== ''
                ? styles.error
                : '' 
 */

    /*            className={
                  profile.data?.contractor.inn === '' &&
                  !(isValidInn(newData.contractor.inn) || innPresentFlag) &&
                  newData.contractor.inn !== ''
                    ? styles.error
                    : ''}*/


                  /*  className={
                      profile.data?.contractor.kpp === '' &&
                      !isValidKpp(newData.contractor.kpp) &&
                      newData.contractor.kpp !== ''
                        ? styles.error
                        : ''
                    } */