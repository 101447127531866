import type React from 'react'
import { useState } from 'react'
import styles from './ChangePassword.module.scss'
import Modal from '../../../../../Modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { type IProfileState } from '../../../../../../redux/profile/types'
import { saveProfilePassword } from '../../../../../../redux/profile/profileActions'
import { baseNotification } from '../../../../../../utils/estimateUtils'
import { smsSend } from '../../../../../../utils/sendSms'
import { EyePassword } from '../../../../../EyePassword/EyePassword'
import { isPasswordWrong } from '../../../../../../utils/validators'

interface IChangePassword {
  onClose: VoidFunction
}

const ChangePassword = ({ onClose }: IChangePassword): JSX.Element => {
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [errPass, setErrPass] = useState<boolean>(false)
  const [errPassConfirm, setErrPassConfirm] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [smsCode, setSmsCode] = useState<string>('')
  const profile = useSelector<any>(state => state.profile) as IProfileState
  const dispatch = useDispatch<any>()

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    onClose()
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value)
    setErrPass(e.target.value !== '' && isPasswordWrong(e.target.value))
    setErrPassConfirm(passwordConfirm !== '' && e.target.value !== passwordConfirm)
  }

  const handleChangeSmsCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSmsCode(e.target.value)
  }

  const handleChangePasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordConfirm(e.target.value)
    setErrPassConfirm(e.target.value !== '' && e.target.value !== password)
  }

  const askForSmsHandler = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    await smsSend(e, profile.data?.responsible.login)
      .catch(() => { setError('Не удалось отправить СМС') })
  }

  const handleSaveNewPassword = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    setError('')
    if (saving) {
      return
    }
    if (password !== passwordConfirm) {
      setError('Пароли не совпадают')
    }
    setSaving(true)
    dispatch(saveProfilePassword(password, profile.data?.responsible.id, smsCode)).then(() => {
      setSaving(false)
      baseNotification('Успешно', 'Пароль успешно изменен', 'success')
      onClose()
    }).catch(() => {
      baseNotification('Ошибка', 'Произошла ошибка', 'danger')
      setSaving(false)
    })
  }

  return (
    <Modal title="Смена пароля">
      <div className={styles.changePassword}>
        {error !== '' && (
          <div className={styles.changePasswordNotification}>
            {error}
          </div>
        )}
        <form className={styles.changePasswordContent}>

          <label>Новый пароль</label>
          <EyePassword disabled={false}
                       placeholder="(введите новый пароль)"
                       onChange={handleChangePassword}
                       className={errPass ? 'error__box' : ''}/>

          <label>Новый пароль (проверка)</label>
          <EyePassword disabled={password === ''}
                       placeholder="(повторите новый пароль)"
                       onChange={handleChangePasswordConfirm}
                       className={errPassConfirm ? 'error__box' : ''}/>
          <p className="header__sector">Код подтверждения</p>
          <button
            name="sms__query"
            type="button"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={askForSmsHandler}
            disabled={password === '' || passwordConfirm === '' || saving || errPass || errPassConfirm }
          >Прислать СМС</button>
          <input
            name="sms__check"
            type="password"
            placeholder="(код из СМС)"
            onChange={handleChangeSmsCode}
          />
          <hr/>

          <button
            name="close__apply"
            type="button"
            onClick={handleSaveNewPassword}
            disabled={password === '' || passwordConfirm === '' || smsCode === '' || saving || errPass || errPassConfirm}
          >Сменить пароль</button>
          <button name="close__cancel" type="button" onClick={handleClose}>Отмена</button>
        </form>
      </div>
    </Modal>
  )
}

export default ChangePassword
