import { useState } from 'react'
import style from '../../pages/EstimateManagement/FilesSection/filesSection.module.scss'
import { UploadFiles } from './UploadFiles'
import { type IListFiles, type TFilesError } from './types'
import ListFilesTable from './ListFilesTable'
import { ModalFileSizeError } from '../ModalFileSizeError/ModalFileSizeError'

const ListFiles = ({ files, fileButtons, estimate, filesRemoved, type, height }: IListFiles) => {
  const [openModalFileSizeError, setOpenModalFileSizeError] = useState<TFilesError>(
    { oneFileSizeError: false, totalFilesSizeError: false, maxSize: 0, currentFilesSize: 0 }
  )
  const firstType = Array.isArray(type) ? type[0] : type
  return (
    <div className={style.openMenu}>
      <ListFilesTable files={files} fileButtons={fileButtons} height={height}/>
      {fileButtons.addFileBtn &&
       fileButtons.chooseFileBtn &&
       <UploadFiles
          estimate={estimate}
          filesRemoved={filesRemoved}
          type={firstType}
          setOpenModalFileSizeError={setOpenModalFileSizeError}/>
      }
      {(openModalFileSizeError.oneFileSizeError || openModalFileSizeError.totalFilesSizeError) &&
        <ModalFileSizeError openModalFileSizeError={openModalFileSizeError}
                            setOpenModalFileSizeError={setOpenModalFileSizeError}/>
      }
    </div>
  )
}

export { ListFiles }
