const ListCaption = (params: any): JSX.Element => {
  const { listCaptions } = params
  return (
    <div className="bar__captions">
		  {
        listCaptions.map((captionItem: any, key: any) => {
          return (<div title={captionItem.title} key = {key}>
                  {captionItem.captions.reduce(
                    (acc: any, caption: string) =>
                      acc === null
                        ? [<span key = {caption}>{caption}</span>]
                        : [...acc, <br key={`br_${caption}`}/>, <span key = {caption}>{caption}</span>],
                    null
                  )}
              </div>)
        })}
	  </div>
  )
}
export default ListCaption
