import type React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './ParentDataBar.module.scss'
import { type IJobPlanData } from '../../../../types/JobPlan'
import { parseDateString, getShortFIO } from '../../../../utils/estimateUtils'
import { setActiveTab } from '../../../../redux/estimate/actionEstimate'
import { estimateTabs } from '../../../../pages/estimateJob-page/consts'

interface ParentDataBarProps {
  jobPlanData: IJobPlanData
}

function ParentDataBar ({ jobPlanData }: ParentDataBarProps) {
  const history = useHistory()
  const dispatch = useDispatch()
  const gotoPage = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const element = event.target as HTMLElement
    if (element.tagName === 'A') {
      history.push(element.getAttribute('href') as string)
    }
  }
  const contractor = useSelector((state: any) => state.profile.data?.contractor)
  const contractorFIO = getShortFIO({
    lastName: contractor?.signatoryLastName,
    firstName: contractor?.signatoryFirstName,
    secondName: contractor?.signatorySecondName
  })

  const changeTabHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    dispatch(setActiveTab(estimateTabs[0].id))
  }

  return (
    <div className={styles.bar}>

      <div className="limit__text--length">
        <a className="link-light"
           href={`/building-card/${jobPlanData?.buildingId}`}
           onClick={gotoPage}
           title="Карточка объекта">
           {jobPlanData.buildingName}
        </a>
      </div>

      <div className="limit__text--length">{jobPlanData.buildingAddress}</div>

      <div className="limit__text--length">
        <span className="link-light"
           onClick={changeTabHandler}
           title="Страница Сметы">
             {jobPlanData.estimateTitle}
        </span>
      </div>

      <div className="limit__text--length">{jobPlanData.estimateNote}</div>

      <div className="limit__text--length">
        <a className="link-light" href="/_" target="_blank" title="Карточка подрядчика">{contractor?.name}</a>
      </div>

      <div>
        <span>{contractor?.phone}</span>
        <span>{contractorFIO}</span>
      </div>

      <div>
        <span title="Даты: Начало — Окончание">Плановые сроки</span>
        <span>{jobPlanData.workStartPlan ? parseDateString(jobPlanData.workStartPlan) : '.. ... ..'}</span>
        <span className="symbol__mdash--before"></span>
        <span>{jobPlanData.workEndPlan ? parseDateString(jobPlanData.workEndPlan) : '.. ... ..'}</span>
      </div>

    </div>
  )
}

export default ParentDataBar
