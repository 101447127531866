import { useState, useEffect } from 'react'
import { COMPLAINTCODES, COMPLAINTTOPICS } from '../../../utils/getComplaints'
import { ROLECODES } from '../../../utils/getRoleName'
interface IComplaintArbitrator {
  setOpenControlPanelHandler: Function
  complaintCardObject: any
  changeComplaintStateHandler: Function
  setDowntimeDateHandler: Function
  downtimeDate: string
  brigpay: string
  setBrigpay: Function
  fine: string
  setFine: Function
  hasOtherComplaints: boolean
}

const ComplaintArbitrator = (params: IComplaintArbitrator): JSX.Element => {
  const {
    setOpenControlPanelHandler,
    complaintCardObject,
    changeComplaintStateHandler,
    setDowntimeDateHandler,
    downtimeDate,
    brigpay,
    setBrigpay,
    fine,
    setFine,
    hasOtherComplaints
  } = params

  const respondentRole = complaintCardObject.respondentRole
  const complaintStatus = complaintCardObject.status
  const initiatorRole = respondentRole === ROLECODES.FOREMAN ? ROLECODES.BRIGADIER : ROLECODES.FOREMAN
  const rulingDisable = complaintCardObject.topicId === COMPLAINTTOPICS.BREAK && hasOtherComplaints
  const brigPayNumber = parseInt(brigpay)
  const badPay = Number.isNaN(brigPayNumber) || !complaintCardObject.cost || (brigPayNumber > complaintCardObject.cost) || brigPayNumber < 0
  const badFine = fine === ''

  const [disableQueryTab, setDisableQueryTab] = useState<boolean>(false)
  const [disableRulingTab, setDisableRulingTab] = useState<boolean>(false)
  const [queryTabActive, setQueryTabActive] = useState<boolean>(true)
  const [postMessage, setPostMessage] = useState<string>('')
  const [startDowntimeBtnDisable, setStartDowntimeBtnDisable] = useState<boolean>(false)
  const [stopDowntimeBtnDisable, setStopDowntimeBtnDisable] = useState<boolean>(false)

  const startDowntimeDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value
    setStartDowntimeBtnDisable(!date)
    setDowntimeDateHandler(date)
  }

  const stopDowntimeDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value
    setStopDowntimeBtnDisable(!date)
    setDowntimeDateHandler(date)
  }

  const brigpayHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pay = e.target.value
    setBrigpay(pay)
  }

  const fineHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fine = e.target.value
    setFine(fine)
  }

  useEffect(() => {
    const nowDate = new Date().toISOString().slice(0, 10)
    setDowntimeDateHandler(nowDate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDisableRulingTab(complaintStatus === COMPLAINTCODES.QUERY)
  }, [complaintStatus])

  return (
    <div className="manage-complaint__arbitrator">
        <input id="tab__query"
               name="control__tab"
               type="radio"
               onChange={() => { setQueryTabActive(true) }}
               checked={queryTabActive}
               disabled={disableQueryTab}/>
        <label htmlFor="tab__query"><span>Запрос участникам</span></label>

        <input id="tab__ruling"
               name="control__tab"
               type="radio"
               onChange={() => { setQueryTabActive(false) }}
               checked={!queryTabActive}
               disabled={disableRulingTab}/>
        <label htmlFor="tab__ruling"><span>Решение Арбитра</span></label>

        <div id="content__query">
            <textarea placeholder="(введите текст сообщения)"
                      onChange={ e => {
                        setDisableRulingTab(e.target.value !== '' || complaintStatus === COMPLAINTCODES.QUERY)
                        setPostMessage(e.target.value)
                      }}>
            </textarea>
            <button
                name="control__query-to-initiator"
                type="button"
                title="Отправить текст сообщения в адрес Инициатора"
                disabled={!disableRulingTab}
                onClick={() => changeComplaintStateHandler(COMPLAINTCODES.QUERY, null, postMessage, [], initiatorRole)}
            >Запрос Инициатору</button>
            <button
                name="control__query-to-responder"
                type="button"
                title="Отправить текст сообщения в адрес Ответчика"
                disabled={!disableRulingTab}
                onClick={() => changeComplaintStateHandler(COMPLAINTCODES.QUERY, null, postMessage, [], respondentRole)}
            >Запрос Ответчику</button>
        </div>
        <div id="content__ruling">
            <textarea placeholder={
              rulingDisable
              ? "По наряду есть незакрытые споры. Необходимо закрыть все споры по наряду до вынесения решения по расторжению"
              : "(укажите основание решения)"}
              disabled = {rulingDisable}
              onChange={ e => {
                setDisableQueryTab(e.target.value !== '')
                setPostMessage(e.target.value)
              }}>
            </textarea>
            <button
                name="control__complaint--overrule"
                type="button"
                title="Завершение Спора в пользу Ответчика"
                disabled={!disableQueryTab}
                onClick={() => changeComplaintStateHandler(COMPLAINTCODES.OVERRULE, null, postMessage, [], ROLECODES.ARBITRATE)}
            >{complaintCardObject.topicId === COMPLAINTTOPICS.REMARK ? 'Принять работы' : 'Спор отклонён'}</button>
            <button
                name="control__complaint--satisfied"
                type="button"
                title="Завершение Спора и окончание простоя"
                disabled={!disableQueryTab || stopDowntimeBtnDisable}
                onClick={() => changeComplaintStateHandler(COMPLAINTCODES.SATISFIED, downtimeDate, postMessage, [], ROLECODES.ARBITRATE)}
            >Спор удовлетворён</button>
            <div className="block__downtime--end">
                <label className="symbol__colon--after">Окончание простоя</label>
                <input
                    name="input__downtime--end"
                    type="date"
                    title="Дата окончания простоя"
                    value={downtimeDate}
                    onChange={stopDowntimeDateHandler}/>
            </div>
            <button
                name="control__complaint--break-satisfied"
                type="button"
                title="Завершение спора и расторжение договора"
                disabled={!disableQueryTab || rulingDisable || badPay}
                onClick={
                  () =>
                    changeComplaintStateHandler(
                      COMPLAINTCODES.SATISFIED,
                      downtimeDate,
                      postMessage,
                      [],
                      ROLECODES.ARBITRATE,
                      brigpay
                    )
                }
            >Спор удовлетворён</button>
            <div className="block__break--pay">
                <label className="symbol__colon--after">Сумма выплаты Бригадиру</label>
                <input
                    name="input__break--pay"
                    type="text"
                    title="Сумма выплаты Бригадиру"
                    value={brigpay}
                    disabled={hasOtherComplaints}
                    onChange={brigpayHandler}/>
            </div>
            <button
                name="control__complaint--fine-satisfied"
                type="button"
                title="Завершение спора и назначение штрафа бригадиру"
                disabled={!disableQueryTab || badFine}
                onClick={
                  () =>
                    changeComplaintStateHandler(
                      COMPLAINTCODES.SATISFIED,
                      downtimeDate,
                      postMessage,
                      [],
                      ROLECODES.ARBITRATE,
                      null,
                      fine
                    )
                }
            >Назначить штраф</button>
            <div className="block__fine">
                <label className="symbol__colon--after">Выбор штрафа</label>
                <select
                  name="input__fine"
                  title="Сумма штрафа"
                  value={fine}
                  onChange={fineHandler}>
                  <option value={''} disabled>(выберите штраф)</option>
                {complaintCardObject.fines?.map(
                  (fine: any) => 
                    (<option
                      key={fine.id}
                      value={fine.id}>
                        {`${fine.name}: ${fine.summ}руб`}
                    </option>)
                )}
                </select>
            </div>
            <button
                name="control__complaint--downtime"
                type="button"
                title="Продолжение Спора в пользу Инициатора"
                disabled={!disableQueryTab || startDowntimeBtnDisable}
                onClick={() => changeComplaintStateHandler(COMPLAINTCODES.STOP, downtimeDate, postMessage, [], ROLECODES.ARBITRATE)}
            >{complaintCardObject.topicId === COMPLAINTTOPICS.REMARK ? 'Устранить замечания' : 'Простой по договору'}</button>
            <div className="block__downtime--start">
                <label className="symbol__colon--after">Начало простоя</label>
                <input
                    name="input__downtime--start"
                    type="date"
                    title="Дата начала простоя"
                    value={downtimeDate}
                    onChange={startDowntimeDateHandler}/>
            </div>
        </div>
        <button
            name="control__manage-close"
            type="button"
            title="Сворачивание сектора без сохранения изменений"
            onClick={() => setOpenControlPanelHandler(false)}
        >Отмена</button>
    </div>
  )
}

export default ComplaintArbitrator
