import type React from 'react'
import { useEffect, useState } from 'react'
import styles from './ChangeData.module.scss'
import Modal from '../../../../../Modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { type IProfileState } from '../../../../../../redux/profile/types'
import { type IProfileData } from '../../../../../../types/Profile'
import { saveProfile } from '../../../../../../redux/profile/profileActions'
import { baseNotification } from '../../../../../../utils/estimateUtils'
import { isValidEmail, isValidPhone, isValidInn, isValidKpp, isValidOkpo, isValidOgrn } from '../../../../../../utils/validators'
import { profileStatusCodes } from '../../../../../../utils/statusCodes'
import { getProfileStorage } from '../../ProfileStorage'
import { type ISuggestionItem, type IResponseSuggestionItem } from './types'
import axios from 'axios'
import Config from '../../../../../../config'
import { getValidStyle } from './validStyle'

interface IChangeData {
  onClose: VoidFunction
}

const ChangeData = ({ onClose }: IChangeData): JSX.Element => {
  const profile = useSelector<any>(state => state.profile) as IProfileState
  const [newData, setNewData] = useState<IProfileData>(Object.assign({}, profile.data))
  const [addressIsEqual, setAddressIsEqual] = useState<boolean>(
    profile.data?.contractor.legalAddress === profile.data?.contractor.actualAddress
  )
  const [saving, setSaving] = useState<boolean>(false)
  const [valid, setValid] = useState<boolean>(false)
  const [tryreg, setTryreg] = useState<boolean>(false)
  const [requisitesDisabled, setRequisitesDisabled] = useState<boolean>(true)
  const [innPresentFlag, setInnPresentFlag] = useState<boolean>(false)
  const [suggestionsItems, setSuggestionsItems] = useState<ISuggestionItem[]>([])
  const dispatch = useDispatch<any>()

  const contractorId = profile.data?.contractor.id ?? ''

  useEffect(() => {
    const isValid =
      newData.responsible.lastName !== '' &&
      newData.responsible.firstName !== '' &&
      newData.responsible.secondName !== '' &&
      isValidEmail(newData.responsible.email) &&
      newData.contractor.name !== '' &&
      isValidInn(newData.contractor.inn) &&
      !innPresentFlag &&
      isValidKpp(newData.contractor.kpp) &&
      isValidOkpo(newData.contractor.okpo) &&
      isValidOgrn(newData.contractor.ogrn) &&
      newData.contractor.legalAddress !== '' &&
      newData.contractor.actualAddress !== '' &&
      isValidPhone(newData.contractor.phone) &&
      newData.contractor.signatoryLastName !== '' &&
      newData.contractor.signatoryFirstName !== '' &&
      newData.contractor.signatorySecondName !== '' &&
      newData.contractor.signatoryPost !== '' &&
      newData.contractor.signatoryReason !== ''
    setValid(isValid)
  }, [newData, innPresentFlag])

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    onClose()
  }

  const setAddresses = (value: string): void => {
    setNewData(prev => ({
      ...prev,
      contractor: {
        ...prev.contractor,
        legalAddress: value,
        actualAddress: value
      }
    }))
  }

  const setValue = (parentObjectName: 'responsible' | 'contractor', name: string, value: string): void => {
    setNewData(prev => ({
      ...prev,
      [parentObjectName]: {
        ...(parentObjectName === 'responsible' ? { ...prev.responsible } : {}),
        ...(parentObjectName === 'contractor' ? { ...prev.contractor } : {}),
        [name]: value
      }
    }))
  }

  const isInnPresent = async (inn: string): Promise<boolean> => {
    try {
      const token = JSON.parse(localStorage.getItem('user') as string)
      const result = await axios.post(`${Config.BACKEND_ADDRESS as string}Contractor/InnIsFree`, {
        inn
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.token as string}`
        }
      })
      return (result.status !== 200)
    } catch (e) {
      return true
    }
  }

  const getInfoField = (companyInfoData: IResponseSuggestionItem | undefined, key: string): string | null => {
    if (typeof companyInfoData === 'undefined') return null
    switch (key) {
      case 'name': return companyInfoData.value
      case 'kpp': return companyInfoData.data.kpp
      case 'okpo': return companyInfoData.data.okpo
      case 'ogrn': return companyInfoData.data.ogrn
      case 'legalAddress': return companyInfoData.data.address?.value
      default: return null
    }
  }

  const getDataByInn = async (inn: string): Promise<void> => {
    try {
      const token = JSON.parse(localStorage.getItem('user') as string)
      const result = await axios.get(`${Config.BACKEND_ADDRESS as string}Contractor/GetContractorInfoByINN?inn=${inn}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.token as string}`
        }
      })

      const suggestions = result.data?.suggestions as IResponseSuggestionItem[]
      if (Array.isArray(suggestions) && (suggestions.length > 0)) {
        setRequisitesDisabled(true)
        const companyInfo = suggestions[0];
        ['name', 'kpp', 'okpo', 'ogrn', 'legalAddress'].forEach(key => {
          const infoField = getInfoField(companyInfo, key)
          if (infoField !== null) {
            setValue('contractor', key, infoField)
            if ((key === 'legalAddress') && addressIsEqual){
              setValue('contractor', 'actualAddress', infoField)
            }
          }
        })
        if (getInfoField(companyInfo, 'name') === null ||
           !isValidKpp(getInfoField(companyInfo, 'kpp')) ||
           !isValidOkpo(getInfoField(companyInfo, 'okpo')) ||
           !isValidOgrn(getInfoField(companyInfo, 'ogrn')) ||
           getInfoField(companyInfo, 'legalAddress') === null) {
          setRequisitesDisabled(false)
        }
        if (suggestions.length > 1) {
          setSuggestionsItems(suggestions.map(item => ({
            name: item.value,
            kpp: item.data.kpp,
            ogrn: item.data.ogrn,
            legalAddress: item.data.address?.value
          } satisfies ISuggestionItem)))
        } else {
          setSuggestionsItems([])
        }
      } else {
        setRequisitesDisabled(false)
      }
    } catch (e) {
      baseNotification(`Ошибка запроса ${e as string}`, '', 'danger')
      setRequisitesDisabled(false)
    }
  }

  const innHandler = async (inn: string, checkInn: boolean = true): Promise<void> => {
    if (isValidInn(inn)) {
      const innPresent = await isInnPresent(inn)
      if (innPresent) {
        baseNotification('ИНН уже присутствует в базе зарегистрированных организаций', '', 'danger')
        setInnPresentFlag(true)
      } else {
        setInnPresentFlag(false)
        if (checkInn) {
          void getDataByInn(inn)
        }
      }
    } else {
      setInnPresentFlag(false)
    }
    setValue('contractor', 'inn', inn)
  }

  const handleChangeField = (parentObjectName: 'responsible' | 'contractor') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (parentObjectName === 'contractor' &&
       e.target.name === 'legalAddress' &&
       addressIsEqual) {
      setAddresses(e.target.value)
    } else if (e.target.name === 'inn') {
      void innHandler(e.target.value)
    } else {
      setValue(parentObjectName, e.target.name, e.target.value)
    }
  }

  const handleChangeAddressEqual = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAddressIsEqual(e.target.checked)
    if (e.target.checked) {
      setNewData(prev => ({
        ...prev,
        contractor: {
          ...prev.contractor,
          actualAddress: prev.contractor.legalAddress
        }
      }))
    }
  }

  const changeRequisitiesByKpp = (kpp: string): void => {
    const suggestionsItem = suggestionsItems.filter(item => item.kpp === kpp)[0]
    if (suggestionsItem === undefined) return
    setNewData(prev => ({
      ...prev,
      contractor: {
        ...prev.contractor,
        ...suggestionsItem
      }
    }))
  }

  const kppSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    changeRequisitiesByKpp(e.target.value)
  }

  const handleSaveDataToStorage = (): void => {
    const profileStorage = getProfileStorage()
    if (profileStorage != null) {
      localStorage.setItem('profileChangeData', JSON.stringify({ ...profileStorage, [contractorId]: newData }))
    } else {
      localStorage.setItem('profileChangeData', JSON.stringify({ [contractorId]: newData }))
    }
    onClose()
  }

  const handleSaveChanges = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (saving) {
      return
    }

    if (valid) {
      setSaving(true)
      const changeProfileState = {
        newState: profileStatusCodes.onCheck,
        actionText: 'На проверку'
      }
  
      newData.contractor.email = newData.responsible.email
      dispatch(saveProfile(newData, changeProfileState)).then(() => {
        setSaving(false)
        const profileStorage = getProfileStorage()
        if (profileStorage != null) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete profileStorage[contractorId]
          localStorage.setItem('profileChangeData', JSON.stringify(profileStorage))
          baseNotification('Успешно', 'Данные успешно сохранены', 'success')
        }
        onClose()
      }).catch(() => {
        baseNotification('Ошибка', 'Произошла ошибка', 'danger')
        setSaving(false)
      })
    } else {
      setTryreg(true)
      baseNotification('Ошибка', 'Не все поля заполнены корректно', 'danger')
    }
  }

  const hasEmptyRequisites = (contractor: IProfileData['contractor']): boolean =>
    ['name', 'kpp', 'okpo', 'ogrn', 'legalAddress'].some(item => contractor[item as keyof IProfileData['contractor']] === undefined)

  useEffect(() => {
    const profileStorage = getProfileStorage()
    if (profileStorage != null) {
      const parsedData = profileStorage[contractorId]
      if (parsedData !== undefined) {
        setNewData(parsedData)
        setAddressIsEqual(parsedData.contractor.legalAddress === parsedData.contractor.actualAddress)
        void innHandler(parsedData.contractor.inn, hasEmptyRequisites(parsedData.contractor))
      }
    }
  }, [contractorId])

  const chooseSource = (storeData: string | undefined, newData: string): string =>
    storeData !== undefined && storeData !== '' ? storeData : newData

  const disableCheck = (value: string | undefined): boolean =>
    value !== undefined && value !== ''

  return (
    <Modal title="Регистрация">
      <div className={styles.changeData}>
        <div className={styles.changeDataNotification}>
          Все поля должны быть заполнены достоверными данными.
        </div>
        <form className={styles.changeDataContent}>

          <p className={styles.sectionTitle}>Ответственный от организации</p>

          <label>Фамилия</label>
          <input
            name="lastName"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.responsible?.lastName, newData.responsible.lastName)}
            onChange={handleChangeField('responsible')}
            disabled={disableCheck(profile.data?.responsible?.lastName)}
            className={getValidStyle('lastName', newData.responsible.lastName, tryreg)}
          />

          <label>Имя</label>
          <input
            name="firstName"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.responsible?.firstName, newData.responsible.firstName)}
            onChange={handleChangeField('responsible')}
            disabled={disableCheck(profile.data?.responsible.firstName)}
            className={getValidStyle('firstName', newData.responsible.firstName, tryreg)}
          />

          <label>Отчество</label>
          <input
            name="secondName"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.responsible?.secondName, newData.responsible.secondName)}
            onChange={handleChangeField('responsible')}
            disabled={disableCheck(profile.data?.responsible.secondName)}
            className={getValidStyle('secondName', newData.responsible.secondName, tryreg)}
          />

          <label>E-mail</label>
          <input
            name="email"
            type="email"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.responsible?.email, newData.responsible.email)}
            onChange={handleChangeField('responsible')}
            disabled={disableCheck(profile.data?.responsible.email)}
            className={getValidStyle('email', newData.responsible.email, tryreg)}
          />

          <p className={styles.sectionTitle}>Реквизиты организации</p>

          <label>ИНН</label>
          <input
            name="inn"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.inn, newData.contractor.inn)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.inn)}
            className={getValidStyle('inn', newData.contractor.inn, tryreg, innPresentFlag)}
          />

          <label>Наименование</label>
          <input
            name="name"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.name, newData.contractor.name)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.name) || requisitesDisabled}
            className={getValidStyle('name', newData.contractor.name, tryreg)}
          />

          <label>КПП</label>
          { suggestionsItems.length === 0
            ? (<input
              name="kpp"
              type="text"
              placeholder="(введите значение)"
              value={chooseSource(profile.data?.contractor?.kpp, newData.contractor.kpp)}
              onChange={handleChangeField('contractor')}
              disabled={disableCheck(profile.data?.contractor.kpp) || requisitesDisabled}
              className={getValidStyle('kpp', newData.contractor.kpp, tryreg)}
            />)
            : (<select
              className={`selected ${styles.selectKpp}`}
              value={chooseSource(profile.data?.contractor?.kpp, newData.contractor.kpp)}
              onChange={kppSelectChange}
              name="kpp">
              {suggestionsItems.map((item, key) => (
                  <option value={item.kpp} key={key}>{item.kpp}</option>
              ))}
              </select>)
          }
          <label>ОКПО</label>
          <input
            name="okpo"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.okpo, newData.contractor.okpo)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.okpo) || requisitesDisabled}
            className={getValidStyle('okpo', newData.contractor.okpo, tryreg)}
          />

          <label>ОГРН</label>
          <input
            name="ogrn"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.ogrn, newData.contractor.ogrn)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.ogrn) || requisitesDisabled}
            className={getValidStyle('ogrn', newData.contractor.ogrn, tryreg)}
          />

          <label>Адрес (юридический)</label>
          <input
            name="legalAddress"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.legalAddress, newData.contractor.legalAddress)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.legalAddress)}
            readOnly={requisitesDisabled}
            className={getValidStyle('legalAddress', newData.contractor.legalAddress, tryreg)}
          />

          <label>Адрес (фактический)</label>
          <input
            name="actualAddress"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.actualAddress, newData.contractor.actualAddress)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.actualAddress) || addressIsEqual}
            className={getValidStyle('actualAddress', newData.contractor.actualAddress, tryreg)}
          />

          <label>Совпадает с юридическим</label>
          <input
            name=""
            type="checkbox"
            checked={addressIsEqual}
            onChange={handleChangeAddressEqual}
            disabled={
              disableCheck(profile.data?.contractor.legalAddress) &&
              disableCheck(profile.data?.contractor.actualAddress) &&
              profile.data?.contractor.legalAddress === profile.data?.contractor.actualAddress
            }
          />

          <label>Телефон офиса</label>
          <input
            type="tel"
            placeholder="+7 (___) ___-__-__"
            name="phone"
            value={chooseSource(profile.data?.contractor?.phone, newData.contractor.phone)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.phone)}
            className={getValidStyle('phone', newData.contractor.phone, tryreg)}
          />

          <p className={styles.sectionTitle}>Подписант</p>

          <label>Фамилия</label>
          <input
            name="signatoryLastName"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.signatoryLastName, newData.contractor.signatoryLastName)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.signatoryLastName)}
            className={getValidStyle('signatoryLastName', newData.contractor.signatoryLastName, tryreg)}
          />

          <label>Имя</label>
          <input
            name="signatoryFirstName"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.signatoryFirstName, newData.contractor.signatoryFirstName)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.signatoryFirstName)}
            className={getValidStyle('signatoryFirstName', newData.contractor.signatoryFirstName, tryreg)}
          />

          <label>Отчество</label>
          <input
            name="signatorySecondName"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor.signatorySecondName, newData.contractor.signatorySecondName)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.signatorySecondName)}
            className={getValidStyle('signatorySecondName', newData.contractor.signatorySecondName, tryreg)}
          />

          <label>Должность</label>
          <input
            name="signatoryPost"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.signatoryPost, newData.contractor.signatoryPost)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.signatoryPost)}
            className={getValidStyle('signatoryPost', newData.contractor.signatoryPost, tryreg)}
          />

          <label>Основание</label>
          <input
            name="signatoryReason"
            type="text"
            placeholder="(введите значение)"
            value={chooseSource(profile.data?.contractor?.signatoryReason, newData.contractor.signatoryReason)}
            onChange={handleChangeField('contractor')}
            disabled={disableCheck(profile.data?.contractor.signatoryReason)}
            className={getValidStyle('signatoryReason', newData.contractor.signatoryReason, tryreg)}
          />

          <hr/>

          <button
            name="close__apply"
            type="button"
            title="Отправить данные для регистрации"
            disabled={saving}
            onClick={handleSaveChanges}
          >
            Зарегистрировать
          </button>

          <button
            name="close__save"
            type="button"
            title="Сохранить без отправки для регистрации"
            onClick={handleSaveDataToStorage}
          >
            Сохранить
          </button>

          <button
            name="close__cancel"
            type="button"
            onClick={handleClose}
          >
            Отмена
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default ChangeData
