import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import style from './filesSection.module.scss';
import { ListFiles } from "../../../components/EstimateManagement/ListFiles";
import { useFilterRemoved } from "../../../hooks/useFilter";
import { IFileButtons } from "../../../types/Status";

export interface IFilesSection {
  fileButtons: IFileButtons,
  estimate: any,
  filesRemoved: string[],
  sectionSize: string,
  title: string,
  docType: number | number[],
  accept: boolean,
}

const FilesSection = ({fileButtons, estimate, filesRemoved, sectionSize, title, docType, accept}: IFilesSection) => {
  const state = useSelector((state: any) => state.estimateReducer);
  const [open, setOpen] = useState<boolean>(false);

  const openInfo = () => {
    if(accept) setOpen(!open);
  }

  useEffect(() => {
    setOpen(fileButtons.addFileBtn)
  }, [fileButtons])

  const files = useFilterRemoved(estimate.documents, docType, state.filesRemoved);

  const sectionSizeText = sectionSize ? ` (${sectionSize})` : "";

  return (
      <React.Fragment>
        <div className={`section__files--spec ${style.size} ${open ? "open" : ""}`}>
        <div className={`${!accept ? style.notAccept : open ? style.activeContainer : style.container}`}>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <span className={`circle-triangle ${!accept ? style.span : ''}`} onClick={openInfo} title="Развернуть / Свернуть"></span>
              <span style={{marginLeft: '8px'}}>{title}</span>
            </div>
            <div>
              <span className={`symbol__colon--after`}>Файлов</span>
              <span style={{marginLeft: '12px', fontWeight: 'bold'}}>{`${files.length}${sectionSizeText}`}</span>
            </div>
          </div>
          {open && <ListFiles files={files} fileButtons={fileButtons} estimate={estimate} filesRemoved={filesRemoved} type={docType}/>}
        </div>
      </React.Fragment>
  )
}

export {FilesSection}