import { Document, Page } from 'react-pdf';
import { IGalleryImg } from "./types";
import styles from './OrderBox.module.scss'
import { useMemo } from 'react';

interface IDocImage {
  galaryImg: IGalleryImg, 
  onChoose?: (value: React.SetStateAction<number>) => void,
  onOpen?: (value: React.SetStateAction<boolean>) => void,
  index?: number
  activeImg?: number
}

export const DocImage = ({galaryImg, onChoose, onOpen, index, activeImg}: IDocImage): JSX.Element => {
  const clickHandler = () => {
    if (onChoose) onChoose(index ?? -1)
    if (onOpen) onOpen(true)
  }
  const errorHandler = (err: any) => {
    console.log('error=', err)
  }
  if(galaryImg.fileType === 'image' || galaryImg.fileType === 'unknown') {
    return(
      <img
        src={galaryImg.src}
        alt={galaryImg.alt}
        onClick={clickHandler}
        className={activeImg === index ? styles.selected : ''}
      />
    )
  }
  if(galaryImg.fileType === 'pdf' && galaryImg.src) {
    return (
      <div className={`${styles.previewSelectPdf} ${activeImg === index ? styles.selected : ''}`}>
        {
          useMemo(() => (
            <Document
              file={{ data: atob(galaryImg.src) }}
              onLoadError={errorHandler}
              onSourceError={errorHandler}
              onClick={clickHandler}>
              <Page 
                pageNumber={1}
                height={onChoose ? 42 : undefined}
                width={onChoose ? undefined : 616}
                renderAnnotationLayer={false}
                renderTextLayer={false} />
            </Document>), 
          [galaryImg.src])
        }
      </div>
    )
  }
  return <></>
}