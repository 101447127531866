import type React from 'react'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './OrderRow.module.scss'
import { type IOrderListItem } from '../../../../types/Order'
import { baseNotification, getDaysDiff, parseDateString } from '../../../../utils/estimateUtils'
import { formatPrice } from '../../../../utils/formatPrice'
import { useDispatch, useSelector } from 'react-redux'
import { type IStatus } from '../../../../types/Status'
import { setOrderStatus, updateOrderStatus } from '../../../../redux/ordersList/ordersListActions'
import getHtmlParentId from '../../../../utils/getHtmlParentId'
import { orderCodes } from '../../../../utils/statusCodes'
import { getProfileData } from '../../../../redux/profile/profileActions'
import { NotificationSign } from '../../../../components/NotificationSign/notificationSign'

interface IOrderRow {
  order: IOrderListItem
}

const OrderRow = ({ order }: IOrderRow): JSX.Element => {
  const orderStatuses = useSelector<any>(state => state.ordersList.orderStatuses) as IStatus[] | null
  const [changingStatus, setChangingStatus] = useState<boolean>(false)
  const dispatch = useDispatch<any>()
  const history = useHistory()

  const statusText = useMemo(() => {
    if (orderStatuses === null || (orderStatuses.length === 0)) {
      return ''
    }

    const statusData = orderStatuses.filter(item => item.currentStateCode === order.status)?.[0]

    return statusData?.currentStateText ?? ''
  }, [orderStatuses, order.status])

  const statusClassName = useMemo(() => {
    if (order.status === orderCodes.runOverdue ||
        order.status === orderCodes.doneOverdue ||
        order.status === orderCodes.noBrigade) {
      return styles.error
    }
    return ''
  }, [order.status])

  const actions = useMemo(() => {
    return orderStatuses?.filter(item => item.currentStateCode === order.status && !(item.buttons == null))
  }, [order.status, orderStatuses])

  const handleClickAction = (code: number | undefined, type?: string) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (changingStatus) {
      return
    } 

    if (code === undefined) {
      if(type === 'startComplaint') {
        history.push(`/complaint-card?createId=${order.id}&remark=true`)
      }
      return
    }

    if (code === orderCodes.complaint) {
      history.push(`/complaint-card?createId=${order.id}`)
      return
    }

    setChangingStatus(true)

    dispatch(updateOrderStatus({
      entityId: order.id,
      newState: code
    })).then((res: any) => {
      setChangingStatus(false)
      if (res.status === 200) {
        dispatch(setOrderStatus(order.id, code))
        dispatch(getProfileData())
      } else {
        baseNotification('Произошла ошибка', '', 'danger')
      }
    }).catch(() => {
      setChangingStatus(false)
      baseNotification('Произошла ошибка', '', 'danger')
    })
  }

  const orderRowClickHandler = (event: React.MouseEvent<HTMLElement>): void => {
    const targetElement = event.target as HTMLElement
    const orderId = getHtmlParentId(targetElement)
    if (orderId !== null) history.push(`/order/${orderId}`)
  }

  return (
    <div className={`${styles.orderRow} 
                     ${statusClassName} 
                     animate__clickable 
                     status__order--prepaid`}
         id={order.id}
         onClick={orderRowClickHandler}>
      <div>
        {order.buildingName}
        <NotificationSign
          hasNotification={order.hasNotification}
          notificationText={order.notificationText}
          page='order-list'
        />
      </div>
      <div>{order.buildingAddress}</div>
      <div>{order.estimateTitle}</div>
      <div>
        <span>{order.chainName}</span>
        <br/>
        <span>{order.orderName}</span>
      </div>
      <div>
        <span>{parseDateString(order.workStartPlan)}</span>
        <span className="symbol__mdash--before"></span>
        <span>{parseDateString(order.workEndPlan)}</span>
      </div>

      <div className="mark__curr-ruble--after">{formatPrice(order.cost ?? 0)}</div>

      <div>
        {order.brigadeFIO ?? (order.status === orderCodes.runReady ? '(подбор бригады)' : '(бригада не подобрана)')}
      </div>

      <div>
        <span>{getDaysDiff(order.workStartPlan, order.workEndPlan)}</span>
        <span>дн.</span>
      </div>

      <div>
        <span className={styles.orderRowStatus}>{statusText}</span>

        {(actions !== undefined)
          ? actions[0]?.buttons?.map((btn, index) => (
            <button
              key={index}
              className={styles.orderButtons}
              type="button"
              title={btn.title}
              onClick={handleClickAction(btn.statusCode, btn.type)}
              disabled={changingStatus}
            >
              {btn.title}
            </button>
          ))
          : null}
      </div>

      <div title="Всего / Нерешённые">
        <span>{order.complaintTotal ?? 0}</span>
        <span className="symbol__slash--before"></span>
        <span>{order.activeComplaints ?? 0}</span>
      </div>

      <div title="Подрядчик / Бригада">
        <span>Подр.</span>
        <span className="symbol__slash--before"></span>
        <span>Бриг.</span>
        <span>{order.foremanDowntime}</span>
        <span className="symbol__slash--before"></span>
        <span>{order.brigadeDowntime}</span>
      </div>

    </div>
  )
}

export default OrderRow
