import {
  SET_BUILDING_ITEM_OPENSTATE,
  SET_BUILDING_LIST_SCROLL_Y
} from "./buildingListActions";

const initialState = {
  openItems: {},
  scrollPosition: 0,
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BUILDING_ITEM_OPENSTATE:
      return { ...state, openItems: {...state.openItems, ...payload }};
    case SET_BUILDING_LIST_SCROLL_Y:
      return {...state, scrollPosition: payload}
    default:
      return { ...state };
  }
};

export default reducer;
