import {
  GET_ORDERS_LIST_ERROR,
  GET_ORDERS_LIST_START,
  GET_ORDERS_LIST_SUCCESS,
  SET_ORDER_FILTERS,
  SET_ORDER_STATUS
} from './ordersListActionTypes';

const initialState = {
  orders: null,
  meta: null,
  orderStatuses: null,
  currentStatuses: null,
  error: false,
  loading: false,
  filters: {
    buildingFilter: '',
    estimateFilter: '',
    orderFilter: '',
    brigadeFilter: '',
    dateFrom: null,
    dateTo: null,
    orderStatus: -1,
    hasOpenComplaints: false,
    withDowntimeType: -1
  },
};

const changeOrderStatus = (state, orderId, status) => {
  return {...state, orders: [...state.orders.map(item => {
      if (item.id === orderId) {
        return {...item, status: status};
      }

      return item;
  })]};
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDERS_LIST_START:
      return {...state, loading: true};

    case GET_ORDERS_LIST_SUCCESS:
      return {...state, loading: false, ...payload};

    case GET_ORDERS_LIST_ERROR:
      return {...state, loading: false, error: true};

    case SET_ORDER_STATUS:
      return changeOrderStatus(state, payload.orderId, payload.status);
    
    case SET_ORDER_FILTERS:
      return {...state, filters: {...state.filters, ...payload.filters}}

    default:
      return { ...state };
  }
};

export default reducer;