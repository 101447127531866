import React, {memo} from 'react';
import style from './requestBreakdown.module.scss';
import { useDispatch } from "react-redux";
import {
  saveEstimateDocument,
  removeEstimateDocuments,
  updateStatusEstimate,
  setMarkFileRemove
} from "../../../redux/estimate/actionEstimate";
import { getInitialForeman } from "../../../utils/getInitialForeman";
import { handleDate } from "../../../utils/estimateUtils";
import { IButton } from "../../../types/Status";
import { estimateCodes } from '../../../utils/statusCodes';
import { ROLECODES } from '../../../utils/getRoleName';
import { getProfileData } from '../../../redux/profile/profileActions';

const StatusBar = memo((({data, 
                          employees, 
                          setModalChangeRequisites, 
                          setModalAddBreakdown,
                          filesAdded, 
                          filesRemoved,
                          id, 
                          modeStatus,
                          user}: any) => {
  const currentState = modeStatus?.currentStateCode;
  const getEstimateDate = () => {
    if ((currentState === estimateCodes.offerRequest) || 
        (currentState === estimateCodes.repeatOfferRequest) ||
        (currentState === estimateCodes.offerCalculation)) {
      return data.offerCalcPlanReadyDate 
    }
    if ((currentState === estimateCodes.offerAccepted) || 
        (currentState === estimateCodes.offerCanceled)) {
      return data.offerCalcFactReadyDate 
    }
    if ((currentState === estimateCodes.jobPlanRequire) || 
        (currentState === estimateCodes.jobPlanPaidRequire) || 
        (currentState === estimateCodes.moreJobPlanDataRequire) || 
        (currentState === estimateCodes.jobPlanDataRequireDone)) {
      return data.jobPlanCalcPlanReadyDate 
    }
    if (currentState === estimateCodes.jobPlanDone) {
      return data.jobPlanCalcFactReadyDate
    }
    return null;
  }

  const state = {
    calculationCp: handleDate(getEstimateDate()),
    foreman: getInitialForeman(employees, data.foremanId),
    prepaymentType: data.prepaymentType || '-',
    detailing: ["без проверки", "с проверкой"][data.detailing ?? -1] ?? '-',
    cost: data.cost,
    fullcost: data.signedEstimatesCost,
    hourlyRate: data?.hourlyRate || 0,
  };

  const dispatch = useDispatch<any>();

  const updateStatus = (newStatus: any) => {
      const data = {
        entityId: id,
        newState: newStatus
      }
      dispatch(updateStatusEstimate(data))
  } 

  const saveDocument = (newStatus: number | undefined) => {
    const data = {
      estimateId: id,
      documents: filesRemoved
    }
    const newStatusData = {
      entityId: id,
      newState: newStatus
    }
    dispatch(removeEstimateDocuments(data));
    dispatch(saveEstimateDocument(filesAdded, newStatusData));
  };

  const btnHandler = async (item: IButton) => {
    let cancelUpdate = false;
    switch(item.type) {
      case "saveBtn":
        saveDocument(item.statusCode);
        break;
      case "requisitesChangeBtn":
        setModalChangeRequisites(true);
        break;
      case "addBreakdownBtn":
        cancelUpdate = true;
        setModalAddBreakdown(true);
        break;
    }
    if(item.statusCode && !cancelUpdate && (item.type !== "saveBtn")) {
      setMarkFileRemove([]);
      updateStatus(item.statusCode);
    }
  }

  return (
    <React.Fragment>
      <div className={style.container}>
        <div title="Статус Сметы/Разбивки">
          <span className={style.status}>{modeStatus.currentStateText}</span>
        </div>
        {
          modeStatus?.buttons && modeStatus.buttons
          .filter((item: IButton) => item.statusCode || item.type === "requisitesChangeBtn")
          .map((item: IButton) => (
            <button onClick={() => btnHandler(item)}
              className={`bttn__menu ${style.button}`}
              type="button"
              key={item.title}>{item.title}
            </button>
          ))
        }
        {!modeStatus?.buttons &&
          <div>
            <span className={style.noChange}>изменение сметы недоступно</span>
          </div>
        }
        <div title="Дата готовности Расчёта КП"
             style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span className={style.calculationCp}>Дата расчета</span>
          <span style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>
            {state.calculationCp}
          </span>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span className={style.foreman}>Прораб</span>
          <span style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>{state.foreman}</span>
        </div>

        <div className="sign__prepayment--escrow"
             style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span style={{fontSize: '12px', color: 'white'}}>Авансирование</span>
          <span style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>{state.prepaymentType}</span>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span style={{fontSize: '12px', color: 'white'}}>Вариант расчёта</span>
          <span style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>{state.detailing}</span>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span style={{fontSize: '12px', color: 'white'}} title="Стоимость нормо-часа (руб./час)">Ставка</span>
          <span style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>{state.hourlyRate}</span>
        </div>

        <div>
          {
            user.role === ROLECODES.CONTRACTOR && 
              <div style={{display: 'flex', fontSize: '16px', fontWeight: 'bold', color: 'white', justifyContent: 'flex-end'}}
                title="Стоимость Сметы по варианту расчёта">{state.cost}
              </div>
          }
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <span style={{justifySelf: "end", fontFamily: 'thin', fontSize: "12px", color: 'white', marginRight: '4px'}} className="symbol__colon--after" title="Стоимость сопровождения договора СМР">СМР</span>
            <span style={{fontSize: '12px', justifySelf: 'end', color: 'white'}}>{state.fullcost}</span>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}))

export {StatusBar};