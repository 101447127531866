import { useState } from 'react';
import style from "../Equipment/equipment.module.scss";
import { TitleEquipment } from "../../../components/EstimateManagement/TitleEquipment";
import { EstimateListType } from '../Equipment/types';
import { OpenAllButton } from '../../../components/OpenAllButton/OpenAllButton';

const EstimateList = ({arr, all, type} : {arr: any[], all: any[], type: EstimateListType}) => {
  const contentId = type === EstimateListType.System ? "content__grouping--1" : "content__grouping--2";
  const [openAll, setOpenAll] = useState<boolean>(false);
  return (
    <>
      <div className={style.header} id={contentId}>
        {arr.map((el: any, index: number) =>
            <TitleEquipment estimate={all} title={el} key={index} listType={type} openAll={openAll}/>,
        )}
      </div>
      {arr.length > 0 && <OpenAllButton openAll={openAll} setOpenAll={setOpenAll}/>}
    </>
  )

};

export {EstimateList}