import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './MutualListEstimate.module.scss'
import { type IEstimate } from '../../../../../../types/Billing'
import { formatPrice } from '../../../../../../utils/formatPrice'
import MutualListContract from '../MutualListContract/MutualListContract'

interface IMutualListEstimate {
  estimate: IEstimate
}

function MutualListEstimate ({ estimate }: IMutualListEstimate): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev)
  }, [])

  return (
    <div className={styles.estimateSection}>
      <div className={`${styles.estimateRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <Link to={`/estimate-management/${estimate.entityId}`} title="Карточка сметы">{estimate.name}</Link>
        </div>
        <div></div>
        <div>{estimate.paidPlatform as unknown as boolean ? formatPrice(estimate.paidPlatform) : ''}</div>
        <div>{estimate.paidBrigade as unknown as boolean ? formatPrice(estimate.paidBrigade) : ''}</div>
        <div>{estimate.actPlatform as unknown as boolean ? formatPrice(estimate.actPlatform) : ''}</div>
        <div>{estimate.actBrigade as unknown as boolean ? formatPrice(estimate.actBrigade) : ''}</div>
      </div>

      {isOpen && (
        <>
          {estimate.dogovors.map(contract => (
            <MutualListContract key={contract.entityId} contract={contract}/>
          ))}
        </>
      )}
    </div>
  )
}

export default MutualListEstimate
