import type React from 'react'
import { useCallback, useMemo } from 'react'
import styles from './StatusBar.module.scss'
import { baseNotification, parseDateString } from '../../../../utils/estimateUtils'
import { type IJobPlanData } from '../../../../types/JobPlan'
import { formatPrice } from '../../../../utils/formatPrice'
import moment, { type Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { type IStatus, type IButton } from '../../../../types/Status'
import {
  cancelEditJobPlan,
  openCreateChainWindow,
  saveNewDates,
  startEditJobPlan,
  updateEntityStatus
} from '../../../../redux/jobPlan/jobPlanActions'
import { type ISelectedJobPlanItem } from '../../../../redux/jobPlan/types'
import { useParams } from 'react-router'
import { estimateCodes } from '../../../../utils/statusCodes'
import estimateJobParams from '../../../../pages/estimateJob-page/estimateJobParams'
import { ROLECODES } from '../../../../utils/getRoleName'

interface IStatusBar {
  startEndDates: [Moment, Moment]
  jobPlanData: IJobPlanData
  canEditPlan: boolean
  orderAjusts: object
  isSomeAdjustPresent: Function
  setOrderAjusts: Function
  actions: IButton[]
  blockNewChain: boolean
  setBlockNewChain: Function
  disableNewChain: boolean
}

function StatusBar ({
  startEndDates,
  jobPlanData,
  canEditPlan,
  orderAjusts,
  isSomeAdjustPresent,
  setOrderAjusts,
  actions,
  blockNewChain,
  setBlockNewChain,
  disableNewChain
}: IStatusBar) {
  const { params } = useParams<any>()
  const id = estimateJobParams(params).id
  const state = useSelector((state: any) => state.estimateReducer)
  const editMode = useSelector<any>(state => state.jobPlan.editMode) as boolean
  const selectedItem = useSelector<any>(state => state.jobPlan.selectedItem) as ISelectedJobPlanItem
  const user = useSelector((state: any) => state.userReducer.user);
  const dispatch = useDispatch<any>()

  const statusText = useMemo(() => {
    const estimateStatuses = state.modeJobplan as IStatus[]
    if (!estimateStatuses) {
      return ''
    }
    const status = estimateStatuses.filter(item => item.currentStateCode === jobPlanData.status)?.[0]
    if (status) {
      return status.currentStateText
    }
  }, [jobPlanData.status, state.modeJobplan])

  const handleClickAction = useCallback((actionCode: number) => (e: React.MouseEvent<HTMLElement>) => {
    if (actionCode) {
      dispatch(updateEntityStatus({
        entityId: id,
        newState: actionCode
      }, false, actionCode === estimateCodes.jobPlanDone ? {} : orderAjusts))
      setOrderAjusts({})
      localStorage.removeItem('orderAjusts')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, orderAjusts])

  const handleStartEdit = useCallback(() => {
    dispatch(startEditJobPlan())
  }, [dispatch])

  const handleCancelEdit = useCallback(() => {
    setBlockNewChain(false)
    dispatch(cancelEditJobPlan())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleSaveDates = useCallback(() => {
    setBlockNewChain(false)
    dispatch(saveNewDates())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleCreateChain = useCallback(() => {
    if (!selectedItem || selectedItem.entityType !== 'order') {
      baseNotification('Выберите наряд с которого будет начинаться новая цепочка', '', 'danger')
      return
    }
    if (selectedItem.orderPos === 0) {
      baseNotification('Новая цепочка не может начинаться с первого наряда', '', 'danger')
      return
    }

    dispatch(openCreateChainWindow())
  }, [selectedItem, dispatch])

  const getButtonByType = (type: string) =>
    actions.filter(actionItem => actionItem.type === type)[0]

  const saveBtn = getButtonByType('saveBtn')
  const exitBtn = getButtonByType('exitBtn')
  const dateChangeBtn = getButtonByType('dateChangeBtn')
  const newChainBtn = getButtonByType('newChainBtn')

  const isButtonDisabled = (actionItem: IButton): boolean => {
    if (actionItem.type !== 'sendAjustsBtn') return false
    return !isSomeAdjustPresent()
  }

  const startDateBoundProblem = moment(jobPlanData.workStartPlan).isAfter(startEndDates[0])
  const endDateBoundProblem = moment(jobPlanData.workEndPlan).isBefore(startEndDates[1])

  return (
    <div className={styles.statusBar}>
      <div>{statusText}</div>
      <div>
        {(actions.filter(actionItem => !actionItem.type || actionItem.type === 'sendAjustsBtn').map(actionItem => (
          <button
              key={actionItem.statusCode}
              className="bttn__small"
              type="button"
              title={actionItem.title}
              disabled={isButtonDisabled(actionItem)}
              onClick={handleClickAction(actionItem.statusCode ?? 0)}
          >
            {actionItem.title}
          </button>
        )))}

        <div></div>

        {!editMode && (
          <a className="link-light" href="/_" title="Открыть страницу внесения изменений">История…</a>
        )}

        {canEditPlan && editMode && newChainBtn && (
          <button
            className="bttn__small"
            name="chain__new"
            type="button"
            title="Создание Цепочки, начинающейся с выделенного Наряда"
            onClick={handleCreateChain}
            disabled={blockNewChain || disableNewChain}
          >
            {newChainBtn.title}
          </button>
        )}
      </div>

      {/* Сумма Разбивки. */}
      <div>
        { 
          user.role === ROLECODES.CONTRACTOR &&
            <div style={{display: 'flex', fontSize: '16px', fontWeight: 'bold', color: 'white', justifyContent: 'flex-end'}}>
              {formatPrice(jobPlanData?.cost)}
            </div>
        }
        <div>
          <span style={{justifySelf: "end", fontFamily: 'thin', fontSize: "12px", color: 'white', marginRight: '4px'}} className="symbol__colon--after" title="Стоимость сопровождения договора СМР">СМР</span>
          <span style={{fontSize: '12px', justifySelf: 'end', color: 'white'}}>{jobPlanData?.signedEstimatesCost}</span>
        </div>
      </div>
      {/* Дата начала первого Наряда. */}
      <div>{parseDateString(startEndDates[0])}</div>
      <div
        className={startDateBoundProblem ? styles.boundProblem : ''}
        title='Старт работ раньше запланированного срока'>
          {startDateBoundProblem ? '!' : ''}
      </div>
      {/* Кнопки выводятся в статусах «Разбивка утверждена» и «В работе». */}
      <div>

        {/* Выводится в режиме просмотра. */}
        {canEditPlan && !editMode && (jobPlanData.status === estimateCodes.jobPlanAccepted) && dateChangeBtn && (
          <button
            className="bttn__small"
            type="button"
            title="Войти в режим изменения дат"
            onClick={handleStartEdit}
          >
            {dateChangeBtn.title}
          </button>
        )}
        {canEditPlan && editMode && (
          <>
            {/* Выводится в режиме редактирования. */}
            { saveBtn && <button
              className="bttn__small"
              type="button"
              title={saveBtn.title}
              onClick={handleSaveDates}
            >
              {saveBtn.title}
            </button>}

            {/* Выводится в режиме редактирования. */}
            { exitBtn && <button
              className="bttn__small"
              type="button"
              title="Выйти из режима редактирования"
              onClick={handleCancelEdit}
            >
              {exitBtn.title}
            </button>}
          </>
        )}
      </div>
      <div
        className={endDateBoundProblem ? styles.boundProblem : ''}
        title='Окончание работ позже запланированного срока'>
          {endDateBoundProblem ? '!' : ''}
      </div>
      {/* Дата окончания последнего Наряда. */}
      <div>{parseDateString(startEndDates[1])}</div>
    </div>
  )
}

export default StatusBar
