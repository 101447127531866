let backendAddress = window._env_.BACKEND_ADDRESS
if (!backendAddress.endsWith('/')) {
  backendAddress = `${backendAddress}/`
}

const config = {
  BACKEND_ADDRESS: backendAddress
}

export default config
