import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useDebounced } from '../../hooks/useDebounced'
import { setDescriptionItem } from '../../redux/estimate/actionEstimate'
import style from './listFiles.module.scss'

const TextArea = ({ desc, id }: any) => {
  const [description, setDescription] = useState<string>(desc)
  const dispatch = useDispatch<any>()
  const debounced = useDebounced(description, 600)

  useEffect(() => {
    const result = {
      description,
      id
    }
    if (debounced) {
      dispatch(setDescriptionItem(result))
    }
  }, [debounced, dispatch, description, id])

  return (
      <textarea onChange={(e) => { setDescription(e.target.value) }} className={style.noteArea}
        placeholder="(указание необязательно)">
        {description}
      </textarea>
  )
}

export default TextArea
