import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getJobPlan } from '../../redux/jobPlan/jobPlanActions';
import { getStatusesJobplan } from "../../redux/estimate/actionEstimate";
import { IJobPlanState } from '../../redux/jobPlan/types';
import JobPlan from '../../components/JobPlan/JobPlan';
import estimateJobParams from '../estimateJob-page/estimateJobParams';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';

export default function JobPlanPage() {
  const {params} = useParams<any>();
  const dispatch = useDispatch<any>();
  const jobPlan = useSelector<any>(state => state.jobPlan) as IJobPlanState;
  const user = useSelector<any>(state => state.userReducer.user) as any;
  const id = estimateJobParams(params).id;

  useEffect(() => {
    dispatch(getStatusesJobplan());
  },[dispatch])

  useEffect(() => {
    if (id && user) {
      dispatch(getJobPlan(id));
    }
  }, [id, user, dispatch]);

  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.add("page__jobplan--card", "role__foreman", "status__jobplan--done");
  }

  if(!jobPlan.loading && (jobPlan.error || !jobPlan.data?.jobPlan?.status)) {
    return (
      <ErrorPanel errorMsg="Не удалось загрузить разбивку"/>
    );
  }

  return (
    <>
      <div>
        {jobPlan.data && (
          <JobPlan jobPlan={jobPlan.editMode ? jobPlan.dataCopy : jobPlan.data}/>
        )}
      </div>
    </>
  );
}