import { useDispatch } from 'react-redux'
import { type IListFilesTable, type Arr } from './types'
import TextArea from './TextArea'
import style from './listFiles.module.scss'
import { downloadFileItem } from '../../utils/downloadItem'
import { humanFileSize } from '../../utils/estimateUtils'
import { deleteEstimateDocument, setNewMarkFileRemove } from '../../redux/estimate/actionEstimate'

const ListFilesTable = ({ files, fileButtons, height, maxHeight }: IListFilesTable) => {
  const dispatch = useDispatch<any>()
  const edit = fileButtons.addFileBtn && fileButtons.chooseFileBtn && fileButtons.removeFileBtn

  const deleteItem = (document: any, estimateId: any) => {
    const documentId = document.id
    if (document.added) {
      dispatch(deleteEstimateDocument({ documentId, estimateId }))
    } else {
      dispatch(setNewMarkFileRemove(documentId))
    }
  }

  return (
        <div className={style.containerStyle} style={{ height: height ?? 'auto', maxHeight: maxHeight ?? 'auto' }}>
            {files && files.map((el: Arr) =>
                <div key={el.document.id}>
                <div className={style.fileArea}>
                    <div>
                        <span style={{ display: 'inline', marginRight: '6px', lineHeight: '24px' }} className="symbol__bull--before"></span>
                        <span>
                            <span className="link-normal" style={el.documentId ? {} : { pointerEvents: 'none' }}
                            onClick={async () => { await downloadFileItem(el.documentId, el.document.originalFileName as string) }}>
                                {`${el.document.originalFileName} (${humanFileSize(el.document.filesize)})`}
                            </span>
                        </span>
                    </div>
                    {fileButtons.removeFileBtn && el?.document && !el.document.fixed &&
                    <button onClick={() => { deleteItem(el.document, el.estimateId) }}
                            className="bttn__small"
                            type="button">
                        Удалить
                    </button>}
                </div>
                {edit && el.document.added
                  ? <TextArea desc={el.document.description} id={el.document.id}/>
                  : <span className={style.noteLabel}>
                        {el.document?.description}
                    </span>
                }
                </div>
            )}
        </div>
  )
}

export default ListFilesTable
