import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { createEstimate } from '../../../redux/actions/actionContractor'
import { useDispatch } from 'react-redux'
import { ModalDataEstimate } from './ModalDataEstimate'
import { parseDate, getStartEndOfMonth } from '../../../utils/estimateUtils'
import { useHistory } from 'react-router-dom'

const initialObjectData = {
  estimateName: '',
  estimateTypeId: '',
  note: '',
  workStartPlan: '',
  workEndPlan: ''
}

const objectValid = {
  estimateName: false,
  estimateTypeId: false,
  workStartPlan: false,
  workEndPlan: false,
  workData: false
}

export const ModalEstimate = ({ setModalBuildingId, types, buildingId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [data, setData] = useState(initialObjectData)
  const [valid, setValid] = useState(objectValid)
  const [inProgress, setInProgress] = useState(false)
  const [selectedTypeValue, setSelectedTypeValue] = useState({})
  const element = document.getElementById('root')
  if (element !== null) {
    element.classList.value = 'page__building--list role__contractor modal__open--creating-estimate'
  }

  useEffect(() => {
    const { workStartPlan, workEndPlan } = data
    if (!workStartPlan && !workEndPlan) {
      const defaultWorkPlan = getStartEndOfMonth()
      setData({ ...data, workStartPlan: defaultWorkPlan.start, workEndPlan: defaultWorkPlan.end })
      setValid({ ...valid, workStartPlan: true, workEndPlan: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const { workStartPlan, workEndPlan } = data
    if (workEndPlan && workStartPlan) {
      setValid({
        ...valid,
        workData: moment(parseDate(workEndPlan)).isAfter(parseDate(workStartPlan), [
          'day',
          'month',
          'year'
        ])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.workStartPlan, data.workEndPlan])

  useEffect(() => {
    setData({
      ...data,
      estimateTypeId: selectedTypeValue?.id
    })
    setValid({
      ...valid,
      estimateTypeId: !!selectedTypeValue?.id
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypeValue])

  const onChange = (e) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })

    const currErrors = { ...valid }
    switch (name) {
      case 'estimateName':
      case 'estimateTypeId':
        currErrors[name] = !!value.trim()
        break
      case 'workStartPlan':
      case 'workEndPlan':
        currErrors[name] = moment(value, 'DD.MM.YYYY', true).isValid()
        break
      default:
        return
    }
    setValid(currErrors)
  }

  const submit = async () => {
    setInProgress(true)
    const createResult = await dispatch(createEstimate({ ...data, buildingId }))
    if (createResult.status === 200) {
      history.push(`/estimate-management/${createResult.data.id}`)
      setModalBuildingId('')
    }
    setInProgress(false)
  }

  const handleDisabledButton = () => {
    return !Object.keys(valid).every((item) => valid[item]) || inProgress
  }

  const closeFormHandle = () => {
    setData(initialObjectData)
    setSelectedTypeValue({})
    setModalBuildingId('')
  }

  if (!buildingId) return null
  return ReactDOM.createPortal(
        <div id="modal__box">
            <div className="modal__back"/>
            <div className={'modal__window creating__estimate'}>

                <h3>Создание Сметы</h3>

                <div>
                    Все обязательные поля должны быть заполнены.
                </div>

                <form>
                    <ModalDataEstimate
                        selectedTypeValue={selectedTypeValue}
                        setSelectedTypeValue={setSelectedTypeValue}
                        className={'estimate'}
                        data={data}
                        onChange={onChange}
                        types={types}
                        submit={submit}
                        valid={valid}
                        handleDisabledButton={handleDisabledButton}
                        closeFormHandle={closeFormHandle}/>
                </form>
            </div>
        </div>,
        document.getElementById('root'))
}
