import styles from './ErrorFallback.module.scss'

export const ErrorFallback = (): JSX.Element => (
  <div className={styles.errorContainer}>
    <div className={styles.errorText}>
      {'\uD83D\uDE41'}
      Что-то пошло не так, попробуйте перезапустить страницу<br/>
      Мы уже отправили сообщение разработчикам о возникшей проблеме</div>
  </div>
)
